<template>
  <div class="panel">
    <h2>Students by HA Status</h2>
    <table>
    <tbody><tr>
      <th rowspan="1">Prospective</th>
      <th><a :href="'/ha-committee/status/?id=118&status=prospective'">All</a></th>
      <td>{{ prospective }}</td>
    </tr>
    <tr>
      <th rowspan="1">Inactive</th>
      <th><a :href="'/ha-committee/status/?id=118&status=inactive'">All</a></th>
      <td>{{ inactive }}</td>
    </tr>
    <tr>
      <th rowspan="1">Expired</th>
      <th><a :href="'/ha-committee/status/?id=118&status=expired'">All</a></th>
      <td>{{ expired }}</td>
    </tr>
    <tr>
      <th rowspan="1">One Document Submitted</th>
      <th><a :href="'/ha-committee/status/?id=118&status=one_document_submitted'">All</a></th>
      <td>{{ one_document_submitted }}</td>
    </tr>
    <tr>
      <th rowspan="2">Under Review</th>
      <th><a :href="'/ha-committee/under-review'">All</a></th>
      <td>{{ under_review }}</td>
    </tr>
    <tr>
      <th><a :href="'/ha-committee/status/?id=118&status=pending_committee_review'">Pending Housing Committee Review</a></th>
      <td>{{ pending_housing_committee_review }}</td>
    </tr>
    <tr>
      <th rowspan="1">Pending Notification</th>
      <th><a :href="'/ha-committee/status/?id=118&status=pending_notification'">All</a></th>
      <td>{{ pending_notification }}</td>
    </tr>
    <tr>
      <th rowspan="1">Approved</th>
      <th><a :href="'/ha-committee/status/?id=118&status=approved'">All</a></th>
      <td>{{ approved }}</td>
    </tr>
    <tr>
      <th rowspan="1">Denied</th>
      <th><a :href="'/ha-committee/status/?id=118&status=denied'">All</a></th>
      <td>{{ denied }}</td>
    </tr>
    <tr>
      <th rowspan="1">Requesting Appeal</th>
      <th><a :href="'/ha-committee/status/?id=118&status=under_appeal'">All</a></th>
      <td>{{ requesting_appeal }}</td>
    </tr>
    <tr>
      <th rowspan="1">Pending Appeal Review by Medical Director</th>
      <th><a :href="'/ha-committee/status/?id=118&status=pending_appeal_review'">All</a></th>
      <td>{{ pending_appeal_by_medical_director }}</td>
    </tr>
    <tr>
      <th rowspan="1">Expired in last 6 months</th>
      <th><a :href="'/ha-committee/expired-in-last-6-months'">All</a></th>
      <td>{{ expired_in_last_six_months }}</td>
    </tr>
    <tr>
      <th colspan="3"><a :href="'/ha-bulk-notify/'">Notify students of HA decisions</a></th>
    </tr>
    </tbody></table>
  </div>
</template>
<script>
  import axios from 'axios'

    export default {
        components: {
        },
        data() {
            return {
                prospective: 0,
                inactive: 0,
                expired: 0,
                one_document_submitted: 0,
                under_review: 0,
                pending_housing_committee_review: 0,
                pending_notification: 0,
                approved: 0,
                denied: 0,
                requesting_appeal: 0,
                pending_appeal_by_medical_director: 0,
                expired_in_last_six_months: 0
            }
        },
        methods: {
            load: function () {
                axios
                    .get('/api/student/ha-status-students/')
                    .then(response => {
                        this.prospective = response.data.prospective;
                        this.inactive = response.data.inactive;
                        this.expired = response.data.expired;
                        this.one_document_submitted = response.data.one_document_submitted;
                        this.under_review = response.data.under_review;
                        this.pending_housing_committee_review = response.data.pending_housing_committee_review;
                        this.pending_notification = response.data.pending_notification;
                        this.approved = response.data.approved;
                        this.denied = response.data.denied;
                        this.requesting_appeal = response.data.requesting_appeal;
                        this.pending_appeal_by_medical_director = response.data.pending_appeal_by_medical_director;
                        this.expired_in_last_six_months = response.data.expired_in_last_six_months;
                    })
                    .catch(error => {
                      // eslint-disable-next-line
                        console.log(error)
                    });
            }
        },
        mounted() {
            this.load()
        }
    }
</script>
<style scoped></style>