<template>
    <div>
        <td class="text-underline accommodation-label" :style="{'padding-left': 10* indent + 'px'}">{{ accommodationObj.name }}</td>
        <template v-if="accommodationObj.sub_categories.length">
            <template v-for="category in accommodationObj.sub_categories">
                <accommodations-edit-table :key="category.id" :accommodationObj="category" :user="user" :accommodationStatus="accommodationStatus" :showHeader="false" :indent="indent + 1"></accommodations-edit-table>
            </template>
        </template>
        <template v-else>
            <template v-for="accommodationType in accommodationObj.accommodation_types">
                <template v-if="accommodationType.student_accommodations.length">
                    <el-table :data="accommodationType.student_accommodations" style="width: 100%" :show-header="false" :key="accommodationType.id">
                        <el-table-column label="Accommodations" class-name="accommodation">
                            <template slot-scope="scope">
                                {{ scope.row.accommodation_type }}
                                <template v-if="scope.row.isNew">
                                    <br>
                                    <template v-if="scope.row.accommodation_type_options">
                                        {{ scope.row.requires_description_text }}:
                                        <el-select size="mini" v-model="scope.row.description" :class="{ 'error-field': scope.row.showCampusLockerError }">
                                            <el-option v-for="option in scope.row.accommodation_type_options" :key="option.id" :value="option.name" :label="option.name"></el-option>
                                        </el-select>
                                        <el-input v-if="scope.row.description == 'Other'" style="width:190px;" size="mini" v-model="scope.row.campus_locker_other" :class="{ 'error-field': scope.row.showCampusLockerOtherError }"></el-input>
                                    </template>
                                    <template v-else-if="scope.row.requires_student">
                                        <el-autocomplete v-if="scope.row.requires_student" style="width:250px;" :fetch-suggestions="querySearch" :trigger-on-focus="false" size="mini" @select="function(student) {scope.row.avoided_student_id = student.id}" v-model="scope.row.selected_student_name" :class="{ 'error-field': scope.row.showStudentError }"></el-autocomplete><br>
                                        (to add multiple students, add this accommodation multiple times)
                                    </template>
                                    <template v-else-if="scope.row.requires_computer_option">
                                        Select computer type (default is No Preference) - 
                                        <el-select style="width:125px;" size="mini" v-model="scope.row.computer_choice" :class="{ 'error-field': scope.row.showComputerChoiceError }">
                                            <el-option value="No Preference" label="No Preference"></el-option>
                                            <el-option value="PC" label="PC"></el-option>
                                            <el-option value="MAC" label="MAC"></el-option>
                                            <el-option value="Other" label="Other"></el-option>
                                        </el-select><br>
                                        <el-input v-if="scope.row.computer_choice == 'Other'" style="width:300px;" size="mini" v-model="scope.row.computer_choice_other" :class="{ 'error-field': scope.row.showComputerChoiceOtherError }"></el-input>
                                    </template>
                                    <template v-else-if="scope.row.show_font_options">
                                        <el-checkbox label="Font Size" @change="onFontSizeToggle(scope.row)"></el-checkbox><br>
                                        <el-form v-if="scope.row.showFontSizeOptions" label-width="75px" label-position="left" class="form-small">
                                            <el-form-item label="Font Size">
                                                <el-input size="mini" v-model="scope.row.font_size" style="width:55px;" :class="{ 'error-field': scope.row.showFontSizeError }"></el-input><br>
                                            </el-form-item>
                                            <el-form-item label="Font Type">
                                                <el-input size="mini" v-model="scope.row.font_type" style="width:300px;" :class="{ 'error-field': scope.row.showFontTypeError }"></el-input>
                                            </el-form-item>
                                        </el-form>
                                        <el-checkbox-group v-model="scope.row.font_options">
                                            <el-checkbox label="Bold"></el-checkbox><br>
                                            <el-checkbox label="Italics"></el-checkbox><br>
                                            <el-checkbox label="All Caps"></el-checkbox><br>
                                            <el-checkbox label="Double Spaced"></el-checkbox><br>
                                            <el-checkbox label="Other" @change="scope.row.showFontOther = !scope.row.showFontOther"></el-checkbox><br>
                                        </el-checkbox-group>
                                        <el-form v-if="scope.row.showFontOther" label-width="75px" label-position="left" class="form-small">
                                            <el-form-item label="Other">
                                                <el-input size="mini" v-model="scope.row.font_options_other" style="width:300px;" :class="{ 'error-field': scope.row.showFontOtherError }"></el-input>
                                            </el-form-item>
                                        </el-form>
                                    </template>
                                    <template v-else-if="scope.row.requires_description">
                                        {{ scope.row.requires_description_text ? scope.row.requires_description_text : "Please Specify" }}:
                                        <el-input size="mini" type="textarea" :rows="1" style="width:300px;" v-model="scope.row.description" :class="{ 'error-field': scope.row.showDescriptionError }"></el-input>
                                    </template>
                                    <template v-else>
                                        {{ scope.row.requires_description_text ? scope.row.requires_description_text : "Notes (optional)" }}:
                                        <el-input size="mini" type="textarea" :rows="1" style="width:300px;" v-model="scope.row.description" :class="{ 'error-field': scope.row.showDescriptionError }"></el-input>
                                    </template>
                                </template>
                                <template v-else>
                                    <template v-if="scope.row.description"> : {{ scope.row.description }}</template>
                                </template>
                                <template v-if="scope.row.requires_student">
                                    {{scope.row.avoided_student_preferred_first_name ? scope.row.avoided_student_preferred_first_name : scope.row.avoided_student_first_name}} {{ scope.row.avoided_student_last_name }} &lt;{{scope.row.avoided_student_uni}}&gt;
                                </template>
                                <template v-if="scope.row.requires_computer_option">
                                    {{ scope.row.computer_choice == "Other" ? "Other: "+scope.row.computer_choice_other : scope.row.computer_choice }}
                                </template>
                                <template v-if="scope.row.show_font_options">
                                    <template v-for="(font_option) in scope.row.font_options">

                                        <template v-if="font_option == 'Font Size'">
                                            <br :key="font_option + 'br'"/>Font Size: {{ scope.row.font_size }}; Font Type: {{scope.row.font_type}}
                                        </template>
                                        <template v-else-if="font_option == 'Other'">
                                            <br :key="font_option + 'br'"/>Other: {{scope.row.font_options_other}}
                                        </template>
                                        <template v-else>
                                            <br :key="font_option + 'br'"/>{{ font_option }}
                                        </template>
                                    </template>
                                </template>

                            </template>
                        </el-table-column>
                        <el-table-column width="45">
                            <template slot-scope="scope">
                                <template v-if="scope.row.isNew">
                                <!-- Essentially a hack - This v-model keeps the checkbox checked -->
                                    <el-checkbox v-model="alwaysTrue" @change="onRemoveAccommodation(scope.row, accommodationType)"></el-checkbox>
                                </template>
                                <template v-else>
                                    <img alt="Check" :src="check">
                                </template>
                            </template>
                        </el-table-column>
                        <el-table-column label="Status" width="225">
                            <template slot-scope="scope">
                                <template v-if="scope.row.isNew">
                                    <template v-if="accommodationStatus == 'recommended'">
                                        <el-select size="mini" style="width:100%;" v-model="scope.row.status">
                                            <el-option value="recommended_temporary" label="Recommended Temporary"></el-option>
                                            <el-option value="recommended_permanent" label="Recommended Permanent"></el-option>
                                        </el-select>
                                    </template>
                                    <template v-else>{{ scope.row.status_display }}</template>
                                </template>
                                <template v-else>{{ scope.row.status_display }}</template>
                            </template>
                        </el-table-column>
                        <el-table-column label="Last changed" width="150">
                            <template slot-scope="scope">
                                {{ scope.row.last_updated_at | formatDateMDY }} {{ scope.row.latest_change_by_identifier }}
                            </template>
                        </el-table-column>
                        <el-table-column label="Comment" width="250">
                            <template slot-scope="scope">
                                <el-input size="mini" type="textarea" :rows="1" v-model="scope.row.comment"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column label="Add" width="51">
                            <a @click="onCreateAccommodation(accommodationType)">[+]</a>
                        </el-table-column>
                    </el-table>
                </template>
                <template v-else>
                    <el-table :data="[accommodationType]" style="width: 100%" :show-header="false" :key="accommodationType.id">
                        <el-table-column prop="name" label="Accommodations" class-name="accommodation"></el-table-column>
                        <el-table-column width="45">
                            <el-checkbox @change="onCreateAccommodation(accommodationType)"></el-checkbox>
                        </el-table-column>
                        <el-table-column label="Status" width="225"></el-table-column>
                        <el-table-column label="Last changed" width="150"></el-table-column>
                        <el-table-column label="Comment" width="250"></el-table-column>
                        <el-table-column label="Add" width="51"></el-table-column>
                    </el-table>
                </template>
            </template>
        </template>
    </div>
</template>
<script>
    import check from '../../../assets/img/check.png'
    import moment from 'moment'
    import axios from 'axios'

    export default {
        components: {
        },
        data() {
            return {
                check: check,
                alwaysTrue: true //this is essentially a hack to keep a checkbox above checked
            }
        },
        props: ['accommodationObj', 'showHeader', 'indent', 'accommodationStatus', 'user'],
        name: 'accommodations-edit-table',
        methods: {
            onCreateAccommodation: function(accommodationType){
                var newAccommodationObj = {};
                newAccommodationObj.accommodation_type_id = accommodationType.id;
                newAccommodationObj.accommodation_type = accommodationType.name;
                newAccommodationObj.status = this.accommodationStatus == 'recommended' ? 'recommended_temporary' : this.accommodationStatus;
                newAccommodationObj.latest_change_by_identifier = this.user;
                newAccommodationObj.last_updated_at = moment(Date.now()).format("M/D/YYYY");
                newAccommodationObj.requires_description = accommodationType.requires_description;
                newAccommodationObj.requires_description_text = accommodationType.requires_description_text;
                newAccommodationObj.requires_student = accommodationType.requires_student;
                newAccommodationObj.requires_computer_option = accommodationType.requires_computer_option;
                newAccommodationObj.show_font_options = accommodationType.show_font_options;
                newAccommodationObj.accommodation_type_options = accommodationType.accommodation_type_options;
                newAccommodationObj.isNew = true;

                if(accommodationType.requires_computer_option){
                    newAccommodationObj.computer_choice = "No Preference";
                } else if(accommodationType.show_font_options) {
                    newAccommodationObj.font_options = [];
                }

                if(this.accommodationStatus == 'requested'){
                    newAccommodationObj.status_display = "Requested";
                } else if(this.accommodationStatus == 'supplemental'){
                    newAccommodationObj.status_display = "Supplemental";
                }

                accommodationType.student_accommodations.push(newAccommodationObj);
            },
            onRemoveAccommodation: function(accommodation, accommodationType){
                var index = accommodationType.student_accommodations.indexOf(accommodation);
                if(index !== -1){
                    accommodationType.student_accommodations.splice(index, 1);
                }
                this.alwaysTrue = true;
            },
            querySearch: function(queryString, cb) {
                 axios.get("/api/student/search/?query=" + queryString)
                    .then(response => {
                       cb(response.data.rows);
                    });
            },
            onFontSizeToggle: function(row){
                row.showFontSizeOptions = !row.showFontSizeOptions;

                if(row.showFontSizeOptions){
                    row.font_options.unshift("Font Size");
                } else {
                    var index = row.font_options.indexOf("Font Size");
                    if(index != -1 ){
                        row.font_options.unshift();
                    }
                }
            }
        },
        mounted() {
        },
        beforeCreate: function () {
            this.$options.components.accommodationsReviewTable = require('./AccommodationsEditTable.vue').default
        }
    }
</script>
<style scoped>
    .accommodation-label{
        font-size: 12.6px;
        border:0px;
    }
    .el-table td.accommodation{
        padding-left: 2em;
    }
    .el-table td {
        border-left:0px;
        border-right:0px;
        border-top:0px;
        border-bottom-color:#CEC1D6;
    }
    .el-table table {
        border-right:0px;
    }
    .el-table::before{
        background-color:#CEC1D6;
    }
    .form-small {
        padding: 10px 0px 10px 10px;
    }
    #content .form-small .el-form-item {
        margin: 0px;
    }
    #content .form-small .el-form-item .el-form-item__label {
        font-size: inherit;
        height: 30px;
        line-height: 30px;
    }
    #content .form-small .el-form-item .el-form-item__content {
        font-size: inherit;
        height: 30px;
        line-height: 30px;
    }
    #content .form-small .el-form-item .el-form-item__content .el-input {
        height:30px;
        line-height:30px;
    }
</style>