<template>
  <div>
    <h3>{{ title }}</h3>
    <el-table :default-sort="{prop: 'date', order: 'descending'}" :data="documents">
      <el-table-column prop="name" label="Name" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
      <el-table-column prop="date" label="Date" width="150" sortable :sort-orders="['ascending', 'descending']">
        <template slot-scope="scope">
          {{scope.row.date | formatDateMDY}}
        </template>
      </el-table-column>
      <el-table-column prop="status" label="Status" width="150" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
      <el-table-column label="File" width="400" sortable :sort-orders="['ascending', 'descending']"><template slot-scope="scope"><a :href="'/api/document/download/' + scope.row.id" target="_blank">{{ scope.row.filename }}</a></template></el-table-column>
      <el-table-column label="Document Duplication Options" label-class-name="bold-title">
        <template slot-scope="scope">
          <a v-if="scope.row.can_duplicate_as_disability_doc" @click="onDuplicateAsDisabilityDoc(scope.row.id)">Duplicate as Disability document</a>
          <a v-if="scope.row.can_duplicate_as_housing_doc" @click="onDuplicateAsHousingDoc(scope.row.id)">Duplicate as Housing document</a>
        </template>
      </el-table-column>
      <el-table-column width="56"><template slot-scope="scope"><router-link :to="'/document/edit/' + scope.row.id + '?student_id=' + studentId">edit</router-link></template></el-table-column>
    </el-table>
  </div>
</template>
<script>
  import axios from 'axios'

  export default {
    data() {
      return {
      }
    },
    props: ['title', 'documents', 'studentId'],
    methods: {
      onEdit: function(document_id){
      },
      onDuplicateAsDisabilityDoc: function(document_id){
        axios.post("/api/document/copy-as-disability-document/", {  id: document_id })
          .then(response => {
            window.location.reload()
          });
      },
      onDuplicateAsHousingDoc: function(document_id){
        axios.post("/api/document/copy-as-housing-document/", {  id: document_id })
          .then(response => {
            window.location.reload()
          });
      }
    }
  }
</script>
<style scoped>
  .bold-title {
    font-weight: bold;
  }
</style>
