import { render, staticRenderFns } from "./ApplicationProcessReviews.vue?vue&type=template&id=2327035a&scoped=true&"
import script from "./ApplicationProcessReviews.vue?vue&type=script&lang=js&"
export * from "./ApplicationProcessReviews.vue?vue&type=script&lang=js&"
import style0 from "./ApplicationProcessReviews.vue?vue&type=style&index=0&id=2327035a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../../opt/jenkins_node_modules/doris/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2327035a",
  null
  
)

export default component.exports