<template>
  <div>
  <table class="report-table report editable boxed-body housing-accommodations-table">
    <thead>
      <tr>
        <th style="" class="" title=""><span>Accommodation Type</span></th>
        <th style="" class="" title=""><span>Add</span></th>
        <th style="" class="" title=""><span>Year</span></th>
        <th style="" class="" title=""><span>Expiration<br>(temp. accommodations only)</span></th>
        <th style="" class="" title=""><span>Status</span></th>
        <th style="" class="" title=""><span>Description</span></th>
      </tr>
    </thead>

    <tbody>
    <tr v-for="accommodation in accommodations" :key="accommodation.id">
      <td>{{ accommodation.accommodation_type }}</td>
      <td><el-checkbox v-model="accommodation.keep" size="mini"></el-checkbox> {{ accommodation.latest_change_date | formatDateMDY }} <template v-if="accommodation.latest_change_email">({{ accommodation.latest_change_email }})</template></td>
      <td>
        <el-select size="mini" v-model="accommodation.year_id">
          <el-option v-for="year in accommodation_years" :key="year.id" :label="year.name" :value="year.id"></el-option>
        </el-select>
      </td>
      <td>
        <el-date-picker size="mini" type="date" placeholder="Pick a day" v-model="accommodation.expiration"></el-date-picker>
      </td>
      <td>
        <el-select size="mini" v-model="accommodation.status">
          <el-option v-for="next_status in accommodation.next_statuses" :key="next_status.value" :value="next_status.value" :label="next_status.display"></el-option>

        </el-select>
      </td>
      <td><el-input type="textfield" size="mini" v-model="accommodation.description"></el-input></td>
    </tr>
    </tbody>
    <tbody>
      <tr v-for="at in accommodation_types" :key="at.id">
        <td>{{ at.name }}</td>
        <td>
          <el-checkbox v-model="at.add" size="mini"></el-checkbox>
        </td>
        <td>
          <el-select size="mini" v-model="at.year_id">
            <el-option v-for="year in accommodation_years" :key="year.id" :label="year.name" :value="year.id"></el-option>
          </el-select>
        </td>
        <td>
          <el-date-picker size="mini" type="date" placeholder="Pick a day" v-model="at.expiration"></el-date-picker>
        </td>
        <td>
          <el-select size="mini" v-model="at.status">
            <el-option label="Requested" value="requested" selected></el-option>
          </el-select>
        </td>
        <td>
          <el-input type="textfield" size="mini" v-model="at.description"></el-input>
        </td>
      </tr>
    </tbody>
  </table>
  <h4>Animal Accommodations</h4>
  <table class="report-table report editable boxed-body housing-accommodations-table">
    <thead>
      <tr>
        <th style="" class="" title=""><span>Accommodation</span></th>
        <th style="" class="" title=""><span>Add</span></th>
        <th style="" class="" title=""><span>Type of Animal</span></th>
        <th style="" class="" title=""><span>Animal Information</span></th>
        <th style="" class="" title=""><span>Status</span></th>
        <th style="" class="" title=""><span>Description</span></th>
      </tr>
    </thead>
    <tbody>
    <tr v-for="animal_accommodation in animal_accommodations" :key="animal_accommodation.id">
      <td>{{ animal_accommodation.accommodation_type }}</td>
      <td><el-checkbox v-model="animal_accommodation.keep" size="mini"></el-checkbox>  {{ animal_accommodation.latest_change_date | formatDateMDY }} <template v-if="animal_accommodation.latest_change_email">({{ animal_accommodation.latest_change_email }})</template></td>

      <td v-if="animal_accommodation.accommodation_type == 'Assistance Animal'">
        <el-select size="mini" v-model="animal_accommodation.assistance_animal_id">
          <el-option v-for="animal in assistance_animals" :key="animal.id" :label="animal.name" :value="animal.id"></el-option>
        </el-select>
        <div v-if="otherAssistanceAnimalId == animal_accommodation.assistance_animal_id" class="other-text-box">
          <label>Other:</label>
          <div class="inline">
            <el-input type="textfield" size="mini" v-model="animal_accommodation.assistance_animal_other"></el-input>
          </div>
        </div>
      </td>
      <td v-if="animal_accommodation.accommodation_type == 'Service Animal'">
        <el-select size="mini" v-model="animal_accommodation.service_animal_id">
          <el-option v-for="animal in service_animals" :key="animal.id" :label="animal.name" :value="animal.id"></el-option>
        </el-select>
      </td>
      <td>
        <label>{{ animal_accommodation.accommodation_type == 'Service Animal' ? "Breed:" : "Description:" }}</label>
        <div class="inline">
          <el-input type="textfield" size="mini" v-model="animal_accommodation.animal_information"></el-input>
        </div>
      </td>
      <td>
        <el-select size="mini" v-model="animal_accommodation.status">
          <el-option v-for="next_status in animal_accommodation.next_statuses" :key="next_status.value" :value="next_status.value" :label="next_status.display"></el-option>
        </el-select>
      </td>
      <td>
        <el-input type="textfield" size="mini" v-model="animal_accommodation.description"></el-input>
      </td>
    </tr>
    </tbody>
    <tbody>
      <tr v-for="animal_at in animal_accommodation_types" :key="animal_at.id">
        <td>{{ animal_at.name }}</td>
        <td>
          <el-checkbox v-model="animal_at.add" size="mini"></el-checkbox>
        </td>
        <td v-if="animal_at.requires_assistance_animal">
          <el-select size="mini" v-model="animal_at.assistance_animal_id">
            <el-option v-for="animal in assistance_animals" :key="animal.id" :label="animal.name" :value="animal.id"></el-option>
          </el-select>
          <div v-if="otherAssistanceAnimalId == animal_at.assistance_animal_id" class="other-text-box">
            <label>Other:</label>
            <div class="inline">
              <el-input type="textfield" size="mini" v-model="animal_at.assistance_animal_other"></el-input>
            </div>
          </div>
        </td>
        <td v-if="animal_at.requires_service_animal">
          <el-select size="mini" v-model="animal_at.service_animal_id">
            <el-option v-for="animal in service_animals" :key="animal.id" :label="animal.name" :value="animal.id"></el-option>
          </el-select>
        </td>
        <td>
          <label>{{ animal_at.requires_service_animal ? "Breed:" : "Description:" }}</label>
          <div class="inline">
            <el-input type="textfield" size="mini" v-model="animal_at.animal_information"></el-input>
          </div>
        </td>
        <td>
          <el-select size="mini" v-model="animal_at.status">
            <el-option label="Requested" value="requested" selected></el-option>
          </el-select>
        </td>
        <td>
          <el-input type="textfield" size="mini" v-model="animal_at.description"></el-input>
        </td>
      </tr>
    </tbody>
  </table>
  <el-button type="primary" size="mini" @click="onSubmit()">Save All Changes</el-button>
  <el-button type="text" size="mini" @click="onCancel">Cancel</el-button>
  </div>
</template>
<script>
    import axios from 'axios'
    import moment from 'moment'

    import check from '../../assets/img/check.png'

    export default {
        data() {
            return {
                check: check,
                accommodations: [],
                animal_accommodations: [],
                accommodation_types: [],
                animal_accommodation_types: [],
                assistance_animals: [],
                service_animals: [],
                accommodation_years: [],
                otherAssistanceAnimalId: false
            }
        },
        props: ['id', 'isStudentRequest'],
        methods: {
            load: function () {
              axios
                .get('/api/student/housing-accommodations/' + this.id + '?IncludeNextStatuses=true')
                .then(response => {
                  this.accommodations = response.data.rows;
                })
                .catch(error => {
                  // eslint-disable-next-line
                  console.log(error)
                });

              axios
                .get('/api/student/animal-accommodations/' + this.id + '?IncludeNextStatuses=true')
                .then(response => {
                  response.data.rows.forEach(function(row){
                    if(row.assistance_animal){
                      if(row.assistance_animal === "Other"){
                        row.animal_type = "Other: " + row.assistance_animal_other;

                      } else {
                        row.animal_type = row.assistance_animal;
                      }
                    } else {
                      row.animal_type = row.service_animal;
                    }
                  });
                  this.animal_accommodations = response.data.rows;
                })
                .catch(error => {
                  // eslint-disable-next-line
                  console.log(error)
                });

              axios
                      .get('/api/simple/animal-accommodation-types/')
                      .then(response => {
                        response.data.rows.forEach(function(row){
                          row.add = false;
                          row.status = "requested";
                          row.description = "";
                          row.animal_information = "";
                          if(row.requires_service_animal){
                            row.service_animal_id = null;
                          } else {
                            row.assistance_animal_id = null;
                            row.assistance_animal_other = "";
                          }
                        });
                        this.animal_accommodation_types = response.data.rows;
                      })
                      .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                      });


              axios
                .get('/api/simple/housing-accommodation-years/')
                .then(response => {
                  this.accommodation_years = response.data.rows;
                  var currentYearId = this.accommodation_years.find(o => o.is_current === true).id;

                  axios
                          .get('/api/simple/housing-accommodation-types/')
                          .then(response => {
                            response.data.rows.forEach(function(row){
                              row.add = false;
                              row.year_id = currentYearId;
                              row.expiration = "";
                              row.status = "requested";
                              row.description = "";
                            });
                            this.accommodation_types = response.data.rows;
                          })
                          .catch(error => {
                            // eslint-disable-next-line
                            console.log(error)
                          });
                })
                .catch(error => {
                  // eslint-disable-next-line
                  console.log(error)
                });

              axios
                .get('/api/simple/assistance-animals/')
                .then(response => {
                  this.assistance_animals = response.data.rows;

                  response.data.rows.forEach(function(row){
                    if(row.name == "Other"){
                      this.otherAssistanceAnimalId = row.id;
                    }
                  }, this);
                })
                .catch(error => {
                  // eslint-disable-next-line
                  console.log(error)
                });

              axios
                .get('/api/simple/service-animals/')
                .then(response => {
                  this.service_animals = response.data.rows;
                })
                .catch(error => {
                        // eslint-disable-next-line
                  console.log(error);
                });
          },
          onSubmit: function() {
            var params = {};
            var accommodations_to_add = [];

            params["id"] = parseInt(this.id);

            this.accommodation_types.forEach(function(row){
              if (row.add) {
                var accommodation = {};
                accommodation["description"] = row.description;
                accommodation["housing_accommodation_type_id"] = row.id;
                accommodation["year_id"] = row.year_id;
                accommodation["is_student_request"] = this.isStudentRequest;
                if(row.expiration){
                  accommodation["expiration_date"] = moment(row.expiration).format("YYYY-MM-DD");
                }
                accommodations_to_add.push(accommodation);
              }
            }, this);

            this.animal_accommodation_types.forEach(function(row){
              if (row.add) {
                var accommodation = {};
                accommodation["description"] = row.description;
                accommodation["housing_accommodation_type_id"] = row.id;
                accommodation["is_student_request"] = this.isStudentRequest;
                accommodation["animal_information"] = row.animal_information;
                if(row.requires_service_animal){
                  accommodation["service_animal_id"] = row.service_animal_id;
                } else {
                  accommodation["assistance_animal_id"] = row.assistance_animal_id;
                  accommodation["assistance_animal_other"] = row.assistance_animal_other;
                }

                accommodations_to_add.push(accommodation);
              }
            }, this);

            var animal_accommodations_to_update = [];
            var accommodations_to_update = [];
            var accommodations_to_remove = [];

            this.accommodations.forEach(function(row){
              if(row.keep){
                accommodations_to_update.push(row);
              } else {
                accommodations_to_remove.push(row);
              }
            }, this);


            this.animal_accommodations.forEach(function(row){
              if(row.keep){
                animal_accommodations_to_update.push(row);
              } else {
                accommodations_to_remove.push(row);
              }
            }, this);

            params["accommodations_to_add"] = accommodations_to_add;
            params["accommodations_to_update"] = accommodations_to_update;
            params["animal_accommodations_to_update"] = animal_accommodations_to_update;
            params["accommodations_to_remove"] = accommodations_to_remove;

            axios
                .post('/api/student/housing-accommodations/', params)
                .then(response => {
                  this.$router.push({ path: '/student/ha-accommodations/' + this.id});
                })
                .catch(error => {
                  // eslint-disable-next-line
                  console.log(error);
                });
          },
          onCancel: function() {
            this.$router.push({ path: '/student/ha-accommodations/' + this.id});
          }
        },
        mounted() {
            this.load()
        }
    }
</script>
<style scoped>
    tbody {
      border-bottom: thin solid black;
    }

    label, .inline {
      display:inline-block;
    }

    .other-text-box {
      margin-top: 5px;
    }
</style>
