<template>
    <div>
        <h2>Current Term Summary</h2>
        <div v-if="currentTerm">
            Term: {{ currentTerm }}
        </div>
        <p v-if="!currentTermCourses.length">No classes on file</p>
        <div v-if="currentTermCourses.length > 0">
            <h3>Classes</h3>
            <el-table :data="currentTermCourses" class="current-term-courses-table">
                <el-table-column label="Day" width="95">
                    <template slot-scope="scope">
                        <template v-for="(day,index) in scope.row.days">
                            <tr v-if="scope.row.times.length > 1" :key="index">{{ day }}</tr>
                        </template>
                        <span v-if="scope.row.times.length <= 1">{{ renderDayArray(scope.row.days) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="" label="Time" width="170">
                    <template slot-scope="scope">
                        <tr v-for="(time,index) in scope.row.times" :key="index">{{ time.start_time }} - {{ time.end_time }}</tr>
                    </template>
                </el-table-column>
                <el-table-column prop="location" label="Location"></el-table-column>
                <el-table-column prop="course_identifier" label="Course ID"></el-table-column>
                <el-table-column prop="section_code" label="Section"></el-table-column>
                <el-table-column prop="name" label="Class Name" width="225"></el-table-column>
                <el-table-column label="Instructor" width="150"><template slot-scope="scope"><a :href="'/group-email/compose/?course_id=' + scope.row.id + '&regarding_id=' + id + '&regarding_class=Student'">{{ scope.row.instructor_first_name }} {{ scope.row.instructor_last_name }}</a></template></el-table-column>
                <el-table-column prop="grading" label="Grading"></el-table-column>
                <el-table-column label="Accessible"><template slot-scope="scope">{{ scope.row.accessible ? "Yes" : "No" }}</template></el-table-column>
            </el-table>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
            }
        },
        props: ['currentTerm', 'currentTermCourses', 'id'],
        methods: {
            renderDayArray(days){
                if(days.length > 2){
                    return days[0] + "-" + days[days.length - 1];
                }
                return days.join(" ");
            }
        }
    }
</script>
<style scoped>
    .current-term-courses-table {
        width: 1200px;
    }
</style>