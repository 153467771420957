<template>
  <div>
    <template v-if="showDetails">
      <template v-for="note in contactNotes">
        <table width="100%" :key="note.id">
          <thead>
          <tr>
            <th><img v-if="prop === 'student_name' && order ==='ascending'" alt="Sort-asc" :src="sortAsc"><img v-if="prop === 'student_name' && order ==='descending'" alt="Sort-desc" :src="sortDesc"><a @click="handleDetailSort('student_name')">Student Name</a></th>
            <th><img v-if="prop === 'subject' && order ==='ascending'" alt="Sort-asc" :src="sortAsc"><img v-if="prop === 'subject' && order ==='descending'" alt="Sort-desc" :src="sortDesc"><a @click="handleDetailSort('subject')">Subject/Heading</a></th>
            <th><img v-if="prop === 'contact_types' && order ==='ascending'" alt="Sort-asc" :src="sortAsc"><img v-if="prop === 'contact_types' && order ==='descending'" alt="Sort-desc" :src="sortDesc"><a @click="handleDetailSort('contact_types')">Type of Contact</a></th>
            <th><img v-if="prop === 'reasons' && order ==='ascending'" alt="Sort-asc" :src="sortAsc"><img v-if="prop === 'reasons' && order ==='descending'" alt="Sort-desc" :src="sortDesc"><a @click="handleDetailSort('reasons')">Reason for Contact</a></th>
            <th><img v-if="prop === 'author_name' && order ==='ascending'" alt="Sort-asc" :src="sortAsc"><img v-if="prop === 'author_name' && order ==='descending'" alt="Sort-desc" :src="sortDesc"><a @click="handleDetailSort('author_name')">ODS Staff</a></th>
            <th><img v-if="prop === 'status_display' && order ==='ascending'" alt="Sort-asc" :src="sortAsc"><img v-if="prop === 'status_display' && order ==='descending'" alt="Sort-desc" :src="sortDesc"><a @click="handleDetailSort('status')">Status</a></th>
            <th><img v-if="prop === 'created_at' && order ==='ascending'" alt="Sort-asc" :src="sortAsc"><img v-if="prop === 'created_at' && order ==='descending'" alt="Sort-desc" :src="sortDesc"><a @click="handleDetailSort('created_at')">Date of Contact</a></th>
            <th></th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td><router-link :to="'/student/profile/' + note.student_id">{{ note.student_name }}</router-link></td>
            <td>{{ note.subject }}</td>
            <td>{{ note.contact_types ? note.contact_types.join(", ") : null }}</td>
            <td>{{ note.reasons ? note.reasons.join(", ") : null }}</td>
            <td>{{ note.author_name }}</td>
            <td>{{ note.status_display }}</td>
            <td>{{ note.created_at | formatDateMDY }} {{ note.author_identifier }}</td>
            <td><router-link :to="'/contact-notes/show/'+note.id">show</router-link></td>
            <td><router-link :to="'/contact-notes/show-group/'+note.group_id" v-if="note.group_id != null">show group</router-link></td>
          </tr>
          <tr v-if="note.text"><td colspan="9"><span class="formatted-text">{{ note.text }}</span></td></tr>
          <tr v-if="note.asset1_file_name || note.asset2_file_name || note.asset3_file_name || note.asset4_file_name || note.asset5_file_name"><td colspan="9">File attachment(s): <span class="text-italic">
                                <template v-if="note.asset1_file_name"><a :href="'/api/contact-note/download-file?id=' + note.id + '&file_column=asset1_file_name'" target="_blank">{{ note.asset1_file_name }}</a></template>
                            <template v-if="note.asset2_file_name">, <a :href="'/api/contact-note/download-file?id=' + note.id + '&file_column=asset2_file_name'" target="_blank">{{ note.asset2_file_name }}</a></template>
                            <template v-if="note.asset3_file_name">, <a :href="'/api/contact-note/download-file?id=' + note.id + '&file_column=asset3_file_name'" target="_blank">{{ note.asset3_file_name }}</a></template>
                            <template v-if="note.asset4_file_name">, <a :href="'/api/contact-note/download-file?id=' + note.id + '&file_column=asset4_file_name'" target="_blank">{{ note.asset4_file_name }}</a></template>
                            <template v-if="note.asset5_file_name">, <a :href="'/api/contact-note/download-file?id=' + note.id + '&file_column=asset5_file_name'" target="_blank">{{ note.asset5_file_name }}</a></template>
                        </span>
          </td>
          </tr>
          <tr v-if="note.comment_text"><td colspan="9"><p><span class="formatted-text">{{ note.comment_text }}</span></p><p>-{{ note.comment_author_name }}, {{ note.comment_date | formatDateMDY }}</p></td></tr>
          </tbody>
          <tfoot><tr><td colspan="9"></td></tr></tfoot>
        </table>
      </template>
    </template>
    <template v-else>
      <el-table :default-sort="{prop: 'created_at', order: 'descending'}" :data="contactNotes" @sort-change="handleSort">
        <el-table-column prop="student_name" label="Student Name" sortable="custom" :sort-orders="['ascending', 'descending']" width="250"><template slot-scope="scope"><a :href="'/student/profile/' + scope.row.student_id">{{ scope.row.student_name }}</a></template></el-table-column>
        <el-table-column prop="subject" label="Subject/Heading" sortable="custom" :sort-orders="['ascending', 'descending']"></el-table-column>
        <el-table-column prop="author_name" label="ODS Staff" sortable="custom" :sort-orders="['ascending', 'descending']" width="130"></el-table-column>
        <el-table-column prop="status" label="Status" sortable="custom" :sort-orders="['ascending', 'descending']" width="125"><template slot-scope="scope">{{ scope.row.status_display }}</template></el-table-column>
        <el-table-column prop="created_at" label="Date of contact" sortable="custom" :sort-orders="['ascending', 'descending']" width="150"><template slot-scope="scope">{{ scope.row.created_at | formatDateMDY }} {{ scope.row.author_identifier }}</template></el-table-column>
        <el-table-column prop="num_files" label="Files" sortable="custom" :sort-orders="['ascending', 'descending']" width="78"><template slot-scope="scope">{{ scope.row.num_files > 0 ? scope.row.num_files : "None" }}</template></el-table-column>
        <el-table-column width="64">
          <template slot-scope="scope">
            <template v-if="scope.row.can_view_note">
              <router-link :to="'/contact-notes/show/'+scope.row.id">show</router-link>
            </template>
            <template v-else>
              Access Denied
            </template>
          </template>
        </el-table-column>
        <el-table-column width="100"><template slot-scope="scope"><a @click="onShowGroup(scope.row.group_id)" :href="'/contact-notes/show-group/'+scope.row.group_id" v-if="scope.row.group_id != null">show group</a></template></el-table-column>
      </el-table>
    </template>
  </div>
</template>
<script>
  import sortAsc from '../../assets/img/sort-asc.gif'
  import sortDesc from '../../assets/img/sort-desc.gif'

  export default {
    data() {
      return {
        prop: "created_at",
        order: "descending",
        sortAsc: sortAsc,
        sortDesc: sortDesc
      }
    },
    props: ['id', 'contactNotes', 'showDetails'],
    methods: {
      handleSort: function({column, prop, order}) {
        this.prop = prop;
        this.order = order;
        this.$emit('sortdata', {prop: prop, order: order});
      },
      handleDetailSort: function(prop){
        if(prop === this.prop){
          if(this.order === "descending"){
            this.order = "ascending";
          } else {
            this.order = "descending";
          }
        } else {
          this.prop = prop;
          this.order = "ascending";
        }
        this.$emit('sortdata', {prop: this.prop, order: this.order});
      }
    },
    mounted() {
    }
  }
</script>
<style scoped>
</style>
