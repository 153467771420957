<template>
  <div id="container">
    <core-header />
    <core-mainnav />
    <core-view />
    <core-footer />
    <template v-if="loggedIn">
      <core-timeout />
    </template>
    <core-refresh />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
  name: 'app',
  computed: {
    ...mapGetters('auth', ['name', 'isAdmin', 'loggedIn'])
  }
}
</script>

<style src="./assets/css/layout.css">
</style>
