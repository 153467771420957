<template>
    <div>
        <hr>
        <h2>File Naming</h2>
        <div class="file-naming">
            <ul>
                <li>Housing application<br>
                <em>{{ uni }}HAApp{{ date }}</em></li>
                <li>Housing disability documentation<br>
                <em>{{ uni }}HADisdoc{{ date }}</em></li>
                <li>Housing decision letter<br>
                <em>{{ uni }}HADecLett{{ date }}</em></li>
                <li>Housing appeal request<br>
                <em>{{ uni }}HAAppealReq{{ date }}</em></li>
                <li>HA appeal decision letter<br>
                <em>{{ uni }}HAAppealDecLett{{ date }}</em></li>
                <li>Application for Accommodations and Services<br>
                <em>{{ uni }}App{{ date }}</em></li>
                <li>Disability Documentation<br>
                <em>{{ uni }}Disdoc{{ date }}</em><br>
                Add b,c, d etc. for additional documentation<br>
                <em>{{ uni }}Disdoc{{ date }}B{{ uni }}Disdoc{{ date }}C</em></li>
                <li>Undergraduate Orientation Checklist<br>
                <em>{{ uni }}UgradOrient{{ date }}</em></li>
                <li>Graduate Orientation Checklist<br>
                <em>{{ uni }}GradOrient{{ date }}</em></li>
                <li>Accommodation Letter Request Form<br>
                <em>{{ uni }}ALRF{{ date }}</em></li>
                <li>Student Acknowledgement Form<br>
                <em>{{ uni }}StudentAckForm{{ date }}</em></li>
                <li>Release Form<br>
                <em>{{ uni }}ReleaseForm{{ date }}</em></li>
                <li>Request for Note-taking Service<br>
                <em>{{ uni }}NTRequest{{ date }}</em></li>
                <li>Request for Speech-to-Text Services<br>
                <em>{{ uni }}STTRequest{{ date }}</em></li>
                <li>Request for Sign Language Interpreting Services<br>
                <em>{{ uni }}InterpRequest{{ date }}</em></li>
            </ul>
        </div>
        <div class="file-naming">
            <ul>
                <li>Testing Accommodation Request Form<br>
                <em>{{ uni }}TARF{{ date }} {{ uni }}TARF{{ date }}B</em></li>
                <li>Note-taking Services Guidelines<br>
                <em>{{ uni }}NTGuide{{ date }}</em></li>
                <li>Sign Language Interpreting, Speech-to-Text Guidelines<br>
                <em>{{ uni }}InterpSTTGuide{{ date }}</em></li>
                <li>Testing Accommodations Agreement<br>
                <em>{{ uni }}TestAgree{{ date }}</em></li>
                <li>Alternate Format Guidelines<br>
                <em>{{ uni }}AltFormGuide{{ date }}</em></li>
                <li>Electronic Text Distribution Agreement<br>
                <em>{{ uni }}ElectronicAgree{{ date }}</em></li>
                <li>Equipment Loan Agreement<br>
                <em>{{ uni }}EquipAgree{{ date }}</em></li>
                <li>Contact Note (historical)<br>
                <em>{{ uni }}ContactNote{{ date }}</em></li>
                <li>Returned Signed Accommodation Letters<br>
                <em>UNICourseNameDate {{ uni }}Chemistry{{ date }}</em><br>
                Use the date the letter is returned. There will inevitably be variability in the naming of courses, but if there is a precedent already set, please follow it.</li>
                <li>Graduate Liaison Letter<br>
                <em>{{ uni }}GradLiasLett{{ date }}</em></li>
                <li>Accommodations Review Form<br>
                <em>{{ uni }}AccommReviewForm{{ date }}</em></li>
                <li>Letter of Previous Accommodations<br>
                <em>{{ uni }}LettPrevAccomm{{ date }}</em></li>
            </ul>
        </div>
        <div class="file-naming">
            <h2>Instructions for uploading a document</h2>
            <ul>
                <li>Ensure scanner is on and set to mode #2</li>
                <li>Place documents to be scanned face down and upside down into the feeder</li>
                <li>Click simplex for single-sided documents</li>
                <li>Click duplex for double-sided documents</li>
                <li>Click on image of scanned document with red border</li>
                <li>Click File, Save As</li>
                <li>Select the scanned documents folder you previously set up in your G Drive</li>
                <li>Enter a file name using the guide below</li>
                <li>Click Save</li>
                <li>In DORIS, go to the student's record</li>
                <li>Click on Documents tab in student record</li>
                <li>Select enter ODS application, new disability documentation, or another type of document</li>
                <li>Browse, find file in your folder</li>
                <li>Click Save Changes</li>
            </ul>
        </div>
    </div>
</template>
<script>
    import moment from 'moment'

    export default {
        data() {
            return {
                date: ''
            }
        },
        props: ['id', 'uni'],
        methods: {
            load: function(){
                this.date = moment(Date.now()).format("MM-DD-YY");
            }
        },
        mounted() {
            this.load();
        }
    }
</script>
<style scoped>
    .file-naming{
        float:left;
        width:30em;
    }
</style>