<template>
    <div>
        <h4>Remove enrolled course</h4>
        If a course is listed in SIS (not added manually) and the student is active in DORIS, removing the course will not be permanent: It will reappear within approximately one week.
        <el-form label-position="left">
            <el-form-item label="Course:" >
                <el-select size="small" v-model="courseId" class="course-select">
                    <template v-if="course.id">
                        <el-option v-for="course in courses" :key="course.id" :value="course.id" :label="course.class_name"></el-option>
                    </template>
                </el-select>
                <el-button size="mini" type="primary" @click="removeCourse">Remove Course</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>

    export default {
        data() {
            return {
                courseId: null
            }
        },
        props: ['id', 'courses'],
        methods: {
            load: function(){
                if(this.courses.length > 0){
                    this.courseId = this.courses[0].id;
                }
            },
            removeCourse: function() {
                // eslint-disable-next-line
                console.log("Remove Course", this.courseId);
            }
        },
        mounted() {
            this.load();
        }
    }
</script>
<style scoped>
    .course-select {
        width: 250px;
    }
</style>