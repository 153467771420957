<template>
<div v-if="showInstructions">
  <h4>Instructions</h4>
  <ul>
    <li v-for="(instruction, index) in instructions" :key="index"><div v-html="instruction"></div></li>
  </ul>
</div>
</template>
<script>
  import axios from 'axios'

  export default {
    data() {
      return {
        instructions: [],
        showInstructions: false
      }
    },
    props: ['id'],
    methods: {
      load: function () {
        axios
            .get('/api/student/ha-status-instructions/' + this.id)
            .then(response => {
              if(response.data.success){
                this.instructions = response.data.instructions;
                this.showInstructions = this.instructions.length > 0;
              } else {
                this.showInstructions = false;
              }
            })
            .catch(error => {
              // eslint-disable-next-line
                console.log(error)
            });
      }
    },
    mounted() {
        this.load()
    }
  }
</script>
<style scoped>
</style>