<template>
    <div v-if="history && history.length">
        <h2 v-if="showTitle">History</h2>
        <dl class="sections">
            <template v-for="item in history">
                <dt :key="item.id">{{ item.date | formatDateMDY }}</dt>
                <dd :key="item.id">{{ item.text }}</dd>
            </template>
        </dl>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        data () {
            return {
                history: []
            }
        },
        props: ['id', 'type', 'showTitle'],
        methods: {
            load: function(){
                axios.get("/api/simple/get-status-change-history/", {params: {id: parseInt(this.id), type: this.type}})
                    .then(response => {
                        this.history = response.data.rows;
                    });
            }
        },
        mounted(){
            this.load();
        }
    }
</script>

<style scoped>
</style>
