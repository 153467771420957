<template>
  <div>
  <h2>Records by ODS Status</h2>

  <table>
  <tbody>
    <tr>
      <th rowspan="2">Guests</th>
      <th><a :href="'/student/groups/?id=100&status=guest_registered_temporary'">Registered</a></th>
      <td>{{ guests_registered }}</td>
    </tr>
    <tr>
      <th><a :href="'/student/groups/?id=100&status=guest_expired'">Expired</a></th>
      <td>{{ guests_expired }}</td>
    </tr>
    <tr>
      <th rowspan="10">Student Applicants</th>
      <th><a :href="'/student/groups/?id=161'">Intensive Case Management</a></th>
      <td>{{ student_applicants_intensive }}</td>
    </tr>
    <tr>
    <th><a :href="'/student/groups/?id=100&status=applicant'">Applicant</a></th>
      <td>{{ student_applicants_applicant }}</td>
    </tr>
    <tr>
      <th><a :href="'/student/groups/?id=100&status=prospective'">Prospective</a></th>
      <td>{{ prospectives_all }}</td>
    </tr>
    <tr>
    <th><a :href="'/student/groups/?id=100&status=one_doc_submitted'">One Document Submitted</a></th>
      <td>{{ student_applicants_one_document_submitted }}</td>
    </tr>
    <tr>
    <th><a :href="'/student/groups/?id=100&status=pending_assignment'">Pending Assignment to Coordinator</a></th>
      <td>{{ student_applicants_pending_assignment_to_coordinator }}</td>
    </tr>
    <tr>
    <th><a :href="'/student/groups/?id=100&status=coordinator_review'">Coordinator Review</a></th>
      <td>{{ student_applicants_coordinator_review }}</td>
    </tr>
    <tr>
    <th><a :href="'/student/groups/?id=100&status=psychologist_review'">Psychologist Review</a></th>
      <td>{{ student_applicants_psychologist_review }}</td>
    </tr>
    <tr>
    <th><a :href="'/student/groups/?id=100&status=director_review'">Director Review</a></th>
      <td>{{ student_applicants_director_review }}</td>
    </tr>
    <tr>
    <th><a :href="'/student/groups/?id=100&status=pending_orientation'">Pending Orientation</a></th>
      <td>{{ student_applicants_pending_orientation }}</td>
    </tr>
    <tr>
    <th><a :href="'/student/groups/?id=100&status=denied'">Denied</a></th>
      <td>{{ student_applicants_denied }}</td>
    </tr>
    <tr>
    <th rowspan="5">Students Registered and enrolled this term</th>
      <th><a :href="'/student/groups/?id=162'">Intensive Case Management</a></th>
      <td>{{ students_registered_and_enrolled_all_intensive }}</td>
    </tr>
    <tr>
    <th><a :href="'/student/groups/?id=101'">All</a></th>
      <td>{{ students_registered_and_enrolled_all }}</td>
    </tr>
    <tr>
    <th><a :href="'/student/groups/?id=102'">Temporary</a></th>
      <td>{{ students_registered_and_enrolled_temporary }}</td>
    </tr>
    <tr>
    <th><a :href="'/student/groups/?id=103'">Tenure</a></th>
      <td>{{ students_registered_and_enrolled_tenure }}</td>
    </tr>
    <tr>
    <th><a :href="'/student/groups/?id=104'">Appeal pending</a></th>
      <td>{{ students_registered_and_enrolled_appeal_pending }}</td>
    </tr>
    <tr>
    <th rowspan="2">Registered with ODS but not enrolled this term</th>
      <th><a :href="'/student/groups/?id=163'">Intensive Case Management</a></th>
      <td>{{ registered_but_not_enrolled_all_intensive }}</td>
    </tr>
    <tr>
    <th><a :href="'/student/groups/?id=105'">All</a></th>
      <td>{{ registered_but_not_enrolled_all }}</td>
    </tr>
    <tr>
    <th rowspan="1">Inactive Students</th>
    <th><a :href="'/student/groups/?id=100&status=inactive'">Inactive Application</a></th>
      <td>{{ inactive_students_inactive_application }}</td>
    </tr>
    <tr>
    <th rowspan="5">Expired Students</th>
    <th><a :href="'/student/groups/?id=100&status=expired'">All</a></th>
      <td>{{ expired_students_all }}</td>
    </tr>
    <tr>
    <th><a :href="'/student/groups/?id=106'">Temporary registration expired</a></th>
      <td>{{ expired_students_temporary_registration_expired }}</td>
    </tr>
    <tr>
    <th><a :href="'/student/groups/?id=107'">Expired - graduated</a></th>
      <td>{{ expired_students_expired_graduated }}</td>
    </tr>
    <tr>
    <th><a :href="'/student/groups/?id=108'">Expired with ODS but currently enrolled in classes</a></th>
      <td>{{ expired_students_expired_but_currently_enrolled }}</td>
    </tr>
    <tr>
    <th><a :href="'/student/groups/?id=109'">Expired - other</a></th>
      <td>{{ expired_students_expired_other }}</td>
    </tr>
    <tr>
    <th rowspan="2">Housing Accommodations</th>
    <th><a :href="'/student/groups/?id=100&status=ha_only'">HA Only</a></th>
      <td>{{ housing_accommodations_ha_only }}</td>
    </tr>
    <tr>
    <th><a :href="'/student/groups/?id=110'">With HA Application</a></th>
      <td>{{ housing_accommodations_with_ha_application }}</td>
    </tr>
    <tr>
    <th rowspan="3">Faculty</th>
    <th><a :href="'/faculty/groups/?id=100'">All</a></th>
      <td>{{ faculty_all }}</td>
    </tr>
    <tr>
    <th><a :href="'/faculty/groups/?id=101&status=registered'">Registered</a></th>
      <td>{{ faculty_registered }}</td>
    </tr>
    <tr>
    <th><a :href="'/faculty/groups/?id=101&status=expired'">Expired</a></th>
      <td>{{ faculty_expired }}</td>
    </tr>
</tbody></table>
</div>
</template>
<script>
  import axios from 'axios'

    export default {
        components: {
        },
        data() {
            return {
                prospectives_all: 0,
                guests_registered: 0,
                guests_expired: 0,
                student_applicants_intensive: 0,
                student_applicants_applicant: 0,
                student_applicants_one_document_submitted: 0,
                student_applicants_pending_assignment_to_coordinator: 0,
                student_applicants_coordinator_review: 0,
                student_applicants_psychologist_review: 0,
                student_applicants_director_review: 0,
                student_applicants_pending_orientation: 0,
                student_applicants_denied: 0,
                students_registered_and_enrolled_all_intensive: 0,
                students_registered_and_enrolled_all: 0,
                students_registered_and_enrolled_temporary: 0,
                students_registered_and_enrolled_tenure: 0,
                students_registered_and_enrolled_appeal_pending: 0,
                registered_but_not_enrolled_all_intensive: 0,
                registered_but_not_enrolled_all: 0,
                inactive_students_inactive_application: 0,
                expired_students_all: 0,
                expired_students_temporary_registration_expired: 0,
                expired_students_expired_graduated: 0,
                expired_students_expired_but_currently_enrolled: 0,
                expired_students_expired_other: 0,
                housing_accommodations_ha_only: 0,
                housing_accommodations_with_ha_application: 0,
                faculty_all: 0,
                faculty_registered: 0,
                faculty_expired: 0
            }
        },
        methods: {
            load: function () {
                axios
                    .get('/api/student/records-by-ods-status/')
                    .then(response => {
                        this.prospectives_all = response.data.prospectives_all;
                        this.guests_registered = response.data.guests_registered;
                        this.guests_expired = response.data.guests_expired;
                        this.student_applicants_intensive = response.data.student_applicants_intensive;
                        this.student_applicants_applicant = response.data.student_applicants_applicant;
                        this.student_applicants_one_document_submitted = response.data.student_applicants_one_document_submitted;
                        this.student_applicants_pending_assignment_to_coordinator = response.data.student_applicants_pending_assignment_to_coordinator;
                        this.student_applicants_coordinator_review = response.data.student_applicants_coordinator_review;
                        this.student_applicants_psychologist_review = response.data.student_applicants_psychologist_review;
                        this.student_applicants_director_review = response.data.student_applicants_director_review;
                        this.student_applicants_pending_orientation = response.data.student_applicants_pending_orientation;
                        this.student_applicants_denied = response.data.student_applicants_denied;
                        this.students_registered_and_enrolled_all_intensive = response.data.students_registered_and_enrolled_all_intensive;
                        this.students_registered_and_enrolled_all = response.data.students_registered_and_enrolled_all;
                        this.students_registered_and_enrolled_temporary = response.data.students_registered_and_enrolled_temporary;
                        this.students_registered_and_enrolled_tenure = response.data.students_registered_and_enrolled_tenure;
                        this.students_registered_and_enrolled_appeal_pending = response.data.students_registered_and_enrolled_appeal_pending;
                        this.registered_but_not_enrolled_all_intensive = response.data.registered_but_not_enrolled_all_intensive;
                        this.registered_but_not_enrolled_all = response.data.registered_but_not_enrolled_all;
                        this.inactive_students_inactive_application = response.data.inactive_students_inactive_application;
                        this.expired_students_all = response.data.expired_students_all;
                        this.expired_students_temporary_registration_expired = response.data.expired_students_temporary_registration_expired;
                        this.expired_students_expired_graduated = response.data.expired_students_expired_graduated;
                        this.expired_students_expired_but_currently_enrolled = response.data.expired_students_expired_but_currently_enrolled;
                        this.expired_students_expired_other = response.data.expired_students_expired_other;
                        this.housing_accommodations_ha_only = response.data.housing_accommodations_ha_only;
                        this.housing_accommodations_with_ha_application = response.data.housing_accommodations_with_ha_application;
                        this.faculty_all = response.data.faculty_all;
                        this.faculty_registered = response.data.faculty_registered;
                        this.faculty_expired = response.data.faculty_expired;
                    })
                    .catch(error => {
                      // eslint-disable-next-line
                        console.log(error)
                    });
            }
        },
        mounted() {
            this.load()
        }
    }
</script>
<style scoped></style>