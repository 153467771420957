<template>
    <div>
        <h2>Withdrawal History</h2>
        <table v-if="withdrawals.length > 0" class="withdrawals-table">
            <tbody>
            <tr v-for="withdrawal in withdrawals" :key="withdrawal.id">
                <td>Withdrew {{ withdrawal.wd_date }} - {{ withdrawal.wd_reason }}<br>
                    {{ withdrawal.end_semester ? "Leave" : "Open-ended leave" }}  of absence beginning {{ withdrawal.begin_semester }} {{ withdrawal.end_semester ? "and ending " + withdrawal.end_semester : "" }}</td>
            </tr>
            </tbody></table>
        <p v-if="withdrawals.length == 0">None</p>
    </div>
</template>
<script>
    export default {
        data() {
            return {
            }
        },
        props: ['withdrawals'],
        methods: {
        }
    }
</script>
<style scoped>
    .withdrawals-table {
        width: 450px;
    }
</style>