<template>
  <div id="container">
    <div id="content">
      <home-students-requiring-action></home-students-requiring-action>
      <home-contact-notes-summary></home-contact-notes-summary>
      <br style="clear: both;"/>
      <home-weekend-session-alerts></home-weekend-session-alerts>
      <h2>My Tasks</h2>
      <a v-if="hasFutureTasks" :href="'/tasks/future-tasks'">View my future tasks</a>
      <home-messages-and-tasks title="Messages" api="messages"></home-messages-and-tasks>
      <home-messages-and-tasks title="Finished tasks" api="finished-tasks"></home-messages-and-tasks>
      <home-messages-and-tasks title="Tasks assigned to me" api="pending-tasks"></home-messages-and-tasks>
      <home-messages-and-tasks title="Tasks and messages I am copied on" api="cc-tasks"></home-messages-and-tasks>
      <home-messages-and-tasks title="My drafts" api="draft-tasks"></home-messages-and-tasks>

      <p>
        <a href="/tasks/edit">Create a new task</a>
        <br>
        <span class="help">(Use this link to assign tasks to others, or to send messages)</span>
      </p>
      <p>
        <a href="/tasks/future-tasks/edit">Create a new future task</a>
        <br>
        <span class="help">(Use this link to assign future tasks to others)</span>
      </p>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    metaInfo () {
      return {
        title: 'My Home Page'
      }
    },
    components: {
    },
    data () {
      return {
        hasFutureTasks: false
      }
    },
    methods: {
      load: function(){
        axios.get("/api/home/has-future-tasks")
          .then(response => {
            this.hasFutureTasks = response.data.has_future_tasks;
          });
      }
    },
    created () {
      this.load();
    }
  }
</script>

<style scoped>
</style>
