import Vue from 'vue'

import './components'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import { sync } from 'vuex-router-sync'
import './assets/js/filters.js'

import IdleVue from 'idle-vue'
const eventsHub = new Vue()
Vue.use(IdleVue, {
        eventEmitter: eventsHub,
        events: ['keydown', 'mousedown', 'touchstart'],
        idleTime: 5000  //5 seconds before considered 'idle'
})

import VueMeta from 'vue-meta'
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

import VueLodash from 'vue-lodash'
import lodash from 'lodash'
Vue.use(VueLodash, { lodash: lodash })

sync(store, router)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
  mounted: () => {
      document.dispatchEvent(new Event("x-app-rendered"));

      //this triggers the timeout timers on other tabs - in case you are closing the only active tab
    window.addEventListener('beforeunload', function(){
        localStorage.setItem('message',JSON.stringify({'command': 'idle'}));
        localStorage.removeItem('message');
    })
  },
  created: () => {
  }
}).$mount('#app')
