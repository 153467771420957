<template>
    <div>
        <h2>{{ title }}</h2>
        <table width="100%"><tbody>
            <tr>
                <th width="14%"></th>
                <th v-for="coordinator in coordinators" width="14%" :key="coordinator.id">{{ coordinator.coordinator_name }}</th>
            </tr>
            <tr v-for="row in data" :key="row.id">
                <th>{{ row.title }}</th>
                <td v-for="coordinator in coordinators" :key="coordinator.id">{{ row[String(coordinator.coordinator_id)] }}</td>
            </tr>
        </tbody></table>
    </div>
</template>
<script>
    export default {
        data() {
            return {
            }
        },
        props: ['data', 'coordinators', 'title'],
        methods: {
        },
        mounted() {
        }
    }
</script>
<style scoped>
    table td, table th {
        font-size:11.7px;
    }
</style>