<template>
    <el-table :default-sort="{prop: 'timestamp', order: 'descending'}" :data="history" style="width: 100%" class="table-top-align font-purple">
        <el-table-column prop="timestamp" label="Time" width="150" sortable :sort-orders="['ascending', 'descending']">
            <template slot-scope="scope">
                {{ scope.row.timestamp | formatDateTimeMDYhma }}
            </template>
        </el-table-column>
        <el-table-column label="Change summary" sortable :sort-orders="['ascending', 'descending']">
            <template slot-scope="scope">
                <p>{{ scope.row.timestamp | formatDateTimeMDYhma }} {{ scope.row.username }}{{ scope.row.label }}<template v-if="isStudentHistory"> on {{ scope.row.first_name }}{{ scope.row.middle_name ? " " + scope.row.middle_name : "" }}{{ scope.row.last_name ? " " + scope.row.last_name : "" }} &gt;{{ scope.row.uni }}&lt;</template></p>
                <table class="changes-table">
                   <tbody>
                        <tr>
                            <th>Field</th>
                            <th>Value</th>
                        </tr>
                        <tr v-for="(value, key) in scope.row.changes" :key="key">
                            <th>{{ key }}</th>
                            <td>{{ value }}</td>
                        </tr>
                    </tbody>
                </table>
            </template>
        </el-table-column>
    </el-table>
</template>
<script>

    export default {
        data() {
            return {
            }
        },
        props: ['history', 'isStudentHistory'],
        methods: {
        },
        mounted() {
        }
    }
</script>
<style scoped>
    .el-table table.changes-table {
        border-right: 0px;
        min-width: 300px; 
    }
</style>