<template>
<div>
  <h4>Housing Accommodations</h4>
  <el-table :data="accommodations" style="width: 100%">
    <el-table-column prop="year" label="Year" sortable :sort-orders="['ascending', 'descending']" width="150"></el-table-column>
    <el-table-column prop="accommodation_type" label="Accommodation Type" sortable :sort-orders="['ascending', 'descending']" ></el-table-column>
    <el-table-column prop="description" label="Description" sortable :sort-orders="['ascending', 'descending']" width="200"></el-table-column>
    <el-table-column prop="status_display" label="Status" sortable :sort-orders="['ascending', 'descending']" width="150"></el-table-column>
    <el-table-column prop="duration" label="Duration" sortable :sort-orders="['ascending', 'descending']" width="150"></el-table-column>
  </el-table>
  <h4>Animal Accommodations</h4>
  <el-table :data="animal_accommodations" style="width: 100%">
    <el-table-column prop="accommodation_type" label="Accommodation Type" sortable :sort-orders="['ascending', 'descending']" ></el-table-column>
    <el-table-column prop="animal_type" label="Type of Animal" sortable :sort-orders="['ascending', 'descending']" ></el-table-column>
    <el-table-column prop="animal_information" label="Animal Information" sortable :sort-orders="['ascending', 'descending']" ></el-table-column>
    <el-table-column prop="description" label="Description"></el-table-column>
    <el-table-column prop="status_display" label="Status" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
  </el-table>
</div>
</template>
<script>
  import axios from 'axios'

  export default {
    data() {
      return {
        accommodations: [],
        animal_accommodations: []
      }
    },
    props: ['id'],
    methods: {
      load: function () {
        axios
            .get('/api/student/housing-accommodations/' + this.id)
            .then(response => {
              this.accommodations = response.data.rows;
            })
            .catch(error => {
              // eslint-disable-next-line
                console.log(error)
            });
        axios
            .get('/api/student/animal-accommodations/' + this.id)
            .then(response => {
              response.data.rows.forEach(function(row) {
                if(row.assistance_animal){
                  if(row.assistance_animal === "Other"){
                    row.animal_type = "Other: " + row.assistance_animal_other;

                  } else {
                    row.animal_type = row.assistance_animal;
                  }
                } else {
                  row.animal_type = row.service_animal;
                }
              });
                this.animal_accommodations = response.data.rows;
            })
            .catch(error => {
              // eslint-disable-next-line
                console.log(error)
            });
      }
    },
    mounted() {
        this.load()
    }
  }
</script>
<style scoped>
</style>