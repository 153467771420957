<template>
  <div>
  <h2>Display Current Semester</h2>
    <table class="display current-semesters">
       <tbody><tr>
         <th>Current Term (updated {{ updatedFromSisAt }}):</th>
         <td>{{ currentTerm }}</td>
       </tr>
       <tr>
         <th>Standing:</th>
         <td>{{ standing }}</td>
       </tr>
       <tr>
         <th>School:</th>
         <td>{{ school }}</td>
       </tr>
       <tr>
         <th>Affiliation:</th>
         <td>{{ affiliation }}</td>
       </tr>
       <tr>
         <th>Enrollment:</th>
         <td>{{ enrollment }}</td>
       </tr>
       <tr>
         <th>Expected Graduation:</th>
         <td>{{ expectedGraduation }}</td>
       </tr>
       <tr>
         <th>Dean:</th>
         <td>{{ dean }}</td>
       </tr>
       <tr v-for="advisor in advisors" :key="advisor.id">
           <th><span v-if="advisors.indexOf(advisor) == 0">{{ advisors.length > 1 ? "Advisors:" : "Advisor:" }}</span></th>
           <td><a v-if="advisor.email" :href="'mailto:' + advisor.email">{{ advisor.first_name }}{{ advisor.middle_name ? " " + advisor.middle_name : "" }} {{ advisor.last_name }}</a>
               <span v-if="!advisor.email">{{ advisor.first_name }}{{ advisor.middle_name ? " " + advisor.middle_name : "" }} {{ advisor.last_name }}</span>
           </td>
       </tr>
       <tr>
         <th>Veteran Status:</th>
         <td>{{ veteranStatus ? veteranStatus : "No Veteran Status"}}</td>
       </tr>
     </tbody></table>
  </div>
</template>
<script>

    export default {
        data() {
            return {
            }
        },
        props: ["updatedFromSisAt", "currentTerm", "standing", "school", "affiliation", "enrollment", "expectedGraduation", "dean", "advisors", "veteranStatus"],
        methods: {
        }
    }
</script>
<style lang="scss" scoped>
    table.display.current-semester {
        width: 500px;

        th {
            white-space:nowrap;
        }
    }

</style>