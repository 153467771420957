<template>
    <div v-if="recommendations.length" class="notes-display">
        <div v-for="recommendation in recommendations" :key="recommendation.id">
            <template v-if="recommendation.isExpanded">&#8617;</template>
            <template v-else>&#8618;</template>
            <a @click="recommendation.isExpanded = !recommendation.isExpanded"><b>{{ recommendation.subject }} from {{ recommendation.note_date | formatDateMDY }} by {{ recommendation.author_name }}</b></a>
            <template v-if="recommendation.isExpanded">
                <div class="note">
                    <template v-if="!recommendation.showEditField">
                        <p class="formatted-text">{{ recommendation.body }}</p>
                        <template v-if="recommendation.can_be_edited"><a @click="recommendation.showEditField=true">edit</a></template>
                    </template>
                    <template v-else>
                        <el-input type="textarea" v-model="recommendation.body" :rows="6"></el-input>
                        <el-button size="mini" type="primary" class="bottom-margin" @click="saveRecommendationEdit(recommendation)">Save</el-button>
                    </template>
                </div>
            </template>
        </div>
    </div>        
</template>
<script>
  import axios from 'axios'

    export default {
        data() {
            return {
                recommendations: []
            }
        },
        props: ['id', 'limited'],
        methods: {
            load: function () {
                axios.get('/api/student/previous-recommendations/', {params:{id: this.id, limited: this.limited}})
                    .then(response => {
                        this.recommendations = response.data.rows;

                        for(var i = 0; i < this.recommendations.length; i++){
                            var recommendation = this.recommendations[i];

                            this.$set(recommendation, 'isExpanded', false);
                            this.$set(recommendation, 'showEditField', false);
                            recommendation.can_be_edited = true;
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            },
            saveRecommendationEdit: function(recommendation){
                this.$message.closeAll();

                axios.put("/api/accommodation/update-review-note/", {id: recommendation.id, body: recommendation.body})
                    .then(response => {
                        if (response.data.success) {
                            this.load();
                        } else {
                            this.$message({
                                showClose: true,
                                duration: 20000,
                                type: 'error',
                                message: response.data.message
                            });
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            }
        },
        mounted() {
            this.load();
        }
    }
</script>
<style scoped>
    .notes-display {
        border: 1px solid #CEC1D6;
        padding: 5px;
        text-align: left;
    }
    .note {
        border-top: 1px dashed;
        border-bottom: 1px dashed;
        margin: .5em 2em;
    }
    .bottom-margin {
        margin-bottom: 3px;
    }
</style>