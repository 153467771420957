<template>
<div class="coordinator">
  <div class="coordinator-table table-margin">
    <h2>Active Cases</h2>
    <table>
      <thead>
        <tr>
          <th>Status</th>
          <th class="counter">Counter</th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td>Prospective</td>
          <td><a :href="'/student/groups/?id=114&coordinator_id=' + this.coordinatorId + '&status=prospective'">{{ activeCases.prospective }}</a></td>
        </tr>
        <tr>
          <td>Director Review</td>
          <td><a :href="'/student/groups/?id=114&coordinator_id=' + this.coordinatorId + '&status=director_review'">{{ activeCases.director_review }}</a></td>
        </tr>
        <tr>
          <td>Psychologist Review</td>
          <td><a :href="'/student/groups/?id=114&coordinator_id=' + this.coordinatorId + '&status=psychologist_review'">{{ activeCases.psychologist_review }}</a></td>
        </tr>
        <tr>
          <td>Coordinator Review</td>
          <td><a :href="'/student/groups/?id=114&coordinator_id=' + this.coordinatorId + '&status=coordinator_review'">{{ activeCases.coordinator_review }}</a></td>
        </tr>
        <tr>
          <td>One Doc Submitted</td>
          <td><a :href="'/student/groups/?id=114&coordinator_id=' + this.coordinatorId + '&status=one_doc_submitted'">{{ activeCases.one_doc_submitted }}</a></td>
        </tr>
        <tr>
          <td>Pending Orientation</td>
          <td><a :href="'/student/groups/?id=114&coordinator_id=' + this.coordinatorId + '&status=pending_orientation'">{{ activeCases.pending_orientation }}</a></td>
        </tr>
        <tr>
          <td>Registered Temporary</td>
          <td><a :href="'/student/groups/?id=112&coordinator_id=' + this.coordinatorId">{{ activeCases.registered_temporary}}</a></td>
        </tr>
        <tr>
          <td>Registered Tenure</td>
          <td><a :href="'/student/groups/?id=113&coordinator_id=' + this.coordinatorId">{{ activeCases.registered_tenure }}</a></td>
        </tr>
        <tr>
          <td>Guest Pending Assignment</td>
          <td><a :href="'/student/groups/?id=114&coordinator_id=' + this.coordinatorId + '&status=guest_pending_assignment'">{{ activeCases.guest_pending_assignment }}</a></td>
        </tr>
        <tr>
          <td>Guest Coordinator Review</td>
          <td><a :href="'/student/groups/?id=114&coordinator_id=' + this.coordinatorId + '&status=guest_coordinator_review'">{{ activeCases.guest_coordinator_review }}</a></td>
        </tr>
        <tr>
          <td>Guest Director Review</td>
          <td><a :href="'/student/groups/?id=114&coordinator_id=' + this.coordinatorId + '&status=guest_director_review'">{{ activeCases.guest_director_review }}</a></td>
        </tr>
        <tr>
          <td>Guest Registered Tempoary</td>
          <td><a :href="'/student/groups/?id=114&coordinator_id=' + this.coordinatorId + '&status=guest_registered_temporary'">{{ activeCases.guest_registered_temporary }}</a></td>
        </tr>
        <tr>
          <td>Total</td>
          <td><a :href="'/student/groups/?id=111&coordinator_id=' + this.coordinatorId">{{ activeCases.total }}</a></td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="coordinator-table">
    <h2>Other Records</h2>
    <table>
      <thead>
        <tr>
          <th>Status</th>
          <th class="counter">Counter</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Temporary Expired but Enrolled This Term</td>
          <td><a :href="'/student/groups/?id=115&coordinator_id=' + this.coordinatorId">{{ otherRecords.enrolled_this_term }}</a></td>
        </tr>
        <tr>
          <td>Registered and Enrolled next Term</td>
          <td><a :href="'/student/groups/?id=116&coordinator_id=' + this.coordinatorId">{{ otherRecords.enrolled_next_term }}</a></td>
        </tr>
        <tr>
          <td>Inactive Students</td>
          <td><a :href="'/student/groups/?id=114&coordinator_id=' + this.coordinatorId + '&status=inactive'">{{ otherRecords.inactive }}</a></td>
        </tr>
        <tr>
          <td>Faculty</td>
          <td><a :href="'/faculty/groups/coordinator_' + this.coordinatorId">{{ otherRecords.faculty }}</a></td>
        </tr>
        <tr>
          <td>Expired in last 6 months</td>
          <td><a :href="'/student/groups/?id=117&coordinator_id=' + this.coordinatorId">{{ otherRecords.expired_in_last_6_months }}</a></td>
        </tr>
        <tr>
          <td>Intensive Case Management</td>
          <td><a :href="'/student/groups/?id=160&coordinator_id=' + this.coordinatorId">{{ otherRecords.intensive_case }}</a></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
</template>
<script>
  import axios from 'axios'

  export default {
    data() {
      return {
        activeCases: {},
        otherRecords: {}
      }
    },
    props: ['coordinatorId'],
    methods: {
      load: function () {
        axios
            .get('/api/student/coordinator-active-cases', {params: {coordinator_id: this.coordinatorId}})
            .then(response => {
              this.activeCases = response.data;
            })
            .catch(error => {
              // eslint-disable-next-line
                console.log(error)
            });

        axios
            .get('/api/student/coordinator-other-records', {params: {coordinator_id: this.coordinatorId}})
            .then(response => {
              this.otherRecords = response.data;
            })
            .catch(error => {
              // eslint-disable-next-line
                console.log(error)
            });
      }
    },
    mounted() {
        this.load()
    }
  }
</script>
<style lang="scss" scoped>
  .coordinator-table {
    float: left;
    width: 30%;
    margin-bottom: 13px;

    table {
      width:100%;
    }
  }
  .table-margin {
    margin-right: 100px;
  }
  .counter {
    width: 25%;
  }
</style>
