<template>
    <div>
        <h2>Activities</h2>
        <el-table v-if="activities.length > 0" :data="activities" class="activities-table">
            <el-table-column prop="name" label="Name" width="125"></el-table-column>
            <el-table-column prop="description" label="Description" width="200"></el-table-column>
            <el-table-column prop="begin_date" label="Begin Date" width="125"></el-table-column>
            <el-table-column prop="end_date" label="End Date" width="100"></el-table-column>
            <el-table-column prop="hours_per_week" label="Hours per Week" width="125"></el-table-column>
            <el-table-column prop="note_text" label="Notes"></el-table-column>
        </el-table>
        <p v-if="activities.length == 0">None</p>
    </div>
</template>
<script>
    export default {
        data() {
            return {
            }
        },
        props: ['activities'],
        methods: {
        }
    }
</script>
<style scoped>
    .activities-table {
        width: 850px;
    }
</style>