<template>
  <div>
  <table class="report-table report editable boxed-body housing-accommodations-table">
    <thead>
      <tr>
        <th style="" class="" title=""><span>Accommodation Type</span></th>
        <th style="" class="" title=""><span>Add</span></th>
        <th style="" class="" title=""><span>Year</span></th>
        <th style="" class="" title=""><span>Expiration<br>(temp. accommodations only)</span></th>
        <th style="" class="" title=""><span>Requested or<br>Recommended</span></th>
        <th style="" class="" title=""><span>Status</span></th>
        <th style="" class="" title=""><span>Description</span></th>
      </tr>
    </thead>

    <tbody>
    <tr v-for="accommodation in accommodations" :key="accommodation.id">
      <td>{{ accommodation.accommodation_type }}</td>
      <td><img alt="Check" :src="check"> {{ accommodation.latest_change_date | formatDateMDY }} <template v-if="accommodation.latest_change_email">({{ accommodation.latest_change_email }})</template></td>
      <td>{{ accommodation.year }}</td>
      <td>{{ accommodation.expiration | formatDateMDY }}</td>
      <td>{{ accommodation.is_student_request ? "Requested" : "Recommended" }}</td>
      <td>
        <el-select size="mini" v-model="accommodation.status">
          <el-option v-for="status in accommodation.next_statuses" :key="status.value" :value="status.value" :label="status.display"></el-option>
          <el-option label="UNDO: previous action" value="undo"></el-option>
        </el-select>
      </td>
      <td>{{ accommodation.description }}</td>
    </tr>
    </tbody>
    <tbody>
      <tr v-for="at in accommodation_types" :key="at.id">
        <td>{{ at.name }}</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    </tbody>
  </table>
  <h4>Animal Accommodations</h4>
  <table class="report-table report editable boxed-body housing-accommodations-table animal-table">
    <thead>
      <tr>
        <th style="" class="" title=""><span>Accommodation</span></th>
        <th style="" class="" title=""><span>Add</span></th>
        <th style="" class="" title=""><span>Type of Animal</span></th>
        <th style="" class="" title=""><span>Animal Information</span></th>
        <th style="" class="" title=""><span>Requested or<br>Recommended</span></th>
        <th style="" class="" title=""><span>Status</span></th>
        <th style="" class="" title=""><span>Description</span></th>
      </tr>
    </thead>
    <tbody>
    <tr v-for="animal_accommodation in animal_accommodations" :key="animal_accommodation.id">
      <td>{{ animal_accommodation.accommodation_type }}</td>
      <td><img alt="Check" :src="check"> {{ animal_accommodation.latestChangeDateFormatted }} <template v-if="animal_accommodation.latest_change_email">({{ animal_accommodation.latest_change_email }})</template></td>
      <td>{{ animal_accommodation.animal_type }}</td>
      <td>{{ animal_accommodation.animal_information }}</td>
      <td>{{ animal_accommodation.is_student_request ? "Requested" : "Recommended" }}</td>
      <td>
        <el-select size="mini" v-model="animal_accommodation.status">
          <el-option v-for="status in animal_accommodation.next_statuses" :key="status.value" :value="status.value" :label="status.display"></el-option>
          <el-option label="UNDO: previous action" value="undo"></el-option>
        </el-select>
      </td>
      <td>{{ animal_accommodation.description }}</td>
    </tr>
    </tbody>
    <tbody>
      <tr v-for="animal_at in animal_accommodation_types" :key="animal_at.id">
        <td>{{ animal_at.name }}</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    </tbody>
  </table>
  <el-button type="primary" size="mini" @click="onSubmit()">Save All Changes</el-button>
  <el-button type="text" size="mini" @click="onCancel">Cancel</el-button>
  </div>
</template>
<script>
    import axios from 'axios'

    import check from '../../assets/img/check.png'

    export default {
        data() {
            return {
                check: check,
                accommodations: [],
                animal_accommodations: [],
                accommodation_types: [],
                animal_accommodation_types: []
            }
        },
        props: ['id', 'isStudentRequest'],
        methods: {
            load: function () {
              axios
                .get('/api/student/housing-accommodations/' + this.id + '?IncludeNextStatuses=true')
                .then(response => {
                  response.data.rows.forEach(function(row){
                    row.next_statuses.unshift({value: row.status.toLowerCase(), display: row.status});
                  });
                  this.accommodations = response.data.rows;
                })
                .catch(error => {
                  // eslint-disable-next-line
                  console.log(error)
                });

              axios
                .get('/api/student/animal-accommodations/' + this.id + '?IncludeNextStatuses=true')
                .then(response => {
                  response.data.rows.forEach(function(row){
                    if(row.assistance_animal){
                      if(row.assistance_animal === "Other"){
                        row.animal_type = "Other: " + row.assistance_animal_other;

                      } else {
                        row.animal_type = row.assistance_animal;
                      }
                    } else {
                      row.animal_type = row.service_animal;
                    }
                    row.next_statuses.unshift({value: row.status.toLowerCase(), display: row.status});
                  });
                  this.animal_accommodations = response.data.rows;
                })
                .catch(error => {
                  // eslint-disable-next-line
                  console.log(error)
                });

              axios
                .get('/api/simple/housing-accommodation-types/')
                .then(response => {
                  response.data.rows.forEach(function(row){
                    row.add = false;
                    row.year_id = null;
                    row.expiration = "";
                    row.status = "requested";
                    row.description = "";
                  });
                  this.accommodation_types = response.data.rows;
                })
                .catch(error => {
                  // eslint-disable-next-line
                  console.log(error)
                });

              axios
                .get('/api/simple/animal-accommodation-types/')
                .then(response => {
                  response.data.rows.forEach(function(row){
                    row.add = false;
                    row.status = "requested";
                    row.description = "";
                    row.animal_id = null;
                    row.animal_information = "";
                  });
                  this.animal_accommodation_types = response.data.rows;
                })
                .catch(error => {
                  // eslint-disable-next-line
                  console.log(error)
                });
          },
          onSubmit: function() {
            var accommodations = [];

            this.accommodations.forEach(function(row){
                accommodations.push({id: row.id, status: row.status.toLowerCase()});
            });

            this.animal_accommodations.forEach(function(row){
                accommodations.push({id: row.id, status: row.status.toLowerCase()});
            });

            axios
                .put('/api/student/housing-accommodations/', {accommodations: accommodations})
                .then(response => {
                  this.$router.push({ path: '/student/ha-accommodations/' + this.id});
                })
                .catch(error => {
                  // eslint-disable-next-line
                  console.log(error);
                });
          },
          onCancel: function() {
            this.$router.push({ path: '/student/ha-accommodations/' + this.id});
          }
        },
        mounted() {
            this.load()
        }
    }
</script>
<style scoped>
    td {
      text-align: left;
    }

    tbody {
      border-bottom: thin solid black;
    }

    label, .inline {
      display:inline-block;
    }
</style>
