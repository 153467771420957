<template>
  <!--<el-container>-->
  <transition name="fade" mode="out-in" appear>
    <router-view />
  </transition>
  <!--</el-container>-->
</template>
<script>
  export default {
    metaInfo () {
      return {
        titleTemplate: '%s — Columbia University ODS'
      }
    }
  }
</script>
<style scoped>
  .fade-enter-active, .fade-leave-active {
    transition: opacity .2s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

</style>
