<template>
  <div>
    <!-- This table is deliberately empty. It renders the headers for the accommodations review table and does not display the "No Data" message -->
    <el-table :data="[{}]" style="width: 100%" :span-method="spanMethod">
      <el-table-column label="Accommodations" class-name="accommodation" :min-width="95.0"></el-table-column>
      <el-table-column :min-width="4.5"></el-table-column>
      <el-table-column label="Status" width="220"></el-table-column>
      <el-table-column label="Last changed" width="180" v-if="!canEditAccommodationDate"></el-table-column>
      <el-table-column label="Effective Date"  width="180" v-if="canEditAccommodationDate"></el-table-column>
      <el-table-column label="Comment" width="250"></el-table-column>
    </el-table>
    <template v-for="availableAccommodation in availableAccommodations">
      <accommodations-review-table :key="availableAccommodation.id" :accommodationObj="availableAccommodation" :canEditAccommodationDate="canEditAccommodationDate" :showHeader="availableAccommodations.indexOf(availableAccommodation) == 0" :indent="0"></accommodations-review-table>
    </template>
    <el-button type="primary" size="mini" @click="onSubmit()">Save changes</el-button> or <router-link :to="'/student/accommodations/' + this.id" >Cancel and return to student record</router-link>
  </div>
</template>
<script>
  import axios from 'axios'
  import accommodationsReviewTable from './accommodations-sub-components/AccommodationsReviewTable.vue'

  export default {
    components: {
      accommodationsReviewTable
    },
    data() {
      return {
      }
    },
    props: ['id', 'availableAccommodations', 'canEditAccommodationDate'],
    methods: {
      onSubmit: function() {
        var accommodations = [];
        for(var i = 0; i < this.availableAccommodations.length; i++){
          accommodations = accommodations.concat(this.submitAccommodations(this.availableAccommodations[i]));
        }

        axios.put('/api/accommodation/update-student-accommodations/', {accommodations: accommodations})
          .then(response => {
            this.$router.push({ path: '/student/accommodations/' + this.id});
          })
          .catch(error => {
            // eslint-disable-next-line
            console.log(error);
          });
      },
      submitAccommodations: function(accommodation){
        var accommodationsToReturn = [];
        if(accommodation.sub_categories.length){
          for(var i = 0; i < accommodation.sub_categories.length; i++){
            accommodationsToReturn = accommodationsToReturn.concat(this.submitAccommodations(accommodation.sub_categories[i]));
          }
        } else {
          for(var j = 0; j < accommodation.accommodation_types.length; j++){
            var accommodationType = accommodation.accommodation_types[j];
            for(var k = 0; k < accommodationType.student_accommodations.length; k++){
              var accommodationToSubmit = accommodationType.student_accommodations[k];
              var params = {};
              params.id = accommodationToSubmit.id;
              params.status = accommodationToSubmit.status;
              params.comment = accommodationToSubmit.comment;
              params.comment_id = accommodationToSubmit.comment_id;
              params.effective_date = accommodationToSubmit.last_updated_at;

              accommodationsToReturn.push(params);
            }
          }
        }
        return accommodationsToReturn;
      },
      spanMethod: function(){
        return [0,0];
      }
    },
    mounted() {
    }
  }
</script>
<style scoped>
</style>
