<template>
    <div style="position:relative" id="status_and_workflow">
        <div class="workflow_action">
            <h3>Coordinator action required</h3>
            <student-application-process-reviews :id="id" :limited=true ></student-application-process-reviews>
            <p>After you've reviewed this applicant's documentation, disabilities, and accommodations, please make a recommendation and click the button to move them on to Director Review</p>

            <el-form label-position="top">
                <el-form-item label="My recommendation is:">
                    <el-select v-model="recommendation" size="small" class="workflow-input-large">
                        <el-option v-for="item in recommendations" :key="item.value" :label="item.name" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item v-if="recommendation === 'short_term'">
                    Select an End Date: <el-date-picker size="small" type="date" placeholder="Pick a date" v-model="shortTermEndDate" value-format="yyyy-MM-dd"></el-date-picker>
                </el-form-item>
                <el-form-item v-if="recommendation === 'temporary_term'">
                    Select the End Term: 
                    <el-select size="small" v-model="endOfSemesterId" class="workflow-input-small">
                        <el-option v-for="semester in nextSixSemesters" :key="semester.id" :value="semester.id" :label="semester.name"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="Coordinator's summary of accommodations:">
                    <el-input v-model="summary" type="textarea" :rows="4"></el-input>
                </el-form-item>
            </el-form>

            <el-button type="primary" size="small" class="bottom-margin" @click="onSubmit('Save Draft')">Save as draft</el-button><br>
            <el-button v-if="psychologistReviewRequired" type="primary" size="small" @click="onSubmit('Send to Psychologist Review')">Send to Psychologist for review</el-button>
            <el-button type="primary" size="small" @click="onSubmit('Send to Director Review')">Send to Director for review</el-button>

            <p>If too much time has passed with no action, you may</p>

            <el-button type="primary" size="small" @click="showStatusChangeFields = true">Change status to Inactive</el-button>
            <el-form v-if="showStatusChangeFields">
                <el-form-item>
                    <span class="inactive-inputs-label">Reason: </span>
                    <el-select size="small" v-model="inactiveReason" class="workflow-input">
                        <el-option v-for="reason in inactiveReasons" :key="reason.name" :value="reason.name" :label="reason.name"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <span class="inactive-inputs-label">Comment: </span>
                    <el-input size="small" v-model="inactiveComment" class="workflow-input"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" size="small" @click="onChangeStatus('inactive')">Deactivate</el-button>
                </el-form-item>
            </el-form>
            <student-student-workflow-system-administrator-options :id="id" :studentName="studentName" v-on:refreshstudent="refreshStudent"></student-student-workflow-system-administrator-options>
        </div>
   </div>
</template>
<script>
    import axios from 'axios'
    //
    // import applicationProcessReviews from '../application_process_reviews.vue'
    // import systemAdministratorOptions from './SystemAdministratorOptions.vue'

    export default {
        data() {
            return {
                previousReviews: [],
                recommendation: "",
                recommendations: [],
                summary: "",
                nextSixSemesters: [],
                shortTermEndDate: null,
                endOfSemesterId: null,
                draftRecommendationId: null,
                showStatusChangeFields: false,
                inactiveReasons: [],
                inactiveReason: null,
                inactiveComment: null,
                psychologistReviewRequired: false
            }
        },
        props: ['id', 'isGuest', 'studentName'],
        methods: {
            load: function () {
                axios.get("/api/student/recommendation-values/", {params:{id: this.id}})
                    .then(response => {
                        this.recommendations = response.data.recommendation_values;
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });

                axios.get("/api/student/draft-recommendation/", {params:{id: this.id}})
                    .then(response => {
                        this.summary = response.data.body;
                        this.draftRecommendationId = response.data.id;
                        this.recommendation = response.data.recommendation;
                        if(this.recommendation == "short_term"){
                            this.shortTermEndDate = response.data.recommended_student_expiration_date;
                        } else if(this.recommendation == "temporary_term"){
                            this.endOfSemesterId = response.data.end_of_semester_id;
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });

                axios.get("/api/simple/next-six-semesters/")
                    .then(response => {
                        this.nextSixSemesters = response.data.rows;
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });

                axios.get("/api/simple/get-inactive-reasons/")
                    .then(response => {
                        this.inactiveReasons = response.data.rows;
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });

                axios.get("/api/student/workflow-information/", {params:{id: this.id}})
                    .then(response => {
                        this.psychologistReviewRequired = response.data.psychologistReviewRequired;
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            },
            onChangeStatus: function(newStatus) {
                this.$message.closeAll();

                if(this.isGuest){
                    newStatus = 'guest_' + newStatus;
                }

                axios.put("/api/student/change-status/", {
                        id: parseInt(this.id),
                        new_status: newStatus,
                        inactive_reason: this.inactiveReason,
                        inactive_comment: this.inactiveComment
                    })
                    .then(response => {
                        if (response.data.success) {
                            this.$emit('refreshstudent');
                        } else {
                            this.$message({
                                showClose: true,
                                duration: 20000,
                                type: 'error',
                                message: response.data.message
                            });
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            },
            onSubmit: function(action) {
                this.$message.closeAll();

                var params = {};
                params.id = parseInt(this.id);
                params.action = action;
                params.recommendation = this.recommendation;
                params.body = this.summary;
                params.draft_recommendation_id = this.draftRecommendationId;
                params.short_term_end_date = this.shortTermEndDate;
                params.end_of_semester_id = this.endOfSemesterId;

                axios.put("/api/student/coordinator-review-complete/", params)
                    .then(response => {
                        if (response.data.success) {
                          this.draftRecommendationId = response.data.note_id
                            this.$emit('refreshstudent');

                        } else {
                            this.$message({
                                showClose: true,
                                duration: 20000,
                                type: 'error',
                                message: response.data.message
                            });
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            },
            refreshStudent: function(){
                this.$emit('refreshstudent');
            }
        },
        mounted() {
            this.load()
        }
    }
</script>
<style scoped>
    .workflow-input {
        width:300px;
    }
    .workflow-input-large {
        width:325px;
    }
    .workflow-input-small {
        width:175px;
    }
    .workflow_action .el-form-item__label {
        padding:0px;
        line-height:130%;
    }
    .bottom-margin {
        margin-bottom:3px;
    }
</style>