<template>
    <div>
        <h1>{{ name }} - {{ uni }} | {{ status }}</h1>
        <ul class="page_sub">
            <li v-bind:class="{ selected: isSelected('profile') }"><a v-bind:href="'/faculty/profile/' + id">Profile</a></li>
            <li v-bind:class="{ selected: isSelected('academic') }"><a :href="'/faculty/notes/' + id">Contact Notes</a></li>
            <li v-bind:class="{ selected: isSelected('contact-notes') }"><a v-bind:href="'/faculty/disabilities/' + id">Disabilities</a></li>
            <li v-bind:class="{ selected: isSelected('files-attached-to-contact-notes') }"><a v-bind:href="'/faculty/documents/' + id">Documents</a></li>
            <li v-bind:class="{ selected: isSelected('documents') }"><a :href="'/faculty/accommodations/' + id">Accommodations</a></li>
            <li v-bind:class="{ selected: isSelected('documents') }"><a :href="'/faculty/services/' + id">Services</a></li>
            <br style="clear: left;">
        </ul>
    </div>
</template>
<script>
    import axios from 'axios'
  export default {
      data() {
          return {
              menu: {}
          }
      },
      props: ['selected', 'id', 'name', 'uni', 'haStatus', 'status'],
      methods: {
          isSelected: function (selection) {
              if (this.selected == selection) return true;
          },
          getMenu: function () {
              axios.get("/api/faculty/menu/", { params: { id: this.id }})
                  .then(response => {
                      if (response.data.success == true) {
                          this.menu = response.data.menu;
                      } else {
                          // eslint-disable-next-line
                          console.log("failed to get menu");
                      }
                  })
                  .catch(error => {
                      // eslint-disable-next-line
                      console.log(error)
                  });
          }

      },
      created () {
          this.getMenu();
      }
  }
</script>
<style scoped>
    h1 { border-bottom: none }
    h1 + ul.page_sub { margin-top: -1.5em; }
    ul.page_sub:after { display: block; clear: left; content: "\00a0"; height: 0; }

    ul.page_sub
    {
        list-style: none;
        margin: 0 0 0 3em;
        display: block;
        clear: right;
        height: 25px;
    }

    .page_sub li
    {
        float: left;
        margin: 0;
    }

    .page_sub li span
    {
        display: block;
        padding: .2em .6em;
        color: #633C7D;
        background: #fff;
    }

    .page_sub a
    {
        display: block;
        padding: .2em .6em;
        text-decoration: none;
        background: #633C7D;
        color: #fff;
        border-left: 1px solid #8964A2;
        border-right: 1px solid #391C4D;
        border-top: 1px solid #8964A2;
        border-bottom: 1px solid #391C4D;
    }

    .page_sub a:link, .page_sub a:visited
    {
        color: #fff;
    }

    .page_sub a:hover
    {
        background: #894BAF !important;
    }

    .page_sub .selected a, .page_sub a.selected
    {
        background: #894BAF;
        color: #fff;
    }

</style>