<template>
    <div>
        <ul class="page_sub">
            <li :class="{ selected: isSelected('setup') }"><router-link :to="'/note-taking-service/setup'">Add new</router-link></li>
            <li :class="{ selected: isSelected('unfilled-requests') }"><router-link :to="'/note-taking-service/unfilled-requests'">Pending hire</router-link></li>
            <li :class="{ selected: isSelected('recipients') }"><router-link :to="'/note-taking-service/recipients'">Current - by student</router-link></li>
            <li :class="{ selected: isSelected('active-services') }"><router-link :to="'/note-taking-service/active-services'">Current - by course</router-link></li>
            <li :class="{ selected: isSelected('books-by-status') }"><router-link :to="'/group-email/compose?email_note_taking_related_instructors=true'">Email Instructors</router-link></li>
            <br style="clear: left;">
        </ul>
    </div>
</template>
<script>
  export default {
      data() {
          return {
          }
      },
      props: ['selected'],
      methods: {
          isSelected: function (selection) {
              if (this.selected == selection) return true;
          }
      },
      created () {
      }
  }
</script>
<style scoped>
    h1 + ul.page_sub { margin-top: -1.5em; }
    ul.page_sub:after { display: block; clear: left; content: "\00a0"; height: 0; }

    ul.page_sub
    {
        list-style: none;
        margin: 0 0 0 3em;
        display: block;
        clear: right;
        height: 25px;
    }

    .page_sub li
    {
        float: left;
        margin: 0;
    }

    .page_sub li span
    {
        display: block;
        padding: .2em .6em;
        color: #633C7D;
        background: #fff;
    }

    .page_sub a
    {
        display: block;
        padding: .2em .6em;
        text-decoration: none;
        background: #633C7D;
        color: #fff;
        border-left: 1px solid #8964A2;
        border-right: 1px solid #391C4D;
        border-top: 1px solid #8964A2;
        border-bottom: 1px solid #391C4D;
    }

    .page_sub a:link, .page_sub a:visited
    {
        color: #fff;
    }

    .page_sub a:hover
    {
        background: #894BAF !important;
    }

    .page_sub .selected a, .page_sub a.selected
    {
        background: #894BAF;
        color: #fff;
    }

</style>
