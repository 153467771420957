<template>
    <div>
        <td class="text-underline accommodation-label" :style="{'padding-left': 10* indent + 'px'}">{{ accommodationObj.name }}</td>
        <template v-if="accommodationObj.sub_categories.length">
            <template v-for="category in accommodationObj.sub_categories">
                <accommodations-review-table :key="category.id" :accommodationObj="category" :showHeader="false" :indent="indent + 1" :canEditAccommodationDate="canEditAccommodationDate"></accommodations-review-table>
            </template>
        </template>
        <template v-else>
            <template v-for="accommodationType in accommodationObj.accommodation_types">
                <template v-if="accommodationType.student_accommodations.length">
                    <el-table :key="accommodationType.id" :data="accommodationType.student_accommodations" style="width: 100%" :show-header="false">
                        <el-table-column label="Accommodations" class-name="accommodation" :min-width="95.0">
                            <template slot-scope="scope">
                                {{ scope.row.accommodation_type }}<template v-if="scope.row.description"> : {{ scope.row.description }}</template>
                                <template v-if="scope.row.requires_student">
                                    {{scope.row.avoided_student_preferred_first_name ? scope.row.avoided_student_preferred_first_name : scope.row.avoided_student_first_name}} {{ scope.row.avoided_student_last_name }} &lt;{{scope.row.avoided_student_uni}}&gt;
                                </template>
                                <template v-if="scope.row.requires_computer_option">
                                    {{ scope.row.computer_choice == "Other" ? "Other: "+scope.row.computer_choice_other : scope.row.computer_choice }}
                                </template>
                                <template v-if="scope.row.show_font_options">
                                    <template v-for="(font_option) in scope.row.font_options">

                                        <template v-if="font_option == 'Font Size'">
                                            <br :key="font_option + 'br'"/>Font Size: {{ scope.row.font_size }}; Font Type: {{scope.row.font_type}}
                                        </template>
                                        <template v-else-if="font_option == 'Other'">
                                            <br :key="font_option + 'br'"/>Other: {{scope.row.font_options_other}}
                                        </template>
                                        <template v-else>
                                            <br :key="font_option + 'br'"/>{{ font_option }}
                                        </template>
                                    </template>
                                </template>
                            </template>
                        </el-table-column>
                        <el-table-column :min-width="4.5"><template slot-scope="scope"><img v-if="scope.row.status" alt="Check" :src="check"></template></el-table-column>
                        <el-table-column label="Status" width="220">
                            <template slot-scope="scope">
                                <el-select size="mini" v-model="scope.row.status">
                                    <el-option v-for="(status, sindex) in scope.row.next_statuses" :key="sindex" :value="status.value" :label="status.display"></el-option>
                                </el-select>
                            </template>
                        </el-table-column>
                        <el-table-column label="Last changed" width="180" v-if="!canEditAccommodationDate">
                            <template slot-scope="scope">
                                {{ scope.row.last_updated_at | formatDateMDY }} {{ scope.row.latest_change_by_identifier }}
                            </template>
                        </el-table-column>
                        <el-table-column label="Effective Date"  width="180"  v-if="canEditAccommodationDate">
                          <template slot-scope="scope">
                            <el-date-picker size="mini" style="width:150px" v-model="scope.row.last_updated_at" value-format="yyyy-MM-dd"></el-date-picker>
                          </template>
                        </el-table-column>
                        <el-table-column label="Comment" width="250">
                            <template slot-scope="scope">
                                <el-input size="mini" type="textarea" :rows="1" v-model="scope.row.comment"></el-input>
                            </template>
                        </el-table-column>
                    </el-table>
                </template>
                <template v-else>
                    <el-table :key="accommodationType.id" :data="[accommodationType]" style="width: 100%" :show-header="false">
                        <el-table-column prop="name" label="Accommodations" class-name="accommodation" :min-width="95.0"></el-table-column>
                        <el-table-column :min-width="4.5"></el-table-column>
                        <el-table-column label="Status" width="220"></el-table-column>
                        <el-table-column label="Last changed" width="180" v-if="!canEditAccommodationDate"></el-table-column>
                        <el-table-column label="Effective Date" width="180" v-if="canEditAccommodationDate"></el-table-column>
                        <el-table-column label="Comment" width="250"></el-table-column>
                    </el-table>
                </template>
            </template>
        </template>
    </div>
</template>
<script>
    import check from '../../../assets/img/check.png'

    export default {
        components: {
        },
        data() {
            return {
                check: check
            }
        },
        props: ['accommodationObj', 'showHeader', 'indent', "canEditAccommodationDate"],
        name: 'accommodations-review-table',
        methods: {
        },
        mounted() {
        },
        beforeCreate: function () {
            this.$options.components.accommodationsReviewTable = require('./AccommodationsReviewTable.vue').default
        }
    }
</script>
<style scoped>
    .accommodation-label{
        font-size: 12.6px;
        border:0px;
    }
    .el-table td.accommodation{
        padding-left: 2em;
    }
    .el-table td {
        border-left:0px;
        border-right:0px;
        border-top:0px;
        border-bottom-color:#CEC1D6;
    }
    .el-table table {
        border-right:0px;
    }
    .el-table::before{
        background-color:#CEC1D6;
    }
</style>
