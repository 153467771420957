<template>
  <div>
  <h2>Enter {{ this.emergency_evac.id != null ? "one" : "a new" }} emergency evacuation for {{ this.student_name }}</h2>
    <el-form :model="emergency_evac" :rules="rules" ref="evacForm" label-width="113px" label-position="right">
      <el-form-item label="Start Date" prop="start_date">
        <el-date-picker type="date" size="mini" placeholder="Pick a day" v-model="emergency_evac.start_date"></el-date-picker>
      </el-form-item>
      <el-form-item label="Building Name" prop="building">
        <el-input placeholder="Building Name" size="mini" v-model="emergency_evac.building"></el-input>
      </el-form-item>
      <el-form-item label="Room Number" prop="room_number">
        <el-input placeholder="Room Number" size="mini" v-model="emergency_evac.room_number"></el-input>
      </el-form-item>
      <el-form-item label="Description" prop="description">
        <el-input type="textarea" :rows="3" placeholder="Description" v-model="emergency_evac.description"></el-input>
      </el-form-item>
      <el-form-item label="End Date" prop="end_date">
        <el-date-picker type="date" size="mini" placeholder="Pick a day" v-model="emergency_evac.end_date"></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="mini" @click="onSubmit('evacForm')">Save Changes</el-button>
        <el-button @click="onDelete" size="mini" v-if="emergency_evac.id != null">Delete</el-button>
        <el-button type="text" size="mini" @click="onCancel">Cancel</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
    import axios from 'axios'

    export default {
      data() {
        return {
          emergency_evac: {
            id: null,
            building: null,
            room_number: null,
            description: null,
            start_date: null,
            end_date: null
          },
          rules: {
            start_date: [{ required: true, message: 'Start Date is required' }],
            building: [{ required: true, whitespace: true, message: 'Building Name is required' }],
            room_number: [{ required: true, whitespace: true, message: 'Room Number is required' }],
            description: [{ required: true, whitespace: true, message: 'Description is required' }],
            end_date: [{ required: true, message: 'End Date is required' }]
          }
        }
      },
      props: ['id', 'student_id', 'student_name'],
      methods: {
        load: function () {
          if(this.id){
            axios
                .get('/api/student/emergency-evac/' + this.id)
                .then(response => {
                    this.emergency_evac.id = response.data.id;
                    this.emergency_evac.building = response.data.building;
                    this.emergency_evac.room_number = response.data.room_number;
                    this.emergency_evac.description = response.data.description;
                    this.emergency_evac.start_date = response.data.start_date;
                    this.emergency_evac.end_date = response.data.end_date;
                })
                .catch(error => {
                  // eslint-disable-next-line
                    console.log(error)
                });
          }
        },
        onSubmit: function(formName) {
          this.$refs[formName].validate((valid) => {
            if(valid){
              if(this.id){
                this.emergency_evac.id = this.id
                axios
                    .put('/api/student/emergency-evac/', this.emergency_evac)
                    .then(response => {
                      this.$router.push({ path: '/student/emergency-evacuation/' + this.student_id });
                    })
                    .catch(error => {
                      // eslint-disable-next-line
                      console.log(error);
                    });
              } else {
                this.emergency_evac.student_id = this.student_id
                axios
                    .post('/api/student/emergency-evac/', this.emergency_evac)
                    .then(response => {
                      this.$router.push({ path: '/student/emergency-evacuation/' + this.student_id });
                    })
                    .catch(error => {
                      // eslint-disable-next-line
                      console.log(error);
                    });
              }
            }
          });
        },
        onDelete: function() {
          axios
              .delete('/api/student/emergency-evac/', {params: {id: this.emergency_evac.id}})
              .then(response => {
                this.$router.push({ path: '/student/emergency-evacuation/' + this.student_id });
              })
              .catch(error => {
                // eslint-disable-next-line
                console.log(error);
              });
        },
        onCancel: function() {
          this.$router.push({ path: '/student/emergency-evacuation/' + this.student_id });
        }
      },
      mounted() {
          this.load()
      }
    }
</script>
<style scoped>
</style>
