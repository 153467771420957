<template>
    <div class="sections inner-block wide-block" id="contact-notes-summary">
        <h2>My Contact Notes</h2>
        <table>
            <template v-for="item in items">
                <tr v-for="(inneritem, index) in item.items" :key="inneritem.id">
                    <th :rowspan="item.items.length" v-if="index == 0">{{item.category}}</th>

                    <th><router-link :to="inneritem.link">{{inneritem.title}}</router-link></th>
                    <td>{{inneritem.count}}</td>
                </tr>
            </template>
        </table>
        <p>
            <router-link to="/contact-notes/edit" class="action">Enter a new contact note</router-link>
            <br>
            <span class="help">(Use this link to create a contact note or a contact note draft online)</span>
        </p>
    </div>
</template>

<script>
    import axios from 'axios'
    export default {
        data () {
            return {
                items: []
            }
        },
        methods: {
            load: function() {
                axios
                    .get('/api/home/contact-notes-summary')
                    .then(response => {
                        this.items = response.data.rows
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            }
        },
        mounted () {
            this.load()
        }
    }
</script>

<style scoped>

</style>
