<template>
    <div class="ha-committee-notes">
        <h3>Housing Committee Notes</h3>
        <div v-if="notes.length" class="notes-display">
            <div v-for="note in notes" :key="note.id">
                <template v-if="note.isExpanded">&#8617;</template>
                <template v-else>&#8618;</template>
                <a @click="note.isExpanded = !note.isExpanded"><b>{{ note.subject }} from {{ note.date | formatDateMDY }} by {{ note.user_name }}</b></a>
                <template v-if="note.isExpanded">
                    <div class="note">
                        <template v-if="!note.showEditField">
                            <p class="formatted-text">{{ note.body }}</p>
                            <p class="formatted-text"><a @click="note.showEditField=true">edit</a></p>
                        </template>
                        <template v-else>
                            <el-input type="textarea" v-model="note.body" :rows="6"></el-input>
                            <el-button size="mini" type="primary" class="bottom-margin" @click="updateNote(note)">Save</el-button>
                        </template>
                    </div>
                </template>
            </div>
        </div>

        <a href="#" v-if="!show" v-on:click="show = !show">Add</a>
        <transition name="expand">
            <p v-if="show">
                <el-form ref="note" :model="note" :rules="rules" label-width="120px" label-position="top">
                    <el-form-item prop="date" label="Date">
                        <el-date-picker v-model="note.date" type="date" placeholder="Pick a day" value-format="yyyy-MM-dd"></el-date-picker>
                    </el-form-item>
                    <el-form-item prop="body" label="HA Committee Meeting Notes">
                        <el-input v-model="note.body" type="textarea" :autosize="{ minRows: 2}"
                                  placeholder="Please input"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="onSubmit('note')">Save</el-button>
                        <el-button @click="show = !show">Cancel</el-button>
                    </el-form-item>
                </el-form>
            </p>
        </transition>
    </div>
</template>
<script>
    import axios from 'axios'

    export default {
        data() {
            return {
                note: {
                    date: '',
                    body: ''
                },
                rules: {
                    date: [{required: true, message: 'Please pick a date', trigger: 'change'}],
                    body: [{required: true, message: 'Please enter a note', trigger: 'blur'}]
                },
                show: false,
                notes: []
            }
        },
        props: ['id'],
        methods: {
            load: function () {
                //get the student id from the url
                axios.get("/api/student/housing-committee-notes/" + this.id)
                    .then(response => {
                        this.notes = response.data.rows;


                        for(var i = 0; i < this.notes.length; i++){
                            var note = this.notes[i];

                            this.$set(note, 'isExpanded', false);
                            this.$set(note, 'showEditField', false);
                            note.can_be_edited = true;
                        }
                    });

            },
            updateNote(thisNote) {
                axios
                    .put('/api/student/housing-committee-notes/', {id: thisNote.id, body: thisNote.body})
                    .then(response => {
                        if (response.data.success) {

                            // eslint-disable-next-line
                            console.log("success");
                            this.load();
                        } else {
                            // eslint-disable-next-line
                            console.log("error: " + response.data.message);
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            },
            onSubmit(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        // eslint-disable-next-line
                        console.log("date:" + this.note.date);
                        // eslint-disable-next-line
                        console.log("body:" + this.note.body);
                        axios
                            .post('/api/student/housing-committee-notes/', {id: parseInt(this.id), body: this.note.body, date: this.note.date})
                            .then(response => {
                                if (response.data.success) {
                                    this.show = false;

                                    this.note.body = '';
                                    this.note.date = '';
                                    // eslint-disable-next-line
                                    console.log("success");
                                    this.load();
                                } else {
                                    // eslint-disable-next-line
                                    console.log("error: " + response.data.message);
                                }
                            })
                            .catch(error => {
                                // eslint-disable-next-line
                                console.log(error)
                            });
                    } else {
                        // eslint-disable-next-line
                        console.log('invalid form');
                        return false;
                    }
                });
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
    .el-form {
        width: 100%;
    }
    .el-form-item.is-required .el-form-item__label::before {
       content: '';
       color: #ff4949;
       margin-right: 0;
    }
    .ha-committee-notes {
        margin-top: 2em;
        text-align: center;
        width: 400px;
        border: 1px solid #CEC1D6;
        padding: 10px;
    }
</style>
