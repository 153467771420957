<template>
    <div style="position:relative" id="status_and_workflow">
        <div class="workflow_action">
            <h3>Application has become inactive</h3>
            <p>Reason for deactivation: {{ this.inactiveReason }} {{ this.inactiveReason == 'Other' ? ' - ' + this.inactiveComment : '' }}</p>
            <p>If there is new activity for this application, you may reactivate the guest. If the guest's record hasn't expired, they'll be moved to Guest Coordinator Review status, otherwise they will move to Guest Expired.</p>

            <el-button type="primary" size="small" @click="onSubmit('guest_coordinator_review')">Reactive Guest</el-button>
            <student-student-workflow-system-administrator-options :id="id" :studentName="studentName" v-on:refreshstudent="refreshStudent"></student-student-workflow-system-administrator-options>
        </div>
   </div>
</template>
<script>
    import axios from 'axios'



    export default {
        components: {

        },
        data() {
            return {
            }
        },
        props: ['id', 'inactiveReason', 'inactiveComment', 'studentName'],
        methods: {
            onSubmit(newStatus) {
                this.$message.closeAll();
                
                axios.put("/api/student/change-status/", {
                        id: parseInt(this.id),
                        new_status: newStatus
                    })
                    .then(response => {
                        if (response.data.success) {
                            this.$emit('refreshstudent');
                        } else {
                            this.$message({
                                showClose: true,
                                duration: 20000,
                                type: 'error',
                                message: response.data.message
                            });
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            },
            refreshStudent: function(){
                this.$emit('refreshstudent');
            }
        }
    }
</script>
<style scoped>
</style>