import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import store from '@/store'



Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      beforeEnter: checkAuth
    },
    {
      path: '/home',
      name: 'home2',
      component: Home,
      beforeEnter: checkAuth
    },
    {
      path: '/search/list',
      name: 'searchlist',
      // route level code-splitting
      // this generates a separate chunk (search.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "search" */ './views/SearchList.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/search',
          name: 'search',
        component: () => import(/* webpackChunkName: "search" */ './views/Search.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/login',
      name: 'login',
      meta: {
        authNotRequired: true,
      },
      component: () => import(/* webpackChunkName: "login" */ './views/Login.vue'),
      beforeEnter: function(routeTo, routeFrom, next) {

        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ name: routeFrom || 'home'})
        } else {
          // Continue to the login page
          next()
        }
      }
    },
    {
      path: '/logout',
          name: 'logout', //logout has no view
        beforeEnter: function(routeTo, routeFrom, next) {
      store.dispatch('auth/logOut')
      // const authNotRequiredOnPreviousRoute = routeFrom.matched.some(
      //   route => route.meta.authNotRequired
      // )
      // Navigate back to previous page, or login as a fallback
      window.location = location.protocol + '//' + location.host+"/home";

    }
    },
    {
      path: '/admin',
          name: 'admin',
        component: () => import(/* webpackChunkName: "login" */ './views/admin/Admin.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/admin/edit-user',
          name: 'adminCreateUser',
        component: () => import(/* webpackChunkName: "login" */ './views/admin/EditUser.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/admin/edit-user/:id',
          name: 'adminEditUser',
        component: () => import(/* webpackChunkName: "login" */ './views/admin/EditUser.vue'),
        beforeEnter: checkAuth
    },


    {
      path: '/case-management-notes/new',
          name: 'caseManagementNotesNew',
        component: () => import(/* webpackChunkName: "login" */ './views/case-management-notes/New.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/case-management-notes/edit/:id',
          name: 'caseManagementNotesEdit',
        component: () => import(/* webpackChunkName: "login" */ './views/case-management-notes/Edit.vue'),
        beforeEnter: checkAuth
    },


    {
      path: '/contact-notes/author/:id',
          name: 'contactNotesAuthor',
        component: () => import(/* webpackChunkName: "login" */ './views/contact-notes/Author.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/contact-notes',
          name: 'contactNotes',
        component: () => import(/* webpackChunkName: "login" */ './views/contact-notes/ContactNotes.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/contact-notes/edit',
          name: 'contactNotesEdit',
        component: () => import(/* webpackChunkName: "login" */ './views/contact-notes/Edit.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/contact-notes/edit/:id',
          name: 'contactNotesEdit2',
        component: () => import(/* webpackChunkName: "login" */ './views/contact-notes/Edit.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/contact-notes/edit-template/:id',
          name: 'contactNotesEditTemplate',
        component: () => import(/* webpackChunkName: "login" */ './views/contact-notes/EditTemplate.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/contact-notes/list-templates',
          name: 'contactNotesListTemplates',
        component: () => import(/* webpackChunkName: "login" */ './views/contact-notes/ListTemplates.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/contact-notes/my-contact-notes/:type',
          name: 'contactNotesMyContactNotes',
        component: () => import(/* webpackChunkName: "login" */ './views/contact-notes/MyContactNotes.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/contact-notes/new-template',
          name: 'contactNotesNewTemplate',
        component: () => import(/* webpackChunkName: "login" */ './views/contact-notes/NewTemplate.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/contact-notes/search',
          name: 'contactNotesSearch',
        component: () => import(/* webpackChunkName: "login" */ './views/contact-notes/Search.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/contact-notes/show/:id',
          name: 'contactNotesShow',
        component: () => import(/* webpackChunkName: "login" */ './views/contact-notes/Show.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/contact-notes/show-group/:id',
          name: 'contactNotesShowGroup',
        component: () => import(/* webpackChunkName: "login" */ './views/contact-notes/ShowGroup.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/contact-notes/show-template/:id',
          name: 'contactNotesShowTemplate',
        component: () => import(/* webpackChunkName: "login" */ './views/contact-notes/ShowTemplate.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/contact-notes/status/:status',
          name: 'contactNotesStatus',
        component: () => import(/* webpackChunkName: "login" */ './views/contact-notes/Status.vue'),
        beforeEnter: checkAuth
    },


    {
      path: '/document/accommodation-letter-doc',
          name: 'documentsAccommodationLetterDoc',
        component: () => import(/* webpackChunkName: "login" */ './views/document/AccommodationLetterDoc.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/document/create-letter-and-course',
          name: 'documentsCreateLetterAndCourse',
        component: () => import(/* webpackChunkName: "login" */ './views/document/CreateLetterAndCourse.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/document/create-letter-for-course',
          name: 'documentsCreateLetterForCourse',
        component: () => import(/* webpackChunkName: "login" */ './views/document/CreateLetterForCourse.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/document',
          name: 'documents',
        component: () => import(/* webpackChunkName: "login" */ './views/document/Document.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/document/edit',
          name: 'documentsCreate',
        component: () => import(/* webpackChunkName: "login" */ './views/document/Edit.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/document/edit/:id',
          name: 'documentsEdit',
        component: () => import(/* webpackChunkName: "login" */ './views/document/Edit.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/document/ha-decision-letter',
          name: 'documentsHaDecisionLetter',
        component: () => import(/* webpackChunkName: "login" */ './views/document/HaDecisionLetter.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/document/search',
          name: 'documentsSearch',
        component: () => import(/* webpackChunkName: "login" */ './views/document/Search.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/etext-service/active-services',
          name: 'etextServicesActiveServices',
        component: () => import(/* webpackChunkName: "login" */ './views/etext-service/ActiveServices.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/etext-service/books-by-status',
          name: 'etextServicesBooksByStatus',
        component: () => import(/* webpackChunkName: "login" */ './views/etext-service/BooksByStatus.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/etext-service/courses-by-school',
          name: 'etextServicesCoursesBySchool',
        component: () => import(/* webpackChunkName: "login" */ './views/etext-service/CoursesBySchool.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/etext-service/recipients',
          name: 'etextServicesRecipients',
        component: () => import(/* webpackChunkName: "login" */ './views/etext-service/Recipients.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/etext-service/setup',
          name: 'etextServicesSetup',
        component: () => import(/* webpackChunkName: "login" */ './views/etext-service/Setup.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/etext-service/show/:id',
          name: 'etextServicesShow',
        component: () => import(/* webpackChunkName: "login" */ './views/etext-service/Show.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/etext-service/students-by-school',
          name: 'etextStudentsBySchool',
        component: () => import(/* webpackChunkName: "login" */ './views/etext-service/StudentsBySchool.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/etext-service/unfilled-requests',
          name: 'etextUnfilledRequests',
        component: () => import(/* webpackChunkName: "login" */ './views/etext-service/UnfilledRequests.vue'),
        beforeEnter: checkAuth
    },


    {
      path: '/group-email/compose',
          name: 'groupEmailCompose',
        component: () => import(/* webpackChunkName: "login" */ './views/group-email/compose/Compose.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/group-email/compose/edit',
          name: 'groupEmailComposeEdit',
        component: () => import(/* webpackChunkName: "login" */ './views/group-email/compose/Edit.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/group-email/compose/preview',
          name: 'groupEmailComposePreview',
        component: () => import(/* webpackChunkName: "login" */ './views/group-email/compose/Preview.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/group-email/compose/finished',
          name: 'groupEmailComposeFinished',
        component: () => import(/* webpackChunkName: "login" */ './views/group-email/compose/Finished.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/group-email/email-lists/edit',
          name: 'groupEmailEmailListsEdit',
        component: () => import(/* webpackChunkName: "login" */ './views/group-email/email-lists/Edit.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/group-email/email-lists',
          name: 'groupEmailEmailLists',
        component: () => import(/* webpackChunkName: "login" */ './views/group-email/email-lists/EmailLists.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/group-email/email-signatures/edit',
          name: 'groupEmailEmailSignaturesEdit',
        component: () => import(/* webpackChunkName: "login" */ './views/group-email/email-signatures/Edit.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/group-email/email-signatures',
          name: 'groupEmailEmailSignatures',
        component: () => import(/* webpackChunkName: "login" */ './views/group-email/email-signatures/EmailSignatures.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/group-email/email-templates/show/:id',
      name: 'groupEmailEmailTemplatesShow',
      component: () => import(/* webpackChunkName: "login" */ './views/group-email/email-templates/Show.vue'),
      beforeEnter: checkAuth
    },
    {
      path: '/group-email/email-templates/edit',
          name: 'groupEmailEmailTemplatesEdit',
        component: () => import(/* webpackChunkName: "login" */ './views/group-email/email-templates/Edit.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/group-email/email-templates',
          name: 'groupEmailEmailTemplates',
        component: () => import(/* webpackChunkName: "login" */ './views/group-email/email-templates/EmailTemplates.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/group-email/draft-emails',
          name: 'groupEmailDraftEmails',
        component: () => import(/* webpackChunkName: "login" */ './views/group-email/DraftEmails.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/group-email',
          name: 'groupEmail',
        component: () => import(/* webpackChunkName: "login" */ './views/group-email/GroupEmail.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/group-email/sent',
          name: 'groupEmailSent',
        component: () => import(/* webpackChunkName: "login" */ './views/group-email/Sent.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/group-email/show/:id',
          name: 'groupEmailShow',
        component: () => import(/* webpackChunkName: "login" */ './views/group-email/Sent.vue'),
        beforeEnter: checkAuth
    },



    {
      path: '/ha-bulk-notify',
          name: 'haBulkNotify',
        component: () => import(/* webpackChunkName: "login" */ './views/ha-bulk-notify/HaBulkNotify.vue'),
        beforeEnter: checkAuth
    },


    {
      path: '/ha-committee/expired-in-last-6-months',
          name: 'haCommitteeExpiredInLast6Months',
        component: () => import(/* webpackChunkName: "login" */ './views/ha-committee/ExpiredInLast6Months.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/ha-committee/status',
          name: 'haCommitteeStatus',
        component: () => import(/* webpackChunkName: "login" */ './views/ha-committee/Status.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/ha-committee/under-review',
          name: 'haCommitteeUnderReview',
        component: () => import(/* webpackChunkName: "login" */ './views/ha-committee/UnderReview.vue'),
        beforeEnter: checkAuth
    },


    {
      path: '/intake-notes/edit/:id',
          name: 'intakeNoteEdit',
        component: () => import(/* webpackChunkName: "login" */ './views/intake-notes/Edit.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/intake-notes/new',
          name: 'intakeNoteNew',
        component: () => import(/* webpackChunkName: "login" */ './views/intake-notes/New.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/intake-notes/search',
          name: 'intakeNoteSearch',
        component: () => import(/* webpackChunkName: "login" */ './views/intake-notes/Search.vue'),
        beforeEnter: checkAuth
    },


    {
      path: '/master-list',
          name: 'masterList',
        component: () => import(/* webpackChunkName: "login" */ './views/master-list/MasterList.vue'),
        beforeEnter: checkAuth
    },



    {
      path: '/note-taking-service/active-services',
          name: 'noteTakingServiceActiveServices',
        component: () => import(/* webpackChunkName: "login" */ './views/note-taking-service/ActiveServices.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/note-taking-service/courses-by-school',
          name: 'noteTakingServiceCoursesBySchool',
        component: () => import(/* webpackChunkName: "login" */ './views/note-taking-service/CoursesBySchool.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/note-taking-service/note-taker-payroll-status',
          name: 'noteTakingServiceNoteTakerPayrollStatus',
        component: () => import(/* webpackChunkName: "login" */ './views/note-taking-service/NoteTakerPayrollStatus.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/note-taking-service/recipients',
          name: 'noteTakingServiceRecipients',
        component: () => import(/* webpackChunkName: "login" */ './views/note-taking-service/Recipients.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/note-taking-service/setup',
          name: 'noteTakingServiceSetup',
        component: () => import(/* webpackChunkName: "login" */ './views/note-taking-service/Setup.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/note-taking-service/show/:id',
          name: 'noteTakingServiceShow',
        component: () => import(/* webpackChunkName: "login" */ './views/note-taking-service/Show.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/note-taking-service/students-by-school',
          name: 'noteTakingServiceStudentsBySchool',
        component: () => import(/* webpackChunkName: "login" */ './views/note-taking-service/StudentsBySchool.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/note-taking-service/unfilled-requests',
          name: 'noteTakingServiceUnfilledRequests',
        component: () => import(/* webpackChunkName: "login" */ './views/note-taking-service/UnfilledRequests.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/note-taking-service/weekly-data',
          name: 'noteTakingServiceWeeklyData',
        component: () => import(/* webpackChunkName: "login" */ './views/note-taking-service/WeeklyData.vue'),
        beforeEnter: checkAuth
    },


    {
      path: '/proctor/edit',
          name: 'proctorNew',
        component: () => import(/* webpackChunkName: "login" */ './views/proctor/Edit.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/proctor/edit/:id',
          name: 'proctorEdit',
        component: () => import(/* webpackChunkName: "login" */ './views/proctor/Edit.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/proctor',
          name: 'proctor',
        component: () => import(/* webpackChunkName: "login" */ './views/proctor/Proctor.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/proctor/show/:id',
          name: 'proctorShow',
        component: () => import(/* webpackChunkName: "login" */ './views/proctor/Show.vue'),
        beforeEnter: checkAuth
    },


    {
      path: '/proctoring-location/edit',
          name: 'proctoringLocationEdit',
        component: () => import(/* webpackChunkName: "login" */ './views/proctoring-location/Edit.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/proctoring-location/edit/:id',
          name: 'proctoringLocationEdit2',
        component: () => import(/* webpackChunkName: "login" */ './views/proctoring-location/Edit.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/proctoring-location',
          name: 'proctoringLocation',
        component: () => import(/* webpackChunkName: "login" */ './views/proctoring-location/ProctoringLocation.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/proctoring-location/show/:id',
          name: 'proctoringLocationShow',
        component: () => import(/* webpackChunkName: "login" */ './views/proctoring-location/Show.vue'),
        beforeEnter: checkAuth
    },


    {
      path: '/proctoring-session/edit',
          name: 'proctoringSessionEdit',
        component: () => import(/* webpackChunkName: "login" */ './views/proctoring-session/Edit.vue'),
        beforeEnter: checkAuth
    },

    {
      path: '/proctoring-session/edit/:id',
          name: 'proctoringSessionEdit2',
        component: () => import(/* webpackChunkName: "login" */ './views/proctoring-session/Edit.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/proctoring-session',
          name: 'proctoringSession',
        component: () => import(/* webpackChunkName: "login" */ './views/proctoring-session/ProctoringSession.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/proctoring-session/show/:id',
          name: 'proctoringSessionShow',
        component: () => import(/* webpackChunkName: "login" */ './views/proctoring-session/Show.vue'),
        beforeEnter: checkAuth
    },



    {
      path: '/proctoring-session-requestor/edit',
          name: 'proctoringSessionRequestorEdit',
        component: () => import(/* webpackChunkName: "login" */ './views/proctoring-session-requestor/Edit.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/proctoring-session-requestor/edit/:id',
          name: 'proctoringSessionRequestorEdit2',
        component: () => import(/* webpackChunkName: "login" */ './views/proctoring-session-requestor/Edit.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/proctoring-session-requestor',
          name: 'proctoringSessionRequestor',
        component: () => import(/* webpackChunkName: "login" */ './views/proctoring-session-requestor/ProctoringSessionRequestor.vue'),
        beforeEnter: checkAuth
    },



    {
      path: '/report/athletes',
          name: 'reportAthletes',
        component: () => import(/* webpackChunkName: "login" */ './views/report/Athletes.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/report/caseload',
          name: 'reportCaseLoad',
        component: () => import(/* webpackChunkName: "login" */ './views/report/CaseLoad.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/report/caseload-by-date',
          name: 'reportCaseLoadByDate',
        component: () => import(/* webpackChunkName: "login" */ './views/report/CaseLoadByDate.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/report/case-management-notes',
          name: 'reportCaseManagementNotes',
        component: () => import(/* webpackChunkName: "login" */ './views/report/CaseManagementNotes.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/report/change-history',
          name: 'reportChangeHistory',
        component: () => import(/* webpackChunkName: "login" */ './views/report/ChangeHistory.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/report/contact-note-by-month-and-user',
          name: 'reportContactNoteByMonthAndUser',
        component: () => import(/* webpackChunkName: "login" */ './views/report/ContactNoteByMonthAndUser.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/report/contact-note-by-reason',
          name: 'reportContactNoteByReason',
        component: () => import(/* webpackChunkName: "login" */ './views/report/ContactNoteByReason.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/report/contact-notes',
          name: 'reportContactNotes',
        component: () => import(/* webpackChunkName: "login" */ './views/report/ContactNotes.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/report/contact-notes-with-ls-and-as',
      name: 'reportContactNotesWithLsAndAs',
      component: () => import(/* webpackChunkName: "login" */ './views/report/ContactNotesWithLsAndAs.vue'),
      beforeEnter: checkAuth
    },
    {
      path: '/report/housing-accommodations',
          name: 'reportHousingAccommodations',
        component: () => import(/* webpackChunkName: "login" */ './views/report/HousingAccommodations.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/report/housing-intake-notes',
          name: 'reportHousingIntakeNotes',
        component: () => import(/* webpackChunkName: "login" */ './views/report/HousingIntakeNotes.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/report/intake-notes',
          name: 'reportIntakeNotes',
        component: () => import(/* webpackChunkName: "login" */ './views/report/IntakeNotes.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/report/letter-tracking',
          name: 'reportLetterTracking',
        component: () => import(/* webpackChunkName: "login" */ './views/report/LetterTracking.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/report/master-list-no-identifiers',
          name: 'reportMasterListNoIdentifiers',
        component: () => import(/* webpackChunkName: "login" */ './views/report/MasterListNoIdentifiers.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/report/notifications-catalog',
          name: 'reportNotificationsCatalog',
        component: () => import(/* webpackChunkName: "login" */ './views/report/NotificationsCatalog.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/report/nys-enrollment-report',
          name: 'reportNysEnrollmentReport',
        component: () => import(/* webpackChunkName: "login" */ './views/report/NysEnrollmentReport.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/report/probably-graduated-students',
          name: 'reportProbablyGraduatedStudents',
        component: () => import(/* webpackChunkName: "login" */ './views/report/ProbablyGraduatedStudents.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/report/part-and-half-time-students',
      name: 'partAndHalfTimeStudents',
      component: () => import(/* webpackChunkName: "login" */ './views/report/PartAndHalfTimeStudents.vue'),
      beforeEnter: checkAuth
    },
    {
      path: '/report/proctor-hours-report',
          name: 'reportProctorHoursReport',
        component: () => import(/* webpackChunkName: "login" */ './views/report/ProctorHoursReport.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/report/proctor-sessions-analysis',
          name: 'reportProctorSessionsAnalysis',
        component: () => import(/* webpackChunkName: "login" */ './views/report/ProctorSessionsAnalysis.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/report/quizzes-and-reschedules',
      name: 'quizzesAndReschedules',
      component: () => import(/* webpackChunkName: "login" */ './views/report/QuizzesAndReschedules.vue'),
      beforeEnter: checkAuth
    },
    {
      path: '/report',
          name: 'report',
        component: () => import(/* webpackChunkName: "login" */ './views/report/Report.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/report/sis-integration',
          name: 'reportSisIntegration',
        component: () => import(/* webpackChunkName: "login" */ './views/report/SisIntegration.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/report/status-data-by-date',
          name: 'reportStatusDataByDate',
        component: () => import(/* webpackChunkName: "login" */ './views/report/StatusDataByDate.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/report/status-student-counts',
          name: 'reportStatusStudentCounts',
        component: () => import(/* webpackChunkName: "login" */ './views/report/StatusStudentCounts.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/report/students-by-accommodation',
          name: 'reportStudentsByAccommodation',
        component: () => import(/* webpackChunkName: "login" */ './views/report/StudentsByAccommodation.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/report/students-by-course-instructor',
          name: 'reportStudentsByCourseInstructor',
        component: () => import(/* webpackChunkName: "login" */ './views/report/StudentsByCourseInstructor.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/report/students-by-graduation-year-and-school',
          name: 'reportStudentsByGraduationYearAndSchool',
        component: () => import(/* webpackChunkName: "login" */ './views/report/StudentsByGraduationYearAndSchool.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/report/students-by-registration-date',
          name: 'reportStudentsByRegistrationDate',
        component: () => import(/* webpackChunkName: "login" */ './views/report/StudentsByRegistrationDate.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/report/students-by-school',
          name: 'reportStudentsBySchool',
        component: () => import(/* webpackChunkName: "login" */ './views/report/StudentsBySchool.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/report/students-semester-eligibility-disability',
          name: 'reportStudentsSemesterEligibiltyDisability',
        component: () => import(/* webpackChunkName: "login" */ './views/report/StudentsSemesterEligibiltyDisability.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/report/students-with-ldhd-or-add',
          name: 'reportStudentsWithLdhdOrAdd',
        component: () => import(/* webpackChunkName: "login" */ './views/report/StudentsWithLdhdOrAdd.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/report/students-with-visual',
          name: 'reportStudentsWithVisual',
        component: () => import(/* webpackChunkName: "login" */ './views/report/StudentsWithVisual.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/report/students-without-contact-notes',
          name: 'reportStudentsWithoutContactNotes',
        component: () => import(/* webpackChunkName: "login" */ './views/report/StudentsWithoutContactNotes.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/report/tech-exams-breakdown',
      name: 'reportTechExamsBreakdown',
      component: () => import(/* webpackChunkName: "login" */ './views/report/TechExamsBreakdown.vue'),
      beforeEnter: checkAuth
    },
    {
      path: '/report/time-in-temporary-status',
          name: 'reportTimeInTemporaryStatus',
        component: () => import(/* webpackChunkName: "login" */ './views/report/TimeInTemporaryStatus.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/report/verification',
          name: 'reportVerification',
        component: () => import(/* webpackChunkName: "login" */ './views/report/Verification.vue'),
        beforeEnter: checkAuth
    },

    {
      path: '/room-reservation-manager/edit',
      name: 'roomReservationManagerCreate',
      component: () => import(/* webpackChunkName: "login" */ './views/room-reservation-manager/Edit.vue'),
      beforeEnter: checkAuth
    },



    {
      path: '/room-reservation-manager/edit/:id',
          name: 'roomReservationManagerEdit',
        component: () => import(/* webpackChunkName: "login" */ './views/room-reservation-manager/Edit.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/room-reservation-manager',
          name: 'roomReservationManager',
        component: () => import(/* webpackChunkName: "login" */ './views/room-reservation-manager/RoomReservationManager.vue'),
        beforeEnter: checkAuth
    },


    {
      path: '/semester/list',
          name: 'semesterList',
        component: () => import(/* webpackChunkName: "login" */ './views/semester/List.vue'),
        beforeEnter: checkAuth
    },


    {
      path: '/semester/edit/:id',
      name: 'semesterEdit',
      component: () => import(/* webpackChunkName: "login" */ './views/semester/Edit.vue'),
      beforeEnter: checkAuth
    },

    {
      path: '/semester/show/:id',
      name: 'semesterShow',
      component: () => import(/* webpackChunkName: "login" */ './views/semester/Edit.vue'),
      beforeEnter: checkAuth
    },

    {
      path: '/service',
          name: 'service',
        component: () => import(/* webpackChunkName: "login" */ './views/service/Service.vue'),
        beforeEnter: checkAuth
    },


    {
      path: '/service-provider/edit/:id',
          name: 'serviceProviderEdit',
        component: () => import(/* webpackChunkName: "login" */ './views/service-provider/Edit.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/service-provider/show/:id',
          name: 'serviceProviderShow',
        component: () => import(/* webpackChunkName: "login" */ './views/service-provider/Show.vue'),
        beforeEnter: checkAuth
    },



    {
      path: '/student/academic/:id',
          name: 'studentAcademic',
        component: () => import(/* webpackChunkName: "login" */ './views/student/Academic.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/student/accommodation-letters/:id',
          name: 'studentAccommodationLetters',
        component: () => import(/* webpackChunkName: "login" */ './views/student/AccommodationLetters.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/student/accommodations/:id',
          name: 'studentAccommodations',
        component: () => import(/* webpackChunkName: "login" */ './views/student/Accommodations.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/student/accommodations-edit/:id',
          name: 'studentAccommodationsEdit',
        component: () => import(/* webpackChunkName: "login" */ './views/student/AccommodationsEdit.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/student/accommodations-review/:id',
          name: 'studentAccommodationsReview',
        component: () => import(/* webpackChunkName: "login" */ './views/student/AccommodationsReview.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/student/add-course/:id',
          name: 'studentAddCourse',
        component: () => import(/* webpackChunkName: "login" */ './views/student/AddCourse.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/student/disabilities/:id',
          name: 'studentDisabilities',
        component: () => import(/* webpackChunkName: "login" */ './views/student/Disabilities.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/student/disabilities-edit/:id',
          name: 'studentDisabilitiesEdit',
        component: () => import(/* webpackChunkName: "login" */ './views/student/DisabilitiesEdit.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/student/documents/:id',
          name: 'studentDocuments',
        component: () => import(/* webpackChunkName: "login" */ './views/student/Documents.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/student/edit/:id',
          name: 'studentEdit',
        component: () => import(/* webpackChunkName: "login" */ './views/student/Edit.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/student/emergency-evacuation/:id',
          name: 'studentEmergencyEvacuation',
        component: () => import(/* webpackChunkName: "login" */ './views/student/EmergencyEvacuation.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/student/emergency-evacuation-edit/:id',
          name: 'studentEmergencyEvacuationEdit',
        component: () => import(/* webpackChunkName: "login" */ './views/student/EmergencyEvacuationEdit.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/student/files-attached-to-contact-notes/:id',
          name: 'studentFilesAttachedToContactNotes',
        component: () => import(/* webpackChunkName: "login" */ './views/student/FilesAttachedToContactNotes.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/student/groups',
          name: 'studentGroups',
        component: () => import(/* webpackChunkName: "login" */ './views/student/Groups.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/student/ha-accommodations/:id',
          name: 'studentHaAccommodations',
        component: () => import(/* webpackChunkName: "login" */ './views/student/HaAccommodations.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/student/ha-accommodations-edit/:id',
          name: 'studentHaAccommodationsEdit',
        component: () => import(/* webpackChunkName: "login" */ './views/student/HaAccommodationsEdit.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/student/ha-accommodations-review/:id',
          name: 'studentHaAccommodationsReview',
        coname: 'studentHaAccommodationsReview',
        component: () => import(/* webpackChunkName: "login" */ './views/student/HaAccommodationsReview.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/student/ha-profile/:id',
          name: 'studentHaProfile',
        component: () => import(/* webpackChunkName: "login" */ './views/student/HaProfile.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/student/new',
          name: 'studentNew',
        component: () => import(/* webpackChunkName: "login" */ './views/student/New.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/student/notes/:id',
          name: 'studentNotes',
        component: () => import(/* webpackChunkName: "login" */ './views/student/Notes.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/student/profile/:id',
          name: 'studentProfile',
        component: () => import(/* webpackChunkName: "login" */ './views/student/Profile.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/student/services/:id',
          name: 'studentServices',
        component: () => import(/* webpackChunkName: "login" */ './views/student/Services.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/student/status-data',
          name: 'studentStatusData',
        component: () => import(/* webpackChunkName: "login" */ './views/student/StatusData.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/student/testing/:id',
          name: 'studentTesting',
        component: () => import(/* webpackChunkName: "login" */ './views/student/Testing.vue'),
        beforeEnter: checkAuth
    },


    {
      path: '/tasks/future-tasks/edit',
          name: 'tasksFutureTasksEdit',
        component: () => import(/* webpackChunkName: "login" */ './views/tasks/future-tasks/Edit.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/tasks/future-tasks/edit/:id',
          name: 'tasksFutureTasksEdit2',
        component: () => import(/* webpackChunkName: "login" */ './views/tasks/future-tasks/Edit.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/tasks/future-tasks',
          name: 'tasksFutureTasks',
        component: () => import(/* webpackChunkName: "login" */ './views/tasks/future-tasks/FutureTasks.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/tasks/future-tasks/show/:id',
          name: 'tasksFutureTasksShow',
        component: () => import(/* webpackChunkName: "login" */ './views/tasks/future-tasks/Show.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/tasks/edit',
          name: 'tasksEdit',
        component: () => import(/* webpackChunkName: "login" */ './views/tasks/Edit.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/tasks/edit/:id',
          name: 'tasksEdit2',
        component: () => import(/* webpackChunkName: "login" */ './views/tasks/Edit.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/tasks/pending/:status',
          name: 'tasksPending',
        component: () => import(/* webpackChunkName: "login" */ './views/tasks/Pending.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/tasks/search',
          name: 'tasksSearch',
        component: () => import(/* webpackChunkName: "login" */ './views/tasks/Search.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/tasks/show/:id',
          name: 'tasksShow',
        component: () => import(/* webpackChunkName: "login" */ './views/tasks/Show.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/tasks/status/:status',
          name: 'tasksStatus',
        component: () => import(/* webpackChunkName: "login" */ './views/tasks/Status.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/tasks',
          name: 'tasks',
        component: () => import(/* webpackChunkName: "login" */ './views/tasks/Tasks.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/tasks/waiting/:status',
          name: 'tasksWaiting',
        component: () => import(/* webpackChunkName: "login" */ './views/tasks/Waiting.vue'),
        beforeEnter: checkAuth
    },


    {
      path: '/testing/show/:id',
          name: 'testingShow',
        component: () => import(/* webpackChunkName: "login" */ './views/testing/Show.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/testing',
          name: 'testing',
        component: () => import(/* webpackChunkName: "login" */ './views/testing/Testing.vue'),
        beforeEnter: checkAuth
    },


    {
      path: '/trace/focus',
          name: 'traceFocus',
        component: () => import(/* webpackChunkName: "login" */ './views/trace/Focus.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/trace',
          name: 'trace',
        component: () => import(/* webpackChunkName: "login" */ './views/trace/Trace.vue'),
        beforeEnter: checkAuth
    },


    {
      path: '/user/edit',
          name: 'userEdit',
        component: () => import(/* webpackChunkName: "login" */ './views/user/Edit.vue'),
        beforeEnter: checkAuth
    },
    {
      path: '/user',
          name: 'user',
        component: () => import(/* webpackChunkName: "login" */ './views/user/User.vue'),
        beforeEnter: checkAuth
    }
  ]//,
//scrollBehavior (to, from, savedPosition) {
//  return new Promise((resolve, reject) => {
//    setTimeout(() => {
//      resolve(savedPosition)
//    }, 500)
//  })
// }
})

// ===
// Private helpers
// ===

function checkAuth(routeTo, routeFrom, next) {
  if (store.getters['auth/loggedIn']) {
    //continue to the page
    next()
  } else {
// eslint-disable-next-line
    console.log("PRERETURN URL: ");// eslint-disable-next-line
    console.log(JSON.stringify(routeTo));// eslint-disable-next-line
    // console.log(routeFrom);
    localStorage.setItem('returnURL', JSON.stringify(routeTo));
    // console.log("REUTNR URL SET TO : "+JSON.stringify(routeTo));
    // console.log(routeTo);
    // console.log(routeFrom);
// eslint-disable-next-line
//     console.log(location.protocol);// eslint-disable-next-line
//     console.log(location.host);
    window.location = location.protocol + '//' + location.host + '/api/cas/redirect';
  }
}
// const scrollBehavior = function (to, from, savedPosition) {
//   if (savedPosition) {
//     // savedPosition is only available for popstate navigations.
//     return savedPosition
//   } else {
//     const position = {}
//
//     // scroll to anchor by returning the selector
//     if (to.hash) {
//       position.selector = to.hash
//
//       // specify offset of the element
//       if (to.hash === '#anchor2') {
//         position.offset = { y: 100 }
//       }
//
//       // bypass #1number check
//       if (/^#\d/.test(to.hash) || document.querySelector(to.hash)) {
//         return position
//       }
//
//       // if the returned position is falsy or an empty object,
//       // will retain current scroll position.
//       return false
//     }
//
//     return new Promise(resolve => {
//       // check if any matched route config has meta that requires scrolling to top
//       if (to.matched.some(m => m.meta.scrollToTop)) {
//         // coords will be used if no selector is provided,
//         // or if the selector didn't match any element.
//         position.x = 0
//         position.y = 0
//       }
//
//       // wait for the out transition to complete (if necessary)
//       this.app.$root.$once('triggerScroll', () => {
//         // if the resolved position is falsy or an empty object,
//         // will retain current scroll position.
//         resolve(position)
//       })
//     })
//   }
// }
