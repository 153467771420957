<template>
    <div style="position:relative" id="status_and_workflow">
        <div class="workflow_action">
            <h3>Active ODS Guest Client</h3>
            <p><a @click="showExpiredSection = !showExpiredSection">{{ showExpiredSection ? "hide" : "show" }} expiration options</a> | <a @click="showReviewSection = !showReviewSection">{{ showReviewSection ? "hide" : "show" }} review options</a></p>
            <div v-if="showExpiredSection">
                <p>If necessary, you may change the guest's status to Expired.</p>

                <el-form label-width="85px" label-position="right">
                    <el-form-item label="Reason:" prop="reason">
                        <el-select v-model="reason" size="small" class="workflow-input">
                            <el-option v-for="item in expiredReasons" :key="item.name" :label="item.name" :value="item.name"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="Comment:" prop="comment">
                        <el-input v-model="comment" size="small" class="workflow-input"></el-input>
                    </el-form-item>
                </el-form>

                <el-button type="primary" size="small" @click="onSubmit('guest_expired')">Expire</el-button>
            </div>

            <div v-if="showReviewSection">
                <p>If new documentation has been added, or new accommodations were requested, further review of this student may be necessary. You may send the student back to:</p>

                <el-button type="primary" size="small" @click="onSubmit('guest_coordinator_review')">Guest Coordinator Review</el-button>
            </div>
            <student-student-workflow-system-administrator-options :id="id" :studentName="studentName" v-on:refreshstudent="refreshStudent"></student-student-workflow-system-administrator-options>
        </div>
   </div>
</template>
<script>
    import axios from 'axios'



    export default {
        components: {

        },
        data() {
            return {
                reason: "",
                comment: "",
                expiredReasons: [],
                showExpiredSection: false,
                showReviewSection: false
            }
        },
        props: ['id', 'studentName'],
        methods: {
            load: function () {
                axios.get("/api/simple/get-expiration-reasons")
                    .then(response => {
                        this.expiredReasons = response.data.rows;
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
              },
            onSubmit(newStatus) {
                this.$message.closeAll();
                
                var params  = {id: parseInt(this.id), new_status: newStatus};

                if(newStatus === 'expired'){
                    params["expired_reason"] = this.reason;
                    params["expired_comment"] = this.comment;
                }
                axios.put("/api/student/change-status/", params)
                    .then(response => {
                        if (response.data.success) {
                            this.$emit('refreshstudent');
                        } else {
                            this.$message({
                                showClose: true,
                                duration: 20000,
                                type: 'error',
                                message: response.data.message
                            });
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            },
            refreshStudent: function(){
                this.$emit('refreshstudent');
            }
        },
        mounted() {
            this.load()
        }
    }
</script>
<style scoped>
    .workflow-input {
        width:300px
    }
</style>