<template>
  <div>
    <h2>Emergency Evacuation Information</h2>
    <el-table :data="items" style="width: 100%" >
      <el-table-column prop="start_date" label="Start Date" sortable :sort-orders="['ascending', 'descending']" width="150"><template slot-scope="scope">{{ scope.row.start_date | formatDateMDY }}</template></el-table-column>
      <el-table-column prop="building" label="Building Name" sortable :sort-orders="['ascending', 'descending']" width="200"></el-table-column>
      <el-table-column prop="room_number" label="Room Number" sortable :sort-orders="['ascending', 'descending']" width="150"></el-table-column>
      <el-table-column prop="description" label="Description of Assistance Needed" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
      <el-table-column prop="end_date" label="End Date" sortable :sort-orders="['ascending', 'descending']" width="150"><template slot-scope="scope">{{ scope.row.end_date | formatDateMDY }}</template></el-table-column>
      <el-table-column width="56"><template slot-scope="scope"><router-link :to="'/student/emergency-evacuation-edit/' + id + '?evac_id=' + scope.row.id">edit</router-link></template></el-table-column>
    </el-table>
  </div>
</template>
<script>
  import axios from 'axios'

  export default {
    data() {
      return {
        items: []
      }
    },
    props: ['id'],
    methods: {
      load: function () {
        axios
          .get('/api/student/emergency-evacs/' + this.id)
          .then(response => {
            this.items = response.data.rows;
          })
          .catch(error => {
            // eslint-disable-next-line
            console.log(error)
          });
      }
    },
    mounted() {
      this.load()
    }
  }
</script>
<style scoped>
</style>
