<template>
    <div>
        <br><hr>
        <a @click="showAdminOptions = !showAdminOptions">System Administrator Options</a>
        <template v-if="showAdminOptions">
            <h3>Workflow Actions</h3>
            <template v-for="(status,index) in availableStatuses">
                <template v-if="status.value == 'inactive'">
                    <el-button type="primary" size="small" class="system-admin-button" @click="showInactiveFields = true" :key="index + 'a'" :disabled="disableButtons">Move to '{{ status.name }}'</el-button>
                    <el-form v-if="showInactiveFields" :key="index + 'b'">
                        <el-form-item>
                            <span class="inactive-inputs-label">Reason: </span>
                            <el-select size="small" v-model="inactiveReason" class="workflow-input">
                                <el-option v-for="reason in inactiveReasons" :key="reason.name" :value="reason.name" :label="reason.name"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <span class="inactive-inputs-label">Comment: </span>
                            <el-input size="small" v-model="inactiveComment" class="workflow-input"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" size="small" @click="onChangeStatus(status.value, status.name)" :disabled="disableButtons">Deactivate</el-button>
                        </el-form-item>
                    </el-form>
                    <br :key="index + 'c'">
                </template>
                <template v-else-if="status.value == 'expired'">
                    <el-button type="primary" size="small" class="system-admin-button" @click="showExpiredFields = true" :key="index + 'd'" :disabled="disableButtons">Move to '{{ status.name }}'</el-button>
                    <el-form v-if="showExpiredFields" :key="index + 'e'">
                        <el-form-item>
                            <span class="inactive-inputs-label">Reason: </span>
                            <el-select size="small" v-model="expiredReason" class="workflow-input">
                                <el-option v-for="reason in expiredReasons" :key="reason.name" :value="reason.name" :label="reason.name"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <span class="inactive-inputs-label">Comment: </span>
                            <el-input size="small" v-model="expiredComment" class="workflow-input"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" size="small" @click="onChangeStatus(status.value, status.name)" :disabled="disableButtons">Expire</el-button>
                        </el-form-item>
                    </el-form>
                    <br :key="index + 'f'">
                </template>
                <template v-else>
                    <br v-if="status.value == 'undo'" :key="index + 'g'">
                    <el-button type="primary" size="small" class="system-admin-button" @click="onChangeStatus(status.value, status.name)" :key="index + 'h'" :disabled="disableButtons">
                        <template v-if="status.value != 'undo'">Move to '{{ status.name }}'</template><template v-else>{{ status.name }}</template>
                    </el-button>
                    <br :key="index + 'i'">
                </template>
            </template>
        </template>
   </div>
</template>
<script>
    import axios from 'axios'

    export default {
        components: {
        },
        data() {
            return {
                showAdminOptions: false,
                availableStatuses: [],
                showInactiveFields: false,
                showExpiredFields: false,
                expiredReasons: [],
                expiredReason: null,
                expiredComment: null,
                inactiveReasons: [],
                inactiveReason: null,
                inactiveComment: null,
                disableButtons: false
            }
        },
        props: ['id', 'studentName'],
        methods: {
            load: function () {
                axios.get("/api/student/available-admin-statuses/", {params:{id: this.id}})
                    .then(response => {
                        this.availableStatuses = response.data.statuses;
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });

                axios.get("/api/simple/get-expiration-reasons")
                    .then(response => {
                        this.expiredReasons = response.data.rows;
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });

                axios.get("/api/simple/get-inactive-reasons/")
                    .then(response => {
                        this.inactiveReasons = response.data.rows;
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            },
            onChangeStatus: function(statusValue, statusName) {
                this.disableButtons = true;
                this.$message.closeAll();

                var confirmMessage;

                if(statusValue == 'undo'){
                    confirmMessage = "Please confirm that you wish to undo the previous action:";
                } else {
                    confirmMessage = "Please confirm that you wish to move " + this.studentName + " to " + statusName + " status";
                }

                this.$confirm(confirmMessage)
                    .then(() => {
                        axios.put("/api/student/change-status/", {
                            id: parseInt(this.id),
                            new_status: statusValue,
                            inactive_reason: this.inactiveReason,
                            inactive_comment: this.inactiveComment,
                            expired_reason: this.expiredReason,
                            expired_comment: this.expiredComment
                        })
                        .then(response => {
                            if (response.data.success) {
                                this.disableButtons = false;
                                this.$emit('refreshstudent');
                            } else {
                                this.$message({
                                    showClose: true,
                                    duration: 20000,
                                    type: 'error',
                                    message: response.data.message
                                });
                            }
                        })
                        .catch(error => {
                            this.disableButtons = false;
                            // eslint-disable-next-line
                            console.log(error)
                        });
                    })
                    .catch(() => {
                        //confirmation gets cancelled
                        this.disableButtons = false;
                    });
            }
        },
        mounted() {
            this.load()
        }
    }
</script>
<style scoped>
    .system-admin-button{
        margin-bottom: 3px;
    }
</style>