<template>
  <div>
    <h3>Enrolled courses</h3>
    <el-table :data="courses" style="width: 100%" class="no-print"  @sort-change="onSortChange">
      <el-table-column prop="name" label="Course" sortable :sort-orders="['ascending', 'descending']" :min-width="48.1"></el-table-column>
      <el-table-column prop="course_identifier" label="Course ID" :min-width="19.2" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
      <el-table-column prop="section_code" label="Section" sortable :sort-orders="['ascending', 'descending']" :min-width="14.8"></el-table-column>
      <el-table-column label="Professor" sortable :sort-orders="['ascending', 'descending']" :min-width="25.0">
        <template slot-scope="scope">{{ scope.row.instructor_first_name }} {{ scope.row.instructor_last_name }}</template>
      </el-table-column>
      <el-table-column label="Date" sortable :sort-orders="['ascending', 'descending']" :min-width="15.8">
        <template slot-scope="scope">{{ scope.row.days.join(" ") }}</template>
      </el-table-column>
      <el-table-column label="Time" sortable :sort-orders="['ascending', 'descending']" :min-width="27.5">
        <template slot-scope="scope">
          <tr v-for="(time,index) in scope.row.times" :key="index">{{ time.start_time }} - {{ time.end_time }}</tr>
        </template>
      </el-table-column>
      <el-table-column label="Add exam on date" width="310">
        <template slot-scope="scope">
          <a v-if="!scope.row.show_add_exam" type="text" @click="scope.row.show_add_exam = true">(click to show)</a>
          <template v-if="scope.row.show_add_exam">
            <utils-date-time :initialDate="scope.row.exam.date" @update:date="val => scope.row.exam.date = val" :initialTime="scope.row.exam.time" @update:time="val => scope.row.exam.time = val" :size="'mini'"></utils-date-time>
            <el-form label-width="35px" label-position="left" class="form-small-275">
              <el-form-item label="Len">
                <el-input-number size="mini" v-model="scope.row.exam.length_hours" :min="0" :max="24" controls-position="right" class="length-input"></el-input-number> Hrs
                <el-input-number size="mini" v-model="scope.row.exam.length_minutes" :min="0" controls-position="right" class="length-input"></el-input-number> Mins
                <el-button type="primary" size="mini" @click="onSetFinalTime(scope.row.exam)" class="set-final-button">Final</el-button>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="mini" @click="onAddExam(scope.row.id, scope.row.exam)" :disabled="disableButton">Add Exam</el-button>
              </el-form-item>
            </el-form>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <div style="clear: both" class="print-only"></div>
    <table class="printable-table print-only" style="width:100%">
      <tbody>
        <tr>
          <th style="width: 26.9%">Course</th>
          <th style="width: 10.5%">Course ID</th>
          <th style="width: 7.8%">Section</th>
          <th style="width: 13.9%">Professor</th>
          <th style="width: 8.3%">Date</th>
          <th style="width: 15.0%">Time</th>
          <th style="width: 17.2%">Add Exam on Date</th>
        </tr>
        <tr v-for="course in orderedCourses" :key="course.id"  >
          <td>
            {{ course.name }}
          </td>
          <td>
            {{ course.course_identifier }}
          </td>
          <td>
            {{ course.section_code }}
          </td>
          <td>
            {{ course.instructor_first_name }} {{ course.instructor_last_name }}
          </td>
          <td>
            {{ course.days.join(" ") }}
          </td>
          <td>
            <tr v-for="(time,index) in course.times" :key="index">{{ time.start_time }} - {{ time.end_time }}</tr>
          </td>
          <td>
            <a v-if="!course.show_add_exam" type="text" @click="course.show_add_exam = true">(click to show)</a>
            <template v-if="course.show_add_exam">
              <utils-date-time :initialDate="course.exam.date" @update:date="val => course.exam.date = val" :initialTime="course.exam.time" @update:time="val => course.exam.time = val" :size="'mini'"></utils-date-time>
              <el-form label-width="35px" label-position="left" class="form-small-275">
                <el-form-item label="Len">
                  <el-input-number size="mini" v-model="course.exam.length_hours" :min="0" :max="24" controls-position="right" class="length-input"></el-input-number> Hrs
                  <el-input-number size="mini" v-model="course.exam.length_minutes" :min="0" controls-position="right" class="length-input"></el-input-number> Mins
                  <el-button type="primary" size="mini" @click="onSetFinalTime(course.exam)" class="set-final-button">Final</el-button>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" size="mini" @click="onAddExam(course.id, course.exam)" :disabled="disableButton">Add Exam</el-button>
                </el-form-item>
              </el-form>
            </template>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
  import axios from 'axios'
  import moment from 'moment'

  export default {
    components: {

    },
    data() {
      return {
        sortField: '',
        sortOrder: '',
        disableButton: false
      }
    },
    props: ['id', 'courses'],
    computed: {
      orderedCourses: function () {
        if(this.sortField != ''){
          return this.lodash.orderBy(this.courses, [this.sortField], [this.sortOrder]);
        } else {
          return this.courses;
        }
      }
    },
    methods: {
      onSortChange: function(sorter){
        this.sortField = sorter.prop;
        this.sortOrder = sorter.order == "ascending" ? "asc" : "desc";
      },
      onSetFinalTime: function(exam){
        exam.length_hours = 3;
        exam.length_minutes = 0;
        return true;
      },
      onAddExam: function(courseId, exam) {
        if(exam.date && exam.time && (exam.length_hours > 0 || exam.length_minutes > 0)){
          var params = {};

          params.course_id = courseId;
          params.exam_date = exam.date;
          params.exam_time = moment(exam.time, ["h:mm a", "h:mma", "h a", "ha"]).format("HH:mm");
          params.exam_length = (exam.length_hours * 60) + exam.length_minutes;

          this.disableButton = true;

          axios.post('/api/testing-service/create-exam/', params)
            .then(response => {
              var testing_service_id = response.data.testing_service_id;
              axios.post('/api/testing-service/add-students-to-exam/', {service_id: testing_service_id, student_ids: [parseInt(this.id)]})
                .then(response => {
                  this.$router.push({ path: '/testing/show/' + testing_service_id});
                })
                .catch(error => {

                  this.disableButton = false;
                  // eslint-disable-next-line
                  console.log(error);
                });
            })
            .catch(error => {
              // eslint-disable-next-line
              console.log(error);
            });
        }
      }
    },
    mounted() {
    }
  }
</script>
<style scoped>
  .form-small-275 {
    width: 275px;
  }
  #content .form-small-275 .el-form-item {
    margin: 0px;
  }
  #content .form-small-275 .el-form-item .el-form-item__label {
    font-size: inherit;
  }
  #content .form-small-275 .el-form-item .el-form-item__content {
    font-size: inherit;
  }
  .form-small-275 .el-input-number .el-input-number__increase {
    width: 12px;
  }
  .form-small-275 .el-input-number .el-input-number__decrease {
    width: 12px;
  }
  .form-small-275 .set-final-button {
    padding: 5px 10px;
  }
  .form-small-275 .length-input {
    width: 84px;
  }
  .form-small-275 .el-input-number.is-controls-right .el-input__inner {
    padding-right: 23.5px !important;
    padding-left: 11.5px !important;
  }
</style>
