<template>
  <div v-if="loggedIn" id="footer">
    <table class="noborder">
        <tr>
            <td>
                <!-- LEFT -->
                <table class="noborder">
                    <tr>
                        <th>Morningside (Main line)</th>
                        <td>212-854-2388</td>
                    </tr>
                    <tr>
                        <th>Morningside (Second line)</th>
                        <td>212-854-3984</td>
                    </tr>
                    <tr>
                        <th>Morningside fax</th>
                        <td>212-854-3448</td>
                    </tr>
                    <tr>
                        <th>Testing</th>
                        <td>212-854-6464</td>
                    </tr>
                </table>
            </td>
            <td></td>
            <td>
                <table class="noborder">
                    <tr>
                        <th>Cumc-(Bard 202)</th>
                        <td>212-342-2922</td>
                    </tr>
                    <tr>
                        <th>Cumc-(Bard 105)</th>
                        <td>212-304-7029</td>
                    </tr>
                </table>
            </td>
            <td></td>
            <td>
                <!-- RIGHT -->
                <table class="noborder">
                    <tr>
                        <th>&nbsp;</th>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <th>&nbsp;</th>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <th></th>
                        <td><a href="https://wikis.cuit.columbia.edu/confluence/display/DAWN/Home"
                               target="_blank">DAWN</a></td>
                    </tr>
                    <tr>
                        <th></th>
                        <td><a href="https://doristest.health.columbia.edu/" target="_blank">DORIS Test Server</a></td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>
</div>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    computed: {
      ...mapGetters('auth', ['loggedIn'])
    }
  }
</script>

<style lang="scss" scoped>
</style>
