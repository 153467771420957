 <template>
  <div class="panel medium-block">
  <h2>Students by Coordinator</h2>

  <table>
  <tbody><tr>
    <th></th>
    <th>Active</th>
    <th>Coordinator Review</th>
    <th>Prospective</th>
    <th>1 Doc Submitted</th>
    <th>Pending Orientation</th>
    <th>Registered Temporary</th>
    <th>Registered Tenure</th>
    <th>Intensive Case Management</th>
  </tr>

  <tr v-for="item in items" :key="item.id">
    <td><b><a :href="'/student/groups/?id=111&coordinator_id=' + item.id">{{ item.name }}</a></b></td>
    <td><a :href="'/student/groups/?id=111&coordinator_id=' + item.id">{{ item.active }}</a></td>
    <td><a :href="'/student/groups/?id=114&status=coordinator_review&coordinator_id=' + item.id">{{ item.coordinator_review }}</a></td>
    <td><a :href="'/student/groups/?id=114&status=prospective&coordinator_id=' + item.id">{{ item.prospective }}</a></td>
    <td><a :href="'/student/groups/?id=114&status=one_doc_submitted&coordinator_id=' + item.id">{{ item.one_doc_submitted }}</a></td>
    <td><a :href="'/student/groups/?id=114&status=pending_orientation&coordinator_id=' + item.id">{{ item.pending_orientation }}</a></td>
    <td><a :href="'/student/groups/?id=112&coordinator_id=' + item.id">{{ item.registered_temporary }}</a></td>
    <td><a :href="'/student/groups/?id=113&coordinator_id=' + item.id">{{ item.registered_tenure }}</a></td>
    <td><a :href="'/student/groups/?id=160&coordinator_id=' + item.id">{{ item.intensive_case }}</a></td>
  </tr>
  </tbody></table>

</div>
</template>
<script>
  import axios from 'axios'

    export default {
        components: {
        },
        data() {
            return {
                items: []
            }
        },
        methods: {
            load: function () {
                axios
                    .get('/api/student/students-by-coordinator/')
                    .then(response => {
                        this.items = response.data.rows.sort(function(a, b){
                          var nameA = a.name;
                          var nameB = b.name
                          if (nameA === nameB) { return 0 }
                          if (nameA < nameB) { return -1 }
                          if (nameA > nameB) { return 1 }
                        });
                    })
                    .catch(error => {
                      // eslint-disable-next-line
                        console.log(error)
                    });
            }
        },
        mounted() {
            this.load()
        }
    }
</script>
<style scoped></style>