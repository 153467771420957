<template>
  <div v-if="loggedIn" id="main_nav">
    <ul>
      <li v-bind:class="{ selected: isSelected('/') }"><router-link to="/">My Home Page</router-link></li>
      <li v-bind:class="{ selected: isSelected('/search') }"><router-link to="/search">Search</router-link></li>
      <li v-bind:class="{ selected: isSelected('/student/status-data') }"><router-link to="/student/status-data">Status Data</router-link></li>
      <li v-bind:class="{ selected: isSelected('/contact-notes') }" ><router-link to="/contact-notes">Contact Notes</router-link></li>
      <li v-bind:class="{ selected: isSelected('/tasks') }"><router-link to="/tasks">Tasks</router-link></li>
      <li v-bind:class="{ selected: isSelected('/documents') }"><router-link to="/document">Documents</router-link></li>
      <li v-bind:class="{ selected: isSelected('/services') }"><router-link to="/service">Services</router-link></li>
      <li v-bind:class="{ selected: isSelected('/reports') }"><router-link to="/report">Reports</router-link></li>
      <li v-bind:class="{ selected: isSelected('/group-email') }"><router-link to="/group-email">Group Email</router-link></li>
    </ul>
    <!--<pre>{{ JSON.stringify($route, null, 2) }}</pre>-->
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  export default {
    data() {
      return {
        // user: {}
      }
    },
    methods: {
      // getUser: function() {
      //   axios.get("/api/user")
      //     .then(response => {
      //       if(response.data.success == true) {
      //         this.user = response.data.user;
      //         this.isDev = response.data.isDev;
      //         if(response.data.isDev) {
      //           // document.getElementById("container").style.backgroundImage = 'url(' + this.devbg + ')';
      //         }
      //       } else {
      //         // eslint-disable-next-line
      //         console.log("failed to get user");
      //       }
      //     })
      //     .catch(error => {
      //       // eslint-disable-next-line
      //       console.log(error)
      //     });
      //},
      isSelected: function(selection) {
        if(this.$route.path == selection) return true;
      }
    },
    computed: {
      ...mapGetters('auth', ['loggedIn'])
    },
    created () {
      //this.getUser();
    },
    mounted () {
    }


  }
</script>
