<template>
    <div>
        <h2>Registration History</h2>
        <el-table v-if="registrations.length > 0" :data="registrations" class="registrations-table">
            <el-table-column prop="name" label="Term" width="115"></el-table-column>
            <el-table-column prop="enrollment" label="Enrollment" width="125"></el-table-column>
            <el-table-column prop="school" label="School"></el-table-column>
        </el-table>
        <p v-if="registrations.length == 0">None</p>
    </div>
</template>
<script>
    export default {
        data() {
            return {
            }
        },
        props: ['registrations'],
        methods: {
        }
    }
</script>
<style scoped>
    .registrations-table {
        width: 645px;
    }
</style>