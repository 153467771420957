import { render, staticRenderFns } from "./CurrentSemester.vue?vue&type=template&id=e0cb5d3a&scoped=true&"
import script from "./CurrentSemester.vue?vue&type=script&lang=js&"
export * from "./CurrentSemester.vue?vue&type=script&lang=js&"
import style0 from "./CurrentSemester.vue?vue&type=style&index=0&id=e0cb5d3a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../opt/jenkins_node_modules/doris/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e0cb5d3a",
  null
  
)

export default component.exports