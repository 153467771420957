<template>
  <div>
    <h3>{{ isCompletedExams ? 'Completed' : 'Cancelled'}} exams ({{ exams.length }})</h3>
    <el-table :default-sort="{prop: 'start_time', order: 'descending'}" :data="exams" style="width: 100%"
              :row-class-name="setRowClass"
              class="no-print"  @sort-change="onSortChange">
      <el-table-column label="Course" sortable :sort-orders="['ascending', 'descending']" :min-width="14.0">
        <template slot-scope="scope">
          <router-link :to="{ path: '/testing/show/' + scope.row.service_id + '/#'  + id}">{{ scope.row.class_name }}</router-link>
        </template>
      </el-table-column>
      <el-table-column prop="start_time" label="Date" :min-width="10.0" sortable :sort-orders="['ascending', 'descending']">
        <template slot-scope="scope">
          {{ scope.row.start_time | formatDateMDY }}
        </template>
      </el-table-column>
      <el-table-column prop="instructor_name" label="Professor" sortable :sort-orders="['ascending', 'descending']" :min-width="10.0"></el-table-column>
      <el-table-column prop="start_time" label="Start" sortable :sort-orders="['ascending', 'descending']"   width="70" >
        <template slot-scope="scope">
          {{ scope.row.start_time | formatTimehma }}
        </template>
      </el-table-column>
      <el-table-column prop="end_time" label="Finish" sortable :sort-orders="['ascending', 'descending']"   width="70" >
        <template slot-scope="scope">
          {{ scope.row.end_time | formatTimehma }}
        </template>
      </el-table-column>
      <el-table-column label="Proctoring Session" sortable :sort-orders="['ascending', 'descending']" :min-width="12.0">
        <template slot-scope="scope">
          <router-link v-if="scope.row.proctoring_session_id" :to="'/proctoring-session/show/' + scope.row.proctoring_session_id">{{ scope.row.location }}</router-link>
          <template v-if="!scope.row.proctoring_session_id">N/A</template>
        </template>
      </el-table-column>
      <el-table-column label="Student Comments" sortable :sort-orders="['ascending', 'descending']" :min-width="67.5">
        <template slot-scope="scope">
          <template v-for="email in scope.row.exam_emails">
            <template v-if="!email.is_exam_location_email">R - {{ email.created_at | formatDateTimeMDYhma }}<br :key="email.id"></template>
            <span :key="email.id" v-if="email.is_exam_location_email" class="color-red">L - {{ email.created_at | formatDateTimeMDYhma }}<br></span>
          </template>
          <span class="formatted-text color-red" :class="{ 'formatted-text': !scope.row.show_comments_more, 'hidden-text': scope.row.show_comments_more }">{{ scope.row.comments }}</span>
          <a v-if="scope.row.show_comments_more" @click="scope.row.show_comments_more = false">More</a>
          <template v-if="!isCompletedExams"><br><br>Cancellation reason: {{ scope.row.cancellation_reason == "Other" ? scope.row.other_cancellation_reason : scope.row.cancellation_reason }}</template>
          <template v-if="scope.row.computer_use_notes && scope.row.computer_use_notes != ''">
            <br><b>Computer Use Notes</b><br>
            <span class="formatted-text">{{ scope.row.computer_use_notes }}</span>
          </template>
        </template>
      </el-table-column>
      <el-table-column label="Exam Comments" sortable :sort-orders="['ascending', 'descending']" :min-width="67.5">
        <template slot-scope="scope">
          <template v-if="scope.row.listening_film_options && scope.row.listening_film_options.length"><b>Prof will:</b><br></template>
          <template v-for="(option, index) in scope.row.listening_film_options">{{ option }}<br :key="index"/></template>
          <div v-if="scope.row.is_missing_exam_specs" class="color-aqua">Missing Exam Specs</div>
          <template v-if="scope.row.delivery_instructions.indexOf('Urgent Delivery') !== -1"><span class="color-red"><b>Urgent Delivery</b><br><span v-if="scope.row.urgent_delivery_instructions !== ''" class="formatted-text">{{ scope.row.urgent_delivery_instructions }}</span></span></template>
          <template v-for="comment in scope.row.exam_comments">
                        <span :key="comment.id" v-if="comment.content != ''" :class="{ 'color-red': comment.content.length > 80, 'formatted-text': !comment.show_exam_comment_more, 'hidden-text': comment.show_exam_comment_more }" class="formatted-text">{{ comment.content }}
                            <br>
                            <a v-if="comment.show_exam_comment_more" @click="comment.show_exam_comment_more = false">More</a>
                            <br>
                        </span>
          </template>
          <template v-if="scope.row.legacy_exam_comments"><br>{{ scope.row.legacy_exam_comments }}</template>
        </template>
      </el-table-column>
    </el-table>
    <div style="clear: both" class="print-only"></div>
    <table class="printable-table print-only"  >
      <tbody>
        <tr>
          <th style="width: 7.3%">Course</th>
          <th style="width: 5.1%">Date</th>
          <th style="width: 5.1%">Professor</th>
          <th style="width: 3.5%">Start</th>
          <th style="width: 3.5%">Finish</th>
          <th style="width: 6.1%">Proctoring Session</th>
          <th style="width: 34.5%">Student Comments</th>
          <th style="width: 34.5%">Exam Comments</th>
        </tr>
        <tr v-for="exam in orderedExams" :key="exam.id" :class="{
          'cancelled-exam-row': exam.status == 'cancelled',
          'placeholder-exam-row': exam.is_placeholder_exam,
           'virtual-ds-proctored-row': (exam.exam_type === 'Virtual' && exam.virtual_exam_type === 'Proctored by DS')}">
          <td>
            <router-link :to="{ path: '/testing/show/' + exam.service_id + '/#'  + id}">{{ exam.class_name }}</router-link>
          </td>
          <td>
            {{ exam.start_time | formatDateMDY }}
          </td>
          <td>
            {{ exam.instructor_name }}
          </td>
          <td>
            {{ exam.start_time | formatTimehma }}
          </td>
          <td>
            {{ exam.end_time | formatTimehma }}
          </td>
          <td>
            <router-link v-if="exam.proctoring_session_id" :to="'/proctoring-session/show/' + exam.proctoring_session_id">{{ exam.location }}</router-link>
            <template v-if="!exam.proctoring_session_id">N/A</template>
          </td>
          <td>
            <template v-for="email in exam.exam_emails">
              <template v-if="!email.is_exam_location_email">R - {{ email.created_at | formatDateTimeMDYhma }}<br :key="email.id"></template>
              <span :key="email.id" v-if="email.is_exam_location_email" class="color-red">L - {{ email.created_at | formatDateTimeMDYhma }}<br></span>
            </template>
            <span class="formatted-text color-red" :class="{ 'formatted-text': !exam.show_comments_more, 'hidden-text': exam.show_comments_more }">{{ exam.comments }}</span>
            <a v-if="exam.show_comments_more" @click="exam.show_comments_more = false">More</a>
            <template v-if="!isCompletedExams"><br><br>Cancellation reason: {{ exam.cancellation_reason == "Other" ? exam.other_cancellation_reason : exam.cancellation_reason }}</template>
            <template v-if="exam.computer_use_notes && exam.computer_use_notes != ''">
              <br><b>Computer Use Notes</b><br>
              <span class="formatted-text">{{ exam.computer_use_notes }}</span>
            </template>
          </td>
          <td>
            <template v-if="exam.listening_film_options && exam.listening_film_options.length"><b>Prof will:</b><br></template>
            <template v-for="(option, index) in exam.listening_film_options">{{ option }}<br :key="index"/></template>
            <div v-if="exam.is_missing_exam_specs" class="color-aqua">Missing Exam Specs</div>
            <template v-if="exam.delivery_instructions.indexOf('Urgent Delivery') !== -1"><span class="color-red"><b>Urgent Delivery</b><br><span v-if="exam.urgent_delivery_instructions !== ''" class="formatted-text">{{ exam.urgent_delivery_instructions }}</span></span></template>
            <template v-for="comment in exam.exam_comments">
                        <span :key="comment.id" v-if="comment.content != ''" :class="{ 'color-red': comment.content.length > 80, 'formatted-text': !comment.show_exam_comment_more, 'hidden-text': comment.show_exam_comment_more }" class="formatted-text">{{ comment.content }}
                            <br>
                            <a v-if="comment.show_exam_comment_more" @click="comment.show_exam_comment_more = false">More</a>
                            <br>
                        </span>
            </template>
            <template v-if="exam.legacy_exam_comments"><br>{{ exam.legacy_exam_comments }}</template>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>

  export default {
    data() {
      return {
        sortField: 'start_time',
        sortOrder: 'desc'
      }
    },
    computed: {
      orderedExams: function () {
        if(this.sortField != ''){
          return this.lodash.orderBy(this.exams, [this.sortField], [this.sortOrder]);
        } else {
          return this.exams;
        }
      }
    },
    props: ['id', 'exams', 'isCompletedExams'],
    methods: {
      onSortChange: function(sorter){
        this.sortField = sorter.prop;
        this.sortOrder = sorter.order == "ascending" ? "asc" : "desc";
      },
      setRowClass: function({row, rowIndex}){
          if(row.status === 'cancelled'){
              return "cancelled-exam-row"
          } else if (row.exam_type === 'Virtual' && row.virtual_exam_type === 'Proctored by DS') {
              return "virtual-ds-proctored-row"
          }
      },
    },
    mounted() {
    }
  }
</script>
<style scoped>
  .cancelled-exams-table tr {
    background-color: #fcff00;
  }
  .exams-table td {
    vertical-align: top;
  }
</style>
