<template>
<div>
  <template v-if="faculty.length">
    <h1>{{ formattedTitle }}</h1>
    <p><a :href="'/facutly/new?'">Create a new faculty record</a></p>
    <el-table :data="faculty" style="width: 100%">
      <el-table-column prop="uni" label="UNI" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
      <el-table-column prop="first" label="First" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
      <el-table-column prop="last" label="Last" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
      <el-table-column prop="ods_status" label="ODS Status" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
      <el-table-column prop="coordinator" label="Coordinator" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
      <el-table-column prop="last_updated" label="Last Updated" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
      <el-table-column width="64"><template slot-scope="scope"><a :href="'/faculty/show/' + scope.row.id">show</a></template></el-table-column>
    </el-table>
    <a :href="'/api/faculty/groups-download.xlsx' +this.searchParams" target="_blank">Download as CSV</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;({{ this.faculty.length}} faculty record{{ this.faculty.length > 1 ? 's' : '' }})
  </template>
  <template v-else>
    <h1>No faculty matched your search terms.</h1>
    <p>You may <a :href="'/faculty/'">search again</a>, or</p>
    <p><a :href="'/faculty/new/'">Create a new faculty record</a></p>
  </template>
</div>
</template>
<script>
  import axios from 'axios'

  export default {
    data() {
      return {
        coordinatorName: null,
        title: null,
        faculty: []
      }
    },
    props: ['urlParams'],
    computed: {
      formattedTitle: function() {
        var formattedTitle = "";

        if(this.title){
          formattedTitle += this.title;
        }

        if(this.coordinatorName){
          formattedTitle += " - " + this.coordinatorName;
        }

        return formattedTitle;
      },
        groupParams: function() {
            var paramsString = "";

            if(this.urlParams.groups){
                paramsString += "[";

                for(var i=0; i<this.urlParams.groups.length; i++){
                    paramsString += "%22"+this.urlParams.groups[i]+"%22";

                    if(i < this.urlParams.groups.length-1){
                        paramsString += ",";
                    }
                }

                paramsString += "]";
            }

            return paramsString;
        }
    },
    methods: {
      load: function () {
          this.searchParams = location.search;
        axios
            .get('/api/faculty/groups/'+location.search)
            .then(response => {
              this.coordinatorName = response.data.coordinator_name;
              this.faculty = response.data.rows;
              this.title = response.data.title;
            })
            .catch(error => {
              // eslint-disable-next-line
                console.log(error)
            });
      }
    },
    mounted() {
        this.load()
    }
  }
</script>
<style scoped>
</style>