 <template>
  <div class="panel  medium-block" style="margin-top: 20px">
  <h2>Faculty by Coordinator</h2>

  <table>
  <tbody><tr v-for="item in items" :key="item.id"><th>

    <a :href="'/faculty/groups/?id=103'" v-if="item.id=='unassigned'">{{ item.name }}</a>
    <a :href="'/faculty/groups/?id=102&coordinator_id=' + item.id" v-else>{{ item.name }}</a>
    </th><td>{{ item.value }}</td></tr>

  </tbody></table>
</div>
</template>
<script>
  import axios from 'axios'

    export default {
        components: {
        },
        data() {
            return {
                items: []
            }
        },
        methods: {
            load: function () {
                axios
                    .get('/api/student/faculty-by-coordinator/')
                    .then(response => {
                        response.data.rows.forEach(function(row){
                            if (row.id == -1){
                                row.id = "unassigned";
                            }
                        });
                        this.items = response.data.rows;
                    })
                    .catch(error => {
                      // eslint-disable-next-line
                        console.log(error)
                    });
            }
        },
        mounted() {
            this.load()
        }
    }
</script>
<style scoped></style>