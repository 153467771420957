<template>
    <div>

        <el-table :data="exams" style="width: 100%" class="proctoring-sessions-exams-table no-print" :row-class-name="getRowClassName" @sort-change="onSortChange">
            <el-table-column prop="student_name" label="Student Name" sortable :sort-orders="['ascending', 'descending']" :min-width="8.5">
                <template slot-scope="scope">
                    <a :href="'/testing/show/' + scope.row.service_id">{{ scope.row.student_name }} &lt;{{ scope.row.uni }}&gt;</a>
                </template>
            </el-table-column>
            <el-table-column prop="start_time" label="Start" sortable :sort-orders="['ascending', 'descending']"  width="70">
                <template slot-scope="scope">
                    {{ scope.row.start_time | formatTimehhmmA }}
                </template>
            </el-table-column>
            <el-table-column prop="end_time" label="Finish" sortable :sort-orders="['ascending', 'descending']"  width="70">
                <template slot-scope="scope">
                    {{ scope.row.end_time | formatTimehhmmA }}
                </template>
            </el-table-column>
            <el-table-column prop="department" label="Class" sortable :sort-orders="['ascending', 'descending']" :min-width="11.0">
                <template slot-scope="scope">
                    {{ scope.row.department }} {{ scope.row.course_number }} SEC <b>{{ scope.row.section_code }}</b><br>{{ scope.row.class_name }}
                </template>
            </el-table-column>
            <el-table-column prop="instructor_name" label="Professor" sortable :sort-orders="['ascending', 'descending']" :min-width="18.3">
                <template slot-scope="scope">
                    {{ scope.row.instructor_name }}<br>{{ scope.row.instructor_email }}
                    <template v-if="scope.row.alternate_instructor_email"><br><span style="color:red;" class="text-italic">{{ scope.row.alternate_instructor_email }}</span></template>
                    <template v-if="scope.row.secondary_alternate_instructor_email"><br><span style="color:red;" class="text-italic">{{ scope.row.secondary_alternate_instructor_email }}</span></template>
                    <template v-if="scope.row.teaching_assistants && scope.row.teaching_assistants.length > 2">
                        <br><span style="color:#212488;">({{ scope.row.teaching_assistants.length }}) TAs listed</span>
                    </template>
                    <template v-else>
                        <template v-for="(ta) in scope.row.teaching_assistants">
                            <br :key="ta.id + '-br'"><span style="color:#212488;" :key="ta.id">TA - {{ ta.first_name }} {{ ta.last_name }}</span>
                        </template>
                    </template>
                </template>
            </el-table-column>
            <el-table-column prop="exam_received" label="Exam Received" sortable :sort-orders="['ascending', 'descending']" :min-width="6.1">
                <template slot-scope="scope">
                    <a :href="'/testing/show/' + scope.row.id">{{ scope.row.exam_received ? "Yes" : "No" }}</a>
                </template>
            </el-table-column>
            <el-table-column label="Exam Comments" :min-width="7.5">
                <template slot-scope="scope">
                    <template v-if="scope.row.exam_permissions && scope.row.exam_permissions.indexOf('Listening section or film clip') !== -1">
                        <template v-if="scope.row.listening_film_options && scope.row.listening_film_options.length">
                            <div style="color:red;">
                                <b>Prof. will:</b><br>
                                <template v-for="option in scope.row.listening_film_options">- {{ option }}</template>
                            </div>
                        </template>
                    </template>
                    <div v-if="scope.row.is_missing_exam_specs" class="color-aqua">- Missing Exam Specs</div>
                    <template v-if="scope.row.urgent_delivery_instructions && scope.row.urgent_delivery_instructions.indexOf('Urgent Delivery') !== -1">
                        <div style="color:red;">
                            <b>Urgent Delivery</b><br>
                            <template v-if="instruction !== 'Urgent Delivery'">
                                <template v-for="instruction in scope.row.urgent_delivery_instructions" >{{ instruction }}<br :key="instruction.id"></template>
                            </template>
                        </div>
                    </template>
                    <template v-for="comment in scope.row.exam_comments">
                        <span :key="comment.id" v-if="comment.content != ''" :class="{ 'formatted-text': !comment.show_exam_comment_more, 'hidden-text': comment.show_exam_comment_more }" class="formatted-text">{{ comment.content }}
                            <br>
                            <a v-if="comment.show_exam_comment_more" @click="comment.show_exam_comment_more = false">More</a>
                            <br>
                        </span>
                    </template>
                    <template v-if="scope.row.legacy_exam_comments && scope.row.legacy_exam_comments != ''">
                        <br><b>Archived comments</b><br>
                        {{ scope.row.legacy_exam_comments }}
                    </template>
                    <template v-if="scope.row.computer_use_notes && scope.row.computer_use_notes != ''">
                        <br><b>Archived comments</b><br>
                        {{ scope.row.computer_use_notes }}
                    </template>
                    <template v-if="scope.row.exam_updates && scope.row.exam_updates.length">
                        <template v-for="update in scope.row.exam_updates">
                            <br :key="update.id">
                            <span style="color:red;"  :key="update.id">{{ update.name }}<template v-if="update.other">: {{ update.other }}</template><br>{{ update.content }}</span>
                            <template v-if="update.exam_updates && update.exam_updates.length">
                                <template v-for="file in update.exam_updates">
                                    <br :key="file.id">
                                    <a :href="'/testing/show/' + scope.row.id"  :key="file.id">Exam update</a>
                                </template>
                            </template>
                        </template>
                    </template>
                </template>
            </el-table-column>
            <el-table-column prop="exam_emails" label="Student Reminders(R), Locations Reminders (L) and Comments" :min-width="15.4">
                <template slot-scope="scope">
                    <template v-for="email in scope.row.exam_emails">
                        <template v-if="!email.is_exam_location_email">R - {{ email.created_at | formatDateTimeMDYhma }}<br :key="email.id"></template>
                        <span v-if="email.is_exam_location_email" class="color-red" :key="email.id">L - {{ email.created_at | formatDateTimeMDYhma }}<br></span>
                    </template>
                    <span class="formatted-text" :class="{ 'formatted-text': !scope.row.show_comments_more, 'hidden-text': scope.row.show_comments_more }"><a :href="'/testing/show/' + scope.row.id" style="color:red;">{{ scope.row.comments }}</a></span>
                    <a v-if="scope.row.show_comments_more" @click="scope.row.show_comments_more = false">More</a>
                </template>
            </el-table-column>
            <el-table-column prop="exam_equipment" label="Equipment" :min-width="17.5">
                <template slot-scope="scope">
                    <template v-if="scope.row.exam_equipment && scope.row.exam_equipment.indexOf('Computer') !== -1">
                        1 Computer – Exam Admin ({{ scope.row.admin_computer_type_preference ? scope.row.admin_computer_type_preference : "Any" }})<br>
                    </template>
                    <template v-if="studentComputer(scope.row.enrollment_accommodations)">
                        1 Computer – Student Use ({{ scope.row.ultimate_computer_choice }})<br>
                    </template>
                    <template v-for="equipment in scope.row.exam_equipment">
                        <template v-if="equipment != 'Computer'">
                            {{ equipment }}<br :key="equipment"/>
                        </template>
                    </template>
                    <template v-if="scope.row.exam_equipment.length === 0 && !studentComputer(scope.row.enrollment_accommodations)">None</template>
                </template>
            </el-table-column>
            <el-table-column label="Student Accommodations" :min-width="30.0">
                <template slot-scope="scope">
                    <template v-for="accommodation in scope.row.enrollment_accommodations">
                        <template v-if="accommodation.name != 'Rest Breaks'">
                            <template v-if="accommodation.accommodation_type_id == 8">
                                <span :key="accommodation.id + '-span1'" :class="{ 'color-red': accommodation.highlight_accommodation }">- {{ accommodation.display_value }}: {{ scope.row.ultimate_computer_choice }}</span><br :key="accommodation.id + '-br1'">
                            </template>
                            <template v-else-if="accommodation.name == 'Other Testing Accommodation'">
                                <span :key="accommodation.id + '-span2'" :class="{ 'color-red': accommodation.highlight_accommodation }">- {{ accommodation.description }}</span><br :key="accommodation.id + '-br2'">
                            </template>
                            <template v-else>
                                <span :key="accommodation.id + '-span3'" :class="{ 'color-red': accommodation.highlight_accommodation }">- {{ accommodation.display_value ? accommodation.display_value : accommodation.name }}</span><br :key="accommodation.id + '-br3'">
                            </template>
                        </template>
                    </template>
                </template>
            </el-table-column>
        </el-table>

        <div style="clear: both" class="print-only"></div>
        <table class="printable-table print-only">
            <tbody>
            <tr>
                <th style="width: 6.6%">Student Name</th>
                <th style="width: 4.8%">Start</th>
                <th style="width: 4.8%">Finish</th>
                <th style="width: 8.8%">Class</th>
                <th style="width: 14.5%">Professor</th>
                <th style="width: 4.8%">Exam Received</th>
                <th style="width: 5.6%">Exam Comments</th>
                <th style="width: 12.0%">Student Reminders(R), Locations Reminders (L) and Comments</th>
                <th style="width: 13.7%">Equipment</th>
                <th style="width: 24.1%">Student Accommodations</th>
            </tr>
            <tr v-for="exam in orderedExams" :key="exam.id" :class="{
              'placeholder-exam-row': exam.is_placeholder_exam,
              'virtual-ds-proctored-row': (exam.exam_type === 'Virtual' && exam.virtual_exam_type === 'Proctored by DS')
            }">
                <td><a :href="'/testing/show/' + exam.service_id">{{ exam.student_name }} &lt;{{ exam.uni }}&gt;</a></td>

                <td>{{ exam.start_time | formatTimehhmmA }}</td>
                <td>{{ exam.end_time | formatTimehhmmA }}</td>
                <td>{{ exam.department }} {{ exam.course_number }} SEC <b>{{ exam.section_code }}</b><br>{{ exam.class_name }}</td>
                <td>
                    {{ exam.instructor_name }}<br>{{ exam.instructor_email }}
                    <template v-if="exam.alternate_instructor_email"><br><span style="color:red;" class="text-italic">{{ exam.alternate_instructor_email }}</span></template>
                    <template v-if="exam.secondary_alternate_instructor_email"><br><span style="color:red;" class="text-italic">{{ exam.secondary_alternate_instructor_email }}</span></template>
                    <template v-if="exam.teaching_assistants && exam.teaching_assistants.length > 2">
                        <br><span style="color:#212488;">({{ exam.teaching_assistants.length }}) TAs listed</span>
                    </template>
                    <template v-else>
                        <template v-for="(ta) in exam.teaching_assistants">
                            <br :key="ta.id + '-br'"><span style="color:#212488;" :key="ta.id">TA - {{ ta.first_name }} {{ ta.last_name }}</span>
                        </template>
                    </template>
                </td>
                <td><a :href="'/testing/show/' + exam.id">{{ exam.exam_received ? "Yes" : "No" }}</a></td>
                <td>
                    <template v-if="exam.exam_permissions && exam.exam_permissions.indexOf('Listening section or film clip') !== -1">
                        <template v-if="exam.listening_film_options && exam.listening_film_options.length">
                            <div style="color:red;">
                                <b>Prof. will:</b><br>
                                <template v-for="option in exam.listening_film_options">- {{ option }}</template>
                            </div>
                        </template>
                    </template>
                    <div v-if="exam.is_missing_exam_specs" class="color-aqua">- Missing Exam Specs</div>
                    <template v-if="exam.urgent_delivery_instructions && exam.urgent_delivery_instructions.indexOf('Urgent Delivery') !== -1">
                        <div style="color:red;">
                            <b>Urgent Delivery</b><br>
                            <template v-if="instruction !== 'Urgent Delivery'">
                                <template v-for="instruction in exam.urgent_delivery_instructions" >{{ instruction }}<br :key="instruction.id"></template>
                            </template>
                        </div>
                    </template>
                    <template v-for="comment in exam.exam_comments">
                        <span :key="comment.id" v-if="comment.content != ''" :class="{ 'formatted-text': !comment.show_exam_comment_more, 'hidden-text': comment.show_exam_comment_more }" class="formatted-text">{{ comment.content }}
                            <br>
                            <a v-if="comment.show_exam_comment_more" @click="comment.show_exam_comment_more = false">More</a>
                            <br>
                        </span>
                    </template>
                    <template v-if="exam.legacy_exam_comments && exam.legacy_exam_comments != ''">
                        <br><b>Archived comments</b><br>
                        {{ exam.legacy_exam_comments }}
                    </template>
                    <template v-if="exam.computer_use_notes && exam.computer_use_notes != ''">
                        <br><b>Archived comments</b><br>
                        {{ exam.computer_use_notes }}
                    </template>
                    <template v-if="exam.exam_updates && exam.exam_updates.length">
                        <template v-for="update in exam.exam_updates">
                            <br :key="update.id">
                            <span style="color:red;"  :key="update.id">{{ update.name }}<template v-if="update.other">: {{ update.other }}</template><br>{{ update.content }}</span>
                            <template v-if="update.exam_updates && update.exam_updates.length">
                                <template v-for="file in update.exam_updates">
                                    <br :key="file.id">
                                    <a :href="'/testing/show/' + exam.id"  :key="file.id">Exam update</a>
                                </template>
                            </template>
                        </template>
                    </template>
                </td>
                <td>
                    <template v-for="email in exam.exam_emails">
                        <template v-if="!email.is_exam_location_email">R - {{ email.created_at | formatDateTimeMDYhma }}<br :key="email.id"></template>
                        <span v-if="email.is_exam_location_email" class="color-red" :key="email.id">L - {{ email.created_at | formatDateTimeMDYhma }}<br></span>
                    </template>
                    <span class="formatted-text" :class="{ 'formatted-text': !exam.show_comments_more, 'hidden-text': exam.show_comments_more }"><a :href="'/testing/show/' + exam.id" style="color:red;">{{ exam.comments }}</a></span>
                    <a v-if="exam.show_comments_more" @click="exam.show_comments_more = false">More</a>
                </td>
                <td>
                    <template v-if="exam.exam_equipment && exam.exam_equipment.indexOf('Computer') !== -1">
                        1 Computer – Exam Admin ({{ exam.admin_computer_type_preference ? exam.admin_computer_type_preference : "Any" }})<br>
                    </template>
                    <template v-if="studentComputer(exam.enrollment_accommodations)">
                        1 Computer – Student Use ({{ exam.ultimate_computer_choice }})<br>
                    </template>
                    <template v-for="equipment in exam.exam_equipment">
                        <template v-if="equipment != 'Computer'">
                            {{ equipment }}<br :key="equipment"/>
                        </template>
                    </template>
                    <template v-if="exam.exam_equipment.length === 0 && !studentComputer(exam.enrollment_accommodations)">None</template>
                </td>
                <td>
                    <template v-for="accommodation in exam.enrollment_accommodations">
                        <template v-if="accommodation.name != 'Rest Breaks'">
                            <template v-if="accommodation.accommodation_type_id == 8">
                                <span :key="accommodation.id + '-span1'" :class="{ 'color-red': accommodation.highlight_accommodation }">- {{ accommodation.display_value }}: {{ exam.ultimate_computer_choice }}</span><br :key="accommodation.id + '-br1'">
                            </template>
                            <template v-else-if="accommodation.name == 'Other Testing Accommodation'">
                                <span :key="accommodation.id + '-span2'" :class="{ 'color-red': accommodation.highlight_accommodation }">- {{ accommodation.description }}</span><br :key="accommodation.id + '-br2'">
                            </template>
                            <template v-else>
                                <span :key="accommodation.id + '-span3'" :class="{ 'color-red': accommodation.highlight_accommodation }">- {{ accommodation.display_value ? accommodation.display_value : accommodation.name }}</span><br :key="accommodation.id + '-br3'">
                            </template>
                        </template>
                    </template>
                </td>
            </tr>


            </tbody>
        </table>
    </div>
</template>
<script>

    export default {
        data() {
            return {

                filter1: 'all',
                filter2: 'all',
                filter3: 'all',
                sortField: 'start_time',
                sortOrder: 'asc'
            }
        },
        props: ['exams'],
        computed: {
          orderedExams: function () {
// eslint-disable-next-line
            return this.lodash.orderBy(this.exams, [this.sortField], [this.sortOrder])
          }
        },
        methods: {
            onSortChange: function(sorter){
               this.sortField = sorter.prop;
               this.sortOrder = sorter.order == "ascending" ? "asc" : "desc";
            },
            getRowClassName: function({row, rowIndex}) {
                if(row.is_placeholder_exam){
                    return "placeholder-exam-row";
                } else if(row.exam_type === 'Virtual' && row.virtual_exam_type === 'Proctored by DS') {
                    return "virtual-ds-proctored-row"
                }
            },
            studentComputer: function(accommodations){
                if(accommodations){
                    for(var i = 0; i < accommodations.length; i++){
                        if(accommodations[i].accommodation_type_id == 8){
                            return true;
                        }
                    }
                }
                return false;
            }
        },
        mounted() {
        }
    }
</script>
<style scoped>
    tr.placeholder-exam-row {
        background-color: #ffdafe;
        font-weight: bold;
        font-style: italic;
    }
    tr.virtual-ds-proctored-row {
      background-color: #d7ece2;
      font-weight: bold;
      font-style: italic;
    }
    .exams-table th, .exams-table td{
        vertical-align: top;
        font-size: 11.7px;
    }
</style>