<template>
<div id="header">

    <div v-if="loggedIn" id="login_info">
        <router-link to="/user">{{name}}</router-link> ::

      <template v-if="isAdmin"><router-link to="/admin">CUODS Admin</router-link> :: </template>

        <a href="/docs/">Docs</a> ::

        <router-link to="/logout">Logout</router-link>
    </div>

    <h1>
        <router-link to="/"><img :src="logo" alt="Disability Office Record Information System" border="0"/></router-link>
    </h1>

    <!--
    <h1 class="print-only">
        <a href="/home/index">
            <img src="/images/logo/ods_logo_print.png" alt="Disability Office Record Information System" border="0"/>
        </a>
    </h1>
    -->

</div>
</template>

<script>
    /* eslint-disable */

    // https://tc39.github.io/ecma262/#sec-array.prototype.find
    if (!Array.prototype.find) {
        Object.defineProperty(Array.prototype, 'find', {
            value: function(predicate) {
                // 1. Let O be ? ToObject(this value).
                if (this == null) {
                    throw new TypeError('"this" is null or not defined');
                }

                var o = Object(this);

                // 2. Let len be ? ToLength(? Get(O, "length")).
                var len = o.length >>> 0;

                // 3. If IsCallable(predicate) is false, throw a TypeError exception.
                if (typeof predicate !== 'function') {
                    throw new TypeError('predicate must be a function');
                }

                // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
                var thisArg = arguments[1];

                // 5. Let k be 0.
                var k = 0;

                // 6. Repeat, while k < len
                while (k < len) {
                    // a. Let Pk be ! ToString(k).
                    // b. Let kValue be ? Get(O, Pk).
                    // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
                    // d. If testResult is true, return kValue.
                    var kValue = o[k];
                    if (predicate.call(thisArg, kValue, k, o)) {
                        return kValue;
                    }
                    // e. Increase k by 1.
                    k++;
                }

                // 7. Return undefined.
                return undefined;
            },
            configurable: true,
            writable: true
        });
    }

    require('es6-promise').polyfill();
    import logo from '../../assets/img/ods_logo.png'
    import devbg from '../../assets/img/devbg.gif'
    import { mapGetters } from 'vuex'
    //import axios from 'axios'
    export default {
         data () {
            return {
                logo: logo,
                devbg: devbg,
                isDev: false,
                activeOrIdle: 'active'//,
                //user: {}
            }
        },
        methods: {
            // getUser: function() {
            //     axios.get("/api/user")
            //     .then(response => {
            //         if(response.data.success == true) {
            //             this.user = response.data.user;
            //             this.isDev = response.data.isDev;
            //             if(response.data.isDev) {
            //                 // document.getElementById("container").style.backgroundImage = 'url(' + this.devbg + ')';
            //             }
            //         } else {
            //             // eslint-disable-next-line
            //             console.log("failed to get user");
            //         }
            //     })
            //     .catch(error => {
            //         // eslint-disable-next-line
            //         console.log(error)
            //     });
            //  }
        },
      computed: {
        ...mapGetters('auth', ['name', 'isAdmin', 'loggedIn'])
      },
      created () {
            //this.getUser();
        },
        onIdle() {
            this.activeOrIdle = 'IDLE'
        },
        onActive() {
            this.activeOrIdle = 'ACTIVE'
        }

    }
</script>

<style scoped>
</style>
