<template>
<div>
  <h1>Expired in last 6 months</h1>
  <el-table :default-sort="{prop: 'uni', order: 'ascending'}" :data="students" style="width: 100%" v-loading="loading" element-loading-text="Loading...">
    <el-table-column prop="uni" label="UNI" sortable :sort-orders="['ascending', 'descending']" width="70px"></el-table-column>
    <el-table-column prop="first" label="First" sortable :sort-orders="['ascending', 'descending']" width="80px">
      <template slot-scope="scope">
        <a :href="'/student/ha-profile/' + scope.row.id">{{ scope.row.first }}</a>
      </template>
    </el-table-column>
    <el-table-column prop="last" label="Last" sortable :sort-orders="['ascending', 'descending']" width="80px">
      <template slot-scope="scope">
        <a :href="'/student/ha-profile/' + scope.row.id">{{ scope.row.last }}</a>
      </template>
    </el-table-column>
    <el-table-column prop="preferred_name" label="Preferred Name" sortable :sort-orders="['ascending', 'descending']" width="80px"></el-table-column>
    <el-table-column prop="approved_accommodations" label="Approved Accommodations" sortable :sort-orders="['ascending', 'descending']" min-width="105px">
      <template slot-scope="scope">
        <p class="no-margin" v-for="accommodation in scope.row.approved_accommodations" :key="accommodation.id">{{ accommodation }}</p>
      </template>
    </el-table-column>
    <el-table-column prop="date_expired" label="Date Expired" sortable :sort-orders="['ascending', 'descending']" width="80px"></el-table-column>
    <el-table-column prop="reason_for_expired" label="Reason For Expired" sortable :sort-orders="['ascending', 'descending']" min-width="155px"></el-table-column>
    <el-table-column width="60"><template slot-scope="scope"><a :href="'/ha-committee/show/' + scope.row.id">show</a></template></el-table-column>
  </el-table>
  <a :href="'/api/student/housing-groups-download.xlsx?id=120'" target="_blank" style="margin-top: 15px">Download</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;({{ this.students.length}} record{{ this.students.length > 1 || this.students.length == 0 ? 's' : '' }})
</div>
</template>
<script>
  import axios from 'axios'

  export default {
    data() {
      return {
        title: "",
        students: [],
        loading: false,
      }
    },
    props: ['urlParams'],
    computed: {
      formattedTitle: function() {
        if(!this.loading){
          var formattedTitle = "All students with status of " + this.title;

          return formattedTitle;
        }
        return "";
      },
        groupParams: function() {
            var paramsString = "";

            if(this.urlParams.groups){
                paramsString += "[";

                for(var i=0; i<this.urlParams.groups.length; i++){
                    paramsString += "%22"+this.urlParams.groups[i]+"%22";

                    if(i < this.urlParams.groups.length-1){
                        paramsString += ",";
                    }
                }

                paramsString += "]";
            }

            return paramsString;
        }
    },
    methods: {
      load: function () {
        this.loading = true;
        axios
            .get('/api/student/housing-groups/?id=120')
            .then(response => {
              this.students = response.data.rows;
              this.title = response.data.title;
              this.loading = false;
            })
            .catch(error => {
              // eslint-disable-next-line
                console.log(error)
            });
      }
    },
    mounted() {
        this.load()
    }
  }
</script>
<style scoped>
  .no-margin {
    margin: 0;
  }

  .intake-note {
    margin-top: 0;
  }

  #content .el-table .cell {
    padding: 1px;
  }

  .el-table {
    font-size: 12px;
  }

  .el-table td {
    vertical-align: top;
  }
</style>