<template>
    <div>
        <el-table :data="orderedExams" :row-class-name="getRowClassName" v-loading="loading" element-loading-text="Loading..."
                  :show-header="!isCancelledExams" ref="examTable" class="no-print" @sort-change="onSortChange" >
            <el-table-column prop="student_name" label="Student Name" sortable :sort-orders="['ascending', 'descending']" :min-width="10.0" >
                <template slot-scope="scope">
                    <a :href="'/testing/show/' + scope.row.service_id + '/#'  +scope.row.student_id">{{ scope.row.student_name }} &lt;{{ scope.row.uni }}&gt;</a>
                </template>
            </el-table-column>
            <el-table-column prop="start_time" label="Time" sortable :sort-orders="['ascending', 'descending']"  width="65" >
                <template slot-scope="scope">
                    <b>{{ scope.row.start_time | formatDateAbbrMonth }}</b><br>{{ scope.row.start_time | formatTimehhmmA }} - {{ scope.row.end_time | formatTimehhmmA }}
                </template>
            </el-table-column>
            <el-table-column prop="department" label="Class" sortable :sort-orders="['ascending', 'descending']"  :min-width="11.7">
                <template slot-scope="scope">
                    {{ scope.row.department }} {{ scope.row.course_number }} SEC <b>{{ scope.row.section_code }}</b><br>{{ scope.row.class_name }}
                </template>
            </el-table-column>
            <el-table-column prop="instructor_name" label="Professor" sortable :sort-orders="['ascending', 'descending']"  class-name="professor-column" :min-width="17.0">
                <template slot-scope="scope">
                    {{ scope.row.instructor_name }}<br>{{ scope.row.instructor_email }}
                    <template v-if="scope.row.alternate_instructor_email"><br><span style="color:red;" class="text-italic">{{ scope.row.alternate_instructor_email }}</span></template>
                    <template v-if="scope.row.secondary_alternate_instructor_email"><br><span style="color:red;" class="text-italic">{{ scope.row.secondary_alternate_instructor_email }}</span></template>
                    <template v-if="scope.row.teaching_assistants && scope.row.teaching_assistants.length > 2">
                        <br><span style="color:#212488;">({{ scope.row.teaching_assistants.length }}) TAs listed</span>
                    </template>
                    <template v-else>
                        <template v-for="(ta, taIndex) in scope.row.teaching_assistants">
                            <br  :key="taIndex +'-br'"/>
                            <span :key="taIndex" style="color:#212488;">TA - {{ ta.first_name }} {{ ta.last_name }}</span>
                        </template>
                    </template>
                </template>
            </el-table-column>
            <el-table-column prop="location" label="Location" sortable :sort-orders="['ascending', 'descending']"  :min-width="9.2" :sort-method="sortColumn">
                <template slot-scope="scope">
                  <template v-if="scope.row.exam_type === 'To be determined'">
                    N/A
                  </template>
                  <template v-else>
                    {{ scope.row.location ? scope.row.location : "N/A" }}
                  </template>
                </template>
            </el-table-column>
            <el-table-column prop="exam_received" label="Exam Received" sortable :sort-orders="['ascending', 'descending']"  :min-width="7.5">
                <template slot-scope="scope">
                    <a :href="'/testing/show/' + scope.row.service_id">{{ scope.row.exam_received ? "Yes" : "No" }}</a>
                </template>
            </el-table-column>
            <el-table-column label="Exam Requested"  :min-width="15.2">
                <template slot-scope="scope">
                    <a :href="'/testing/show/' + scope.row.service_id">
                        <template v-if="scope.row.manual_opt_professor_out">Manually opted out<br></template>
                        <template v-if="scope.row.is_auto_opted_out">Auto opted out<br></template>
                        <template v-if="scope.row.is_placeholder_opted_out">Placeholder opted out<br></template>
                        <template v-if="scope.row.is_virtual_opted_out">Virtual exam opted out<br></template>
                        <template v-if="scope.row.exam_requested_from_professor">{{ scope.row.exam_requested_from_professor }}<br></template>
                    </a>
                </template>
            </el-table-column>
            <el-table-column label="Exam Comments and Updates"  :min-width="9.0">
                <template slot-scope="scope">
                    <template v-if="scope.row.exam_permissions && scope.row.exam_permissions.indexOf('Listening section or film clip') !== -1">
                        <template v-if="scope.row.listening_film_options && scope.row.listening_film_options.length">
                            <div style="color:red;">
                                <b>Prof. will:</b><br>
                                <template v-for="option in scope.row.listening_film_options">- {{ option }}</template>
                            </div>
                        </template>
                    </template>
                    <div v-if="scope.row.is_missing_exam_specs" class="color-aqua">- Missing Exam Specs</div>
                    <div v-if="scope.row.exam_type === 'To be determined'" class="color-yellow">- Exam Type: TBD</div>
                    <template v-if="scope.row.urgent_delivery_instructions && scope.row.urgent_delivery_instructions.indexOf('Urgent Delivery') !== -1">
                        <div style="color:red;">
                            <b>Urgent Delivery</b><br>
                            <template v-if="instruction !== 'Urgent Delivery'">
                                <template v-for="instruction in scope.row.urgent_delivery_instructions">{{ instruction }}<br  :key="instruction.id"></template>
                            </template>

                        </div>
                    </template>
                    <template v-for="comment in scope.row.exam_comments">
                        <span :key="comment.id" v-if="comment.content != ''" :class="{ '': showFullComments || !comment.show_exam_comment_more, 'hidden-text': !showFullComments && comment.show_exam_comment_more }" class="">
                            {{ comment.content }}<br>
                            <a v-if="!showFullComments && comment.show_exam_comment_more" @click="comment.show_exam_comment_more = false" :key="comment.id">More</a>
                            <br>
                        </span>
                    </template>
                    <template v-if="scope.row.legacy_exam_comments && scope.row.legacy_exam_comments != ''">
                        <br><b>Archived comments</b><br>
                        {{ scope.row.legacy_exam_comments }}
                    </template>
                    <template v-if="scope.row.computer_use_notes && scope.row.computer_use_notes != ''">
                        <br><b>Archived comments</b><br>
                        {{ scope.row.computer_use_notes }}
                    </template>
                    <template v-if="scope.row.exam_updates && scope.row.exam_updates.length">
                        <template v-for="update in scope.row.exam_updates">
                            <br :key="update.id + '-br1'">
                            <span style="color:red;"  :key="update.id + '-span'">{{ update.name }}<template v-if="update.other">: {{ update.other }}</template>
                                <br  :key="update.id + '-br2'">{{ update.content }}</span>
                            <template v-if="update.exam_updates && update.exam_updates.length">
                                <template v-for="file in update.exam_updates"><br :key="file.id"><a :href="'/testing/show/' + scope.row.service_id"  :key="file.id">Exam update</a></template>
                            </template>
                        </template>
                    </template>
                </template>
            </el-table-column>
            <el-table-column prop="exam_emails" label="Student Reminders(R), Locations Reminders (L) and Comments"  :min-width="15.5">
                <template slot-scope="scope">
                    <template v-for="email in scope.row.exam_emails">
                        <template v-if="!email.is_exam_location_email">
                            R - {{ email.created_at | formatDateTimeMDYhma }}
                            <br :key="email.id">
                        </template>
                        <span v-if="email.is_exam_location_email" class="color-red" :key="email.id">L - {{ email.created_at | formatDateTimeMDYhma }}<br></span>
                    </template>
                    <span class="" :class="{ '': showFullComments || !scope.row.show_comments_more, 'hidden-text': !showFullComments && scope.row.show_comments_more }"><a :href="'/testing/show/' + scope.row.service_id" style="color:red;">{{ scope.row.comments }}</a></span>
                    <a v-if="!showFullComments && scope.row.show_comments_more" @click="scope.row.show_comments_more = false">More</a>
                    <template v-if="isCancelledExams"><br><br><span style="color:red;">{{ scope.row.cancellation_reason }}<br><br>{{ scope.row.other_cancellation_reason }}</span></template>
                </template>
            </el-table-column>
            <el-table-column label="Student Updates"  :min-width="9.6">
                <template slot-scope="scope">
                    <template v-if="scope.row.student_exam_updates && scope.row.student_exam_updates.length">
                        <template v-for="update in scope.row.student_exam_updates">
                            <br  :key="update.id + '-br'">
                            <span style="color:red;" :key="update.id + '-span'">{{ update.name }}<template v-if="update.other">: {{ update.other }}</template><br>{{ update.content }}</span>
                            <template v-if="update.exam_updates && update.exam_updates.length">
                                <template v-for="file in update.exam_updates">
                                    <br  :key="file.id">
                                    <a :href="'/testing/show/' + scope.row.service_id"  :key="file.id">Exam update</a>
                                </template>
                            </template>
                        </template>
                    </template>
                </template>
            </el-table-column>
            <el-table-column prop="exam_equipment" label="Equipment"  :min-width="11.5">
                <template slot-scope="scope">
                    <template v-if="scope.row.exam_equipment && scope.row.exam_equipment.indexOf('Computer') !== -1">
                        1 Computer – Exam Admin ({{ scope.row.admin_computer_type_preference ? scope.row.admin_computer_type_preference : "Any" }})<br>
                    </template>
                    <template v-if="studentComputer(scope.row.enrollment_accommodations)">
                        1 Computer – Student Use ({{ scope.row.ultimate_computer_choice }})<br>
                    </template>
                    <template v-for="equipment in scope.row.exam_equipment">
                        <template v-if="equipment != 'Computer'">
                            {{ equipment }}<br :key="equipment"/>
                        </template>
                    </template>
                 </template>
            </el-table-column>
            <el-table-column label="Student Accommodations" :min-width="31.5"  class-name="student-accommodations-column">
                <template slot-scope="scope">
                    <template v-for="accommodation in scope.row.enrollment_accommodations" >
                        <template v-if="accommodation.name != 'Rest Breaks'">
                            <template v-if="accommodation.accommodation_type_id == 8">
                                <span :key="accommodation.id +'span1'" :class="{ 'color-red': accommodation.highlight_accommodation }">- {{ accommodation.display_value }}: {{ scope.row.ultimate_computer_choice }}</span><br :key="accommodation.id" >
                            </template>
                            <template v-else-if="accommodation.name == 'Other Testing Accommodation'">
                                <span :key="accommodation.id +'span2'"  :class="{ 'color-red': accommodation.highlight_accommodation }">- {{ accommodation.description }}</span><br :key="accommodation.id" >
                            </template>
                            <template v-else>
                                <span  :key="accommodation.id +'span3'"  :class="{ 'color-red': accommodation.highlight_accommodation }">- {{ accommodation.display_value ? accommodation.display_value : accommodation.name }}{{accommodation.avoided_student_name ? ": "+accommodation.avoided_student_name : ""}}</span><br :key="accommodation.id" >
                            </template>
                        </template>
                    </template>
                </template>
            </el-table-column>

<!--            <el-table-column label="&nsbp;" :min-width="200"> </el-table-column>-->
        </el-table>


        <table  class="printable-table print-only" >
            <tbody>
                <tr>
                    <th style="width: 6.8%">Student Name</th>
                    <th style="width: 65px">Time</th>
                    <th style="width: 7.2%">Class</th>
                    <th style="width: 11.2%">Professor</th>
                    <th style="width: 5.9%">Location</th>
                    <th style="width: 4.6%">Exam Received</th>
                    <th style="width: 9.9%">Exam Requested</th>
                    <th style="width: 5.9%">Exam Comments and Updates</th>
                    <th style="width: 9.9%">Student Reminders(R), Locations Reminders (L) and Comments</th>
                    <th style="width: 5.9%">Student Updates</th>
                    <th style="width: 7.2%">Equipment</th>
                    <th style="width: 20.5%">Student Accommodations</th>
                </tr>
                <tr v-for="exam in orderedExams" :key="exam.id" :class="{
                  'placeholder-exam-row': (exam.is_placeholder_exam && exam.status == 'active'),
                  'cancelled-exam-row': exam.status == 'cancelled',
                   'virtual-ds-proctored-row': (exam.exam_type === 'Virtual' && exam.virtual_exam_type === 'Proctored by DS')}">
                    <td><a :href="'/testing/show/' + exam.service_id">{{ exam.student_name }} &lt;{{ exam.uni }}&gt;</a></td>

                    <td><b>{{ exam.start_time | formatDateAbbrMonth }}</b><br>{{ exam.start_time | formatTimehhmmA }} - {{ exam.end_time | formatTimehhmmA }}</td>
                    <td>{{ exam.department }} {{ exam.course_number }} SEC <b>{{ exam.section_code }}</b><br>{{ exam.class_name }}</td>
                    <td>
                        {{ exam.instructor_name }}<br>{{ exam.instructor_email }}
                        <template v-if="exam.alternate_instructor_email"><br><span style="color:red;" class="text-italic">{{ exam.alternate_instructor_email }}</span></template>
                        <template v-if="exam.secondary_alternate_instructor_email"><br><span style="color:red;" class="text-italic">{{ exam.secondary_alternate_instructor_email }}</span></template>
                        <template v-if="exam.teaching_assistants && exam.teaching_assistants.length > 2">
                            <br><span style="color:#212488;">({{ exam.teaching_assistants.length }}) TAs listed</span>
                        </template>
                        <template v-else>
                            <template v-for="(ta, taIndex) in exam.teaching_assistants">
                                <br  :key="taIndex +'-br'"/>
                                <span :key="taIndex" style="color:#212488;">TA - {{ ta.first_name }} {{ ta.last_name }}</span>
                            </template>
                        </template>
                    </td>
                    <td>
                      <template v-if="exam.exam_type === 'To be determined'">
                        N/A
                      </template>
                      <template v-else>
                        {{ exam.location ? exam.location : "N/A" }}
                      </template>
                    </td>
                    <td><a :href="'/testing/show/' + exam.service_id">{{ exam.exam_received ? "Yes" : "No" }}</a></td>
                    <td>
                        <a :href="'/testing/show/' + exam.service_id">
                            <template v-if="exam.manual_opt_professor_out">Manually opted out<br></template>
                            <template v-if="exam.is_auto_opted_out">Auto opted out<br></template>
                            <template v-if="exam.is_placeholder_opted_out">Placeholder opted out<br></template>
                            <template v-if="exam.is_virtual_opted_out">Virtual exam opted out<br></template>
                            <template v-if="exam.exam_requested_from_professor">{{ exam.exam_requested_from_professor }}<br></template>
                        </a>
                    </td>
                    <td>
                        <template v-if="exam.exam_permissions && exam.exam_permissions.indexOf('Listening section or film clip') !== -1">
                            <template v-if="exam.listening_film_options && exam.listening_film_options.length">
                                <div style="color:red;">
                                    <b>Prof. will:</b><br>
                                    <template v-for="option in exam.listening_film_options">- {{ option }}</template>
                                </div>
                            </template>
                        </template>
                        <div v-if="exam.is_missing_exam_specs" class="color-aqua">- Missing Exam Specs</div>
                        <div v-if="exam.exam_type === 'To be determined'" class="color-yellow">- Exam Type: TBD</div>
                        <template v-if="exam.urgent_delivery_instructions && exam.urgent_delivery_instructions.indexOf('Urgent Delivery') !== -1">
                            <div style="color:red;">
                                <b>Urgent Delivery</b><br>
                                <template v-if="instruction !== 'Urgent Delivery'">
                                    <template v-for="instruction in exam.urgent_delivery_instructions">{{ instruction }}<br  :key="instruction.id"></template>
                                </template>

                            </div>
                        </template>
                        <template v-for="comment in exam.exam_comments">
                        <span :key="comment.id" v-if="comment.content != ''" :class="{ '': showFullComments || !comment.show_exam_comment_more, 'hidden-text': !showFullComments && comment.show_exam_comment_more }" class="">
                            {{ comment.content }}<br>
                            <a v-if="!showFullComments && comment.show_exam_comment_more" @click="comment.show_exam_comment_more = false" :key="comment.id">More</a>
                            <br>
                        </span>
                        </template>
                        <template v-if="exam.legacy_exam_comments && exam.legacy_exam_comments != ''">
                            <br><b>Archived comments</b><br>
                            {{ exam.legacy_exam_comments }}
                        </template>
                        <template v-if="exam.computer_use_notes && exam.computer_use_notes != ''">
                            <br><b>Archived comments</b><br>
                            {{ exam.computer_use_notes }}
                        </template>
                        <template v-if="exam.exam_updates && exam.exam_updates.length">
                            <template v-for="update in exam.exam_updates">
                                <br :key="update.id + '-br1'">
                                <span style="color:red;"  :key="update.id + '-span'">{{ update.name }}<template v-if="update.other">: {{ update.other }}</template>
                                <br  :key="update.id + '-br2'">{{ update.content }}</span>
                                <template v-if="update.exam_updates && update.exam_updates.length">
                                    <template v-for="file in update.exam_updates"><br :key="file.id"><a :href="'/testing/show/' + exam.service_id"  :key="file.id">Exam update</a></template>
                                </template>
                            </template>
                        </template>
                    </td>
                    <td>
                        <template v-for="email in exam.exam_emails">
                            <template v-if="!email.is_exam_location_email">
                                R - {{ email.created_at | formatDateTimeMDYhma }}
                                <br :key="email.id">
                            </template>
                            <span v-if="email.is_exam_location_email" class="color-red" :key="email.id">L - {{ email.created_at | formatDateTimeMDYhma }}<br></span>
                        </template>
                        <span class="" :class="{ '': showFullComments || !exam.show_comments_more, 'hidden-text': !showFullComments && exam.show_comments_more }"><a :href="'/testing/show/' + exam.service_id" style="color:red;">{{ exam.comments }}</a></span>
                        <a v-if="!showFullComments && exam.show_comments_more" @click="exam.show_comments_more = false">More</a>
                        <template v-if="isCancelledExams"><br><br><span style="color:red;">{{ exam.cancellation_reason }}<br><br>{{ exam.other_cancellation_reason }}</span></template>
                    </td>
                    <td>
                        <template v-if="exam.student_exam_updates && exam.student_exam_updates.length">
                            <template v-for="update in exam.student_exam_updates">
                                <br  :key="update.id + '-br'">
                                <span style="color:red;" :key="update.id + '-span'">{{ update.name }}<template v-if="update.other">: {{ update.other }}</template><br>{{ update.content }}</span>
                                <template v-if="update.exam_updates && update.exam_updates.length">
                                    <template v-for="file in update.exam_updates">
                                        <br  :key="file.id">
                                        <a :href="'/testing/show/' + exam.service_id"  :key="file.id">Exam update</a>
                                    </template>
                                </template>
                            </template>
                        </template>
                    </td>
                    <td>
                        <template v-if="exam.exam_equipment && exam.exam_equipment.indexOf('Computer') !== -1">
                            1 Computer – Exam Admin ({{ exam.computer_type_preference ? exam.computer_type_preference : "Any" }})<br>
                        </template>
                        <template v-if="studentComputer(exam.enrollment_accommodations)">
                            1 Computer – Student Use ({{ exam.ultimate_computer_choice }})<br>
                        </template>
                        <template v-for="equipment in exam.exam_equipment">
                            <template v-if="equipment != 'Computer'">
                                {{ equipment }}<br :key="equipment">
                            </template>
                        </template>
                        <template v-if="exam.exam_equipment.length === 0 && !studentComputer(exam.enrollment_accommodations)">None</template>
                    </td>
                    <td>
                        <template v-for="accommodation in exam.enrollment_accommodations" >
                            <template v-if="accommodation.name != 'Rest Breaks'">
                                <template v-if="accommodation.accommodation_type_id == 8">
                                    <span :key="accommodation.id +'span1'" :class="{ 'color-red': accommodation.highlight_accommodation }">- {{ accommodation.display_value }}: {{ exam.ultimate_computer_choice }}</span><br :key="accommodation.id" >
                                </template>
                                <template v-else-if="accommodation.name == 'Other Testing Accommodation'">
                                    <span :key="accommodation.id +'span2'"  :class="{ 'color-red': accommodation.highlight_accommodation }">- {{ accommodation.description }}</span><br :key="accommodation.id" >
                                </template>
                                <template v-else>
                                    <span  :key="accommodation.id +'span3'"  :class="{ 'color-red': accommodation.highlight_accommodation }">- {{ accommodation.display_value ? accommodation.display_value : accommodation.name }}{{accommodation.avoided_student_name ? ": "+accommodation.avoided_student_name : ""}}</span><br :key="accommodation.id" >
                                </template>
                            </template>
                        </template>
                    </td>
                </tr>


            </tbody>
        </table>

    </div>
</template>
<script>
    export default {
        data() {
            return {
                refreshData: false
            }
        },
        props: ['exams', 'isCancelledExams', 'showFullComments', 'loading', 'sortField', 'sortOrder'],
        computed: {
          orderedExams: function () {
            if(this.sortField != '' && this.sortOrder != ''){
                return this.lodash.orderBy(this.exams, [( o ) => { return eval("o." + this.sortField) || ''}], [this.sortOrder])
            } else {
                return this.exams;
            }
          }
        },
        methods: {
            load: function(){
            },
            onSortChange: function(sorter){
                this.$emit('onSortChange', sorter);
            },
            getRowClassName: function({row, rowIndex}) {
                if(row.status == 'cancelled'){
                    return 'cancelled-exam-row';
                } else if(row.is_placeholder_exam){
                    return "placeholder-exam-row";
                } else if(row.exam_type === 'Virtual' && row.virtual_exam_type === 'Proctored by DS') {
                    return 'virtual-ds-proctored-row';
                }
            },
            studentComputer: function(accommodations){
                if(accommodations){
                    for(var i = 0; i < accommodations.length; i++){
                        if(accommodations[i].accommodation_type_id == 8){
                            return true;
                        }
                    }
                }
                return false;
            },

            sortColumn: function(a, b){
                var locationA = a.location;
                var locationB = b.location;
                
                locationA = locationA == null || locationA == '' ? ' ' : locationA;
                locationB = locationB == null || locationB == '' ? ' ' : locationB;
                if (locationA === locationB) { return 0 }
                if (locationA < locationB) { return -1 }
                if (locationA > locationB) { return 1 }
            },
            clearSort() {
                this.$refs["examTable"].clearSort();
            }
        },
        mounted() {
            this.load();
        }
    }
</script>
<style scoped>
    .cancelled-exams-table tr {
        background-color: #fcff00;
        font-style: italic;
        font-weight: bold;
    }
    tr.placeholder-exam-row {
        background-color: #ffdafe;
        font-weight: bold;
        font-style: italic;
    }
    tr.virtual-ds-proctored-row {
      background-color: #d7ece2;
      font-weight: bold;
      font-style: italic;
    }

    .exams-table th, .exams-table td{
        vertical-align: top;
        font-size: 11.7px;
    }

    #content .el-table .professor-column .cell {
        word-break: break-all;
    }
</style>