<template>
  <div>
    <h2>{{ title }}</h2>
    <slot></slot>
    <template v-if="emails && emails.length">
      <el-table :data="emails" class="emails-table no-print" @sort-change="onSortChange">
        <el-table-column prop="created_at" label="Sent" sortable :sort-orders="['ascending', 'descending']" :min-width="10.0">
          <template slot-scope="scope">{{ scope.row.created_at | formatDateTimeMDYhma }}</template>
        </el-table-column>
        <el-table-column prop="email" label="Address" sortable :sort-orders="['ascending', 'descending']" :min-width="15.0">
          <template slot-scope="scope">
            <template v-if="scope.row.recipient_emails  && scope.row.recipient_emails.length">
              <template v-for="recipient in scope.row.recipient_emails">{{recipient}}<br :key="recipient"/></template>
            </template>
            <template v-else>
              {{ scope.row.email }}
            </template>
          </template>
        </el-table-column>
        <el-table-column v-if="showCCField" prop="cc" label="CC" sortable :sort-orders="['ascending', 'descending']" :min-width="10.0"></el-table-column>
        <el-table-column prop="subject" label="Subject" sortable :sort-orders="['ascending', 'descending']" :min-width="25.0"></el-table-column>
        <el-table-column prop="body" label="Body" sortable :sort-orders="['ascending', 'descending']" :min-width="50.0">
          <template slot-scope="scope">
            <span class="formatted-text" v-html="scope.row.body"></span>
          </template>
        </el-table-column>
        <el-table-column width="64">
          <template slot-scope="scope">
            <router-link :to="'/group-email/show/'+scope.row.id">show</router-link>
          </template>
        </el-table-column>
      </el-table>
    </template>
    <template v-else>
      <br><span class="text-italic">No messages have been sent yet</span>
    </template>


    <table class="printable-table print-only" v-if="emails && emails.length">
      <tbody>
        <tr>
          <th style="width: 9.2%">Sent</th>
          <th style="width: 13.7%">Address</th>
          <th style="width: 8.9%" v-if="showCCField">CC</th>
          <th style="width: 22.6%">Subject</th>
          <th style="width: 45.3%">Body</th>
        </tr>
        <tr v-for="email in orderedEmails" :key="email.id">
          <td>{{ email.created_at | formatDateTimeMDYhma }}</td>
          <td>
            <template v-if="email.recipient_emails  && email.recipient_emails.length">
              <template v-for="recipient in email.recipient_emails">{{recipient}}<br :key="recipient"/></template>
            </template>
            <template v-else>
              {{ email.email }}
            </template>
          </td>
          <td v-if="showCCField">

          </td>
          <td>{{email.subject}}</td>
          <td>
            <span class="formatted-text" v-html="email.body"></span>
          </td>
        </tr>

      </tbody>
    </table>

  </div>
</template>
<script>

  export default {
    data() {
      return {
        sortField: 'created_at',
        sortOrder: 'desc'
      }
    },
    props: ['emails', 'title', 'showCCField'],
    computed: {
      orderedEmails: function () {
// eslint-disable-next-line
        return this.lodash.orderBy(this.emails, [this.sortField], [this.sortOrder])
      }
    },
    methods: {
      onSortChange: function(sorter){
        this.sortField = sorter.prop;
        this.sortOrder = sorter.order == "ascending" ? "asc" : "desc";
      }
    },
    mounted() {
    }
  }
</script>
<style scoped>
  .emails-table td {
    vertical-align: top;
  }
  .emails-table {
    padding-top:2px;
  }
</style>
