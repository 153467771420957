<template>
    <div>
        <el-table :default-sort="{prop: sortProp, order: 'ascending'}" :data="data" style="width: 100%" v-loading="loading" element-loading-text="Loading..." class="table-top-align">
            <el-table-column v-if="fullView" prop="uni" label="UNI" sortable :sort-orders="['ascending', 'descending']" width="70"></el-table-column>
            <el-table-column v-if="fullView" prop="first_name" label="First" sortable :sort-orders="['ascending', 'descending']" width="100"></el-table-column>
            <el-table-column v-if="fullView" prop="last_name" label="Last" sortable :sort-orders="['ascending', 'descending']" width="100"></el-table-column>
            <el-table-column v-if="fullView" prop="preferred_first_name" label="Preferred" sortable :sort-orders="['ascending', 'descending']" width="100"></el-table-column>
            <el-table-column v-if="!fullView" prop="student_name" label="Student Name" sortable :sort-orders="['ascending', 'descending']" width="175"></el-table-column>
            <el-table-column prop="status_display" label="ODS Status" sortable :sort-orders="['ascending', 'descending']" width="115"></el-table-column>
            <el-table-column prop="enrollment" label="Enrollment" sortable :sort-orders="['ascending', 'descending']" width="115"></el-table-column>
            <el-table-column prop="coordinator" label="Coord." sortable :sort-orders="['ascending', 'descending']" width="85"></el-table-column>
            <el-table-column prop="disabilities" label="Disabilities" sortable :sort-orders="['ascending', 'descending']" width="175">
                <template slot-scope="scope">
                    <template v-for="(disability, dIndex) in scope.row.disabilities">{{ disability }}<br :key="dIndex"></template>
                </template>
            </el-table-column>
            <el-table-column prop="accommodations" label="Accommodations" sortable :sort-orders="['ascending', 'descending']">
                <template slot-scope="scope">
                    <template v-for="(accommodation, aIndex) in scope.row.accommodations">{{ accommodation }}<br :key="aIndex"></template>
                </template>
            </el-table-column>
            <el-table-column v-if="showOdsRegDate" prop="ods_registration_date" label="ODS Reg Date" sortable :sort-orders="['ascending', 'descending']" width="100">
                <template slot-scope="scope">{{ scope.row.ods_registration_date | formatDateMDY }}</template>
            </el-table-column>
            <el-table-column v-if="!fullView" prop="school" label="School" sortable :sort-orders="['ascending', 'descending']" width="240"></el-table-column>
            <el-table-column v-if="showActivities" prop="activities" label="Activities" sortable :sort-orders="['ascending', 'descending']">
                <template slot-scope="scope">
                    <template v-for="(activity, aIndex) in scope.row.activities">{{ activity }}<br :key="aIndex"></template>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>
<script>
    export default {
        data() {
            return {
            }
        },
        props: ['data', 'loading', 'sortProp', 'fullView', 'showActivities', 'showOdsRegDate'],
        methods: {
        },
        mounted() {
        }
    }
</script>
<style scoped>
    table td, table th {
        font-size:11.7px;
    }
</style>