<template>
  <div style="position:relative" id="status_and_workflow">
    <div>
      <h2>HA Status</h2>
      <p id="ha_status_display"><em>{{ currentStatus }}</em></p>
      <p>
        <a :href="'/contact-notes/edit?student_id=' + this.id + '&ha_app=true'" class="action">Enter a new contact note</a>
        <br>
        <span class="help">(Use this link to create a contact note or a contact note draft online)</span>
      </p>
    </div>

    <div class="workflow_action">
      <template v-if="statusInstructions.length">
        <h3>Instructions</h3>
        <ul>
          <li v-for="item in statusInstructions" :key="item.id"><p v-html="item"></p></li>
        </ul>
      </template>
      <br>
      <h3>Workflow Actions</h3>
      <p>HA Status: <strong>{{ currentStatus }}</strong></p>

      <div id="actions">
        <h4>Possible actions:</h4>
        <el-form ref="action" :model="action" :rules="rules" label-position="top">
          <el-select  v-model="action.value" placeholder="Select an action" size="small" @change="onSelect()">
            <el-option v-for="item in workflowActions" :key="item.name" :label="item.name" :value="item.new_status"></el-option>
          </el-select>
          <p></p>
          <template v-if="requiresComment">
            <el-form-item label="Comments"><el-input v-model="action.comment" type="textarea" :autosize="{ minRows: 4}" ></el-input></el-form-item>
          </template>
          <el-form-item><el-button type="primary" @click="onSubmit('action')" :disabled="!action.value">Submit Action</el-button></el-form-item>
        </el-form>
      </div>
    </div>
    <div class="clear"><!-- --></div>
  </div>
</template>
<script>
  import axios from 'axios'

  export default {
    data() {
      return {
        currentStatus: '',
        instructions: '',
        workflowActions: [],
        inactiveReason: '',
        requiresComment: false,
        action: {
          value: '',
          comment: ''
        },
        rules: {
          date: [{required: true, message: 'Please pick an action', trigger: 'change'}]//,
          //body: [{required: true, message: 'Please enter a note', trigger: 'blur'}]
        },
        statusInstructions: []
      }
    },
    props: ['id', 'name'],
    methods: {
      load: function () {
        axios.get('/api/student/ha-workflow-actions/' + this.id)
          .then(response => {
            this.currentStatus = response.data.current_status;
            this.instructions = response.data.instructions;
            this.workflowActions = response.data.workflow_actions;
            this.inactiveReason = response.data.inactiveReason;
          })
          .catch(error => {
            // eslint-disable-next-line
            console.log(error)
          });
        axios.get('/api/student/ha-status-instructions/' + this.id)
          .then(response => {
            this.statusInstructions = response.data.instructions;
          })
          .catch(error => {
            // eslint-disable-next-line
            console.log(error)
          });
      },
      onSelect() {
        // eslint-disable-next-line
        console.log("selected" + this.action.value);
        for (var i = 0; i < this.workflowActions.length; i++) {
          var obj = this.workflowActions[i];

          if(obj.new_status == this.action.value) {
            if(obj.requires_comment) {
              this.requiresComment = true;
              // eslint-disable-next-line
              console.log('requres comment');
            } else {
              this.requiresComment = false;
              this.action.comment = '';
              // eslint-disable-next-line
              console.log('nope');
            }
            break;
          }
        }
      },
      onSubmit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            var message = "";
            if(this.action.value == 'delete'){
              message = 'Are you sure you want to delete all HA records for student ' + this.name + '?  There is no undo.';
            } else {
              message = 'Are you sure?'
            }
            this.$confirm(message)
              .then(() => {
                axios.put("/api/student/change-ha-status/", {
                  id: parseInt(this.id),
                  newStatus: this.action.value,
                  comment: this.action.comment,
                  reason: this.action.reason
                })
                  .then(response => {
                    if (response.data.success) {
                      if(this.action.value == 'delete'){
                        this.$router.push({ path: '/student/profile/' + this.id});
                      } else {
                        window.location.reload();
                      }
                    } else {
                      // eslint-disable-next-line
                      console.log("error: " + response.data.message);
                    }
                  })
                  .catch(error => {
                    // eslint-disable-next-line
                    console.log(error)
                  });
              }).catch(() => {});
          } else {
            // eslint-disable-next-line
            console.log('invalid form');
            return false;
          }
        });
      }
    },
    mounted() {
      this.load()
    }
  }
</script>
<style lang="scss" scoped>
  #actions {
    .el-form {
      width: 100%;

      .el-select {
        width: 350px;
      }
      .el-select-dropdown__item {
        font-size: 12px;
      }
      .el-select-dropdown__wrap {
        max-height: 600px;
      }
    }
  }
</style>
