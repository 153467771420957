<template>
  <div>
  <h2>{{ this.isAcademicDisability ? "Academic" : "Housing" }} Disabilities</h2>
  <el-table :data="items">
    <el-table-column prop="position" label="" width="60"></el-table-column>
    <el-table-column prop="duration" label="Term" width="70"></el-table-column>
    <el-table-column prop="ods_category" label="ODS Category" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
    <el-table-column prop="ods_type" label="ODS Type" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
    <el-table-column prop="description" label="Description" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
    <el-table-column prop="nys_category" label="NY State Category" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
    <el-table-column prop="dsm_code" label="DSM Category" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
    <el-table-column prop="primaryDisability" label="Is Primary Disability" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
    <el-table-column width="56"><template slot-scope="scope"><router-link :to="'/student/disabilities-edit/' + id + '?disability_id=' + scope.row.id + '&is_academic=' + (isAcademicDisability ? 'true' : 'false')" >edit</router-link></template></el-table-column>
  </el-table>
  </div>
</template>
<script>
  import axios from 'axios'

  export default {
    data() {
      return {
        items: []
      }
    },
    props: ['id', 'isAcademicDisability'],
    methods: {
      load: function () {
        var url_str = "";
        if(this.isAcademicDisability){
          url_str = '/api/student/academic-disabilities/';
        } else {
          url_str = '/api/student/housing-disabilities/';
        }
        axios
            .get(url_str, {params:{id: this.id}})
            .then(response => {

                response.data.rows.forEach(function(row){
                    if(row.is_primary_disability){
                        row.primaryDisability = "Yes";
                    }
                });
                this.items = response.data.rows;
            })
            .catch(error => {
              // eslint-disable-next-line
                console.log(error)
            });
      }
    },
    mounted() {
      this.load()
    }
  }
</script>
<style scoped>
</style>
