<template>

    <form v-if="this.items.length" action="/tasks/bulk_update" method="post">

        <h3>{{ title }}</h3>
        <el-table :default-sort="{prop: 'updated_at', order: 'descending'}" :data="items" :row-class-name="tableRowClassName"
                  class="table-top-align" @selection-change="handleSelectionChange" style="max-width:1063px">
            <el-table-column prop="subject" label="Subject" sortable :sort-orders="['ascending', 'descending']" width="400">
                <template slot-scope="scope"><router-link :to="'/tasks/show/' + scope.row.id">{{ scope.row.subject }}</router-link>
                </template>
            </el-table-column>
            <el-table-column prop="author_name" label="Author" sortable :sort-orders="['ascending', 'descending']" width="150"></el-table-column>
            <el-table-column prop="author_name" label="Recipients/Assigned to" sortable :sort-orders="['ascending', 'descending']" width="150">
                <template slot-scope="scope">
                    <template v-if="scope.row.broadcast">
                        All Doris Users
                    </template>
                    <template v-else>
                        <template v-for="recipient in scope.row.recipients"><div :key="recipient.index">{{recipient}}</div></template>
                    </template>
                </template>
            </el-table-column>
            <el-table-column prop="student_name" label="Student" sortable :sort-orders="['ascending', 'descending']" width="200">
                <template slot-scope="scope">
                    <router-link :to="'/student/profile/' + scope.row.student_id">{{ scope.row.student_name }}</router-link>
                </template>
            </el-table-column>
            <el-table-column prop="updated_at" label="Age" sortable :sort-orders="['ascending', 'descending']" width="115"><template slot-scope="scope">{{ scope.row.age }}</template></el-table-column>
            <el-table-column type="selection"></el-table-column>
        </el-table>
        <div class="table-append" style="max-width:1043px">With checked messages:
            <el-button v-if="api == 'messages'" size="mini" type="primary" @click="hideTasks">Hide</el-button>
            <el-button v-if="api == 'pending-tasks'" size="mini" type="primary" @click="updateTasksStatus('finished')">Finish</el-button>
            <el-button v-if="api == 'finished-tasks'" size="mini" type="primary" @click="updateTasksStatus('closed')">Close</el-button>
            <el-button v-if="api == 'finished-tasks'" size="mini" type="primary" @click="updateTasksStatus('reopened')">Reopen</el-button>
            <el-button v-if="api == 'draft-tasks'" size="mini" type="primary" @click="deleteTasks()">Disregard</el-button>
        </div>
    </form>
</template>

<script>
    import axios from 'axios'

    export default {
        data() {
            return {
                items: [],
                actions: [],
                sortKey: 'updated_at',
                sortOrders: {},
                selectedTasks: []
            }
        },
        props: ['title', 'api'],
        methods: {
            load: function () {
                axios
                    .get('/api/home/' + this.api)
                    .then(response => {
                        this.items = response.data.rows;
                        this.actions = response.data.actions;
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            },
            tableRowClassName({row}) {
                if (row.task_assignment_status == 'unread') {
                    return "unread";
                }
            },
            handleSelectionChange(val) {
                this.selectedTasks = val;
            },
            hideTasks: function(){
                let me = this;
                me.$message.closeAll();
                if(me.selectedTasks.length){
                    let selectedTaskIds = me.selectedTasks.map(x => x.id);
                    axios.post('/api/task/hide-tasks/', {ids: selectedTaskIds})
                    .then(function() {
                        me.$message({
                            showClose: true,
                            duration: 20000,
                            type: 'success',
                            message: "The tasks were updated."
                        });
                        me.load();
                    });
                } else {
                    me.$message({
                        showClose: true,
                        duration: 20000,
                        type: 'error',
                        message: "No tasks were selected."
                    });
                }
            },
            updateTasksStatus: function(status){
                let me = this;
                me.$message.closeAll();
                if(me.selectedTasks.length){
                    let selectedTaskIds = me.selectedTasks.map(x => x.id);
                    axios.post('/api/task/update-tasks-status/', {ids: selectedTaskIds, status: status})
                    .then(function() {
                        me.$message({
                            showClose: true,
                            duration: 20000,
                            type: 'success',
                            message: "The tasks were updated."
                        });
                        me.load();
                    });
                } else {
                    me.$message({
                        showClose: true,
                        duration: 20000,
                        type: 'error',
                        message: "No tasks were selected."
                    });
                }
            },
            deleteTasks: function(){
                let me = this;
                me.$message.closeAll();
                if(me.selectedTasks.length){
                    let finished = [];
                    for(let i = 0; i < me.selectedTasks.length; i++){
                        axios.delete('/api/task/', {params: {id: me.selectedTasks[i].id, status: status}})
                        .then(function() {
                            finished.push(me.selectedTasks[i]);
                            if(finished.length == this.selectedTasks.length){
                                me.$message({
                                    showClose: true,
                                    duration: 20000,
                                    type: 'success',
                                    message: "The tasks were updated."
                                });
                                me.load();
                            }
                        });
                    }
                } else {
                    me.$message({
                        showClose: true,
                        duration: 20000,
                        type: 'error',
                        message: "No tasks were selected."
                    });
                }
            }
        },
        mounted() {
            this.load()
        }
    }
</script>

<style scoped>
    div.table-append {
        text-align: right;
        border-bottom: 1px solid #dfe6ec;
        border-right: 1px solid #dfe6ec;
        border-left: 1px solid #dfe6ec;
        line-height: 24px;
        padding-right: 18px;
    }
</style>
