<template>
    <div>
        <h2>Program(s) of Study</h2>
        <el-table v-if="programs.length > 0" :data="programs" class="programs-table">
            <el-table-column prop="name" label="Program Name"></el-table-column>
            <el-table-column prop="degree_name" label="Degree Name"></el-table-column>
            <el-table-column prop="degree_type" label="Degree Type"></el-table-column>
            <el-table-column prop="graduation_date" label="Graduation Date"></el-table-column>
        </el-table>
        <p v-if="programs.length == 0">None</p>
    </div>
</template>
<script>
    export default {
        data() {
            return {
            }
        },
        props: ['programs'],
        methods: {
        }
    }
</script>
<style scoped>
    .programs-table {
        width: 550px;
    }
</style>