<template>
<div>
  <h1>{{ formattedTitle }}</h1>
  <el-form class="term-select" label-position="left">
    <el-form-item label="Students active during year: " >
      <el-select style="width:150px;" size="mini" v-model="yearId" @change="getData">
        <el-option value="null" label="All Students"></el-option>
        <el-option v-for="year in years" :key="year.id" :value="year.id" :label="year.name"></el-option>
      </el-select>
    </el-form-item>
  </el-form>
  <el-table :default-sort="{prop: 'uni', order: 'ascending'}" :data="students" style="width: 100%" v-loading="loading" element-loading-text="Loading...">
    <el-table-column prop="uni" label="UNI" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
    <el-table-column prop="first" label="First" sortable :sort-orders="['ascending', 'descending']">
      <template slot-scope="scope">
        <a :href="'/student/ha-profile/' + scope.row.id">{{ scope.row.first }}</a>
      </template>
    </el-table-column>
    <el-table-column prop="last" label="Last" sortable :sort-orders="['ascending', 'descending']">
      <template slot-scope="scope">
        <a :href="'/student/ha-profile/' + scope.row.id">{{ scope.row.last }}</a>
      </template>
    </el-table-column>
    <el-table-column prop="preferred_name" label="Preferred Name" sortable :sort-orders="['ascending', 'descending']"></el-table-column>

    <el-table-column prop="school" label="School" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
    <el-table-column prop="program" label="Program" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
    <el-table-column prop="latest_term" label="Latest Term" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
    <el-table-column prop="standing" label="Standing" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
    <el-table-column prop="expected_graduation" label="Expected Graduation" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
    <el-table-column prop="actual_graduation" label="Actual Graduation" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
    <el-table-column prop="housing_disabilities" label="Housing Disabilities" sortable :sort-orders="['ascending', 'descending']">
      <template slot-scope="scope">
        <p class="no-margin" v-for="ha_disability in scope.row.disabilities" :key="ha_disability.id">{{ ha_disability }}</p>
      </template>
    </el-table-column>
    <el-table-column prop="approved_accommodations" label="Approved Accommodations" sortable :sort-orders="['ascending', 'descending']" min-width="105px">
      <template slot-scope="scope">
        <p class="no-margin" v-for="accommodation in scope.row.approved_accommodations" :key="accommodation.id">{{ accommodation }}</p>
      </template>
    </el-table-column>
    <el-table-column prop="requested_accommodations" label="Requested Accommodations" sortable :sort-orders="['ascending', 'descending']" min-width="105px">
      <template slot-scope="scope">
        <p class="no-margin" v-for="accommodation in scope.row.requested_accommodations" :key="accommodation.id">{{ accommodation }}</p>
      </template>
    </el-table-column>
    <el-table-column prop="updated" label="Updated" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
    <el-table-column prop="intake_notes" label="Intake Note" sortable :sort-orders="['ascending', 'descending']" min-width="115px">
      <template slot-scope="scope">
        <p class="intake-note" v-for="note in scope.row.intake_notes" :key="note.id"><b>{{ note.title }}</b><br>{{ note.body }}</p>
      </template>
    </el-table-column>
  </el-table>
  <a :href="'/api/student/housing-groups-download.xlsx' + this.xlsXsearchParams" target="_blank" style="margin-top: 15px;">Download</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;({{ this.students.length}} student record{{ this.students.length > 1 || this.students.length == 0 ? 's' : '' }})
</div>
</template>
<script>
  import axios from 'axios'

  export default {
    metaInfo () {
      return {
        title: "All students with status of " + this.title
      }
    },
    data() {
      return {
        title: "",
        students: [],
        searchParams: [],
        xlsXsearchParams: [],
        loading: false,
        years: [],
        yearId: "null"
      }
    },
    props: ['urlParams'],
    computed: {
      formattedTitle: function() {
        if(!this.loading){
          var formattedTitle = "All students with status of " + this.title;

          return formattedTitle;
        }
        return "";
      },
      groupParams: function() {
          var paramsString = "";

          if(this.urlParams.groups){
              paramsString += "[";

              for(var i=0; i<this.urlParams.groups.length; i++){
                  paramsString += "%22"+this.urlParams.groups[i]+"%22";

                  if(i < this.urlParams.groups.length-1){
                      paramsString += ",";
                  }
              }

              paramsString += "]";
          }

          return paramsString;
      }
    },
    methods: {
      getData: function() {
        var params = this.searchParams;

        if(this.yearId != "null"){
          params += "&ha_status_year_id="+this.yearId;
          this.xlsXsearchParams = params += "&ha_status_year_id="+this.yearId;
        } else {
          this.xlsXsearchParams = params;
        }

        this.loading = true;

        axios
                .get('/api/student/housing-groups/'+params)
                .then(response => {
                  this.students = response.data.rows;
                  this.title = response.data.title;
                  this.loading = false;
                })
                .catch(error => {
                  // eslint-disable-next-line
                  console.log(error)
                });
      },
      load: function () {
        this.searchParams = location.search;
          axios.get('/api/simple/housing-accommodation-years/', {params: {}})
                .then(response => {
                  if(response.data.success) {
                    this.years = response.data.rows;

                    this.years.sort(function(a,b){
                      let comparison = 0;
                      if (a.name > b.name) {
                        comparison = 1;
                      } else if (a.name < b.name) {
                        comparison = -1;
                      }
                      return comparison;
                    });

                    this.getData();
                  }
                })
                .catch(error => {
                  // eslint-disable-next-line
                  console.log(error)
                });
      }
    },
    mounted() {
        this.load()
    }
  }
</script>
<style scoped>
  .no-margin {
    margin: 0;
  }

  .intake-note {
    margin-top: 0;
  }

  #content .el-table .cell {
    padding: 1px;
  }

  .el-table {
    font-size: 12px;
  }

  .el-table td {
    vertical-align: top;
  }


  .term-select {
    float:right;
    width: 335px;
  }
</style>
