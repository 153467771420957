/**
 * Vuex
 *
 * @library
 *
 * https://vuex.vuejs.org/en/
 */

// Lib imports
import Vue from 'vue'
import Vuex from 'vuex'
//import dispatchActionForAllModules from '@utils/dispatch-action-for-all-modules'


// Store functionality
//import actions from './actions'
//import getters from './getters'
import modules from './modules'
//import mutations from './mutations'
//import state from './state'

Vue.use(Vuex)

// Create a new store
const store = new Vuex.Store({
 // actions,
 // getters,
  modules //,
 // mutations,
 // state
})

export default store

// Automatically run the `init` action for every module,
// if one exists.
dispatchActionForAllModules('init')


function dispatchActionForAllModules(
  actionName,
  { modulePrefix = '', flags = {} } = {}
) {
  // For every module...
  for (const moduleName in modules) {
    const moduleDefinition = modules[moduleName]

    // If the action is defined on the module...
    if (moduleDefinition.actions && moduleDefinition.actions[actionName]) {
      // Dispatch the action if the module is namespaced. Otherwise,
      // set a flag to dispatch the action globally at the end.
      if (moduleDefinition.namespaced) {
        store.dispatch(`${modulePrefix}${moduleName}/${actionName}`)
      } else {
        flags.dispatchGlobal = true
      }
    }

    // If there are any nested sub-modules...
    if (moduleDefinition.modules) {
      // Also dispatch the action for these sub-modules.
      dispatchActionForAllModules(actionName, {
        modules: moduleDefinition.modules,
        modulePrefix: modulePrefix + moduleName + '/',
        flags,
      })
    }
  }

  // If this is the root and at least one non-namespaced module
  // was found with the action...
  if (!modulePrefix && flags.dispatchGlobal) {
    // Dispatch the action globally.
    store.dispatch(actionName)
  }
}



