<template>
    <div>
        <template v-if="showDetails">
            <table>
                <thead>
                    <tr>
                        <th><img v-if="prop === 'subject' && order ==='ascending'" alt="Sort-asc" :src="sortAsc"><img v-if="prop === 'subject' && order ==='descending'" alt="Sort-desc" :src="sortDesc"><a @click="handleDetailSort('subject')">Subject</a></th>
                        <th><img v-if="prop === 'author_name' && order ==='ascending'" alt="Sort-asc" :src="sortAsc"><img v-if="prop === 'author_name' && order ==='descending'" alt="Sort-desc" :src="sortDesc"><a @click="handleDetailSort('author_name')">Author</a></th>
                        <th><img v-if="prop === 'type' && order ==='ascending'" alt="Sort-asc" :src="sortAsc"><img v-if="prop === 'type' && order ==='descending'" alt="Sort-desc" :src="sortDesc"><a @click="handleDetailSort('type')">Type</a></th>
                        <th><img v-if="prop === 'status_display' && order ==='ascending'" alt="Sort-asc" :src="sortAsc"><img v-if="prop === 'status_display' && order ==='descending'" alt="Sort-desc" :src="sortDesc"><a @click="handleDetailSort('status_display')">Status</a></th>
                        <th><img v-if="prop === 'recipients' && order ==='ascending'" alt="Sort-asc" :src="sortAsc"><img v-if="prop === 'recipients' && order ==='descending'" alt="Sort-desc" :src="sortDesc"><a @click="handleDetailSort('recipients')">Recipients / Assigned to</a></th>
                        <th><img v-if="prop === 'student_name' && order ==='ascending'" alt="Sort-asc" :src="sortAsc"><img v-if="prop === 'student_name' && order ==='descending'" alt="Sort-desc" :src="sortDesc"><a @click="handleDetailSort('student_name')">Student</a></th>
                        <th><img v-if="prop === 'faculty' && order ==='ascending'" alt="Sort-asc" :src="sortAsc"><img v-if="prop === 'faculty' && order ==='descending'" alt="Sort-desc" :src="sortDesc"><a @click="handleDetailSort('faculty')">Faculty</a></th>
                        <th><img v-if="prop === 'last_updated_at' && order ==='ascending'" alt="Sort-asc" :src="sortAsc"><img v-if="prop === 'last_updated_at' && order ==='descending'" alt="Sort-desc" :src="sortDesc"><a @click="handleDetailSort('last_updated_at')">Last Updated</a></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="task in tasks">
                        <tr class="spacer" :key="task.id + 'spacer'"><td colspan="9"></td></tr>
                        <tr class="subhead" :key="task.id">
                            <td>{{ task.subject }}</td>
                            <td>{{ task.author_name }}</td>
                            <td>{{ task.type }}</td>
                            <td>{{ task.status }}</td>
                            <td>{{ constructRecipientsList(task.recipients) }}</td>
                            <td>{{ task.student_name }}</td>
                            <td>{{ task.faculty }}</td>
                            <td>{{ task.last_updated_at | formatDateMDY }} {{ task.latest_change_by_identifier }}</td>
                            <td><router-link  :to="'/tasks/show/' + task.id">show</router-link></td>
                        </tr>
                        <tr v-if="task.body" class="taskbody" :key="task.id+'taskbody'">
                            <td colspan="9">
                                <p><span class="formatted-text">{{ task.body }}</span></p>
                                <template v-if="task.supervisor_comments">
                                    <div v-for="comment in task.supervisor_comments" :key="comment.id">
                                        <blockquote style="width: 35em;">{{ comment.text }}</blockquote>
                                        <cite>-{{ comment.supervisor_name }}, {{ comment.updated_at | formatDateTimeMDYhma }}</cite>
                                    </div>
                                </template>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </template>
        <template v-else>
            <el-table :default-sort="{prop: 'last_updated_at', order: 'descending'}" :data="tasks" @sort-change="handleSort">
                <el-table-column prop="subject" label="Subject" sortable="custom" :sort-orders="['ascending', 'descending']" width="325"></el-table-column>
                <el-table-column prop="author_name" label="Author" sortable="custom" :sort-orders="['ascending', 'descending']" width="130"></el-table-column>
                <el-table-column prop="type" label="Type" sortable="custom" :sort-orders="['ascending', 'descending']" width="85"></el-table-column>
                <el-table-column prop="status_display" label="Status" sortable="custom" :sort-orders="['ascending', 'descending']" width="90"></el-table-column>
                <el-table-column prop="recipients" label="Recipients / Assigned to" sortable="custom" :sort-orders="['ascending', 'descending']">
                    <template slot-scope="scope">{{ constructRecipientsList(scope.row.recipients) }}</template>
                </el-table-column>
                <el-table-column prop="student_name" label="Student" sortable="custom" :sort-orders="['ascending', 'descending']" width="145">
                    <template slot-scope="scope"><a :href="'/student/profile/' + scope.row.student_id">{{ scope.row.student_name }}</a></template>
                </el-table-column>
                <el-table-column prop="last_updated_at" label="Last Updated" sortable="custom" :sort-orders="['ascending', 'descending']" width="150">
                    <template slot-scope="scope">{{ scope.row.last_updated_at | formatDateMDY }} {{ scope.row.latest_change_by_identifier }}</template>
                </el-table-column>
                <el-table-column width="64"><template slot-scope="scope"><router-link :to="'/tasks/show/'+scope.row.id">show</router-link></template></el-table-column>
            </el-table>
        </template>
    </div>
</template>
<script>

    import sortAsc from '../../assets/img/sort-asc.gif'
    import sortDesc from '../../assets/img/sort-desc.gif'

    export default {
        data() {
            return {
                prop: "created_at",
                order: "descending",
                sortAsc: sortAsc,
                sortDesc: sortDesc
            }
        },
        props: ['id', 'tasks', 'showDetails'],
        methods: {
            handleSort: function({column, prop, order}) {
                this.prop = prop;
                this.order = order;
                this.$emit('sortdata', {prop: prop, order: order});
            },
            handleDetailSort: function(prop){
                if(prop === this.prop){
                    if(this.order === "descending"){
                        this.order = "ascending";
                    } else {
                        this.order = "descending";
                    }
                } else {
                    this.prop = prop;
                    this.order = "ascending";
                }
                this.$emit('sortdata', {prop: this.prop, order: this.order});
            },
            constructRecipientsList: function(recipientsObjs){
                if(recipientsObjs){
                    var recipientsStrs = [];
                    recipientsObjs.forEach(function(obj){
                        recipientsStrs.push(obj.name + " <" + obj.email + ">");
                    });

                    return recipientsStrs.join(", ");
                }
            }
        },
        mounted() {
        }
    }
</script>
<style scoped>
    tr.spacer td {
        height: 1em;
        border: 0px;
    }
    tr.subhead td {
        background-color: #eef;
    }
</style>
