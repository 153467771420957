import Vue from "vue"
import moment from 'moment'

Vue.filter("formatDateMDY", function(val) {
    if (val) {
        return moment(String(val)).format('MM/DD/YYYY');
    }
});

Vue.filter("formatDateMD", function(val) {
    if (val) {
        return moment(String(val)).format('MM/DD');
    }
});

Vue.filter("formatDateTrace", function(val) {
    if (val) {
        return moment(String(val)).format('YYYY-MM-DD');
    }
});

Vue.filter("formatDateFullMonth", function(val) {
    if (val) {
        return moment(String(val)).format('MMMM DD, YYYY');
    }
});

Vue.filter("formatDateMonthAndYear", function(val) {
    if (val) {
        return moment(String(val)).format('MMMM YYYY');
    }
});

Vue.filter("formatDateAbbrMonth", function(val) {
    if (val) {
        return moment(String(val)).format('DD MMM YYYY');
    }
});

Vue.filter("formatDateDayOfWeek", function(val) {
    if (val) {
        return moment(String(val)).format('dddd MM/DD');
    }
});

Vue.filter("formatTimehma", function(val) {
    if (val) {
        return moment(String(val)).format('h:mma');
    }
});

Vue.filter("formatTimehhmmA", function(val) {
    if (val) {
        return moment(String(val)).format('hh:mmA');
    }
});

Vue.filter("formatDateTimeMDYhma", function(val) {
    if (val) {
        return moment(String(val)).format('MM/DD/YYYY h:mma');
    }
});

Vue.filter("formatDateTimeMDYhhmmA", function(val) {
    if (val) {
        return moment(String(val)).format('MM/DD/YYYY hh:mmA');
    }
});

Vue.filter("formatDateTimeMDYhhmmssA", function(val) {
    if (val) {
        return moment(String(val)).format('MM/DD/YYYY hh:mm:ssA');
    }
});
