import { render, staticRenderFns } from "./Prospective.vue?vue&type=template&id=4b04b430&scoped=true&"
import script from "./Prospective.vue?vue&type=script&lang=js&"
export * from "./Prospective.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../../opt/jenkins_node_modules/doris/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b04b430",
  null
  
)

export default component.exports