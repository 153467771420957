import { render, staticRenderFns } from "./Denied.vue?vue&type=template&id=97aa3c40&scoped=true&"
import script from "./Denied.vue?vue&type=script&lang=js&"
export * from "./Denied.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../../opt/jenkins_node_modules/doris/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97aa3c40",
  null
  
)

export default component.exports