 <template>
    <div class="panel">
  <h2>History</h2>
  <a v-on:click="show = !show">Show/Hide history</a>
  <div v-if="show">
  <table class="display"><tbody>
  <tr v-for="item in items" :key="item.id"><th>{{ item.date | formatDateMDY }}</th><th v-if="!isAcademic">{{ item.document_type }}</th><td>{{ item.change_text }}</td></tr>
  </tbody></table>
  </div>
</div>
</template>
<script>
  import axios from 'axios'

    export default {
        data() {
            return {
                items: [],
                show: true
            }
        },
        props: ['id', 'isAcademic'],
        methods: {
            load: function () {
                var urlStr = '/api/student/ha-history/';
                if(this.isAcademic){
                    urlStr = '/api/student/academic-history/';
                    this.show = false;
                }
                axios
                    .get(urlStr, {params:{id: this.id}})
                    .then(response => {
                        this.items = response.data.rows;
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            }
        },
        mounted() {
            this.load()
        }
    }
</script>
<style scoped></style>