<template>
  <div class="sections inner-block wide-block" id="students-requiring-action">
    <h2>Students Requiring Action</h2>
    <table>
      <tr v-for="item in items" :key="item.index">
        <th><router-link :to="item.link">{{item.title}}</router-link></th>
        <td>{{item.count}}</td>
      </tr>
    </table>
  </div>
</template>

<script>
  import axios from 'axios'
  export default {
    data () {
      return {
        items: []
      }
    },
    methods: {
      load: function() {
        axios
          .get('/api/home/students-requiring-action')
          .then(response => {
            this.items = response.data.rows.sort(this.sortByOrder);
          })
          .catch(error => {
            // eslint-disable-next-line
            console.log(error)
          });
      },
      sortByOrder: function(a,b) {
        let aOrder = a.order;
        let bOrder = b.order;
        return ((aOrder < bOrder) ? -1 : ((aOrder > bOrder) ? 1 : 0));
      }
    },
    mounted () {
      this.load()
    }
  }
</script>

<style scoped>

</style>
