<template>
    <div style="position:relative" id="status_and_workflow">
        <div class="workflow_action">
            <h3>Director action required: Assign to coordinator</h3>

            <el-autocomplete style="width:250px;" :fetch-suggestions="querySearch" :trigger-on-focus="false" size="mini" @select="function(coordinator) {coordinator_id = coordinator.id}" v-model="coordinator" ></el-autocomplete>
            <el-button type="primary" size="small" @click="onSubmit">Assign to coordinator</el-button>
            <student-student-workflow-system-administrator-options :id="id" :studentName="studentName" v-on:refreshstudent="refreshStudent"></student-student-workflow-system-administrator-options>
        </div>
   </div>
</template>
<script>
    import axios from 'axios'



    export default {
        components: {

        },
        data() {
            return {
                coordinator: null,
                coordinator_id: null
            }
        },
        props: ['id', 'isGuest', 'studentName'],
        methods: {
            load: function () {
            },
            querySearch: function(queryString, cb) {
                 axios.get("/api/student/search-coordinators/?query=" + queryString)
                    .then(response => {
                       cb(response.data.rows);
                    });
            },
            onSubmit: function(action) {
                // eslint-disable-next-line
                console.log(action);

                this.$message.closeAll();
                
                axios.put("/api/student/assign-coordinator/", {id: parseInt(this.id), coordinator_id: this.coordinator_id})
                    .then(response => {
                        if (response.data.success) {
                            // this.$emit('refreshstudent');
                            window.location.reload(); // TODO figure out better way to do this
                        } else {
                            this.$message({
                                showClose: true,
                                duration: 20000,
                                type: 'error',
                                message: response.data.message
                            });
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            },
            refreshStudent: function(){
                this.$emit('refreshstudent');
            }
        },
        mounted() {
            this.load()
        }
    }
</script>
<style scoped>
    .workflow-input {
        width:300px;
    }
    .workflow-input-large {
        width:325px;
    }
    .workflow-input-small {
        width:175px;
    }
    .workflow_action .el-form-item__label {
        padding:0px;
        line-height:130%;
    }
    .bottom-margin {
        margin-bottom:3px;
    }
</style>