<template>
  <div>
    <h2>Courses for {{ semester }}</h2>
    <el-table :data="courses" :style="'width:'+(658 + (totalServices*serviceColumnWidth))+'px'" :span-method="spanMethod">
      <el-table-column prop="course_identifier" label="Course ID" width="109" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
      <el-table-column prop="class_name" label="Course Name" width="225" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
      <el-table-column prop="section_code" label="Section" sortable :sort-orders="['ascending', 'descending']" width="81"></el-table-column>
      <el-table-column label="Day" width="91" sortable :sort-orders="['ascending', 'descending']">
        <template slot-scope="scope">
          <template v-for="(day,index) in scope.row.days">
            <tr v-if="scope.row.times.length > 1" :key="index">{{ day }}</tr>
          </template>
          <span v-if="scope.row.times.length <= 1">{{ renderDayArray(scope.row.days) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="" label="Time" width="152" sortable :sort-orders="['ascending', 'descending']">
        <template slot-scope="scope">
          <tr v-for="(time,index) in scope.row.times" :key="index">{{ time.start_time }} - {{ time.end_time }}</tr>
        </template>
      </el-table-column>
      <el-table-column label="Eligible Services" :width="totalServices * serviceColumnWidth">
        <el-table-column v-if="hasNoteTakingService" label="Note Taking Service Status" :min-width="10.0">
          <template slot-scope="scope">
            <el-button v-if="scope.row.note_taking_status === null" size="mini" type="text" @click="createService(scope.row.id, 'NoteTakingService', false)">Add Service</el-button>
            <el-button v-if="scope.row.showAll" size="mini" type="text" @click="createService(null, 'NoteTakingService', true)">Add All</el-button>
            <div v-if="scope.row.note_taking_status && !scope.row.note_taking_status.cancellation_date">
              <router-link class="status-link" :to="'/note-taking-service/show/' + scope.row.note_taking_status.service_id">{{ scope.row.note_taking_status.status }}</router-link>
              <el-button size="mini" type="text" @click="undoCreateService(scope.row.note_taking_status.id, scope.row.class_name, 'note taking')">Undo</el-button>
              <el-button size="mini" type="text" @click="scope.row.show_cancel_fields = true">Cancel</el-button>
            </div>
            <el-form v-if="scope.row.show_cancel_fields" class="cancel-form">
              <el-form-item label="Cancellation reason">
                <el-input size="mini" v-model="scope.row.cancellation_reason"></el-input>
                <el-button size="mini" type="primary" @click="cancelService(scope.row.note_taking_status.id, scope.row.cancellation_reason, scope.row.class_name)">Confirm Cancellation</el-button>
              </el-form-item>
            </el-form>
            <template v-if="scope.row.note_taking_status && scope.row.note_taking_status.cancellation_date">
              Cancelled
              <el-button size="mini" type="text" @click="uncancelService(scope.row.note_taking_status.id)" v-if="canUncancel">UnCancel</el-button>
            </template>
          </template>
        </el-table-column>
        <el-table-column v-if="hasEtextService" label="E Text Service Status" :min-width="10.0">
          <template slot-scope="scope">
            <el-button v-if="scope.row.etext_status === null" size="mini" type="text" @click="createService(scope.row.id, 'EtextService', false)">Add Service</el-button>
            <el-button v-if="scope.row.showAll" size="mini" type="text" @click="createService(null, 'EtextService', true)">Add All</el-button>
            <div v-if="scope.row.etext_status">
              <router-link class="status-link" :to="'/etext-service/show/' + scope.row.etext_status.service_id">{{ scope.row.etext_status.status }}</router-link>
              <el-button size="mini" type="text" @click="undoCreateService(scope.row.etext_status.id, scope.row.class_name, 'e-text')">Undo</el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column v-if="hasCommunicationService" label="Communication Service Status" :min-width="10.0">
          <template slot-scope="scope">
            <el-button v-if="scope.row.communication_status === null" size="mini" type="text" @click="createService(scope.row.id, 'CommunicationService', false)">Add Service</el-button>
            <el-button v-if="scope.row.showAll" size="mini" type="text" @click="createService(null, 'CommunicationService', true)">Add All</el-button>
            <div v-if="scope.row.communication_status">
              <router-link class="status-link" :to="'/note-taking-service/show/' + scope.row.communication_status.service_id">{{ scope.row.communication_status.status }}</router-link>
              <el-button size="mini" type="text" @click="undoCreateService(scope.row.communication_status.id, scope.row.class_name, 'communication')">Undo</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
  import axios from 'axios'

  export default {
    data() {
      return {
        creatingService: false,
        serviceColumnWidth: 180
      }
    },
    props: ['id', 'courses', 'semester',"hasNoteTakingService", "hasCommunicationService", "hasEtextService", "canUncancel"],
    computed: {
      totalServices: function () {
        var total = 0;
        if(this.hasNoteTakingService){
          total += 1;
        }
        if(this.hasCommunicationService){
          total += 1;
        }
        if(this.hasEtextService){
          total += 1;
        }
        return total == 0 ? 1 : total;
      }
    },
    methods: {
      renderDayArray(days){
        if(days.length > 2){
          return days[0] + "-" + days[days.length - 1];
        }
        return days.join(" ");
      },
      createService: function(id, serviceType, createAll) {
        if(!this.creatingService){
          this.creatingService=true;

          var params = {};
          params.student_id = parseInt(this.id);
          params.service_type = serviceType;
          if(createAll){
            params.course_ids = [];
            for(var i = 0; i < this.courses.length - 1; i++){
              params.course_ids.push(this.courses[i].id);
            }
          } else {
            params.course_ids = [id];
          }
          axios.post('/api/service/create-student-service-enrollment/', params)
                  .then(response => {
                    this.creatingService = false;
                    this.$emit('refreshdata');
                  })
                  .catch(error => {
                    // eslint-disable-next-line
                    console.log(error);
                  });
        }
      },
      undoCreateService: function(id, className, serviceType){
        this.$confirm('Are you sure you want to undo the ' + serviceType + ' for course ' + className + '?')
          .then(() => {
            axios.put('/api/service/undo-student-service-enrollment/', {id: id})
              .then(response => {
                this.$emit('refreshdata');
              })
              .catch(error => {
                // eslint-disable-next-line
                console.log(error);
              });
          }).catch(() => {});
      },
      cancelService: function(id, cancellationReason, className){
        if(cancellationReason && cancellationReason != ""){
          this.$confirm('Are you sure you want to cancel the note taking service for course ' + className + '?')
            .then(() => {
              axios.put('/api/service/cancel-student-service-enrollment/', {id: id, cancellation_reason: cancellationReason})
                .then(response => {
                  this.$emit('refreshdata');
                })
                .catch(error => {
                  // eslint-disable-next-line
                  console.log(error);
                });
            }).catch(() => {});
        } else {
          this.$alert('Please enter a cancellation reason');
        }
      },
      uncancelService: function(id){

        this.$confirm('Are you sure you want to uncancel this notetaking service?')
            .then(() => {
              axios.put('/api/service/uncancel-student-service-enrollment/', {id: id})
                  .then(response => {
                    this.$emit('refreshdata');
                  })
                  .catch(error => {
                    // eslint-disable-next-line
                    console.log(error);
                  });
            }).catch(() => {});
      },
      spanMethod: function({row, column, rowIndex, columnIndex}){
        if(rowIndex == this.courses.length - 1 && columnIndex == 0){
          return {rowspan: 1, colspan: 5};
        } else if(rowIndex == this.courses.length - 1 && columnIndex < 5){
          return {rowspan: 0, colspan: 0};
        }
        return {rowspan: 1, colspan: 1};
      }
    },
    mounted() {
    }
  }
</script>
<style scoped>
  .status-link {
    padding-right: 10px;
  }
  .cancel-form {
    width: 100%;
  }
  .cancel-form label {
    font-size: inherit;
    line-height: inherit;
  }
  .cancel-form .el-form-item__content {
    line-height: inherit;
  }
  .cancel-form .el-input {
    padding-bottom: 3px;
  }
</style>
