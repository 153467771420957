<template>
    <div style="position:relative" id="status_and_workflow">
        <div class="workflow_action">
            <h3>Applicant data entry instructions</h3>
            <p>After you've entered an application and uploaded at least one disability document, the student will automatically be moved to "Pending Assignment" status.</p>
            <p>If too much time has passed with no action, you may</p>

            <el-button type="primary" size="small" @click="showStatusChangeFields = true">Change status to Inactive</el-button>
            <el-form v-if="showStatusChangeFields">
                <el-form-item>
                    <span class="inactive-inputs-label">Reason: </span>
                    <el-select size="small" v-model="inactiveReason" class="workflow-input">
                        <el-option v-for="reason in inactiveReasons" :key="reason.name" :value="reason.name" :label="reason.name"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <span class="inactive-inputs-label">Comment: </span>
                    <el-input size="small" v-model="inactiveComment" class="workflow-input"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" size="small" @click="onChangeStatus('inactive')">Deactivate</el-button>
                </el-form-item>
            </el-form>
            <student-student-workflow-system-administrator-options :id="id" :studentName="studentName" v-on:refreshstudent="refreshStudent"></student-student-workflow-system-administrator-options>
        </div>
   </div>
</template>
<script>
    import axios from 'axios'



    export default {
        components: {

        },
        data() {
            return {
                showStatusChangeFields: false,
                inactiveReasons: [],
                inactiveReason: null,
                inactiveComment: null
            }
        },
        props: ['id', 'studentName'],
        methods: {
            load: function(){
                axios.get("/api/simple/get-inactive-reasons/")
                    .then(response => {
                        this.inactiveReasons = response.data.rows;
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            },
            onChangeStatus: function(newStatus) {
                this.$message.closeAll();
                
                axios.put("/api/student/change-status/", {
                        id: parseInt(this.id),
                        new_status: newStatus,
                        inactive_reason: this.inactiveReason,
                        inactive_comment: this.inactiveComment
                    })
                    .then(response => {
                        if (response.data.success) {
                            this.$emit('refreshstudent');
                        } else {
                            this.$message({
                                showClose: true,
                                duration: 20000,
                                type: 'error',
                                message: response.data.message
                            });
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            },
            refreshStudent: function(){
                this.$emit('refreshstudent');
            }
        },
        mounted(){
            this.load()
        }
    }
</script>
<style scoped>
</style>