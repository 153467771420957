<template>
    <el-form :rules="rules" :model="ruleForm" class="datetime-form">
        <el-form-item required>
            <el-col :span="12">
                <el-form-item prop="dateInput">
                    <el-date-picker type="date" :size="size" placeholder="Pick a date" v-model="ruleForm.dateInput" value-format="yyyy-MM-dd" style="width: 100%;"></el-date-picker>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item prop="timeInput">
                    <el-input class="time-input" :size="size" placeholder="HH:MM PM" v-model="ruleForm.timeInput" prefix-icon="el-icon-time" style="width: 100%;"></el-input>
                </el-form-item>
            </el-col>
        </el-form-item>
    </el-form>
</template>

<script>
    import moment from 'moment'

    export default {
        data () {
            var validateDate = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('Please select the date'));
                } else if(!moment(value, ["YYYY-MM-DD"], true).isValid()) {
                    callback(new Error('Enter date - YYYY-MM-DD'));
                } else {
                    this.$emit('update:date', this.ruleForm.dateInput);
                    callback();
                }
            };
            var validateDateTime = (rule, value, callback) => {
                if (value === '') {
                  callback(new Error('Please input the time'));
                } else if(!moment(value, ["h:mm a","h:mma", "h a", "ha"], true).isValid()) {
                    callback(new Error('Enter time - HH:MM AM or HH AM'));
                } else {
                    this.$emit('update:time', moment(this.ruleForm.timeInput, ["h:mm a", "h:mma", "h a", "ha"]).format("HH:mm"));
                    callback();
                }
            };
            return {
                rules: {
                    dateInput: [
                        { validator: validateDate, trigger: 'blur' },
                        { validator: validateDate, trigger: 'change' }
                    ],
                    timeInput: [
                        { validator: validateDateTime, trigger: 'blur' }
                    ]
                },
                ruleForm: {
                    dateInput: '',
                    timeInput: ''
                }
            }
        },
        props: ['initialDate', 'initialTime', 'size'],
        methods: {
            load: function(){
                this.ruleForm.dateInput = this.initialDate;
                this.ruleForm.timeInput = this.initialTime;
            }
        },
        mounted(){
            this.load();
        }
    }
</script>

<style scoped>
    .datetime-form {
        width:100%;
    }
</style>
