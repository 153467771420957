<template>
  <div>
    <h3 v-if="releaseFormId" class="form-header">
        <a :href="'/document/edit/' + this.releaseFormId + '?student_id=' + this.id">Release Form (click to view)</a><br>Date uploaded: {{ this.releaseFormUpdatedAt }}
    </h3>
    <h3 v-if="!releaseFormId">
        NO STUDENT RELEASE FORM ON FILE! <a :href="'/document/edit?student_id=' + this.id + '&type=OtherDocument'">Click here to upload</a><br>
    </h3>
    <h3 v-if="studentAcknowledgementId" class="form-header">
        <a :href="'/document/edit/' + this.studentAcknowledgementId + '?student_id=' + this.id">Student Acknowledgement Form (click to view)</a><br>Date uploaded: {{ this.studentAcknowledgeUpdatedAt }}
    </h3>
    <h3 v-if="!studentAcknowledgementId" class="form-header">
        NO STUDENT ACKNOWLEDGMENT FORM ON FILE! <a :href="'/document/edit?student_id=' + this.id + '&type=OtherDocument'">Click here to upload</a><br>
    </h3>
    <p>
        <strong>Permission granted to speak with 1:</strong> {{ this.permissionToSpeakName1 }}<br>
        <strong>Relationship to student:</strong> {{ this.permissionToSpeakRelationship1 }}<br>
        <strong>Phone:</strong> {{ permissionToSpeakPhone1 }}
    </p>
    <p>
        <strong>Permission granted to speak with 2:</strong> {{ this.permissionToSpeakName2 }}<br>
        <strong>Relationship to student:</strong> {{ this.permissionToSpeakRelationship2 }}<br>
        <strong>Phone:</strong> {{ permissionToSpeakPhone2 }}
    </p>
    <p>
        <strong>Permission granted to speak with 3:</strong> {{ this.permissionToSpeakName3 }}<br>
        <strong>Relationship to student:</strong> {{ this.permissionToSpeakRelationship3 }}<br>
        <strong>Phone:</strong> {{ permissionToSpeakPhone3 }}
    </p>
  </div>
</template>
<script>
  import axios from 'axios'
  import moment from 'moment'

    export default {
        data() {
            return {
                permissionToSpeakName1: null,
                permissionToSpeakName2: null,
                permissionToSpeakName3: null,
                permissionToSpeakRelationship1: null,
                permissionToSpeakRelationship2: null,
                permissionToSpeakRelationship3: null,
                permissionToSpeakPhone1: null,
                permissionToSpeakPhone2: null,
                permissionToSpeakPhone3: null,
                releaseFormId: null,
                releaseFormUpdatedAt: null,
                studentAcknowledgementId: null,
                studentAcknowledgeUpdatedAt: null
            }
        },
        props: ['id'],
        methods: {
            load: function () {
                axios
                    .get('/api/student/forms-and-permissions/', {params: {id: this.id}})
                    .then(response => {
                        this.permissionToSpeakName1 = response.data.permission_to_speak_name_1;
                        this.permissionToSpeakName2 = response.data.permission_to_speak_name_2;
                        this.permissionToSpeakName3 = response.data.permission_to_speak_name_3;
                        this.permissionToSpeakRelationship1 = response.data.permission_to_speak_relationship_1;
                        this.permissionToSpeakRelationship2 = response.data.permission_to_speak_relationship_2;
                        this.permissionToSpeakRelationship3 = response.data.permission_to_speak_relationship_3;
                        this.permissionToSpeakPhone1 = response.data.permission_to_speak_phone_1;
                        this.permissionToSpeakPhone2 = response.data.permission_to_speak_phone_2;
                        this.permissionToSpeakPhone3 = response.data.permission_to_speak_phone_3;
                        this.releaseFormId = response.data.release_form_id;
                        this.releaseFormUpdatedAt = moment(response.data.release_form_updated_at).format("M/D/YYYY");
                        this.studentAcknowledgementId = response.data.student_acknowledgement_id;
                        this.studentAcknowledgeUpdatedAt = moment(response.data.student_acknowledge_updated_at).format("M/D/YYYY");
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            }
        },
        mounted() {
            this.load()
        }
    }
</script>
<style scoped>
    .form-header {
        width: 450px;
    }
</style>