<template>
  <div>
    <h2>Faculty Information</h2>
    <table class="display" width="40%">
      <tbody><tr>
        <th>PID:</th>
        <td>{{ faculty.pid }}</td>
      </tr>
      <tr>
        <th>UNI:</th>
        <td><a :href="'mailto:' + faculty.uni + '@columbia.edu'">{{ faculty.uni }}</a></td>
      </tr>
      <tr>
        <th>Phone:</th>
        <td>{{ faculty.phone }}</td>
      </tr>
      <tr>
        <th>Email:</th>
        <td><a :href="'mailto:' + faculty.email">{{ faculty.email }}</a></td>
      </tr>
      <tr>
        <th>Expiration Date:</th>
        <td>{{ expirationDate }}</td>
      </tr>
      <tr>
        <th>Coordinator:</th>
        <td>{{ faculty.coordinator }}</td>
      </tr>
      <tr>
        <th>Employment Status:</th>
        <td>{{ faculty.employment_status }}</td>
      </tr>
      <tr>
        <th>Department:</th>
        <td>{{ faculty.department }}</td>
      </tr>
      <tr>
        <th>Rank:</th>
        <td>{{ faculty.rank }}</td>
      </tr>
    </tbody></table>

    <p>
    <span class="help">
      Last updated {{ lastUpdated }} by {{ faculty.profile_last_updated_by }}
    </span>
    </p>
  </div>
</template>
<script>
  import axios from 'axios'
  import moment from 'moment'

    export default {
        data() {
            return {
                faculty: {}
            }
        },
        props: ['id'],
        methods: {
            load: function () {
                axios
                    .get('/api/faculty/information/' + this.id)
                    .then(response => {
                        this.faculty = response.data;
                    })
                    .catch(error => {
                      // eslint-disable-next-line
                        console.log(error)
                    });
            }
        },
        computed: {
          expirationDate: function() {
              if(this.faculty.expiration_date) {
                  return moment(this.faculty.expiration_date).format("M/D/YYYY");
              }

              return "";

          },
          lastUpdated: function() {
              if(this.faculty.profile_last_updated_at) {
                  return moment(this.faculty.profile_last_updated_at).format("M/D/YYYY");
              }
              return "";
          }
        },
        mounted() {
            this.load()
        }
    }
</script>
<style scoped></style>