<template>
    <div style="position:relative" id="status_and_workflow">
        <div class="workflow_action">
            <template v-if="needsPsychologistAssignment">
                <h3>Director action required: Assign to psychologist</h3>
                <h5 v-if="psychologistName">Sent to {{ psychologistName }}, {{ psychologistAssignedDate }} by {{ psychologistAssignedBy }}</h5>
                <el-autocomplete style="width:245px;" :fetch-suggestions="querySearch" :trigger-on-focus="false" size="mini" @select="function(psychologist) {psychologist_id = psychologist.id}" v-model="psychologist" ></el-autocomplete>
                <el-button type="primary" size="small" @click="onAssignPsychologist">Assign to psychologist</el-button>
            </template>
            <template v-else>
                <h3>Psychologist review required</h3>
                <student-application-process-reviews :id="id" :limited=true></student-application-process-reviews>
                <p>Please read through this applicant's documentation, disabilities, and accommodations, and enter a summary of your review here.</p>
                <el-input v-model="body" type="textarea" :rows="4"></el-input>
                <el-button type="primary" size="small" @click="onSubmit">Send review to ODS Coordinator</el-button>
            </template>
            <student-student-workflow-system-administrator-options :id="id" :studentName="studentName" v-on:refreshstudent="refreshStudent"></student-student-workflow-system-administrator-options>
        </div>
   </div>
</template>
<script>
    import axios from 'axios'
    import moment from 'moment'

    // import applicationProcessReviews from '../application_process_reviews.vue'
    // import systemAdministratorOptions from './SystemAdministratorOptions.vue'

    export default {
        data() {
            return {
                psychologist: null,
                psychologist_id: null,
                someCondition: false, //TODO: Finish this condition
                body: "",
                needsPsychologistAssignment: true,
                psychologistName: null,
                psychologistAssignedDate: null,
                psychologistAssignedBy: null
            }
        },
        props: ['id', 'studentName'],
        methods: {
            load: function () {
                axios.get("/api/student/workflow-information/", {params:{id: this.id}})
                    .then(response => {
                        this.needsPsychologistAssignment = response.data.needsPsychologistAssignment;
                        this.psychologistName = response.data.psychologistName;
                        this.psychologistAssignedBy = response.data.psychologistAssignedBy;
                        if(response.data.psychologistAssignedDate){
                            this.psychologistAssignedDate = moment(response.data.psychologistAssignedDate).format("M/D/YYYY h:mma");
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            },
            querySearch: function(queryString, cb) {
                 axios.get("/api/student/search-psychologists/?query=" + queryString)
                    .then(response => {
                       cb(response.data.rows);
                    });
            },
            onAssignPsychologist: function() {
                this.$message.closeAll();

                axios.put("/api/student/assign-psychologist/", {id: parseInt(this.id), psychologist_id: this.psychologist_id})
                    .then(response => {
                        if (response.data.success) {
                            this.load();
                        } else {
                            this.$message({
                                showClose: true,
                                duration: 20000,
                                type: 'error',
                                message: response.data.message
                            });
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            },
            onSubmit: function() {
                this.$message.closeAll();
                
                axios.put("/api/student/psychologist-review-complete/", {id: parseInt(this.id), body: this.body})
                    .then(response => {
                        if (response.data.success) {
                            this.$emit('refreshstudent');
                        } else {
                            this.$message({
                                showClose: true,
                                duration: 20000,
                                type: 'error',
                                message: response.data.message
                            });
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            },
            refreshStudent: function(){
                this.$emit('refreshstudent');
            }
        },
        mounted() {
            this.load()
        }
    }
</script>
<style scoped>
    .workflow-input {
        width:300px;
    }
    .workflow-input-large {
        width:325px;
    }
    .workflow-input-small {
        width:175px;
    }
    .workflow_action .el-form-item__label {
        padding:0px;
        line-height:130%;
    }
    .bottom-margin {
        margin-bottom:3px;
    }
</style>