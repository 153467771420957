<template>
  <div>
    <h2>Enter {{ this.disability.id != null ? "one" : "a new" }} {{ this.is_academic ? "academic" : "housing" }} disability for {{ this.student_name }}</h2>
    <el-form :model="disability" :rules="rules" ref="disabilityForm" label-width="150px" label-position="right">
      <el-form-item label="ODS Category" prop="disability_category">
        <el-select size="mini" v-model="disability.disability_category" @change="onDisabilityCategoryChange">
          <el-option v-for="category in disability_categories" :key="category.ods_category" :label="category.ods_category" :value="category.ods_category"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="ODS Disability Type" prop="disability_type_id">
        <el-select size="mini" v-model="disability.disability_type_id">
          <el-option v-for="type in disability_types" :key="type.id" :label="type.name" :value="type.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Description" prop="description" class="description">
        <el-input placeholder="Description" size="mini" v-model="disability.description"></el-input>
        <span class="form-text">Enter description here if "Temporary Injury" or "Other" is chosen.</span>
      </el-form-item>
      <el-form-item label="Assistive Device" prop="assistive_device" class="description">
        <el-select size="mini" v-model="disability.assistive_device">
          <el-option key="" label="" value=""></el-option>
          <el-option key="Wheelchair" label="Wheelchair" value="Wheelchair"></el-option>
          <el-option key="Other assistive device" label="Other assistive device" value="Other assistive device"></el-option>
        </el-select>
        <span class="form-text">Select one for physical disabilities only.</span>
      </el-form-item>
      <el-form-item label="Primary Disability" prop="is_primary_disability">
        <el-checkbox style="margin-top:6px;" v-model="disability.is_primary_disability"></el-checkbox>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="mini" @click="onSubmit('disabilityForm')">Save Changes</el-button>
        <el-button @click="onDelete" size="mini" v-if="disability.id != null">Delete</el-button>
        <el-button type="text" size="mini" @click="onCancel">Cancel</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
  import axios from 'axios'

  export default {
    data() {
      return {
        disability: {
          id: null,
          disability_category: null,
          disability_type_id: null,
          description: null,
          assistive_device: null,
          is_primary_disability: null
        },
        disability_categories: [],
        disability_types: [],
        rules: {
          disability_category: [{ required: true, message: 'ODS Category is required' }],
          disability_type_id: [{ required: true, message: 'ODS Disability Type is required' }]
        },
        initialLoad:true
      }
    },
    props: ['id', 'student_id', 'student_name', 'is_academic'],
    methods: {
      load: function () {
        axios
          .get('/api/simple/disability-categories/')
          .then(response => {
            this.disability_categories = response.data.rows;

            if(this.id){
              var url_str = "";
              if(this.is_academic){
                url_str = '/api/student/academic-disability/';
              } else {
                url_str = '/api/student/housing-disability/';
              }
              axios
                .get(url_str, {params:{id: this.id}})
                .then(getResponse => {
                  this.disability.id = getResponse.data.id;
                  this.disability.disability_category = getResponse.data.disability_category;
                  this.disability.disability_type_id = getResponse.data.disability_type_id;
                  this.disability.description = getResponse.data.description;
                  this.disability.assistive_device = getResponse.data.assistive_device;
                  this.disability.is_primary_disability = getResponse.data.is_primary_disability;

                  this.onDisabilityCategoryChange(this.disability.disability_category);
                })
                .catch(error => {
                  // eslint-disable-next-line
                  console.log(error)
                });
            } else {
              this.disability.disability_category = response.data.rows[0].ods_category;
              this.onDisabilityCategoryChange(this.disability.disability_category);
              // axios
              //    .get('/api/simple/disability-types-for-category/', {params: {category: this.disability.disability_category}})
              //    .then(response => {
              //        this.disability_types = response.data.rows;
              //    })
              //    .catch(error => {
              //      // eslint-disable-next-line
              //        console.log(error)
              //    });
            }
          })
          .catch(error => {
            // eslint-disable-next-line
            console.log(error)
          });
      },
      onDisabilityCategoryChange: function(category) {
        axios
          .get('/api/simple/disability-types-for-category/', {params: {category: category}})
          .then(response => {
            this.disability_types = response.data.rows;

            if(this.disability_types){
              if(this.initialLoad){
                if(!this.disability.disability_type_id){
                  this.disability.disability_type_id = this.disability_types[0].id;
                }
                this.initialLoad = false;
              } else {
                this.disability.disability_type_id = null;
              }
            }
          })
          .catch(error => {
            // eslint-disable-next-line
            console.log(error)
          });
      },
      onSubmit: function(formName) {
        this.$refs[formName].validate((valid) => {
          if(valid){
            delete this.disability.disability_category
            var url_str = "";
            if(this.is_academic){
              url_str = '/api/student/academic-disabilities/';
            } else {
              url_str = '/api/student/housing-disabilities/';
            }
            if(this.id){
              this.disability.id = this.id
              axios
                .put(url_str, this.disability)
                .then(response => {
                  this.$router.push({ path: '/student/disabilities/' + this.student_id });
                })
                .catch(error => {
                  // eslint-disable-next-line
                  console.log(error);
                });
            } else {
              this.disability.student_id = this.student_id
              axios
                .post(url_str, this.disability)
                .then(response => {
                  this.$router.push({ path: '/student/disabilities/' + this.student_id });
                })
                .catch(error => {
                  // eslint-disable-next-line
                  console.log(error);
                });
            }
          }
        });
      },
      onDelete: function() {
        var url_str = "";
        if(this.is_academic){
          url_str = '/api/student/academic-disabilities/';
        } else {
          url_str = '/api/student/housing-disabilities/';
        }
        axios
          .delete(url_str, {params: {id: this.disability.id}})
          .then(response => {
            this.$router.push({ path: '/student/disabilities/' + this.student_id });
          })
          .catch(error => {
            // eslint-disable-next-line
            console.log(error);
          });
      },
      onCancel: function() {
        this.$router.push({ path: '/student/disabilities/' + this.student_id });
      }
    },
    mounted() {
      this.load()
    }
  }
</script>
<style scoped>
  .el-select {
    width:100%;
  }
  .description {
    margin-bottom:0px;
  }
</style>
