<template>
  <div v-if="this.items.length" id="weekend-session-alerts">
    <h3 class="red">SPECIAL ALERT: Weekend Exam scheduled to take place on:</h3>
    <div  class="panel" style="width: 70em;">
      <table class="listing">
        <tbody>
        <tr v-for="item in items" :key="item.index">
          <td>{{item.date}}</td>
          <td>{{item.start}}-{{item.end}}</td>
          <td>
            <template v-if="item.has_proctors">
              <template v-for="proctor in item.proctors"><div :key="proctor.index">{{proctor}}</div></template>
            </template>
            <template v-else>
              No Proctor Assigned
            </template>
          </td>
          <td>
            <template v-if="item.building">
              {{item.building}} {{item.room}}
            </template>
            <template v-else>
              Student Not Assigned to Proctoring Session
            </template>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
  import axios from 'axios'
  export default {
    data() {
      return {
        items: []
      }
    },
    methods: {
      load: function() {
        axios
          .get('/api/home/weekend-session-alerts')
          .then(response => {
            if(response.data.success) this.items = response.data.rows;
          })
          .catch(error => {
            // eslint-disable-next-line
            console.log(error)
          });
      }
    },
    mounted () {
      this.load()
    }
  }
</script>
<style scoped>

</style>