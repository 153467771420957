<template>
  <div>
    <!-- This table is deliberately empty. It renders the headers for the accommodations review table and does not display the "No Data" message -->
    <el-table :data="[{}]" style="width: 100%" :span-method="spanMethod">
      <el-table-column label="Accommodations" class-name="accommodation"></el-table-column>
      <el-table-column width="45"></el-table-column>
      <el-table-column label="Status" width="225"></el-table-column>
      <el-table-column label="Last changed" width="150"></el-table-column>
      <el-table-column label="Comment" width="250"></el-table-column>
      <el-table-column label="Add" width="51"></el-table-column>
    </el-table>
    <template v-for="availableAccommodation in availableAccommodations">
      <accommodations-edit-table :key="availableAccommodation.id" :user="user" :accommodationObj="availableAccommodation" :accommodationStatus="accommodationStatus" :showHeader="availableAccommodations.indexOf(availableAccommodation) == 0" :indent="0"></accommodations-edit-table>
    </template>
    <el-button type="primary" size="mini" @click="onSubmit()">Save changes</el-button> or <router-link :to="'/student/accommodations/' + this.id">Cancel and return to student record</router-link>
  </div>
</template>
<script>
  import axios from 'axios'
  import accommodationsEditTable from './accommodations-sub-components/AccommodationsEditTable.vue'

  export default {
    components: {
      accommodationsEditTable
    },
    data() {
      return {
      }
    },
    props: ['id', 'availableAccommodations', 'accommodationStatus', 'user'],
    methods: {
      onSubmit: function() {
        var accommodationsObj = {};
        accommodationsObj.newAccommodations = [];
        accommodationsObj.oldAccommodations = [];
        accommodationsObj.formContainsErrors = false;

        for(var i = 0; i < this.availableAccommodations.length; i++){
          var resultObj = this.submitAccommodations(this.availableAccommodations[i]);
          accommodationsObj.newAccommodations = accommodationsObj.newAccommodations.concat(resultObj.newAccommodations);
          accommodationsObj.oldAccommodations = accommodationsObj.oldAccommodations.concat(resultObj.oldAccommodations);
          if(resultObj.formContainsErrors){
            accommodationsObj.formContainsErrors = true;
          }
        }

        if(accommodationsObj.formContainsErrors){
          this.$message({
            showClose: true,
            duration: 20000,
            type: 'error',
            message: 'One or more fields are missing values. These have been highlighted in red'
          });
        } else {
          axios.put('/api/accommodation/update-student-accommodations/', {accommodations: accommodationsObj.oldAccommodations})
            .then(response => {
              this.$router.push({ path: '/student/accommodations/' + this.id});
            })
            .catch(error => {
              // eslint-disable-next-line
              console.log(error);
            });

          axios.post('/api/accommodation/add-student-accommodations/', {student_id: parseInt(this.id), new_accommodations: accommodationsObj.newAccommodations})
            .then(response => {
              this.$router.push({ path: '/student/accommodations/' + this.id});
            })
            .catch(error => {
              // eslint-disable-next-line
              console.log(error);
            });
        }
      },
      submitAccommodations: function(accommodation){
        var returnObj = {};
        returnObj.newAccommodations = [];
        returnObj.oldAccommodations = [];

        if(accommodation.sub_categories.length){
          for(var i = 0; i < accommodation.sub_categories.length; i++){
            var resultObj = this.submitAccommodations(accommodation.sub_categories[i]);
            returnObj.newAccommodations = returnObj.newAccommodations.concat(resultObj.newAccommodations);
            returnObj.oldAccommodations = returnObj.oldAccommodations.concat(resultObj.oldAccommodations);
            if(resultObj.formContainsErrors){
              returnObj.formContainsErrors = true;
            }
          }
        } else {
          for(var j = 0; j < accommodation.accommodation_types.length; j++){
            var accommodationType = accommodation.accommodation_types[j];
            for(var k = 0; k < accommodationType.student_accommodations.length; k++){
              var accommodationToSubmit = accommodationType.student_accommodations[k];
              var params = {};

              if(accommodationToSubmit.isNew){
                if(accommodationToSubmit.showDescriptionError) accommodationToSubmit.showDescriptionError = false;
                if(accommodationToSubmit.showCampusLockerError) accommodationToSubmit.showCampusLockerError = false;
                if(accommodationToSubmit.showCampusLockerOtherError) accommodationToSubmit.showCampusLockerOtherError = false;
                if(accommodationToSubmit.showStudentError) accommodationToSubmit.showStudentError = false;
                if(accommodationToSubmit.showFontSizeError) accommodationToSubmit.showFontSizeError = false;
                if(accommodationToSubmit.showFontTypeError) accommodationToSubmit.showFontTypeError = false;
                if(accommodationToSubmit.showFontOtherError) accommodationToSubmit.showFontOtherError = false;
                if(accommodationToSubmit.showComputerChoiceError) accommodationToSubmit.showComputerChoiceError = false;
                if(accommodationToSubmit.showComputerChoiceOtherError) accommodationToSubmit.showComputerChoiceOtherError = false;

                params.accommodation_type_id = accommodationToSubmit.accommodation_type_id;
                params.description = accommodationToSubmit.description;
                params.status = accommodationToSubmit.status;
                params.comment = accommodationToSubmit.comment;
                params.campus_locker_other = accommodationToSubmit.campus_locker_other;
                params.computer_choice = accommodationToSubmit.computer_choice;
                params.computer_choice_other = accommodationToSubmit.computer_choice_other;
                params.avoided_student_id = accommodationToSubmit.avoided_student_id;
                params.font_size = accommodationToSubmit.font_size;
                params.font_type = accommodationToSubmit.font_type;
                params.font_options = accommodationToSubmit.font_options;
                params.font_options_other = accommodationToSubmit.font_options_other;

                if(accommodationToSubmit.requires_description && !accommodationToSubmit.description){
                  this.$set(accommodationToSubmit, 'showDescriptionError', true);
                  returnObj.formContainsErrors = true;
                }

                if(accommodationToSubmit.accommodation_type_options && !accommodationToSubmit.description){
                  this.$set(accommodationToSubmit, 'showCampusLockerError', true);
                  returnObj.formContainsErrors = true;
                }

                if(accommodationToSubmit.accommodation_type_options && accommodationToSubmit.description == 'Other' && !accommodationToSubmit.campus_locker_other){
                  this.$set(accommodationToSubmit, 'showCampusLockerOtherError', true);
                  returnObj.formContainsErrors = true;
                }

                if(accommodationToSubmit.requires_student && !accommodationToSubmit.avoided_student_id){
                  this.$set(accommodationToSubmit, 'showStudentError', true);
                  returnObj.formContainsErrors = true;
                }

                if(accommodationToSubmit.show_font_options){
                  if(accommodationToSubmit.showFontSizeOptions && !accommodationToSubmit.font_size){
                    this.$set(accommodationToSubmit, 'showFontSizeError', true);
                    returnObj.formContainsErrors = true;
                  }

                  if(accommodationToSubmit.showFontSizeOptions && !accommodationToSubmit.font_type){
                    this.$set(accommodationToSubmit, 'showFontTypeError', true);
                    returnObj.formContainsErrors = true;
                  }

                  if(accommodationToSubmit.showFontOther && !accommodationToSubmit.font_options_other){
                    this.$set(accommodationToSubmit, 'showFontOtherError', true);
                    returnObj.formContainsErrors = true;
                  }

                }

                if(accommodationToSubmit.requuires_computer_option && !accommodationToSubmit.computer_choice){
                  this.$set(accommodationToSubmit, 'showComputerChoiceError', true);
                  returnObj.formContainsErrors = true;
                }

                if(accommodationToSubmit.computer_choice == 'Other' && !accommodationToSubmit.computer_choice_other){
                  this.$set(accommodationToSubmit, 'showComputerChoiceOtherError', true);
                  returnObj.formContainsErrors = true;
                }

                returnObj.newAccommodations.push(params);
              } else {
                if(accommodationToSubmit.comment){
                  params.id = accommodationToSubmit.id;
                  params.status = accommodationToSubmit.status;
                  params.comment = accommodationToSubmit.comment;
                  params.comment_id = accommodationToSubmit.comment_id;
                  returnObj.oldAccommodations.push(params);
                }
              }

            }
          }
        }
        return returnObj;
      },
      spanMethod: function(){
        return [0,0];
      }
    },
    mounted() {
    }
  }
</script>
<style scoped>
</style>
