<template>
    <div>
        <h2>Note Taking Services for {{ semester }}</h2>
        <el-table v-if="noteTakingServices.length" :data="noteTakingServices" style="max-width: 1140px">
            <el-table-column prop="course_identifier" label="Course ID" :min-width="11.5" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
            <el-table-column prop="class_name" label="Course Name" :min-width="22.5" sortable :sort-orders="['ascending', 'descending']">
                <template slot-scope="scope">
                    <a class="status-linkx" :href="'/note-taking-service/show/' + scope.row.service_id">{{ scope.row.class_name }}</a>
                </template>
            </el-table-column>
            <el-table-column label="Service Status" sortable :sort-orders="['ascending', 'descending']" :min-width="19.0">
                <template slot-scope="scope">
                    {{ scope.row.status }}
                    <br>Date Added: {{ scope.row.date_added }}
                    <template v-if="scope.row.cancellation_date"><br>Cancellation Date: {{ scope.row.cancellation_date }}</template>
                    <template v-if="scope.row.cancellation_reason"><br>Reason: {{ scope.row.cancellation_reason }}</template>
                </template>
            </el-table-column>
            <el-table-column label="Note taker" :min-width="12.5" sortaable><template slot-scope="scope">{{ scope.row.notetaker_first_name }} {{ scope.row.notetaker_last_name }}</template></el-table-column>
            <el-table-column label="Last Pickup Week" :min-width="17.0" sortable :sort-orders="['ascending', 'descending']">
                <template slot-scope="scope">
                    <template v-if="scope.row.last_pickup_week">Week: {{ scope.row.last_pickup_week }}: {{ scope.row.last_pickup_week_dates }}</template>
                </template>
            </el-table-column>
            <el-table-column label="Direct Delivery" :min-width="11.5" align="center">
                <template slot-scope="scope"><el-checkbox v-model="scope.row.direct_delivery" @change="setDirectDelivery(scope.row)"></el-checkbox></template>
            </el-table-column>
            <el-table-column label="Note Format Preference" :min-width="19.0">
                <template slot-scope="scope">
                    <el-select size="mini" v-model="scope.row.student_preferred_format" @change="onPreferredFormatChange(scope.row)">
                        <el-option value="Typed Notes" label="Typed Notes"></el-option>
                        <el-option value="Hand-written Notes" label="Hand-written Notes"></el-option>
                    </el-select>
                </template>
            </el-table-column>
        </el-table>
        <p class="empty-text" v-if="noteTakingServices.length == 0">No note taking services have been added for this term.</p>
    </div>
</template>
<script>
    import axios from 'axios'

    export default {
        data() {
            return {
            }
        },
        props: ['id', 'noteTakingServices', 'semester'],
        methods: {
            setDirectDelivery: function(service){
                this.$confirm('Are you sure you want to check this checkbox?')
                    .then(() => {
                        axios.put('/api/service/update-direct-delivery/', {id: service.id, direct_delivery: service.direct_delivery})
                            .then(response => {
                            })
                            .catch(error => {
                                // eslint-disable-next-line
                              console.log(error);
                            });
                    }).catch(() => {
                        service.direct_delivery = !service.direct_delivery;
                    });
            },
            onPreferredFormatChange: function(service){
                this.$confirm('Are you sure you want to check this value?')
                    .then(() => {
                        axios.put('/api/service/update-notes-preference/', {id: service.id, notes_preference: service.student_preferred_format})
                            .then(response => {
                            })
                            .catch(error => {
                                // eslint-disable-next-line
                              console.log(error);
                            });
                    }).catch(() => {
                        if(service.student_preferred_format == "Typed Notes"){
                            service.student_preferred_format = "Hand-written Notes";
                        } else {
                            service.student_preferred_format = "Typed Notes";
                        }
                    });
            }
        },
        mounted() {
        }
    }
</script>
<style scoped>
</style>