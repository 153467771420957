<template>
    <div>
        <el-table v-for="(obj, objIndex) in accommodationsAndCategories" :data="obj.accommodations" :key="obj.category" style="width: 100%" :show-header="accommodationsAndCategories.indexOf(obj) == 0" :span-method="spanMethod" :cell-class-name="cellClassName">
            <el-table-column>
                <el-table-column prop="category" label="Accommodations" min-width="175">
                    <template slot-scope="scope">
                        <template v-if="scope.row.categoryName"><span class="text-underline">{{ scope.row.categoryName }}</span></template>
                        <template v-else>
                            <div style="margin-left: 2em">
                                {{ scope.row.accommodation_type }}: {{ scope.row.description }}
                                <template v-if="scope.row.requires_student">
                                    {{scope.row.avoided_student_preferred_first_name ? scope.row.avoided_student_preferred_first_name : scope.row.avoided_student_first_name}} {{ scope.row.avoided_student_last_name }} &lt;{{scope.row.avoided_student_uni}}&gt;
                                </template>
                                <template v-if="scope.row.requires_computer_option">
                                    {{ scope.row.computer_choice == "Other" ? "Other: "+scope.row.computer_choice_other : scope.row.computer_choice }}
                                </template>
                                <template v-if="scope.row.show_font_options">
                                    <template v-for="(font_option) in scope.row.font_options">

                                        <template v-if="font_option == 'Font Size'">
                                            <br :key="font_option + 'br'"/>Font Size: {{ scope.row.font_size }}; Font Type: {{scope.row.font_type}}
                                        </template>
                                        <template v-else-if="font_option == 'Other'">
                                                <br :key="font_option + 'br'"/>Other: {{scope.row.font_options_other}}
                                        </template>
                                        <template v-else>
                                            <br :key="font_option + 'br'"/>{{ font_option }}
                                        </template>
                                    </template>
                                </template>

                                <br>
                                <small>Current Status: <span class="text-italic">{{ scope.row.status }}</span></small>
                            </div>
                        </template>

                    </template>
                </el-table-column>
            </el-table-column>
            <el-table-column label="Requests">
                <el-table-column label="Req." width="56"><template slot-scope="scope">{{ scope.row.request_creation ? "Yes" : "No" }}</template></el-table-column>
                <el-table-column prop="request_creation" label="Date" width="95"></el-table-column>
            </el-table-column>
            <el-table-column label="Recommendations">
                <el-table-column prop="recommended_user_name" label="UNI" width="85"></el-table-column>
                <el-table-column prop="recommendation" label="Recom'd" width="95">
                    <template slot-scope="scope">
                        <template v-if="scope.row.recommendation == 'Not Recommended'">
                            No
                        </template>
                        <template v-else>
                            {{scope.row.recommendation}}
                        </template>
                    </template>
                </el-table-column>
                <el-table-column prop="recommended_creation" label="Date" width="95"></el-table-column>
            </el-table-column>
            <el-table-column label="Director Review">
                    <el-table-column label="UNI" width="85">
                        <template slot-scope="scope">
                            <template v-if="scope.row.latest_status_change_id && scope.row.expired_id && scope.row.latest_status_change_id == scope.row.expired_id">
                                {{scope.row.expired_user_name}}
                            </template>
                            <template v-else-if="scope.row.status == 'Needs Further Review'">
                                {{scope.row.needs_further_review_user_name}}
                            </template>
                            <template v-else-if="scope.row.no_longer_needed_id">
                                {{scope.row.no_longer_needed_user_name}}
                            </template>
                            <template v-else-if="scope.row.declined_by_student_id">
                                {{scope.row.declined_by_student_user_name}}
                            </template>
                            <template v-else>{{scope.row.eligible_user_name}}
                            </template>
                        </template>
                    </el-table-column>
                    <el-table-column label="Eligible" width="95">
                        <template slot-scope="scope">
                            <template v-if="scope.row.latest_status_change_id && scope.row.expired_id && scope.row.latest_status_change_id == scope.row.expired_id">
                                Expired
                            </template>
                            <template v-else-if="scope.row.status == 'Needs Further Review'">
                                Needs Further Review
                            </template>
                            <template v-else-if="scope.row.no_longer_needed_id">
                                Expired - {{scope.row.no_longer_needed_from == 'eligible_temporary' ? 'temp' : 'permanent'}}; no longer needed
                            </template>
                            <template v-else-if="scope.row.declined_by_student_id">
                                Expired - {{scope.row.no_longer_needed_from == 'eligible_temporary' ? 'temp' : 'permanent'}}; declined by student
                            </template>
                            <template v-else>
                                {{scope.row.eligibility}}
                            </template>
                        </template>
                    </el-table-column>
                    <el-table-column label="Date" width="95">
                        <template slot-scope="scope">
                            <template v-if="scope.row.latest_status_change_id && scope.row.expired_id && scope.row.latest_status_change_id == scope.row.expired_id">
                                {{scope.row.expired_creation}}
                            </template>
                            <template v-else-if="scope.row.status == 'Needs Further Review'">
                                {{scope.row.needs_further_review_creation}}
                            </template>
                            <template v-else-if="scope.row.no_longer_needed_id">
                                {{scope.row.no_longer_needed_creation}}
                            </template>
                            <template v-else-if="scope.row.declined_by_student_id">
                                {{scope.row.declined_by_student_creation}}
                            </template>
                            <template v-else>
                                {{scope.row.eligible_creation}}
                            </template>
                        </template>
                    </el-table-column>
            </el-table-column>
            <el-table-column :key="renderUpdate + objIndex">
                <!--<el-table-column :label="'Comment\n(click to edit)'" label-class-name="formatted-text"> TODO: If the line break is preferred use this definition-->
                <el-table-column label="Comment (click to edit)">
                    <div slot-scope="scope" :key="scope.row.show_comment_edit">
                        <template v-if="scope.row.show_comment_edit">
                            <el-input type="textarea" :rows="1" v-model="scope.row.comment"></el-input>
                            <el-button size="mini" type="primary" @click="updateAccommodationComment(scope.row)" :key="scope.row.show_comment_edit +'button'">Ok</el-button>
                            <a @click="toggleEdit(scope.row)">Cancel</a>
                        </template>
                        <template v-else>
                            <div class="formatted-text" @click="toggleEdit(scope.row)" style="width:100%; height: 100%">{{ scope.row.comment }}</div>
                        </template>
                    </div>
                </el-table-column>
            </el-table-column>
        </el-table>
    </div>
</template>
<script>
    import axios from 'axios'

    export default {
        data() {
            return {
                renderUpdate: false
        }
    },
    props: ['id', 'accommodationsAndCategories'],
    methods: {
        toggleEdit: function(row){
            row.show_comment_edit = !row.show_comment_edit;
           this.renderUpdate = !this.renderUpdate;
        },
        spanMethod: function({row, column, rowIndex, columnIndex}){
            if(rowIndex == 0 && columnIndex == 0){
                return {rowspan: 1, colspan: 10};
            } else if(rowIndex == 0 && columnIndex > 0){
                return {rowspan: 0, colspan: 0};
            }
            return {rowspan: 1, colspan: 1};
        },
        cellClassName: function({row, column, rowIndex, columnIndex}){
            if(rowIndex == 0 && columnIndex == 0){
                return "accommodation-category"
            }
        },
        updateAccommodationComment: function(accommodation){

            var params = [{id: accommodation.id, status: accommodation.actual_status, comment: accommodation.comment, comment_id: accommodation.comment_id}];
            axios.put('/api/accommodation/update-student-accommodations/', {accommodations: params})
                .then(response => {
                    this.toggleEdit(accommodation);
                })
                .catch(error => {
                    // eslint-disable-next-line
                  console.log(error);
                });
        }
    },
    mounted() {
    }
  }
</script>
<style scoped>
    .accommodation-category{
        border-top:0px;
    }
</style>