<template>
  <el-dialog title="Logout Warning" width="25%" :visible.sync="show" :show-close="false" :center="true" @close="onTimeoutCancel" custom-class="timeout-dialog" v-if="timeoutRemaining <= showWarningTime">
        <span>
            Your session will end in {{ timeoutRemaining }} minutes.<br>If you wish to remain logged in,<br>please click the "Continue Session" button below
            <el-progress :percentage="percentage" :show-text="false"></el-progress>
        </span>
    <span slot="footer">
            <el-button @click="onTimeoutCancel" size="mini">Continue Session</el-button>
            <el-button @click="onLogout" size="mini">Logout Now</el-button>
        </span>
  </el-dialog>
</template>
<script>
  import axios from 'axios'

  export default {
    data() {
      return {
        timeoutLength:15, //Amount of time to continue after user is idle, used to reset timeoutRemaining
        timeoutRemaining: 15, // amount of time remaining
        showWarningTime: 5,
        activateLoginTimeout: true,
        show: false,
        percentage: 10,
        logoutTimeout: null,
        timeLeftInterval: null,
        percentageInterval: null,
        isActiveTab: false
      }
    },
    methods: {
      load: function() {
        axios.get("/api/login-timeout-active/")
          .then(response => {
            this.activateLoginTimeout = response.data.activateLoginTimeout;
          });

        if(document.hasFocus()) this.isActiveTab = true;

        window.addEventListener('focus', function() {
          this.isActiveTab = true;
        });
        window.addEventListener('blur', function() {
          this.isActiveTab = false;
        });
        window.addEventListener('storage', this.messageReceive);
      },
      onLogout: function() {
        localStorage.setItem('message',JSON.stringify({'command': 'logout'}));
        localStorage.removeItem('message');
        this.doLogout();
      },
      doLogout: function() {
        this.$router.push({name: 'logout'})
      },
      onTimeoutCancel: function(){
              axios.get("/api/stay-logged-in/")
                  .then(response => {
                  });

          localStorage.setItem('message',JSON.stringify({'command': 'canceltimeout'}));
        localStorage.removeItem('message');
        this.doTimeoutCancel();
      },
      doTimeoutCancel: function() {
        if(this.logoutTimeout){
            clearTimeout(this.logoutTimeout);
        }
          if(this.timeLeftInterval){
              clearInterval(this.timeLeftInterval);
          }
          if(this.percentageInterval){
              clearInterval(this.percentageInterval);
          }

        this.show = false;
        this.timeoutRemaining = this.timeoutLength;
        this.percentage = 10;
      },
      doIdle: function(){
          if(this.logoutTimeout){
              clearTimeout(this.logoutTimeout);
          }
          if(this.timeLeftInterval){
              clearInterval(this.timeLeftInterval);
          }
          if(this.percentageInterval){
              clearInterval(this.percentageInterval);
          }

        this.show = true;

        this.logoutTimeout = setTimeout(() => {
          this.$router.push({name: 'logout'})
        }, this.timeoutLength * 60 * 1000);

        this.timeLeftInterval = setInterval(() => {
          this.timeoutRemaining -= 1;
        }, 60000);

        this.percentageInterval = setInterval(() => {
            if(this.timeoutRemaining < this.showWarningTime) {
                this.percentage += (this.showWarningTime * 10) / (this.showWarningTime);
            }
        }, 30000);
          // eslint-disable-next-line
          console.log("do idle end");
      },
      messageReceive: function(e){
        if (e.key != 'message') return;
        var message = JSON.parse(e.newValue);
        if (!message) return;

        if (message.command == 'idle'){
          this.doIdle();
        } else if (message.command == 'canceltimeout'){
          this.doTimeoutCancel();
        } else if(message.command == 'logout'){
          this.doLogout();
        }
      }
    },
    onIdle () {
      if(this.activateLoginTimeout){
        localStorage.setItem('message',JSON.stringify({'command': 'idle'}));
        localStorage.removeItem('message');
        this.doIdle();
      }
    },
    onActive () {
        this.onTimeoutCancel();
    },
    mounted() {
      this.load();
    }
  }
</script>
<style scoped>
  .timeout-dialog .el-dialog__body {
    text-align: center;
  }
</style>
