<template>
    <div class="section"  :id="enrollment.student_id">
        <template v-if="showEnrollment">
            <h2>[<a @click="showEnrollment = false">-</a>] <a :href="'/student/testing/' + enrollment.student_id">{{ enrollment.student_name }}</a> - {{ enrollment.uni }}
                <template v-if="enrollment.status == 'cancelled'">
                    <template v-if="enrollment.rescheduled_to_exam_id">
                        <span style="color:red;">(Cancelled - Rescheduled)</span> <a :href="'/testing/show/' + enrollment.rescheduled_to_exam_id">Rescheduled Exam</a>
                    </template>
                    <template v-else>
                        <span style="color:red;">(Cancelled - {{ enrollment.other_cancellation_reason ? enrollment.other_cancellation_reason : enrollment.cancellation_reason }})</span>
                    </template>
                </template>
            </h2>
            <a @click="enrollment.editMode = true">Edit this section</a>

            <div class="panel">
                <h4>Approved accommodations</h4>
                <template v-if="editAll || enrollment.editMode">
                    <el-checkbox-group v-model="enrollment.accommodation_ids" class="select-accommodations">
                        <template v-for="accommodation in enrollment.enrollment_accommodations">
                            <el-checkbox :key="accommodation.id" :label="accommodation.id">
                                {{ accommodation.name == "Other Testing Accommodation" ? accommodation.description : accommodation.name }}
                                {{accommodation.avoided_student_name ? ": "+accommodation.avoided_student_name : ""}}
                                {{accommodation.name != "Other Testing Accommodation" && accommodation.description ? ": "+accommodation.description : ""}}

                                <template v-if="accommodation.requires_computer_option && accommodation.computer_choice_is_frozen">
                                    {{ accommodation.computer_choice == "Other" ? "Other: "+accommodation.computer_choice_other : accommodation.computer_choice }}
                                </template>

                                <template v-if="accommodation.show_font_options">
                                    <template v-for="(font_option, idx) in accommodation.font_options">

                                        <template v-if="font_option == 'Font Size'">
                                            Font Size: {{ accommodation.font_size }} pt {{accommodation.font_type}}<template v-if="idx < accommodation.font_options.length-1">, </template>
                                        </template>
                                        <template v-else-if="font_option == 'Other'">
                                            Other: {{accommodation.font_options_other}}<template v-if="idx < accommodation.font_options.length-1">, </template>
                                        </template>
                                        <template v-else>
                                            {{ font_option }}<template v-if="idx < accommodation.font_options.length-1">, </template>
                                        </template>
                                    </template>
                                </template>
                            </el-checkbox>
                            <template v-if="accommodation.requires_computer_option">
                                <el-select style="width:125px;" size="mini" v-model="accommodation.computer_choice" :class="{ 'error-field': accommodation.showComputerChoiceError }" :key="accommodation.id+'select'">
                                    <el-option value="No Preference" label="No Preference"></el-option>
                                    <el-option value="PC" label="PC"></el-option>
                                    <el-option value="MAC" label="MAC"></el-option>
                                    <el-option value="Other" label="Other"></el-option>
                                </el-select>
                                <el-input v-if="accommodation.computer_choice == 'Other'" style="width:200px;" size="mini" v-model="accommodation.computer_choice_other" :class="{ 'error-field': accommodation.showComputerChoiceOtherError }" :key="accommodation.id+'input'"></el-input>

                            </template>
                            <br :key="accommodation.id +'br'">
                        </template>
                        <template v-for="accommodation in enrollment.available_accommodations">
                            <el-checkbox class="available_accommodations" :key="accommodation.id" :label="accommodation.id">
                                {{ accommodation.name == "Other Testing Accommodation" ? accommodation.description : accommodation.name }}
                                {{accommodation.avoided_student_name ? ": "+accommodation.avoided_student_name : ""}}
                                {{accommodation.name != "Other Testing Accommodation" && accommodation.description ? ": "+accommodation.description : ""}}
                                <template v-if="accommodation.requires_computer_option && accommodation.computer_choice_is_frozen">
                                    {{ accommodation.computer_choice == "Other" ? "Other: "+accommodation.computer_choice_other : accommodation.computer_choice }}
                                </template>

                                <template v-if="accommodation.show_font_options">
                                    <template v-for="(font_option, idx) in accommodation.font_options">

                                        <template v-if="font_option == 'Font Size'">
                                            Font Size: {{ accommodation.font_size }} pt {{accommodation.font_type}}<template v-if="idx < accommodation.font_options.length-1">, </template>
                                        </template>
                                        <template v-else-if="font_option == 'Other'">
                                            Other: {{accommodation.font_options_other}}<template v-if="idx < accommodation.font_options.length-1">, </template>
                                        </template>
                                        <template v-else>
                                            {{ font_option }}<template v-if="idx < accommodation.font_options.length-1">, </template>
                                        </template>
                                    </template>
                                </template>
                            </el-checkbox>
                            <template v-if="accommodation.requires_computer_option">
                                <el-select style="width:125px;" size="mini" v-model="accommodation.computer_choice" :class="{ 'error-field': accommodation.showComputerChoiceError }" :key="accommodation.id+'select'">
                                    <el-option value="No Preference" label="No Preference"></el-option>
                                    <el-option value="PC" label="PC"></el-option>
                                    <el-option value="MAC" label="MAC"></el-option>
                                    <el-option value="Other" label="Other"></el-option>
                                </el-select>
                                <el-input v-if="accommodation.computer_choice == 'Other'" style="width:200px;" size="mini" v-model="accommodation.computer_choice_other" :class="{ 'error-field': accommodation.showComputerChoiceOtherError }" :key="accommodation.id+'input'"></el-input>

                            </template>
                            <br :key="accommodation.id +'br'">
                        </template>
                    </el-checkbox-group>
                </template>
                <template v-else>
                    <ul class="accommodations no-chrome" style="width: 23em; float: none">
                        <li v-for="accommodation in enrollment.enrollment_accommodations" :key="accommodation.id">
                            <span class="exam-checkbox">&#9746;</span> {{ accommodation.name == "Other Testing Accommodation" ? accommodation.description : accommodation.name }}
                            {{accommodation.avoided_student_name ? ": "+accommodation.avoided_student_name : ""}}
                            {{accommodation.name != "Other Testing Accommodation" && accommodation.description ? ": "+accommodation.description : ""}}
                            <template v-if="accommodation.requires_computer_option">
                                {{ accommodation.computer_choice == "Other" ? "Other: "+accommodation.computer_choice_other : accommodation.computer_choice }}
                            </template>

                            <template v-if="accommodation.show_font_options">
                                <template v-for="(font_option, idx) in accommodation.font_options">

                                    <template v-if="font_option == 'Font Size'">
                                        Font Size: {{ accommodation.font_size }} pt {{accommodation.font_type}}<template v-if="idx < accommodation.font_options.length-1">, </template>
                                    </template>
                                    <template v-else-if="font_option == 'Other'">
                                        Other: {{accommodation.font_options_other}}<template v-if="idx < accommodation.font_options.length-1">, </template>
                                    </template>
                                    <template v-else>
                                        {{ font_option }}<template v-if="idx < accommodation.font_options.length-1">, </template>
                                    </template>
                                </template>
                            </template>
                        </li>
                        <li v-for="accommodation in enrollment.available_accommodations" class="available_accommodations" :key="accommodation.id">
                            <span class="exam-checkbox">&#9744;</span> {{ accommodation.name == "Other Testing Accommodation" ? accommodation.description : accommodation.name }}
                            {{accommodation.avoided_student_name ? ": "+accommodation.avoided_student_name : ""}}
                            {{accommodation.name != "Other Testing Accommodation" && accommodation.description ? ": "+accommodation.description : ""}}
                            <template v-if="accommodation.requires_computer_option">
                                {{ accommodation.computer_choice == "Other" ? "Other: "+accommodation.computer_choice_other : accommodation.computer_choice }}
                            </template>

                            <template v-if="accommodation.show_font_options">
                                <template v-for="(font_option, idx) in accommodation.font_options">

                                    <template v-if="font_option == 'Font Size'">
                                        Font Size: {{ accommodation.font_size }} pt {{accommodation.font_type}}<template v-if="idx < accommodation.font_options.length-1">, </template>
                                    </template>
                                    <template v-else-if="font_option == 'Other'">
                                        Other: {{accommodation.font_options_other}}<template v-if="idx < accommodation.font_options.length-1">, </template>
                                    </template>
                                    <template v-else>
                                        {{ font_option }}<template v-if="idx < accommodation.font_options.length-1">, </template>
                                    </template>
                                </template>
                            </template>
                        </li>
                    </ul>
                </template>

                <div style="clear:both; margin-top: 30px">
                    <template v-if="editAll || enrollment.editMode">
                        <el-checkbox v-model="enrollment.is_placeholder_exam" @change="onPlaceholderChange">This is a placeholder exam</el-checkbox><br>
                    </template>
                    <template v-else>
                        <template v-if="enrollment.is_placeholder_exam"><span class="exam-checkbox">&#9746;</span></template><template v-else><span class="exam-checkbox">&#9744;</span></template> This is a placeholder exam<br>
                    </template>
                </div>

                <h3>Student Exam Updates</h3>
                <template v-if="editAll || enrollment.editMode">
                    <template v-if="noExamUpdates">No Student Exam Updates<br><br></template>
                    <template v-for="(update, index) in enrollment.student_exam_updates">
                        <div style="margin-bottom: 5px;" class="exam-update" :key="index">
                            <el-select size="mini" v-model="update.student_exam_update_id" style="width:250px">
                                <el-option v-for="option in examUpdateOptions" :key="option.id" :value="option.id" :label="option.name"></el-option>
                            </el-select><br>
                            <template v-if="showExamUpdateOther(update)">
                                Other <el-input style="width: 175px;" size="mini" v-model="update.other"></el-input>
                            </template>
                            <template v-if="update.student_exam_update_id">
                                <el-input type="textarea" v-model="update.content"></el-input>
                            </template>
                            Upload file<br>
                            <el-upload class="small" drag action="/api/testing-service/add-student-exam-update/" :auto-upload="false" :file-list="update.documents" :on-remove="function(file, fileList){onExamUpdateRemove(file, fileList, update)}" :on-change="function(file, fileList){onExamUpdateChange(file, fileList, update)}" :before-remove="beforeExamUpdateRemove" name="asset_file_name" :key="index">
                                <i class="el-icon-upload"></i>
                            </el-upload>
                            <a style="float:right;" @click="deleteExamUpdate(update)">Delete this exam update</a>
                            <div style="clear:both;"></div><br><hr>
                        </div>
                    </template>
                </template>
                <template v-else>
                    <template v-if="enrollment.student_exam_updates && enrollment.student_exam_updates.length">
                        <template v-for="(update, index) in enrollment.student_exam_updates">
                            <div style="margin-bottom: 5px;" class="exam-update" :key="index">
                                {{ update.name }}<template v-if="update.other">: {{ update.other }}</template><br>
                                <span class="formatted-text">{{ update.content }}</span>
                                <template v-for="(doc, docIndex) in update.documents">
                                    <br :key="doc.id + '-br'"/>
                                    <a :href="'/api/document/download/' + doc.id" target="_blank" :key="doc.id + 'doc.id'">Update document {{ docIndex + 1 }}</a>
                                    <br :key="doc.id +'-br1'">
                                </template>
                                <div style="clear:both;"></div><br><hr>
                            </div>
                        </template>
                    </template>
                    <template v-else>
                        No student exam updates<br><br>
                    </template>
                </template>
                <template v-if="editAll || enrollment.editMode"><a @click="addExamUpdate">[+] Add exam update</a></template>
            </div>

            <div class="panel">
                Proctoring Session:
                <template v-if="(editAll || enrollment.editMode) && (examType !== 'Virtual' || (examType === 'Virtual' && virtualExamType === 'Proctored by DS'))">
                  <el-select style="width:100%" size="mini" v-model="enrollment.proctoring_session_id">
                    <el-option key="" value="" label=""></el-option>
                    <el-option v-for="session in enrollment.available_proctoring_sessions" :key="session.id" :value="session.id" :label="session.name"></el-option>
                  </el-select>
                  <br><br>
                </template>
                <template v-else>
                    <template v-if="enrollment.proctoring_session_id"><a :href="'/proctoring-session/show/' + enrollment.proctoring_session_id">{{ enrollment.proctoring_session_start_time | formatDateTimeMDYhhmmA }} - {{ enrollment.proctoring_session_end_time | formatTimehhmmA }} in {{ enrollment.location }}</a></template>
                    <template v-else>No scheduled proctoring session.</template><br><br>
                </template>

                <template v-if="enrollment.length_without_breaks">Exam length + extended time: {{ enrollment.length_without_breaks }}<br/></template>
                <template v-if="enrollment.total_break_time">Maximum rest break time: {{ enrollment.total_break_time }}<br/></template>
                <template v-if="hasMedicalRestBreaks(enrollment) && enrollment.max_medical_rest_break_time">Maximum  Medical Rest Break Time: {{ enrollment.max_medical_rest_break_time }}<br/></template>
                <template v-if="enrollment.ultimate_length">Maximum Exam Length: {{ enrollment.ultimate_length }}<br/></template><br><br>
                <div>
                    <template v-for="email in enrollment.exam_emails">
                        <template v-if="!email.is_exam_location_email">R - {{ email.created_at | formatDateTimeMDYhma }}<br :key="email.id"></template>
                        <span :key="email.id" v-if="email.is_exam_location_email" class="color-red">L - {{ email.created_at | formatDateTimeMDYhma }}<br></span>
                    </template>
                </div>

                <h3>Comments</h3>
                <template v-if="editAll || enrollment.editMode">
                    <el-input type="textarea" :rows="3" v-model="enrollment.comments"></el-input>
                </template>
                <template v-else>
                    <div style="width: 19.6em; background-color: #e4e4e4;" v-html="enrollment.comments_html"></div><br>
                </template>

                <template v-if="enrollment.status == 'cancelled'">
                    <h3>Cancellation Reason</h3>
                    <span style="color:red;">{{ enrollment.cancellation_reason }}<template v-if="enrollment.other_cancellation_reason"><br>{{ enrollment.other_cancellation_reason }}</template></span>
                </template>


                <template v-if="enrollment.reschedule_reason">
                    <h3>Reschedule Reason:</h3>
                    <span style="color: red">{{enrollment.reschedule_reason}}<br/>{{enrollment.other_reschedule_reason}}<br/></span>
                </template>

                <h4 v-if="!enrollment.opt_student_out_of_emails"><a @click="sendEmailTimeReminder()">Email This Student of Exam Start Time</a></h4>
                <h4><a :href="'/group-email/compose?student_id=' + enrollment.student_id + '&regarding_class=TestingService&regarding_id=' + enrollment.service_id">Email student (editable text)</a></h4>

                <template v-if="editAll || enrollment.editMode">
                    <el-checkbox v-model="enrollment.opt_student_out_of_emails">Opt student out of automatic emails for this exam?</el-checkbox>
                </template>
                <template v-else>
                    <template v-if="enrollment.opt_student_out_of_emails"><span class="exam-checkbox">&#9746;</span></template><template v-else><span class="exam-checkbox">&#9744;</span></template> Opt student out of automatic emails for this exam?<br>
                </template>
            </div>

            <div class="panel">
                <template v-if="editAll || enrollment.editMode">
                    Upload TARF:<br>
                    <el-upload class="small no-remove" drag action="/api/testing-service/upload-student-exam-document/" :auto-upload="false" :file-list="enrollment.tarfDocumentFileList" :on-change="onTarfDocumentChange" :before-remove="beforeTarfUpdateRemove">
                        <i class="el-icon-upload"></i>
                    </el-upload>
                </template>
                <template v-if="enrollment.tarf_document_id">Existing TARF: <a :href="'/api/document/download/' + enrollment.tarf_document_id" target="_blank">Testing Accommodation Request</a><br><br><br></template><br><br><br>

                <template v-if="editAll || enrollment.editMode">
                    Upload completed exam:<br>
                    <el-upload class="small no-remove" drag action="/api/testing-service/upload-student-exam-document/" :auto-upload="false" :file-list="enrollment.examDocumentFileList" :on-change="onExamDocumentChange" :before-remove="beforeExamUpdateRemove">
                        <i class="el-icon-upload"></i>
                    </el-upload>
                </template>
                <template v-if="enrollment.exam_document_id">Existing exam document: <a :href="'/api/document/download/' + enrollment.exam_document_id" target="_blank">Exam document</a></template>

                <template v-if="editAll || enrollment.editMode">
                    Upload delivery verification:<br>
                    <el-upload class="small no-remove" drag action="/api/testing-service/upload-student-exam-document/" :auto-upload="false" :file-list="enrollment.deliveryDocumentFileList" :on-change="onDeliveryDocumentChange" :before-remove="beforeDeliveryUpdateRemove">
                        <i class="el-icon-upload"></i>
                    </el-upload>
                </template>
                <template v-if="enrollment.delivery_verification_id">Existing delivery verification document: <a :href="'/api/document/download/' + enrollment.delivery_verification_id" target="_blank">Exam delivery verification</a></template>

                <p>
                    <template v-if="editAll || enrollment.editMode">
                        <el-form style="width:100%" :rules="rules" :model="enrollment" label-position="left" label-width="155px">
                            <el-form-item class="no-padding" prop="completion_time" label="Time exam completed:"><el-input size="mini" v-model="enrollment.completion_time"></el-input></el-form-item>
                        </el-form>
                    </template>
                    <template v-else>
                        Time exam completed:
                        {{ enrollment.completion_time | formatTimehhmmA }}
                    </template>
                </p>

                <template v-if="enrollment.status == 'cancelled'">
                    <h4><a @click="reenableExam">Re-Enable this exam for this student</a></h4>
                    <h4><a @click="showRescheduleExamSection = !showRescheduleExamSection">Reschedule this student's exam</a></h4>
                    <div v-if="showRescheduleExamSection" style="display:block">
                        Enter Rescheduled Exam Date and Time<br>
                        <el-form :rules="rules" :model="rescheduleExam" ref="rescheduleExamForm" label-position="left" label-width="90px">
                            <el-form-item label="Exam date:"><el-date-picker size="mini" v-model="rescheduleExam.date" type="date" value-format="yyyy-MM-dd"></el-date-picker></el-form-item>
                            <el-form-item label="Exam time:" prop="start_time"><el-input style="width:100px" size="mini" v-model="rescheduleExam.start_time"></el-input></el-form-item>
                        </el-form>
                        <el-button size="mini" type="primary" @click="rescheduleStudentExam" :disabled="disableStudentRescheduleButton">Confirm Reschedule</el-button>
                    </div>
                </template>
                <template v-else>
                    <h4><a @click="showCancelExamSection = !showCancelExamSection">Cancel this exam for this student</a></h4>
                    <div v-if="showCancelExamSection" style="display: block;">
                        Cancellation Reason
                        <el-select size='mini' v-model="cancellationReason"><el-option v-for="reason in cancellationReasons" :key="reason" :label="reason" :value="reason"></el-option></el-select>
                        Cancellation Comment
                        <el-input size="mini" v-model="cancellationComment"></el-input>
                        <el-button size="mini" type="primary" @click="cancelExam">Confirm Cancellation</el-button>
                    </div>
                    <h4><a @click="showRescheduleExamSection = !showRescheduleExamSection">Cancel and Reschedule this student's start date and/or time</a></h4>
                    <div v-if="showRescheduleExamSection" style="display:block">
                        Enter Rescheduled Exam Date and Time<br>
                        <el-form :rules="rules" :model="rescheduleExam" ref="rescheduleExamForm" label-position="left" label-width="90px">
                            <el-form-item label="Exam date:"><el-date-picker size="mini" v-model="rescheduleExam.date" type="date" value-format="yyyy-MM-dd"></el-date-picker></el-form-item>
                            <el-form-item label="Exam time:" prop="start_time"><el-input style="width:100px" size="mini" v-model="rescheduleExam.start_time"></el-input></el-form-item>


                            Reschedule Reason<br/>
                            <el-select size='mini' v-model="rescheduleReason" style="width: 286px"><el-option v-for="reason in cancellationReasons" :key="reason" :label="reason" :value="reason"></el-option></el-select>
                            <br/>Reschedule Comment<br/>
                            <el-input size="mini" v-model="rescheduleComment" style="width: 286px"></el-input>
                        </el-form>
                        <el-button size="mini" type="primary" @click="cancelAndRescheduleExam" :disabled="disableStudentRescheduleButton">Confirm Reschedule</el-button>
                    </div>
                </template>
                <h4><a style="color:green;" @click="showScheduleExamSection = !showScheduleExamSection">Schedule exam for this student on additional date(s)</a></h4>
                <div v-if="showScheduleExamSection" style="display:block">
                    Enter New Exam Date and Time<br>
                    <el-form v-for="(startTime, index) in scheduleExam.start_times" :rules="rules" :model="startTime" label-position="left" label-width="90px" :key="index">
                        <el-form-item label="Exam date:"><el-date-picker size="mini" v-model="startTime.date" type="date" value-format="yyyy-MM-dd"></el-date-picker></el-form-item>
                        <el-form-item label="Exam time:" prop="start_time"><el-input style="width:100px" size="mini" v-model="startTime.start_time"></el-input></el-form-item><br>
                    </el-form>
                    <el-button size="mini" type="primary" @click="scheduleAdditionalExams">Confirm New Exam(s)</el-button> &nbsp;&nbsp;&nbsp; <a @click="addAdditionalExam">[+] Add another</a>
                </div>

                <h4 v-if="enrollment.proctoring_session_id && enrollment.status == 'active' && enrollment.is_today">
                    <a @click="sendStudentLocationForExam(enrollment.id)">Email Student Location for Today's exam</a>
                </h4>

                <template v-for="service in enrollment.future_services">
                    <a :key="service.service_id" :href="'/testing/show/' + service.service_id" style="color:red">{{ service.start_time | formatDateTimeMDYhma }} exam</a><br :key="service.id">
                </template>

                <template v-if="enrollment.rescheduled_from_exam_id">
                    <br/><br/><span style="color:red">Reschedule Reason:<br/>
                     {{ enrollment.old_enrollment_reschedule_reason }}
                    <template v-if="enrollment.old_enrollment_other_reschedule_reason">
                       - {{ enrollment.old_enrollment_other_reschedule_reason }}
                    </template>
                    </span>
                </template>
            </div>
            <br clear="both">
            <div v-if="editAll || enrollment.editMode" style="float:right">
                <el-button size="mini" type="primary" @click="deleteEnrollment">Delete</el-button> Remove student from exam?
            </div>
            <el-button v-if="editAll || enrollment.editMode" size="mini" type="primary" @click="onSubmit">Save changes</el-button>
        </template>
        <template v-else>
            <a @click="showEnrollment = true">{{ enrollment.student_name }}</a>
            <br clear="both">
        </template>
        
    </div>
</template>
<script>

    import axios from 'axios'
    import moment from 'moment'

    export default {
        data() {
            var validateTime = (rule, value, callback) => {
                if (value === '') {
                  callback(new Error('Please input the time'));
                } else if(!moment(value, ["h:mm a","h:mma", "h a", "ha"], true).isValid()) {
                    callback(new Error('Enter time - HH:MM AM or HH AM'));
                } else {
                    callback();
                }
            };
            var validateCompletionTime = (rule, value, callback) => {
                if (value === '') {
                    this.enrollment.hasFormErrors = false;
                    callback();
                } else if(!moment(value, ["h:mm a","h:mma", "h a", "ha"], true).isValid()) {
                    this.enrollment.hasFormErrors = true;
                    callback(new Error('Enter time - HH:MM AM or HH AM'));
                } else {
                    this.enrollment.hasFormErrors = false;
                    callback();
                }
            };
            return {
                disableStudentRescheduleButton: false,
                showEnrollment: true,
                noExamUpdates: false,
                showRescheduleExamSection: false,
                rescheduleExam: {
                    date: null,
                    start_time: null
                },
                showCancelExamSection: false,
                cancellationReason: null,
                cancellationComment: null,
                rescheduleReason: null,
                rescheduleComment: null,
                showScheduleExamSection: false,
                scheduleExam: {
                    start_times: [
                        {date: null, start_time: null}
                    ]
                },
                rules: {
                    start_time: [
                        { validator: validateTime, trigger: 'blur' }
                    ],
                    completion_time: [
                        { validator: validateCompletionTime, trigger: 'blur' }
                    ]
                }
            }
        },
        props: ['enrollment', 'cancellationReasons', 'examUpdateOptions', 'editAll', 'examType', 'virtualExamType'],
        methods: {

            hasMedicalRestBreaks: function(enrollment) {
              if(enrollment.enrollment_accommodations.length > 0){
                var accommodationObj = enrollment.enrollment_accommodations.find(o => o.display_value === 'Medical rest breaks (20 min per hour)');
                if(accommodationObj) {
                  return true
                }
              }

              return false
            },
            onPlaceholderChange: function(){
              this.enrollment.opt_student_out_of_emails = this.enrollment.is_placeholder_exam;
            },
            onLoad: function(){
                this.$set(this.enrollment, 'accommodation_ids', []);
                if(this.enrollment.enrollment_accommodations){
                    for(var i = 0; i < this.enrollment.enrollment_accommodations.length; i++){
                        this.enrollment.accommodation_ids.push(this.enrollment.enrollment_accommodations[i].id);
                    }
                }

                this.$set(this.enrollment, 'editMode', false);
                this.$set(this.enrollment, 'hasFormErrors', false);

                if(!this.enrollment.student_exam_updates) this.$set(this.enrollment, 'student_exam_updates', []);
                if(!this.enrollment.student_exam_updates || this.enrollment.student_exam_updates.length === 0) this.noExamUpdates = true;

                for(i = 0; i < this.enrollment.student_exam_updates.length; i++){
                    var update = this.enrollment.student_exam_updates[i];
                    if(update.documents){
                        for(var j = 0; j < update.documents.length; j++){
                            this.$set(update.documents[j],'name', update.documents[j].asset_file_name);
                        }
                    } else {
                        this.$set(update, 'documents', []);
                    }
                }

                this.$set(this.enrollment, 'examDocumentFileList', []);
                this.$set(this.enrollment, 'deliveryDocumentFileList', []);
                this.$set(this.enrollment, 'tarfDocumentFileList', []);

                if(this.enrollment.exam_document_id){
                    this.enrollment.examDocumentFileList.push({'name': this.enrollment.exam_file_name, 'id': this.enrollment.exam_document_id})
                }

                if(this.enrollment.delivery_verification_id){
                    this.enrollment.deliveryDocumentFileList.push({'name': this.enrollment.delivery_verification_file_name, 'id': this.enrollment.delivery_verification_id})
                }

                if(this.enrollment.tarf_document_id){
                    this.enrollment.tarfDocumentFileList.push({'name': this.enrollment.tarf_file_name, 'id': this.enrollment.tarf_document_id})
                }


                this.$emit('ready');
            },
            onSubmit: function(){
                this.$emit('submitenrollments');
            },
            deleteEnrollment: function(){
                this.$confirm("This will permanently delete this student's testing service record for this exam. Are you sure?")
                    .then(() => {
                        axios.delete("/api/testing-service/testing-service-enrollment/", {params:{id: this.enrollment.id}})
                            .then(response => {
                                this.$emit('refreshenrollments');
                            });
                    }).catch(() => {
                        // do nothing
                    });
            },
            reenableExam: function(){
                this.$confirm('Are you sure you wish to Re-Enable this exam for this student?')
                    .then(() => {
                        axios.post("/api/testing-service/reenable-student-exam/", {id: this.enrollment.id})
                            .then(response => {
                                this.$emit('refreshenrollments');
                                this.resetProps();
                            });
                    }).catch(() => {
                        // do nothing
                    });
            },
            rescheduleStudentExam: function() {
                this.$message.closeAll();
                this.disableStudentRescheduleButton = true;
                this.$refs["rescheduleExamForm"].validate((valid) => {
                    if(valid){
                        var params = {};
                        params.id = this.enrollment.id;
                        params.start_time = moment(this.rescheduleExam.date).format('YYYY-MM-DD') + 'T' + moment(this.rescheduleExam.start_time, ["h:mm a","h:mma", "h a", "ha"], true).format('HH:mm');

                        axios.post('/api/testing-service/cancel-and-reschedule-student-exam/', params)
                            .then(response => {
                                if(response.data.success){
                                    this.$emit('refreshenrollments');
                                    this.resetProps();

                                    this.disableStudentRescheduleButton = false;
                                }
                            });
                    } else {

                        this.disableStudentRescheduleButton = false;
                        this.$message({
                            showClose: true,
                            duration: 20000,
                            type: 'error',
                            message: 'Please enter a valid date and time and submit this form again.'
                        });
                    }
                });
            },
            cancelAndRescheduleExam: function() {
                this.$message.closeAll();
                this.disableStudentRescheduleButton = true;
                this.$refs["rescheduleExamForm"].validate((valid) => {
                    if(valid){
                        var params = {};
                        params.id = this.enrollment.id;
                        params.start_time = moment(this.rescheduleExam.date).format('YYYY-MM-DD') + 'T' + moment(this.rescheduleExam.start_time, ["h:mm a","h:mma", "h a", "ha"], true).format('HH:mm');
                        params.reschedule_reason = this.rescheduleReason;
                        params.reschedule_comment = this.rescheduleComment;

                        axios.post('/api/testing-service/cancel-and-reschedule-student-exam/', params)
                            .then(response => {
                                if(response.data.success){
                                    this.$emit('refreshenrollments');
                                    this.resetProps();

                                    this.disableStudentRescheduleButton = false;
                                }
                            });
                    } else {

                        this.disableStudentRescheduleButton = false;
                        this.$message({
                            showClose: true,
                            duration: 20000,
                            type: 'error',
                            message: 'Please enter a valid date and time and submit this form again.'
                        });
                    }
                });
            },
            cancelExam: function() {
                axios.post('/api/testing-service/cancel-student-exam/', {id: this.enrollment.id, cancellation_reason: this.cancellationReason, cancellation_comment: this.cancellationComment})
                    .then(response => {
                        if(response.data.success){
                            this.$emit('refreshenrollments');
                            this.resetProps();
                        }
                    })
            },
            addAdditionalExam: function(){
                this.scheduleExam.start_times.push({date: null, start_time: null});
            },
            scheduleAdditionalExams: function(){
                var formsAreValid = true;
                var params = {};
                params.id = this.enrollment.id;
                params.start_times = [];

                for(var i = 0; i < this.scheduleExam.start_times.length; i++){
                    if(this.scheduleExam.start_times[i].date && this.scheduleExam.start_times[i].start_time){
                        if(moment(this.scheduleExam.start_times[i].start_time, ["h:mm a","h:mma", "h a", "ha"], true).isValid()){
                            params.start_times.push({start_time: moment(this.scheduleExam.start_times[i].date).format('YYYY-MM-DD') + 'T' + moment(this.scheduleExam.start_times[i].start_time, ["h:mm a","h:mma", "h a", "ha"], true).format('HH:mm')});
                        } else {
                            formsAreValid = false;
                        }
                    } else {
                        formsAreValid = false;
                    }
                }

                if(formsAreValid){
                    axios.post('/api/testing-service/schedule-additional-student-exam/', params)
                        .then(response => {
                            if(response.data.success){
                                this.$emit('refreshenrollments');
                                this.resetProps();
                            }
                        });
                } else {
                    this.$message({
                        showClose: true,
                        duration: 20000,
                        type: 'error',
                        message: 'One or more errors prevented this form from being saved. Please correct all errors and submit again.'
                    });
                }
            },
            showExamUpdateOther: function(update){
                if(this.examUpdateOptions){
                    var examUpdateObj = this.examUpdateOptions.find(o => o.name === 'Other');
                    if(examUpdateObj){
                        return update.student_exam_update_id === (examUpdateObj.id);
                    }
                    return false;
                }
            },
            beforeExamUpdateRemove: function(file, fileList){
                if(file.id) {
                    return new Promise((resolve, reject) => {
                        this.$confirm('Are you sure you want to delete this file?')
                            .then(() => resolve(true))
                            .catch(() => reject)
                        });
                }
            },
            onExamUpdateRemove: function(file, fileList, update){
                if(file.id){
                    axios.delete('/api/testing-service/delete-student-exam-update-file/', {params: {id: file.id}})
                        .then(response => {
                            if(response.data.success){
                                update.documents.splice(update.documents.indexOf(file), 1);
                            }
                        });
                } else {
                    update.documents.splice(update.documents.indexOf(file), 1);
                }
            },
            onExamUpdateChange: function(file, fileList, update){
                update.documents.push(file);
            },
            addExamUpdate: function(){
                this.enrollment.student_exam_updates.push({testing_service_enrollment_id: this.enrollment.id, documents: []});
            },
            deleteExamUpdate: function(update){
                if(update.id){
                    this.$confirm('This will permanently delete this student exam update. Are you sure?')
                        .then(() => {
                            axios.delete("/api/testing-service/delete-student-exam-update/", {params: {id: update.id}})
                                .then(response => {
                                    if(response.data.success){
                                        this.enrollment.student_exam_updates.splice(this.enrollment.student_exam_updates.indexOf(update), 1);
                                    }
                                });
                        }).catch(() => {
                            // do nothing
                        });
                } else {
                    this.enrollment.student_exam_updates.splice(this.enrollment.student_exam_updates.indexOf(update), 1);
                }
            },
            onExamDocumentChange: function(file, fileList) {
                //only allow one file to be uploaded, and replace previous file with new upload
                if(fileList.length > 1){
                    fileList[1].id = fileList[0].id;
                }
                this.enrollment.examDocumentFileList = fileList.slice(-1);
            },
            onDeliveryDocumentChange: function(file, fileList) {
                //only allow one file to be uploaded, and replace previous file with new upload
                if(fileList.length > 1){
                    fileList[1].id = fileList[0].id;
                }
                this.enrollment.deliveryDocumentFileList = fileList.slice(-1);
            },
            beforeDeliveryUpdateRemove: function(file, fileList) {
                return false;
            },
            onTarfDocumentChange: function(file, fileList) {
                //only allow one file to be uploaded, and replace previous file with new upload
                if(fileList.length > 1){
                    fileList[1].id = fileList[0].id;
                }
                this.enrollment.tarfDocumentFileList = fileList.slice(-1);
            },
            beforeTarfUpdateRemove: function(file, fileList) {
                return false;
            },
            resetProps: function(){
                this.showRescheduleExamSection = false;
                this.showCancelExamSection = false;
                this.showScheduleExamSection = false;

                this.cancellationReason = null;
                this.cancellationComment = null;
                this.rescheduleExam.date = null;
                this.rescheduleExam.start_time = null;
                this.scheduleExam.start_times = [{date: null, start_time: null}];
            },
            sendEmailTimeReminder: function(studentId, serviceId){

                var recipients = [{
                    recipient_id: this.enrollment.student_id,
                    recipient_class: "Student",
                    regarding_id: this.enrollment.service_id,
                    regarding_class: "TestingService"
                }];


                var identifier = "student_exam_time_reminder";


                this.$confirm('Are you sure you wish to notify this student of their pending exam?')
                    .then(() => {
                        axios.post('/api/group-email/send-auto-email/', {
                            identifier: identifier,
                            recipients: recipients
                        })
                            .then(response => {
                                this.$parent.refreshData();
                            })
                            .catch(error => {
                                // eslint-disable-next-line
                                console.log(error);
                            });
                    }).catch(() => {
                });
            },
            sendStudentLocationForExam: function(enrollmentId){
                window.location.href = "/group-email/compose?email_locations_for_enrollment=true&enrollment_id="+enrollmentId;
                //TODO: implemenet
                // eslint-disable-next-line
                // console.log(enrollmentId);
            }
        },
        mounted() {
            this.onLoad();
        }
    }
</script>


<style scoped>
    .schedule table tr td:last-child { display: none; }
    .section { width: 80em; padding: 1em; margin: 1em 0; clear: left; background: #dde; page-break-inside: avoid; }
    .shaded { background: #ffc; }
    .section .panel { width: 22em; float: left; padding: 0; position: relative; }
    .section .wide  { width: 42em; }
    .section .panel + .panel { margin-left: 2em; }
    .section > h3 { margin: 0.2em 0; }
    .accommodations { line-height: 1.25em; margin-top: 0pt; float: left; }
    p.accommodations { line-height: 1.75em; }
    .accommodations div { display: block; float: none; min-width: 8em; }
    .accommodations .accommodation-option { display: inline-block; min-width: 0px; }
    .el-checkbox { white-space: normal; }
    .el-checkbox__label { display: inline; }
    ul.no-chrome { list-style-type: none; margin: 1em 0 0 0; padding: 0; }
    ul.no-chrome li { margin: 0; padding: 0; display: block; }
    p.tag { position: absolute; width: 20em; height: 5em; background: #ee3; left: 10em; top: -1em; }
    .hover {
        background-color: rgb(184, 170, 192);
    }
    .no-padding .el-form-item__label {
        padding: 0px;
    }
    .form-small-275 {
        width: 275px;
    }
    #content .form-small-275 .el-form-item {
        margin: 0px;
    }
    #content .form-small-275 .el-form-item .el-form-item__label {
        font-size: inherit;
    }
    #content .form-small-275 .el-form-item .el-form-item__content {
        font-size: inherit;
    }
    .el-input-number .el-input-number__increase {
        width: 12px;
    }
    .el-input-number .el-input-number__decrease {
        width: 12px;
    }
    .length-input {
        width: 55px;
    }
    .accommodations div.no-min-width {
        min-width:0px;
        display:inline-block;
    }
    .accommodations div.el-input{
        min-width:0px;
    }
    #content .el-input-number.is-controls-right .el-input__inner {
        padding-right: 23.5px;
        padding-left: 11.5px;
    }
</style>