<template>
    <div>
        <h3>Upcoming and Current exams ({{ exams.length }})</h3>
        <el-table :data="exams" style="width: 100%" :row-class-name="setRowClass"  class="no-print"  @sort-change="onSortChange">
            <el-table-column label="Course" prop="class_name" sortable :sort-orders="['ascending', 'descending']" :min-width="15.5">
                <template slot-scope="scope">
                  <router-link :to="{ path: '/testing/show/' + scope.row.service_id + '/#'  + id}">{{ scope.row.class_name }}</router-link>
                </template>
            </el-table-column>
            <el-table-column prop="start_time" label="Date" :min-width="10.0" sortable :sort-orders="['ascending', 'descending']">
                <template slot-scope="scope">
                    {{ scope.row.start_time | formatDateMDY }}
                </template>
            </el-table-column>
            <el-table-column prop="instructor_name" label="Professor" sortable :sort-orders="['ascending', 'descending']" :min-width="10"></el-table-column>
            <el-table-column prop="start_time" label="Start" sortable :sort-orders="['ascending', 'descending']" :min-width="8.5">
                <template slot-scope="scope">
                    {{ scope.row.start_time | formatTimehma }}
                </template>
            </el-table-column>
            <el-table-column prop="end_time" label="Finish" sortable :sort-orders="['ascending', 'descending']" :min-width="8.5">
                <template slot-scope="scope">
                    {{ scope.row.end_time | formatTimehma }}
                </template>
            </el-table-column>
            <el-table-column label="Proctoring Session" sortable :sort-orders="['ascending', 'descending']" :min-width="10.0">
                <template slot-scope="scope">
                  <router-link :to="{ path: '/proctoring-session/show/' + scope.row.proctoring_session_id  }" v-if="scope.row.proctoring_session_id">{{ scope.row.location }}</router-link>
                    <template v-if="!scope.row.proctoring_session_id">N/A</template>
                </template>
            </el-table-column>
            <el-table-column :min-width="11.0">
                <template slot-scope="scope">
                    <template v-if="scope.row.status == 'active'">
                        <el-button v-if="!scope.row.showCancelFields" type="primary" size="mini" @click="scope.row.showCancelFields = true">Cancel</el-button>
                        <template v-if="scope.row.showCancelFields">
                            Cancellation reason<br>
                            <el-select size="mini" v-model="scope.row.cancellationReason">
                                <el-option v-for="reason in cancellationReasons" :key="reason" :value="reason" :label="reason"></el-option>
                            </el-select>
                            Cancellation comment<br>
                            <el-input size="mini" v-model="scope.row.cancellationComment"></el-input>
                            <el-button size="mini" type="primary" class="cancel-button" @click="onCancelExam(scope.row)">Confirm Cancellation</el-button>
                        </template>
                    </template>
                    <template v-else>
                        <el-button type="primary" size="mini" @click="onEnableExam(scope.row)">Re-enable</el-button>
                        <template v-if="scope.row.cancellation_reason != 'Other'">
                            <br/><span style="color: red">{{ scope.row.cancellation_reason }}</span>
                        </template>
                        <template v-if="scope.row.other_cancellation_reason">
                            <br/><span style="color: red">{{ scope.row.other_cancellation_reason }}</span>
                        </template>
                    </template>
                </template>
            </el-table-column>
            <el-table-column label="Exam Status" sortable :sort-orders="['ascending', 'descending']" :min-width="11.0">
                <template slot-scope="scope">
                    <template v-if="scope.row.is_placeholder_exam && scope.row.is_missing_exam_specs">
                        Placeholder<br/><br/>
                        <div class="aqua">Missing Exam Specs</div>
                    </template>
                    <template v-else>
                        <template v-if="scope.row.is_placeholder_exam">
                            Placeholder
                        </template>
                        <template v-if="scope.row.is_missing_exam_specs">
                            <div class='aqua'>Missing Exam Specs</div>
                        </template>
                    </template>
                </template>
            </el-table-column>
            <el-table-column label="Student Comments" sortable :sort-orders="['ascending', 'descending']" :min-width="40.0">
                <template slot-scope="scope">
                    <template v-for="email in scope.row.exam_emails">
                        <template v-if="!email.is_exam_location_email">R - {{ email.created_at | formatDateTimeMDYhma }}<br :key="email.id"></template>
                        <span v-if="email.is_exam_location_email" class="color-red" :key="email.id">L - {{ email.created_at | formatDateTimeMDYhma }}<br></span>
                    </template>
                    <span class="formatted-text color-red" :class="{ 'formatted-text': !scope.row.show_comments_more, 'hidden-text': scope.row.show_comments_more }">{{ scope.row.comments }}</span>
                    <a v-if="scope.row.show_comments_more" @click="scope.row.show_comments_more = false">More</a>
                    <template v-if="scope.row.cancellation_reason"><br><br>Cancellation reason: {{ scope.row.cancellation_reason == "Other" ? scope.row.other_cancellation_reason : scope.row.cancellation_reason }}</template>
                    <template v-if="scope.row.computer_use_notes && scope.row.computer_use_notes != ''">
                        <br><b>Computer Use Notes</b><br>
                        <span class="formatted-text">{{ scope.row.computer_use_notes }}</span>
                    </template>
                    <template v-if="scope.row.student_exam_updates">
                        <br/><b>Student Exam Updates</b>
                        <template v-for="(exam_update, updateIndex) in scope.row.student_exam_updates">
                            {{ exam_update.name == "Other" ? "Other: "+exam_update.other : exam_update.name }} - <br :key="exam_update.id + 'br1'"/>
                            <span class="formatted-text" :key="exam_update.id+'content'">{{ exam_update.content }}</span>
                            <template v-if="exam_update.documents">
                                <template v-for="(document, docIndex) in exam_update.documents">
                                    <br :key="document.id + 'br'"><a :href="'/api/document/download/' + document.id" target="_blank" :key="document.id + 'a'">Update document {{ docIndex + 1}}</a>
                                </template>
                            </template>

                            <template v-if="updateIndex < scope.row.student_exam_updates.length-1">
                                <br :key="exam_update.id + 'brbr1'"/><br :key="exam_update.id + 'brbr2'"/>
                            </template>
                        </template>
                    </template>
                </template>
            </el-table-column>
            <el-table-column label="Exam Comments" sortable :sort-orders="['ascending', 'descending']" :min-width="40.0">
                <template slot-scope="scope">
                    <template v-if="scope.row.listening_film_options && scope.row.listening_film_options.length"><b>Prof will:</b><br></template>
                    <template v-for="option in scope.row.listening_film_options">{{ option }}<br :key="option.id"/></template>
                    <div v-if="scope.row.is_missing_exam_specs" class="color-aqua">Missing Exam Specs</div>
                    <template v-if="scope.row.delivery_instructions.indexOf('Urgent Delivery') !== -1"><span class="color-red"><b>Urgent Delivery</b><br><span v-if="scope.row.urgent_delivery_instructions !== ''" class="formatted-text">{{ scope.row.urgent_delivery_instructions }}</span></span></template>
                    <template v-for="comment in scope.row.exam_comments">
                        <span :key="comment.id" v-if="comment.content != '' && comment.content != null" :class="{ 'color-red': comment.content.length > 80, 'formatted-text': !comment.show_exam_comment_more, 'hidden-text': comment.show_exam_comment_more }" class="formatted-text"
                              v-html="comment.content">

                            </span><br :key="comment.id+'br1'">
                        <a v-if="comment.show_exam_comment_more" @click="comment.show_exam_comment_more = false" :key="comment.id+'a'">More</a><br :key="comment.id+'br2'"></template>
                    <template v-if="scope.row.legacy_exam_comments"><br>{{ scope.row.legacy_exam_comments }}</template>
                </template>
            </el-table-column>
        </el-table>
        <div style="clear: both" class="print-only"></div>
        <table class="printable-table print-only">
            <tbody>
                <tr>
                    <th style="width: 9.4%">Course</th>
                    <th style="width: 6.1%">Date</th>
                    <th style="width: 6.1%">Professor</th>
                    <th style="width: 4.8%">Start</th>
                    <th style="width: 4.8%">Finish</th>
                    <th style="width: 6.1%">Proctoring Session</th>
                    <th style="width: 6.7%"></th>
                    <th style="width: 6.7%">Exam Status</th>
                    <th style="width: 24.5%">Student Comments</th>
                    <th style="width: 24.5%">Exam Comments</th>
                </tr>
                <tr v-for="exam in orderedExams" :key="exam.id" :class="{
                  'cancelled-exam-row': exam.status == 'cancelled',
                  'placeholder-exam-row': exam.is_placeholder_exam,
                   'virtual-ds-proctored-row': (exam.exam_type === 'Virtual' && exam.virtual_exam_type === 'Proctored by DS')}"
                >
                    <td>
                        <router-link :to="{ path: '/testing/show/' + exam.service_id + '/#'  + id}">{{ exam.class_name }}</router-link>
                    </td>
                    <td>
                        {{ exam.start_time | formatDateMDY }}
                    </td>
                    <td>
                        {{ exam.instructor_name }}
                    </td>
                    <td>
                        {{ exam.start_time | formatTimehma }}
                    </td>
                    <td>
                        {{ exam.end_time | formatTimehma }}
                    </td>
                    <td>
                        <router-link :to="{ path: '/proctoring-session/show/' + exam.proctoring_session_id  }" v-if="exam.proctoring_session_id">{{ exam.location }}</router-link>
                        <template v-if="!exam.proctoring_session_id">N/A</template>
                    </td>
                    <td>
                        <template v-if="exam.status == 'active'">
                            <el-button v-if="!exam.showCancelFields" type="primary" size="mini" @click="exam.showCancelFields = true">Cancel</el-button>
                            <template v-if="exam.showCancelFields">
                                Cancellation reason<br>
                                <el-select size="mini" v-model="exam.cancellationReason">
                                    <el-option v-for="reason in cancellationReasons" :key="reason" :value="reason" :label="reason"></el-option>
                                </el-select>
                                Cancellation comment<br>
                                <el-input size="mini" v-model="exam.cancellationComment"></el-input>
                            </template>
                        </template>
                        <template v-else>
                            <template v-if="exam.cancellation_reason != 'Other'">
                                <br/><span style="color: red">{{ exam.cancellation_reason }}</span>
                            </template>
                            <template v-if="exam.other_cancellation_reason">
                                <br/><span style="color: red">{{ exam.other_cancellation_reason }}</span>
                            </template>
                        </template>
                    </td>
                    <td>
                        <template v-if="exam.is_placeholder_exam && exam.is_missing_exam_specs">
                            Placeholder<br/><br/>
                            <div class="aqua">Missing Exam Specs</div>
                        </template>
                        <template v-else>
                            <template v-if="exam.is_placeholder_exam">
                                Placeholder
                            </template>
                            <template v-if="exam.is_missing_exam_specs">
                                <div class='aqua'>Missing Exam Specs</div>
                            </template>
                        </template>
                    </td>
                    <td>
                        <template v-for="email in exam.exam_emails">
                            <template v-if="!email.is_exam_location_email">R - {{ email.created_at | formatDateTimeMDYhma }}<br :key="email.id"></template>
                            <span v-if="email.is_exam_location_email" class="color-red" :key="email.id">L - {{ email.created_at | formatDateTimeMDYhma }}<br></span>
                        </template>
                        <span class="formatted-text color-red" :class="{ 'formatted-text': !exam.show_comments_more, 'hidden-text': exam.show_comments_more }">{{ exam.comments }}</span>
                        <a v-if="exam.show_comments_more" @click="exam.show_comments_more = false">More</a>
                        <template v-if="exam.cancellation_reason"><br><br>Cancellation reason: {{ exam.cancellation_reason == "Other" ? exam.other_cancellation_reason : exam.cancellation_reason }}</template>
                        <template v-if="exam.computer_use_notes && exam.computer_use_notes != ''">
                            <br><b>Computer Use Notes</b><br>
                            <span class="formatted-text">{{ exam.computer_use_notes }}</span>
                        </template>
                        <template v-if="exam.student_exam_updates">
                            <br/><b>Student Exam Updates</b>
                            <template v-for="(exam_update, updateIndex) in exam.student_exam_updates">
                                {{ exam_update.name == "Other" ? "Other: "+exam_update.other : exam_update.name }} - <br :key="exam_update.id + 'br1'"/>
                                <span class="formatted-text" :key="exam_update.id+'content'">{{ exam_update.content }}</span>
                                <template v-if="exam_update.documents">
                                    <template v-for="(document, docIndex) in exam_update.documents">
                                        <br :key="document.id + 'br'"><a :href="'/api/document/download/' + document.id" target="_blank" :key="document.id + 'a'">Update document {{ docIndex + 1}}</a>
                                    </template>
                                </template>

                                <template v-if="updateIndex < exam.student_exam_updates.length-1">
                                    <br :key="exam_update.id + 'brbr1'"/><br :key="exam_update.id + 'brbr2'"/>
                                </template>
                            </template>
                        </template>
                    </td>
                    <td>
                        <template v-if="exam.listening_film_options && exam.listening_film_options.length"><b>Prof will:</b><br></template>
                        <template v-for="option in exam.listening_film_options">{{ option }}<br :key="option.id"/></template>
                        <div v-if="exam.is_missing_exam_specs" class="color-aqua">Missing Exam Specs</div>
                        <template v-if="exam.delivery_instructions.indexOf('Urgent Delivery') !== -1"><span class="color-red"><b>Urgent Delivery</b><br><span v-if="exam.urgent_delivery_instructions !== ''" class="formatted-text">{{ exam.urgent_delivery_instructions }}</span></span></template>
                        <template v-for="comment in exam.exam_comments">
                        <span :key="comment.id" v-if="comment.content != '' && comment.content != null" :class="{ 'color-red': comment.content.length > 80, 'formatted-text': !comment.show_exam_comment_more, 'hidden-text': comment.show_exam_comment_more }" class="formatted-text"
                              v-html="comment.content">

                            </span><br :key="comment.id+'br1'">
                            <a v-if="comment.show_exam_comment_more" @click="comment.show_exam_comment_more = false" :key="comment.id+'a'">More</a><br :key="comment.id+'br2'"></template>
                        <template v-if="exam.legacy_exam_comments"><br>{{ exam.legacy_exam_comments }}</template>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
    import axios from 'axios'

    export default {
        data() {
            return {
                sortField: '',
                sortOrder: ''
            }
        },
        computed: {
            orderedExams: function () {
                if(this.sortField != ''){
                    return this.lodash.orderBy(this.exams, [this.sortField], [this.sortOrder]);
                } else {
                    return this.exams;
                }
            }
        },
        props: ['id', 'exams', 'isCompletedExams', 'cancellationReasons'],
        methods: {
            onSortChange: function(sorter){
                this.sortField = sorter.prop;
                this.sortOrder = sorter.order == "ascending" ? "asc" : "desc";
            },
            onShowAddExam: function(id){
                var course = this.courses.filter(course => course.id == id);
                // eslint-disable-next-line
                console.log(course);
            },
            setRowClass: function({row, rowIndex}){
                if(row.status == "cancelled"){
                    return "cancelled-exam-row"
                } else if (row.is_placeholder_exam){
                    return "placeholder-exam-row"
                } else if (row.exam_type === 'Virtual' && row.virtual_exam_type === 'Proctored by DS') {
                    return "virtual-ds-proctored-row"
                }
            },
            onCancelExam: function(exam){
                if(!exam.cancellationReason){
                    this.$alert('Please select a cancellation reason');
                } else if(exam.cancellationReason == 'Other' && !exam.cancellationComment){
                    this.$alert('Please enter the \'Other\' reason for cancellation');
                } else {
                    axios.post('/api/testing-service/cancel-student-exam/', {id: exam.id, cancellation_reason: exam.cancellationReason, cancellation_comment: exam.cancellationComment})
                        .then(response => {
                            this.$emit('refreshdata');
                        })
                        .catch(error => {
                            // eslint-disable-next-line
                          console.log(error);
                        });
                }
            },
            onEnableExam: function(exam){

                axios.post('/api/testing-service/reenable-student-exam/', {id: exam.id})
                    .then(response => {
                        this.$emit('refreshdata');
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error);
                    });
            }
        },
        mounted() {
        }
    }
</script>
<style scoped>
    .cancelled-exam{
        background-color: #fcff00;
    }
    .cancel-button{
        margin-top: 3px;
    }
</style>
