<template>
  <div>
    <template v-if="dataHasLoaded && !students.length">
      <h1>No record matched your search terms.</h1>
      <p>You may <a :href="'/search/'">search again</a>, or</p>
      <p><a :href="'/student/new/'">Create a new student, guest, or student record</a></p>
      <p><a :href="'/faculty/'">To create a new faculty record, you must first go to the Faculty module</a></p>
    </template>
    <template v-else>
      <h1>{{ formattedTitle }}</h1>
      <p><a :href="'/student/new/'">Create a new student record</a></p>
      <slot></slot>
      <el-table :default-sort="{prop: 'uni', order: 'ascending'}" :data="students" style="width: 100%" header-cell-class-name="groups-table-header" v-loading="loading" element-loading-text="Loading..." :row-class-name="getRowClassName">
        <el-table-column prop="uni" label="UNI" sortable :sort-orders="['ascending', 'descending']" width="75px"></el-table-column>
        <el-table-column prop="first" label="First" sortable :sort-orders="['ascending', 'descending']">
          <template slot-scope="scope">
            <a :href="'/student/profile/' + scope.row.id">{{ scope.row.first }}</a>
          </template>
        </el-table-column>
        <el-table-column prop="last" label="Last" sortable :sort-orders="['ascending', 'descending']">
          <template slot-scope="scope">
            <a :href="'/student/profile/' + scope.row.id">{{ scope.row.last }}</a>
          </template>
        </el-table-column>
        <el-table-column prop="preferred_name" label="Preferred Name" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
        <el-table-column prop="school" label="School" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
        <el-table-column prop="program" label="Program" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
        <el-table-column prop="coordinator" label="Coordinator" sortable :sort-orders="['ascending', 'descending']"></el-table-column>

        <el-table-column v-if="isHA" prop="approved_housing_accommodations" label="Approved Accommodations" sortable :sort-orders="['ascending', 'descending']" min-width="105px">
          <template slot-scope="scope">
            <p class="no-margin" v-for="accommodation in scope.row.approved_housing_accommodations" :key="accommodation.id">{{ accommodation }}</p>
          </template>
        </el-table-column>
        <el-table-column v-if="isHA" prop="ha_status" label="Status" sortable :sort-orders="['ascending', 'descending']"></el-table-column>

        <el-table-column v-if='!isHA' prop="ods_status" label="ODS Status" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
        <el-table-column v-if='!isHA' prop="expiration_date" label="Expiration Date" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
        <el-table-column v-if='!isHA' prop="expected_graduation" label="Expected Graduation" sortable :sort-orders="['ascending', 'descending']"></el-table-column>

        <el-table-column v-if='!isHA' prop="disabilities" label="Disabilities" sortable :sort-orders="['ascending', 'descending']">
          <template slot-scope="scope">
            <p class="no-margin" v-for="disability in scope.row.disabilities" :key="disability.id">{{ disability }}</p>
          </template>
        </el-table-column>
        <el-table-column v-if='!isHA' prop="accommodations" label="Accommodations" sortable :sort-orders="['ascending', 'descending']" min-width="105px">
          <template slot-scope="scope">
            <p class="no-margin" v-for="accommodation in scope.row.accommodations" :key="accommodation.id">{{ accommodation }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="most_recent_term" label="Most Recent Term" sortable :sort-orders="['ascending', 'descending']" width="60px"></el-table-column>
        <el-table-column prop="future_term" label="Future Term" sortable :sort-orders="['ascending', 'descending']" width="60px"></el-table-column>
        <el-table-column prop="intake_notes" label="Intake Note" sortable :sort-orders="['ascending', 'descending']" min-width="115px">
          <template slot-scope="scope">
            <p class="intake-note" v-for="note in scope.row.intake_notes" :key="note.id">
              <b>{{ note.title }}</b><br>{{ note.body }}
            </p>
          </template>
        </el-table-column>
      </el-table>
      <p><a :href="'/student/new/'">Create a new student record</a></p>
      <a :href="'/api/student/groups-download.xlsx' +this.searchParams" target="_blank">Download as CSV</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;({{ this.students.length}} student record{{ this.students.length > 1 ? 's' : '' }})
    </template>
  </div>
</template>
<script>
  import axios from 'axios'

  export default {
    metaInfo () {
      return {
        title: this.formattedTitle
      }
    },
    data() {
      return {
        coordinatorName: null,
        title: null,
        students: [],
        searchParams: "",
        loading: false,
        dataHasLoaded: false,
        isHA: false
      }
    },
    props: ['pathParams', 'urlParams'],
    computed: {
      formattedTitle: function() {
        if(this.dataHasLoaded){
          let formattedTitle = "";

          if(this.title){
            formattedTitle += this.title;
          }

          if(this.coordinatorName){
            formattedTitle += " - " + this.coordinatorName;
          }

          formattedTitle += " - " + this.students.length + " total";
          return formattedTitle;
        }

        return "";
      },
      groupParams: function() {
        let paramsString = "";

        if(this.pathParams.groups){
          paramsString += "[";

          for(let i=0; i<this.pathParams.groups.length; i++){
            paramsString += "%22"+this.pathParams.groups[i]+"%22";

            if(i < this.pathParams.groups.length-1){
              paramsString += ",";
            }
          }

          paramsString += "]";
        }

        return paramsString;
      }
    },
    methods: {
      load: function () {
        this.searchParams = location.search;
        this.loading = true;
        axios
          .get('/api/student/groups/'+location.search)
          .then(response => {
            this.coordinatorName = response.data.coordinator_name;
            this.title = response.data.title;
            this.students = response.data.rows;
            this.loading = false;
            this.dataHasLoaded = true;
          })
          .catch(error => {
            // eslint-disable-next-line
            console.log(error)
          });


        if(this.urlParams.is_ha){
          this.isHA = true;
        }
      },

      getRowClassName: function({row, rowIndex}) {
        if(row.intensive_case_management){
          return "intensive-case-management-row";
        }
      }
    },
    mounted() {
      this.load()
    }
  }
</script>
<style scoped>
  .no-margin {
    margin: 0;
  }

  .intake-note {
    margin-top: 0;
  }

  #content .el-table .cell {
    padding: 1px;
  }

  .el-table {
    font-size: 13px;
  }

  .el-table td {
    vertical-align: top;
  }

  .el-table th {
    vertical-align: top;
  }
</style>
