<template>
    <div style="position:relative" id="status_and_workflow">
        <div class="workflow_action">
            <h3>Director action required</h3>
            <student-application-process-reviews :id="id" :limited=true></student-application-process-reviews>
            <p>Coordinator recommended <span class="text-italic">{{ coordinatorRecommendation }}</span>
                <template v-if="recommendedSemesterName"><br>Through end of <span class="text-italic">{{ recommendedSemesterName }}</span></template>
                <template v-if="recommendedExpirationDate"><br>Until <span class="text-italic">{{ recommendedExpirationDate }}</span></template>
            </p>
            <p>After you've reviewed this applicant's documentation, disabilities, and accommodations, please enter the resulting decision here.</p>

            <el-form label-position="top">
                <el-form-item label="Eligibility duration:" prop="eligibility">
                    <el-select v-model="eligibility" size="small" class="workflow-input-large">
                        <el-option v-for="item in recommendations" :key="item.value" :label="item.name" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item v-if="eligibility === 'short_term'">
                    Select an End Date: <el-date-picker size="small" type="date" placeholder="Pick a date" v-model="shortTermEndDate" value-format="yyyy-MM-dd"></el-date-picker>
                </el-form-item>
                <el-form-item v-if="eligibility === 'temporary_term'">
                    Select the End Term: 
                    <el-select size="small" v-model="endOfSemesterId" class="workflow-input-small">
                        <el-option v-for="semester in nextSixSemesters" :key="semester.id" :value="semester.id" :label="semester.name"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="Director's summary of accommodations:" prop="summary">
                    <el-input v-model="summary" type="textarea" :rows="4"></el-input>
                </el-form-item>
            </el-form>

            <el-button type="primary" size="small" @click="onSubmit('Approve')">Approve</el-button>
            <el-button type="primary" size="small" @click="onSubmit('Deny')">Deny</el-button>
            <el-button type="primary" size="small" @click="onSubmit('Return to Coordinator')">Send back to coordinator</el-button>
            <student-student-workflow-system-administrator-options :id="id" :studentName="studentName" v-on:refreshstudent="refreshStudent"></student-student-workflow-system-administrator-options>
        </div>
   </div>
</template>
<script>
    import axios from 'axios'
    import moment from 'moment'



    export default {
        components: {

        },
        data() {
            return {
                eligibility: "",
                recommendations: [],
                summary: "",
                nextSixSemesters: [],
                shortTermEndDate: null,
                endOfSemesterId: null,
                coordinatorRecommendation: null,
                recommendedSemesterName: null,
                recommendedExpirationDate: null
            }
        },
        props: ['id', 'isGuest', 'studentName'],
        methods: {
            load: function () {
                axios.get("/api/student/recommendation-values/", {params:{id: this.id}})
                    .then(response => {
                        this.recommendations = response.data.recommendation_values;
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });

                axios.get("/api/simple/next-six-semesters/")
                    .then(response => {
                        this.nextSixSemesters = response.data.rows;
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });

                axios.get("/api/student/workflow-information/", {params:{id: this.id}})
                    .then(response => {
                        this.coordinatorRecommendation = response.data.coordinatorRecommendation;
                        this.recommendedSemesterName = response.data.recommendedSemesterName;
                        if(response.data.recommendedExpirationDate){
                            this.recommendedExpirationDate = moment(response.data.recommendedExpirationDate).format("MM/DD/YYYY");
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            },
            onChangeStatus: function(newStatus) {
                this.$message.closeAll();

                if(this.isGuest){
                    newStatus = 'guest_' + newStatus;
                }

                axios.put("/api/student/change-status/", {
                        id: parseInt(this.id),
                        new_status: newStatus
                    })
                    .then(response => {
                        if (response.data.success) {
                            this.$emit('refreshstudent');
                        } else {
                            this.$message({
                                showClose: true,
                                duration: 20000,
                                type: 'error',
                                message: response.data.message
                            });
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            },
            onSubmit: function(action) {
                this.$message.closeAll();
                
                var params = {};
                params.id = parseInt(this.id);
                params.action = action;
                params.eligibility = this.eligibility;
                params.body = this.summary;
                params.short_term_end_date = this.shortTermEndDate;
                params.end_of_semester_id = this.endOfSemesterId;

                if(params.eligibility == "deny"){
                  params.action = "Deny";
                }

                axios.put("/api/student/director-review-complete/", params)
                    .then(response => {
                        if (response.data.success) {
                            this.$emit('refreshstudent');
                        } else {
                            this.$message({
                                showClose: true,
                                duration: 20000,
                                type: 'error',
                                message: response.data.message
                            });
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            },
            refreshStudent: function(){
                this.$emit('refreshstudent');
            }
        },
        mounted() {
            this.load()
        }
    }
</script>
<style scoped>
    .workflow-input {
        width:300px;
    }
    .workflow-input-large {
        width:325px;
    }
    .workflow-input-small {
        width:175px;
    }
    .workflow_action .el-form-item__label {
        padding:0px;
        line-height:130%;
    }
    .bottom-margin {
        margin-bottom:3px;
    }
</style>