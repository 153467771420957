<template>
  <div>
    <h2>Student Information</h2>
    <table class="display" width="40%">
      <tbody>
      <tr v-if="student.intensive_case_management" class="intensive-case-management">
        <th>Note:</th>
        <td>Intensive case management!</td>
      </tr>
      <tr>
        <th>PID:</th>
        <td>{{ student.pid }}</td>
      </tr>
      <tr>
        <th>UNI:</th>
        <td><a :href="'mailto:' + student.uni + '@columbia.edu'">{{ student.uni }}</a></td>
      </tr>
      <tr>
        <th>Phone:</th>
        <td>{{ student.phone }}</td>
      </tr>
      <tr>
        <th>Email:</th>
        <td><a :href="'mailto:' + student.email">{{ student.email }}</a></td>
      </tr>
      <tr>
        <th>Eligibility:</th>
        <td>{{ student.eligibility_display }}</td>
      </tr>
      <tr v-if="showReferral">
        <th>Referral Source(s):</th>
        <td>
          <template v-for="(referral_source, index) in student.referral_sources_display">{{referral_source}}<br :key="index"/></template>
        </td>
      </tr>
      <tr>
        <th>ODS Registration Date:</th>
        <td>{{ registrationDate }}</td>
      </tr>
      <tr>
        <th>ODS Expiration Date:</th>
        <td>{{ expirationDate }}</td>
      </tr>
      <tr>
        <th>Coordinator:</th>
        <td>{{ student.coordinator }}</td>
      </tr>
      <tr v-for="note in student.intake_notes" :key="note.id">
        <th>
          Intake Note {{ note.note_number }}:<br>
          <div style="font-weight:normal;">Status: <b>{{ note.status_display }}</b></div>
          <a v-if="note.is_editable" :href="'/intake-notes/edit/' + note.id">Edit</a>
        </th>
        <td>
          <span class="formatted-text">{{ note.content }}</span>
          <div style="text-align:right;">Author - {{ note.author_name }} - {{ note.created_at | formatDateMDY }}</div>
          <div v-for="update in note.updates" style="text-align:right;" :key="update.id">Updated - {{ update.updated_by_name }} - {{ update.updated_at | formatDateMDY }}</div>
        </td>
      </tr>
      <tr v-for="note in student.housing_intake_notes" :key="note.id">
        <th>
          Housing Intake Note {{ note.note_number }}:<br>
          <div style="font-weight:normal;">Status: <b>{{ note.status_display }}</b></div>
          <a v-if="note.is_editable" :href="'/intake-notes/edit/' + note.id">Edit</a>
        </th>
        <td>
          <span class="formatted-text">{{ note.content }}</span>
          <div style="text-align:right;">Author - {{ note.author_name }} - {{ note.created_at | formatDateMDY }}</div>
          <div v-for="update in note.updates" style="text-align:right;" :key="update.id">Updated - {{ update.updated_by_name }} - {{ update.updated_at | formatDateMDY }}</div>
        </td>
      </tr>
      <tr v-for="note in student.case_management_notes" :key="note.id">
        <th>
          CM Note {{ note.note_number }}:<br>
          <div style="font-weight:normal;">Status: <b>{{ note.status_display }}</b></div>
          <a v-if="note.is_editable" :href="'/case-management-notes/edit/' + note.id">Edit</a>
        </th>
        <td>
          <span class="formatted-text">{{ note.content }}</span>
          <div style="text-align:right;">Author - {{ note.author_name }} - {{ note.created_at | formatDateMDY }}</div>
          <div v-for="update in note.updates" style="text-align:right;" :key="update.id">Updated - {{ update.updated_by_name }} - {{ update.updated_at | formatDateMDY }}</div>
        </td>
      </tr>
    </tbody></table>

    <p>
    <span class="help">
      Last updated {{ lastUpdated }} by {{ student.profile_last_updated_by }}
    </span>
    </p>
  </div>
</template>
<script>
  import moment from 'moment'

    export default {
        data() {
            return {
                //student: {}
            }
        },
        props: ['id', 'showReferral', 'student'],
        methods: {
            load: function () {
            }
        },
        computed: {
          registrationDate: function() {
              if(this.student.registration_date) {
                  return moment(this.student.registration_date).format("MM/DD/YYYY");
              }
              return "";
          },
          expirationDate: function() {
              if(this.student.expiration_date) {
                  return moment(this.student.expiration_date).format("MM/DD/YYYY");
              }
            return "";
          },
          lastUpdated: function() {
              if(this.student.profile_last_updated_at) {
                  return moment(this.student.profile_last_updated_at).format("MM/DD/YYYY");
              }
            return "";
          }
        },
        mounted() {
            this.load()
        }
    }
</script>
<style scoped></style>