import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=5ad94576&scoped=true&"
import script from "./Header.vue?vue&type=script&lang=js&"
export * from "./Header.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../opt/jenkins_node_modules/doris/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ad94576",
  null
  
)

export default component.exports