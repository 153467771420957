<template>
    <div style="position:relative" id="status_and_workflow">
        <div class="workflow_action">
            <h3>Eligibility has been denied</h3>
            <p>If necessary, you may move the student back to One Document Submitted status.</p>

            <el-button type="primary" size="small" @click="onSubmit('one_doc_submitted')">Allow student to reapply</el-button>
            <student-student-workflow-system-administrator-options :id="id" :studentName="studentName" v-on:refreshstudent="refreshStudent"></student-student-workflow-system-administrator-options>
        </div>
   </div>
</template>
<script>
    import axios from 'axios'

    export default {
        components: {
        },
        data() {
            return {
            }
        },
        props: ['id', 'studentName'],
        methods: {
            onSubmit(newStatus) {
                this.$message.closeAll();
                
                axios.put("/api/student/change-status/", {
                        id: parseInt(this.id),
                        new_status: newStatus
                    })
                    .then(response => {
                        if (response.data.success) {
                            this.$emit('refreshstudent');
                        } else {
                            this.$message({
                                showClose: true,
                                duration: 20000,
                                type: 'error',
                                message: response.data.message
                            });
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            },
            refreshStudent: function(){
                this.$emit('refreshstudent');
            }
        }
    }
</script>
<style scoped>
</style>