<template>
    <div>
        <h2>Contact Notes</h2>
        <slot></slot>
        <template v-if="contactNotes.length">
            <el-table :default-sort="{prop: 'created_at', order: 'descending'}" :data="contactNotes" class="contact-notes-table">
                <el-table-column prop="student_name" label="Student Name" sortable :sort-orders="['ascending', 'descending']" width="250"><template slot-scope="scope"><a :href="'/student/profile/' + scope.row.student_id">{{ scope.row.student_name }}</a></template></el-table-column>
                <el-table-column prop="subject" label="Subject/Heading" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
                <el-table-column prop="author_name" label="ODS Staff" sortable :sort-orders="['ascending', 'descending']" width="130"></el-table-column>
                <el-table-column prop="status" label="Status" sortable :sort-orders="['ascending', 'descending']" width="125"></el-table-column>
                <el-table-column prop="created_at" label="Date of contact" sortable :sort-orders="['ascending', 'descending']" width="150">
                    <template slot-scope="scope">
                       {{ scope.row.created_at  | formatDateMDY }} {{ scope.row.author_identifier }}
                    </template>
                </el-table-column>
                <el-table-column prop="num_files" label="Files" sortable :sort-orders="['ascending', 'descending']" width="78"></el-table-column>
                <el-table-column width="64"><template slot-scope="scope"><router-link :to="{ path: '/contact-notes/show/' + scope.row.id }">show</router-link></template></el-table-column>
                <el-table-column width="100"><template slot-scope="scope"><router-link :to="{ path: '/contact-notes/show-group/' + scope.row.group_id}" v-if="scope.row.group_id != null">show group</router-link></template></el-table-column>
            </el-table>
        </template>
        <template v-else>
            <p class="text-italic">No contact notes have been written {{ isCurrentYear ? 'in the current year' : 'yet' }}</p>
        </template>
    </div>
</template>
<script>

    export default {
        data() {
            return {
            }
        },
        props: ['id', 'contactNotes', 'isCurrentYear'],
        methods: {
        },
        mounted() {
        }
    }
</script>
<style scoped>
    .contact-notes-table {
        padding-top: 2px;
    }
</style>
