<template>
  <div id="overlay-container" style="display: none;">
    <div id="overlay-background" style=" z-index: 10"></div>
    <div id="logout-warning" style="z-index: 101">
      <h1>Logout Warning</h1>
      <p>Your session will end in <span id="timeout-length">5</span> minutes<br/>If you wish to remain logged
        in, please click the "Continue Session" button below</p>
      <p>
        <button type="button" onclick="continueSession()">Continue Session</button>
        <button type="button" onclick="logOut()">Logout Now</button>
      </p>
    </div>
  </div>
</template>
<script>
  import axios from 'axios'
  export default {
    data() {
      return {}
    },
    methods: {
      checkLoggedIn: function() {
        setInterval(function () {
          if (window.location.pathname != "/user/login") {

            axios.get("/user/check_for_expired_session")
              .then(response => {
                var overlayContainer = document.getElementById("overlay-container");
                overlayContainer.style.display = "none";

                if (response.data.timeout <= 5) {
                  var body = document.body,
                    html = document.documentElement;

                  var height = Math.max(body.scrollHeight, body.offsetHeight,
                    html.clientHeight, html.scrollHeight, html.offsetHeight);


                  document.getElementById("timeout-length").innerHTML = response.data.timeout;
                  overlayContainer.style.display = "block";
                  overlayContainer.style.height = height + "px";
                  window.scrollTo(0, 0);
                }

                if (response.data.expired || response.data.timeout <= 0) window.location = "/user/login";
              }).catch(error => {
              // eslint-disable-next-line
              console.log(error)
            });
          }
        }, 1000 * 60);
      },
      continueSession: function() {
        axios.get("/user/stay_logged_in")
          .then(response => {
            if(response.data.logged_in){
              var overlayContainer = document.getElementById("overlay-container");
              overlayContainer.style.display = "none";
            } else {
              this.$router.push({ path: '/user/login'});
            }
          }).catch(error => {
          // eslint-disable-next-line
          console.log(error)
        });
      }
    },
    logOut: function() {
      this.$router.push({ path: '/user/logout'});
    },
    created () {
      //commented this just to get rid of annoying errors on development
      this.checkLoggedIn();
    }
  }

</script>
<style scoped>

</style>
