<template>
  <div>
    <h2>Accommodation Letters</h2>
    <slot></slot>
    <el-table v-if="accommodationLetters.length" :data="accommodationLetters" style="width: 100%">
      <el-table-column prop="type" label="Type" sortable :sort-orders="['ascending', 'descending']" width="175"></el-table-column>
      <el-table-column prop="name" label="Name" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
      <el-table-column prop="date" label="Date" sortable :sort-orders="['ascending', 'descending']" width="100"></el-table-column>
      <el-table-column prop="status" label="Status" sortable :sort-orders="['ascending', 'descending']" width="250">
        <template slot-scope="scope">
          {{scope.row.status_display}}
        </template>
      </el-table-column>
      <el-table-column label="File" sortable :sort-orders="['ascending', 'descending']" width="275">
        <template slot-scope="scope">
          <a v-if="scope.row.filename" :href="'/api/document/download/' + scope.row.id" target="_blank">{{ scope.row.filename }}</a>
          <span v-if="!scope.row.filename" class="italics">Not yet entered</span>
        </template>
      </el-table-column>
      <el-table-column width="64"><template slot-scope="scope"><router-link  :to="'/document/edit/' + scope.row.id + '?type=AccommodationLetter&student_id=' + id">show</router-link></template></el-table-column>
      <el-table-column label="Email Prof*" sortable :sort-orders="['ascending', 'descending']" width="96">
        <template slot-scope="scope">
          <router-link v-if="scope.row.email_id" :to="'/group-email/show/' + scope.row.email_id">view email</router-link>
          <a v-if="!scope.row.email_id && scope.row.status == 'returned_with_agreement' && scope.row.filename" @click="onSendEmail(scope.row)">email</a>
        </template>
      </el-table-column>
    </el-table>
    <p>* The "email" link appears for all documents that are for classes this semester, where the status is 'returned with agreement'. When you click on this it will send an email to the relevant instructor, and you can view the sent email with the "view email" link in the "Email prof" column.</p>
  </div>
</template>
<script>
  import axios from 'axios'

  export default {
    data() {
      return {
      }
    },
    props: ['id', 'accommodationLetters'],
    methods: {
      // onDownloadFile: function(fileId){
      //     this.$alert('Placeholder for download');
      //     //window.location = "/document/download/" + fileId
      // },
      onSendEmail: function(row){
        this.$confirm("This will email the document to the relevant professor. Are you sure?")
          .then(() => {

            var recipients = [{
              recipient_id: row.course_id,
              recipient_class: "Course",
              regarding_id: row.id,
              regarding_class: "AccommodationLetter"
            }];

            axios.post('/api/group-email/send-auto-email/', {
              identifier: "accommodation_letter_to_professor",
              recipients: recipients
            })
              .then(response => {
                this.$alert("The email has been sent.");
                this.$emit('refreshletters');
              })
              .catch(error => {
                // eslint-disable-next-line
                console.log(error);
              });
          }).catch(() => {
          // do nothing
        });
      }
    },
    mounted() {
    }
  }
</script>
<style scoped>
</style>
