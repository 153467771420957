<template>
  <div>
    <h2>Files Attached to Contact Notes</h2>
    <el-table :default-sort="{prop: 'created_at', order: 'descending'}" :data="files">
      <el-table-column prop="subject" label="Subject/Heading" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
      <el-table-column prop="author_name" label="ODS Staff" width="150" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
      <el-table-column prop="created_at" label="Date of contact" width="150" sortable :sort-orders="['ascending', 'descending']">
        <template slot-scope="scope">
          {{ scope.row.created_at | formatDateMDY}}
        </template>
      </el-table-column>
      <el-table-column label="Attachment(s)" width="450" sortable :sort-orders="['ascending', 'descending']">
        <template slot-scope="scope">
          <ul>
            <li class="file-list" v-if="scope.row.asset1_file_name"><a :href="'/api/contact-note/download-file?id=' + scope.row.id + '&file_column=asset1_file_name'" target="_blank">{{ scope.row.asset1_file_name }}</a></li>
            <li class="file-list" v-if="scope.row.asset2_file_name"><a :href="'/api/contact-note/download-file?id=' + scope.row.id + '&file_column=asset2_file_name'" target="_blank">{{ scope.row.asset2_file_name }}</a></li>
            <li class="file-list" v-if="scope.row.asset3_file_name"><a :href="'/api/contact-note/download-file?id=' + scope.row.id + '&file_column=asset3_file_name'" target="_blank">{{ scope.row.asset3_file_name }}</a></li>
            <li class="file-list" v-if="scope.row.asset4_file_name"><a :href="'/api/contact-note/download-file?id=' + scope.row.id + '&file_column=asset4_file_name'" target="_blank">{{ scope.row.asset4_file_name }}</a></li>
            <li class="file-list" v-if="scope.row.asset5_file_name"><a :href="'/api/contact-note/download-file?id=' + scope.row.id + '&file_column=asset5_file_name'" target="_blank">{{ scope.row.asset5_file_name }}</a></li>
          </ul>
        </template>
      </el-table-column>
      <el-table-column width="64"><template slot-scope="scope"><router-link :to="'/contact-notes/show/'+scope.row.id">show</router-link></template></el-table-column>
    </el-table>
  </div>
</template>
<script>

  export default {
    data() {
      return {
      }
    },
    props: ['files'],
    methods: {
      onDownload: function(columnName, fileId){
        this.$alert('Placeholder for download');
        //window.location = "/contact-notes/download/" + fileId + "?column_name=" + columnName
      }
    }
  }
</script>
<style scoped>
  .file-list {
    margin-top: 0px;
    margin-bottom: 0px;
    word-break: break-all;
  }
  ul {
    margin: 0px;
  }
</style>
