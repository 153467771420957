<template>
  <div class="panel">
  <h2>Contact Information</h2>
    <table><tbody>
    <tr><th style="border-width: 0 thin thin 0;"></th><th>Address</th><th>City</th><th>State</th><th>Postal</th><th>Phone</th><th>Mobile</th></tr>
    <tr v-for="item in items"  :key="item.id">
      <th>{{ item.label }}</th>
      <td>{{ item.address }}</td>
      <td>{{ item.city_name }}</td>
      <td>{{ item.state_code }}</td>
      <td>{{ item.postal_code }}</td>
      <td>{{ item.phone_number }}</td>
      <td>{{ item.mobile_phone_number }}</td>
    </tr>
    </tbody></table>
  </div>
</template>
<script>
  import axios from 'axios'

    export default {
        data() {
            return {
                items: []
            }
        },
        props: ['id'],
        methods: {
            load: function () {
                axios
                    .get('/api/student/addresses/' + this.id)
                    .then(response => {
                        this.items = response.data.rows;
                    })
                    .catch(error => {
                      // eslint-disable-next-line
                        console.log(error)
                    });
            }
        },
        mounted() {
            this.load()
        }
    }
</script>
<style scoped>
 .display-thin {
   border-width: 0 thin thin 0;
 }
</style>