<template>
  <p v-if="date"><span class="help">Updated from SIS at {{itemDate(date)}}.</span></p>
</template>
<script>
  import axios from 'axios'
  import moment from 'moment'

    export default {
        data() {
            return {
                date: ""
            }
        },
        props: ['id'],
        methods: {
            load: function () {
                axios
                    .get('/api/student/last-sis-update-date/', {
                        params: {
                            id: this.id
                        }
                    },)
                    .then(response => {
                        this.date = response.data.date;
                    })
                    .catch(error => {
                      // eslint-disable-next-line
                        console.log(error)
                    });
            },
            itemDate: function(date) {
                if(date) {
                    return moment(date).format("M/D/YYYY");
                }
            }
        },
        mounted() {
            this.load()
        }
    }
</script>
<style scoped></style>