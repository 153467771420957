import axios from 'axios'

export const state = {
  currentUser: getSavedState('auth.currentUser'),
}

export const mutations = {
  SET_CURRENT_USER(state, newValue) {
    state.currentUser = newValue
    // console.log("SET_CURRENT_USER", newValue);
    saveState('auth.currentUser', newValue)
    setDefaultAuthHeaders(state)
  },
}

export const getters = {
  // Whether the user is currently logged in.
  loggedIn(state) {
    // console.log("~~~~");
    // console.log(state.currentUser);
    // console.log(!!state.currentUser);
    return !!state.currentUser
  },
  name(state) {
    return state.currentUser.name
  },
  identifier(state) {
    return state.currentUser.identifier
  },
  userEmail(state) {
    return state.currentUser.email
  },
  isAdmin(state) {
    return state.currentUser.isAdmin
  },
  returnUrl(state) {
    return state.currentUser.returnUrl
  }
}

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  init({ state, dispatch }) {
    setDefaultAuthHeaders(state)
    dispatch('validate')
  },

  // Logs in the current user.
  logIn({ commit, dispatch, getters }, { username, password } = {}) {
    if (getters.loggedIn) return dispatch('validate')

    return axios
        .post('/loginhandler/', { email: username, password: password })
        .then((response) => {
      if(response.data.success) {
      return dispatch('getUser')
    } else {
      return { authError: response.data.message};
    }
  }).catch((error) => {
    })
  },

  // Logs out the current user.
  logOut({ commit }) {

    commit('SET_CURRENT_USER', null)
    return axios
        .get('/logouthandler/')
        .then((response) => {
      if(response.data.success) {
      //return Promise.resolve('success');

      window.location = location.protocol + '//' + location.host+"/home";
    } else {
      return Promise.reject('logout failed');
    }
  }).catch((error) => {
    })
  },

  // Validates the current user's token and refreshes it
  // with new data from the API.
  validate({ state, dispatch }) {
    if (!state.currentUser) return Promise.resolve(null)
    dispatch('getUser');
  },
  getUser({  commit }) {
    return axios
        .get('/api/user')
        .then((response) => {
            const user = response.data.user;

            commit('SET_CURRENT_USER', user);

            if(!this.getters['auth/loggedIn']){
// eslint-disable-next-line
              console.log(window.location);
              // window.location.reload();
            }
            return user
          }).catch((error) => {
              if (error.response && error.response.status === 401) {
              commit('SET_CURRENT_USER', null)
            }
            return null
          })
  }

}

// ===
// Private helpers
// ===

function getSavedState(key) {
  let value = window.localStorage.getItem(key);
  return value == "undefined" ? null : JSON.parse(value);
}

function saveState(key, state) {
  window.localStorage.setItem(key, JSON.stringify(state))
}

function setDefaultAuthHeaders(state) {
  axios.defaults.headers.common.Authorization = state.currentUser
      ? state.currentUser.token
      : ''
}
